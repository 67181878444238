import { Component, OnInit } from '@angular/core';
import { MaterialLinksService } from 'src/app/services/materiallinks/material-links.service';
import { OptionGroupsService } from 'src/app/services/optiongroups/option-groups.service';

@Component({
  selector: 'app-material-link-editor',
  templateUrl: './material-link-editor.component.html',
  styleUrls: ['./material-link-editor.component.css']
})
export class MaterialLinkEditorComponent implements OnInit {

  constructor(
    public optionService: OptionGroupsService,
    public materialLinks: MaterialLinksService,
  ) { }

  ngOnInit() {
  }

}
