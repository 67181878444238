import { Injectable } from '@angular/core';
import { OrganizationalMaterialsService } from '../organizational/organizational-materials.service';
import { ProjectsService } from '../../projects/projects.service';
import { MaterialsService } from '../materials.service';
import { MaterialList } from '../material-list';

@Injectable({
  providedIn: 'root'
})
export class GlobalMaterialsService extends MaterialList {

  constructor(
    public projects: ProjectsService,
    public materials: MaterialsService,
  ) { 
    super(projects, materials);
  }


  public deselectAll() {
    this.selectedFiles = [];
    this.materials.setProjectCommonMaterials(this.projects.currentProject.path, this.selectedFiles);

  }

  public setMaterials(urls, materials) {
    this.allFiles = materials;
    this.selectedFiles = [];
    this.allFiles.forEach(m => {
      urls.forEach(u => {
        if (u.startsWith("/Common/") && u.indexOf(m) >= 0) {
          this.selectedFiles.push(m);
        }
      });
    });
    this.refreshTags();
    this.filter();
  }

  setProjectOrganizationMaterials() {
    this.materials.setProjectCommonMaterials(this.projects.currentProject.path, this.selectedFiles);
    // this.materials.setProjectOrganizationMaterials(this.projects.currentProject.path, this.selectedFiles);
  }

}
