import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {
  @Output() expand: EventEmitter<any> = new EventEmitter();
  @Output() showHide: EventEmitter<any> = new EventEmitter();
  @Input("data") _data:any[];
  @Input("materials") materials:boolean = false;
  @Input("checkbox") checkbox:boolean = false;
  @Input("visible") visible:boolean = false;
  @Input("title") title = "Geometry"

  nodes:any[];

  Arr = Array;


  constructor() { }

  ngOnInit() {
    this.refresh();
  }

  get data():any[] {
    return this._data;
  }

  @Input()
  set data(data: any[]) {
    this._data = data;
    this.refresh();
  }

  refresh() {
    this.nodes = [];
    if (!this.data) {
      return;
    }
    if (!this.data.length) {
      return;
    }
    this.iterate(this._data, this.nodes);
  }

  iterate(root:any[], list:any[]) {
    for (let item of root) {
      list.push(item);
      if (item.expand) {
        if (item.nodes.length > 0) {
          this.iterate(item.nodes, list);
        }
      }
    }
  }

  onLabelClick(node:any) {
    this.expand.emit(node);
    node.expand = !node.expand;
    this.refresh();
  }

  onVisibleClick(node) {
    this.showHide.emit(node)
  }

  getRange(node:any) {
    if (node.level){
      return Array(node.level).keys();
    } else {
      return [];
    }
  }

}
