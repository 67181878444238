import { Injectable } from '@angular/core';
import { SimpleKonfiguratorProfileSceneService } from '../scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { ArcRotateCamera, Vector3, FreeCamera, UniversalCamera, TransformNode, Vector2, TargetCamera, SceneLoader } from 'babylonjs';
import { EngineService } from '../engine/engine.service';
import { PostprocessService } from '../postprocess/postprocess.service';
import { HttpClient } from '@angular/common/http';
import { ProjectsService } from '../projects/projects.service';
import { Node } from 'babylonjs/node';
import { ViewFinderService } from '../viewfinder/view-finder.service';
import { SceneService } from '../scene/scene.service';
import { RenderService } from '../render/render.service';
import { Subject } from 'rxjs';

const FOV_FACTOR = 0.80;

@Injectable({
  providedIn: 'root'
})
export class CamerasService {
  scene: SimpleKonfiguratorProfileSceneService;
  list: CameraItem[] = null;
  currentCameraIndex = 0;
  currentCamera: CameraItem;
  editing: boolean = false;
  newLabel: string = "";

  public alphaMin: number = -Math.PI * 2;
  public alphaMax: number = Math.PI * 2;
  public betaMin: number = -Math.PI * 2;
  public betaMax: number = Math.PI * 2;
  public radiusMin: number = 0;
  public radiusMax: number = 100;
  public minZ: number = 0;

  public cameraAdd$: Subject<CameraItem> = new Subject();
  public cameraDelete$: Subject<CameraItem> = new Subject();
  public cameraChange$: Subject<CameraItem[]> = new Subject();
  public cameraMove$: Subject<CameraItem> = new Subject();
  public camerasImport$: Subject<CameraItem[]> = new Subject();

  constructor(
    public projects: ProjectsService,
    public postprocess: PostprocessService,
    public engine: EngineService,
    private http: HttpClient,
    public viewfinder: ViewFinderService,
  ) { }

  public init() {
    console.log("cameras init");

  }

  public serialize() {
    let idx = 0;
    let currentCamera = 0;
    this.list.forEach(c => {
      if (c == this.currentCamera) {
        currentCamera = idx
      }
      idx += 1;
    })
    return {
      list: this.list,
      current: currentCamera,
      options: {
        alphaMin: this.alphaMin,
        alphaMax: this.alphaMax,
        betaMin: this.betaMin,
        betaMax: this.betaMax,
        radiusMin: this.radiusMin,
        radiusMax: this.radiusMax,
        minZ: this.minZ
      }
    };
  }

  public deserialize(data: any) {
    console.log("cameras deserialize");
    if (!data) {
      this.list = [];
      let camera1 = new CameraItem("Default camera", 0, 0, 10, 0, 0, 0);
      let camera2 = new CameraItem("Cam2", 0, 0, 10, 0, 0, 0);
      let camera3 = new CameraItem("Cam3", 0, 0, 10, 0, 0, 0);
      let camera4 = new CameraItem("Cam4", 0, 0, 10, 0, 0, 0);
      this.list.push(camera1);
      this.list.push(camera2);
      this.list.push(camera3);
      this.list.push(camera4);
      this.save(this.list[0]);
    } else {
      try {
        let cameras = data.list;
        if (!cameras) {
          cameras = [];
        }
        let def = false;
        cameras.forEach(cam => {
          if (cam.label == "Default camera") {
            def = true;
          }
        })
        if (!def) {
          let camera1 = new CameraItem("Default camera", 0, 0, 10, 0, 0, 0);
          cameras.unshift(camera1)
        }
        let current = data.current;
        this.list = cameras;
        this.currentCamera = this.list[current];
        this.currentCameraIndex = current;
        if (data.options) {
          this.alphaMin = data.options.alphaMin;
          this.alphaMax = data.options.alphaMax;
          this.betaMin = data.options.betaMin;
          this.betaMax = data.options.betaMax;
          this.radiusMin = data.options.radiusMin;
          this.radiusMax = data.options.radiusMax;
          this.minZ = data.options.minZ;
          this.setBoundaries();
        }
      } catch (e) { }
    }
    this.sort();
    this.onDeserialize();
  }

  public setDefault() {
    this.move(this.list[this.currentCameraIndex]);
  }

  public delete(camera: CameraItem) {
    this.list = this.list.filter(_camera => _camera !== camera);
    this.sort();
    this.cameraDelete$.next(camera);
  }

  public move(camera: CameraItem) {
    if (!camera) {
      return;
    }
    
    let cam = <ArcRotateCamera>this.scene.currentScene.cameras[0];
    if (camera.radius) {
      let newCam = new ArcRotateCamera(
        cam.name,
        cam.alpha,
        cam.beta,
        cam.radius,
        cam.target,
        cam.getScene())
      newCam.target = new Vector3(camera.target_x, camera.target_y, camera.target_z);
      newCam.alpha = camera.alpha;
      newCam.beta = camera.beta;
      newCam.radius = camera.radius;
      // newCam.alpha = Math.max(this.alphaMin, Math.min(cam.alpha, this.alphaMax));
      this.setCameraDefaults(newCam);
      this.scene.currentScene.removeCamera(cam);
      this.scene.currentScene.switchActiveCamera(newCam)
      this.engine.setCamera(newCam);
    } else {
      var CoT1 = new TransformNode("root");
      let newCam = new FreeCamera(
        cam.name,
        cam.position,
        cam.getScene())
      if (camera.focal_length) {
        // 2*Math.atan2(.5*campo.width, Math.sqrt(cameraPos.dist*cameraPos.dist+cameraPos.altezza*cameraPos.altezza));
        let apertureSize = 36;
        // 2*math.atan(apertureSize/(2*fl));
        newCam.fov = 2*Math.atan(apertureSize/(2*camera.focal_length)) * FOV_FACTOR;
        console.log("FOV ", newCam.fov, camera.focal_length);
      }
      newCam.parent = CoT1 as Node;

      var CoT2 = new TransformNode("root");
      let _parent = this.scene.getRootNode()[0].parent;
      this.scene.getRootNode()[0].parent = CoT2 as Node;
      CoT2.parent = _parent;
      CoT2.name = "TransferNode";

      console.log("CAMERASPEED", newCam.speed);
      newCam.speed = newCam.speed / 10;

      this.setCameraDefaults(newCam);
      newCam.position = new Vector3(camera.pos_x, camera.pos_y, camera.pos_z);
      newCam.rotation = new Vector3(camera.alpha, camera.beta, 0);
      CoT1.scaling = new Vector3(1, 1, -1); // CAMERA
      CoT2.scaling = new Vector3(-1, 1, 1); // ROOT NODE
      console.log(newCam.position);
      console.log(newCam.rotation);

      // CoT1.rotateAround(new Vector3(0, 0, 0), new Vector3(0, 1, 0), -Math.PI/2);
      this.scene.currentScene.removeCamera(cam);
      this.scene.currentScene.switchActiveCamera(newCam)
      // newCam.onViewMatrixChangedObservable.add(data => {
      //   console.log("VIEW", data);
      //   this.save(this.currentCamera);
      // })
      this.engine.setCamera(newCam);
    }
    this.postprocess.initPipelines();
    this.postprocess.updateSSAO();
    this.postprocess.updateBloom();
    this.postprocess.updateExposure();
    this.currentCamera = camera;
    console.log(camera);
    this.viewfinder.setFromCamera(camera);
    this.cameraMove$.next(camera);
  }

  public setCameraDefaults(camera) {
    camera.minZ = this.minZ
    camera.pinchPrecision = 200 / camera.radius;
    camera.upperRadiusLimit = 5 * camera.radius;

    camera.wheelDeltaPercentage = 0.01;
    camera.pinchDeltaPercentage = 0.0003;
    camera.inertia = 0.5;
    camera.angularSensibilityX = 200;
    camera.angularSensibilityY = 200;
    camera.panningInertia = 0;
  }

  public save(camera: CameraItem) {
    if (!this.scene.currentScene.cameras[0]) {
      return
    }
    if (this.scene.currentScene.cameras[0] instanceof ArcRotateCamera) {
      let cam = <ArcRotateCamera>this.scene.currentScene.cameras[0];
      console.log("SAVE?", this.scene.currentScene.cameras[0], cam, cam.target);
      if (!cam || !cam.target) {
        return;
      }
      console.log("SAVE CAM", camera, cam);
      camera.target_x = cam.target.x;
      camera.target_y = cam.target.y;
      camera.target_z = cam.target.z;
      camera.alpha = cam.alpha;
      camera.beta = cam.beta;
      camera.radius = cam.radius;
      camera.pos_x = -cam.globalPosition.x;
      camera.pos_y = cam.globalPosition.y;
      camera.pos_z = cam.globalPosition.z;
    } else {
      // let cam = <FreeCamera>this.scene.currentScene.cameras[0];
      // console.log("SAVE?", this.scene.currentScene.cameras[0], cam, cam.target);
      // console.log("SAVE CAM", camera, cam);
      // camera.target_x = null;
      // camera.target_y = null;
      // camera.target_z = null;
      // camera.alpha = cam.rotation.x;
      // camera.beta = cam.rotation.x;
      // camera.radius = null;
      // camera.pos_x = -cam.globalPosition.x;
      // camera.pos_y = cam.globalPosition.y;
      // camera.pos_z = cam.globalPosition.z;
      // camera.fov = cam.fov;
    }
    console.log("camera.set:");
    console.log(camera);
  }

  public add() {
    let cam = <ArcRotateCamera>this.scene.currentScene.cameras[0];
    let camera = new CameraItem(
      "New camera",
      cam.alpha,
      cam.beta,
      cam.radius,
      cam.target.x,
      cam.target.y,
      cam.target.z);

    this.list.push(camera);
    this.cameraAdd$.next(camera);
  }

  public async import(projectPath: string, cb) {
    // TODO
    console.log(projectPath);
    await this.scene.save();
    setTimeout(async () => {
      let cameras = await this.http.get('/simplekonfiguratorprofile/projects/' + projectPath + "/cameras/import").toPromise();
      this.list = cameras as any;
      console.log(this.list);
      cb();
      this.camerasImport$.next(this.list);
    }, 1000)
  }

  public async importGltf(projectPath: string) {
    let cameras = await this.http.get('/simplekonfiguratorprofile/projects/' + projectPath + "/cameras/list/gltf").toPromise();
    console.log("GLTF CAMERAS",cameras);
    let meshes = await SceneLoader.ImportMeshAsync(
      '',
      '/simplekonfiguratorprofile/projects/' + projectPath + "/",
      'Cam/GLTF/' + cameras[0],
      this.engine.currentScene
    );
    let rootElement = meshes.meshes[0];
    let cam = rootElement.getChildren()[0].getChildren()[0].getChildren()[0] as FreeCamera;
    console.log("NEW SCENE", cam);
    let newCam = new FreeCamera(
      cam.name,
      cam.position,
      this.engine.getCurrentScene());
    newCam.position = cam.position;
    newCam.rotation = cam.rotation;
    newCam.fov = 0.698131680488586;
    this.scene.currentScene.removeCamera(this.scene.currentScene.cameras[0]);
    this.scene.currentScene.switchActiveCamera(newCam)
    this.engine.setCamera(newCam);
    console.log("OLD SCENE", this.engine.currentScene);
    console.log(newCam);
    
    
    
    
  }

  public nextCamera() {
    this.currentCameraIndex += 1;
    if (this.currentCameraIndex >= this.list.length) {
      this.currentCameraIndex = 0;
    }
    this.move(this.list[this.currentCameraIndex]);
    console.log(this.currentCameraIndex);
    console.log(this.list[this.currentCameraIndex]);

  }

  public previousCamera() {
    this.currentCameraIndex -= 1
    if (this.currentCameraIndex < 0) {
      this.currentCameraIndex = this.list.length - 1;
    }
    this.move(this.list[this.currentCameraIndex]);
    console.log(this.currentCameraIndex);
    console.log(this.list[this.currentCameraIndex]);
  }

  public setAlpha(min: number, max: number) {
    console.log([min, max]);

    this.alphaMin = min;
    this.alphaMax = max;
    let cam = <ArcRotateCamera>this.scene.currentScene.cameras[0];
    cam.lowerAlphaLimit = min;
    cam.upperAlphaLimit = max;
  }

  public setBeta(min: number, max: number) {
    this.betaMin = min;
    this.betaMax = max;
    let cam = <ArcRotateCamera>this.scene.currentScene.cameras[0];
    cam.lowerBetaLimit = min;
    cam.upperBetaLimit = max;
  }

  public setRadius(min: number, max: number) {
    this.radiusMin = min;
    this.radiusMax = max;
    let cam = <ArcRotateCamera>this.scene.currentScene.cameras[0];
    cam.lowerRadiusLimit = min;
    cam.upperRadiusLimit = max;
  }

  setMinZ(minZ: number) {
    console.log(minZ);

    this.minZ = minZ;
    let cam = <ArcRotateCamera>this.scene.currentScene.cameras[0];
    cam.minZ = minZ;
  }

  public setBoundaries() {
    console.log(this.scene.currentScene.cameras);

    this.setAlpha(this.alphaMin, this.alphaMax);
    this.setBeta(this.betaMin, this.betaMax);
    this.setRadius(this.radiusMin, this.radiusMax);
    this.setMinZ(this.minZ);
  }

  public renameStart() {
    this.editing = true;
    this.newLabel = this.currentCamera.label;
    this.sort();
  }

  public renameEnd() {
    this.editing = false;
    this.currentCamera.label = this.newLabel;
    this.cameraChange$.next(this.list);
  }

  public sort() {
    this.list.sort((a, b) => {
      if (a.label == "Default camera") {
        return -1;
      }
      if (b.label == "Default camera") {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }

  public onDeserialize() { }

}

export class CameraItem {
  constructor(
    public label: string = "",
    public alpha: number = 0,
    public beta: number = 0,
    public radius: number = 0,
    public target_x: number = 0,
    public target_y: number = 0,
    public target_z: number = 0,
    public pos_x: number = 0,
    public pos_y: number = 0,
    public pos_z: number = 0,
    public fov: number = 45,
    public focal_length: number = 0,
    public landscape: boolean = false,
    public render: {
      zmin: number,
      zmax: number,
    } = {
      zmin: 350,
      zmax: 550
    }
  ) { }
}