import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geometry-relations',
  templateUrl: './geometry-relations.component.html',
  styleUrls: ['./geometry-relations.component.css']
})
export class GeometryRelationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
