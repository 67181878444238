import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  private URL = null;
  public list: any;

  constructor(
    protected http: HttpClient
  ) {

  }

  public setUrl(URL) {
    this.URL = URL;
    console.log("DB setUrl", this.URL);
  }

  public async getAll() {
    if (this.URL) {
      console.log("DB getall", this.URL);
      let result = await this.http.get(this.URL).toPromise();
      this.list = result;
      console.log("DB RESULT", result);

      return result;
    }

    return null;

  }

  public async getById(id) {
    let result = await this.http.get(this.URL + "/" + id).toPromise();
    return result
  }

  public async add(item) {
    let result = await this.http.post(this.URL, item).toPromise();
    await this.refresh();
    return result;
  }

  public async edit(item) {
    let result = await this.http.put(this.URL + "/" + item.id, item).toPromise();
    await this.refresh();
    return result;
  }

  public async save() {
    let result = await this.http.put(this.URL, this.list).toPromise();
    // await this.refresh();
    return result;
  }

  public async delete(id) {
    let result = await this.http.delete(this.URL + "/" + id).toPromise();
    await this.refresh();
    return result;
  }


  public async refresh() {
    this.list = await this.getAll();
    console.log("REFRESH", this.list);
  }
}
