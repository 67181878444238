import { SimpleKonfiguratorProfileSceneService } from './simpleprofile/simple-konfigurator-profile-scene.service';
import { GeometryGroup, GroupLayer } from './geometry-groups';
import { EngineService } from '../engine/engine.service';
import { InternalTexture } from 'babylonjs';
import { IOptionGroup } from './simpleprofile/simplekonfiguratorprofileconfig.interface';

export class OptionGroups {
  public scene: SimpleKonfiguratorProfileSceneService;
  public config: any = {};

  public optionGroupList: OptionGroupList = new OptionGroupList(this);
  public optionFullLinksMap = {};
  public globalOptionLinkMap = {};



  constructor(scene: SimpleKonfiguratorProfileSceneService) {
    this.scene = scene;
  }

  public serialize() {
    return this.optionGroupList.serialize();
  }

  public deserialize(config) {
    console.log("DESERIALIZE");

    this.config = config.optionGroups;
    this.optionGroupList = new OptionGroupList(this);
    this.optionGroupList.deserialize(config.optionGroups.list);
    this.registerAll();
  }

  public removeOrphanLayers() {
    const iterator = (list: OptionGroupList) => {
      list.list.forEach(og => {
        og.geometryLayers = og.geometryLayers.filter(lay => uuidMap.indexOf(lay.uuid) >= 0);
        iterator(og.optionGroups);
      })
    }

    const uuidMap = [];
    this.scene.geometryGroups.getGroups().forEach(grp => {
      grp.layers.forEach(lay => {
        uuidMap.push(lay.uuid);
      })
    })

    iterator(this.optionGroupList);
  }

  public removeGroup(option: OptionGroup, group) {
    option.geometryGroups = option.geometryGroups.filter(_group => group != _group);
  }

  public removeLayer(option: OptionGroup, layer) {
    option.geometryLayers = option.geometryLayers.filter(_layer => layer != _layer);
  }

  public addSubGroup(option: OptionGroup) {
    option.optionGroups.add();
  }

  public duplicateSubGroup(option: OptionGroup) {
    option.parent.duplicate(option);
  }

  public registerAll() {
    this.registerFullLinksMap();
    this.registerGlobalOptionLinkMap();
  }

  public registerFullLinksMap() {
    this.optionFullLinksMap = {};

    this.registerFullLinksMapIterator(this.optionGroupList);
  }

  registerFullLinksMapIterator(optionGroupList: OptionGroupList) {
    optionGroupList.list.forEach(og => {
      this.optionFullLinksMap[og.getFullLink()] = og;
      if (og.optionGroups) {
        this.registerFullLinksMapIterator(og.optionGroups);
      }
    })
  }

  public registerGlobalOptionLinkMap() {
    this.globalOptionLinkMap = {}

    this.registerGlobalOptionLinkMapIterator(this.optionGroupList);
  }

  registerGlobalOptionLinkMapIterator(oglist: OptionGroupList) {
    oglist.list.forEach(og => {
      if (og.globalOptionLinkLabel) {
        this.globalOptionLinkMap[og.globalOptionLinkLabel] = og;
      }
      if (og.optionGroups) {
        this.registerGlobalOptionLinkMapIterator(og.optionGroups);
      }
    });
  }

  public applyVisibility() {
    function hide(ogrp: OptionGroupList) {
      ogrp.list.forEach(ogrp => {
        ogrp.geometryLayers.forEach(lay => {
          lay.v = false;
        })
        hide(ogrp.optionGroups);
      })
    }

    hide(this.optionGroupList);

    function show(oglist: OptionGroupList) {
      oglist.list.forEach(ogrp => {
        if (!ogrp.checked) {
          return;
        }
        ogrp.geometryLayers.forEach(lay => {
          if (ogrp.shadowCatchers && ogrp.shadowCatchers.indexOf(lay.uuid) >= 0) {
            return;
          }
          lay.v = true;
        })
        show(ogrp.optionGroups);
      })
    }

    show(this.optionGroupList);
  }

  public setGlobalLink(link: string, index: number) {
    console.log("SETGLOBALLINK", link, index);

    function iterator(oglist: OptionGroupList) {
      oglist.list.forEach(ogrp => {
        if (ogrp.globalOptionLinkLabel == link) {
          // if (!ogrp.checked && (ogrp.linkIndex == index)) {
          if (!ogrp.checked) {
            console.log("SELECT OGRP", link, index, ogrp.getFullLink());
            ogrp.setChecked(true);
          }
        }
        if (ogrp.optionGroups) {
          iterator(ogrp.optionGroups);
        }
      })
    }

    iterator(this.optionGroupList);

    this.scene.geometryGroups.assign();

  }

}

export class OptionGroupList {
  public list: OptionGroup[] = [];
  public parentGroup: OptionGroup;

  constructor(public parent: OptionGroups) {

  }

  public add() {
    let newItem = new OptionGroup(this);
    this.list.push(newItem);
    console.log("ADD", newItem);
  }

  public insert(newItem: OptionGroup) {
    this.list.push(newItem);
    console.log("INS", newItem);
  }

  public duplicate(option: OptionGroup) {
    const iter = (option: OptionGroup, parent: OptionGroupList) => {
      const newItem = new OptionGroup(parent);

      newItem.show = option.show;
      newItem.label = option.label
      newItem.geometryGroups = [...option.geometryGroups]
      newItem.geometryLayers = [...option.geometryLayers]
      // newItem.optionGroups = option.optionGroups
      newItem.linkLabel = option.linkLabel
      newItem.checked = option.checked
      newItem.linkIndex = option.linkIndex
      newItem.globalOptionLinkLabel = option.globalOptionLinkLabel
      if (option.shadowCatchers) {
        newItem.shadowCatchers = [...option.shadowCatchers];
      } else {
        newItem.shadowCatchers = [];
      }
      if (option.shadowCasters) {
        newItem.shadowCasters = [...option.shadowCasters];
      } else {
        newItem.shadowCasters = [];
      }
      if (option.noDisplacement) {
        newItem.noDisplacement = [...option.noDisplacement];
      } else {
        newItem.noDisplacement = [];
      }

      option.optionGroups.list.forEach(op => {
        newItem.optionGroups.insert(
          iter(op, newItem.optionGroups));
      });
      return newItem;
    }

    let newItem = iter(option, this);
    this.list.push(newItem);
    console.log("DUP", newItem);

  }


  public serialize() {
    let result = {
      list: []
    }

    let list = this.list.map(item => {
      console.log(item);

      return item.serialize();
    })

    result.list = list;

    return result;
  }

  public deserialize(config) {
    // console.log("DESERIALIZE", config);

    if (config) {
      let i = 0;
      let lastLink = "";
      this.list = config.map(option => {
        if (lastLink != option.linkLabel) {
          i = 0;
        }

        let result = OptionGroup.deserialize(this, option, i);

        i++;
        lastLink = option.linkLabel;

        return result;
      })
    }
  }

  getIndexOf(group: OptionGroup) {
    let i = 0;
    let name = "";
    for (let g of this.list) {
      if (name != g.linkLabel) {
        i = 0;
      }
      if (g == group) {
        return i;
      }
      i++;
      name = g.linkLabel;
    }
  }

}

export class OptionGroup {
  public show = true;

  constructor(
    public parent: OptionGroupList,
    public label = null,
    public geometryGroups: GeometryGroup[] = [],
    public geometryLayers: GroupLayer[] = [],
    public optionGroups: OptionGroupList = new OptionGroupList(parent.parent),
    public linkLabel: string = null,
    public checked: boolean = false,
    public linkIndex: number = null,
    public globalOptionLinkLabel: string = null,
    public shadowCatchers: string[] = [],
    public shadowCasters: string[] = [],
    public lambert: string[] = [],
    public noDisplacement: string[] = [],
  ) {
    if (!this.label) {
      this.label = "New option group"
    }
    this.optionGroups.parentGroup = this;
  }

  public static deserialize(parent: OptionGroupList, option: IOptionGroup, index) {
    console.log(option);
    let result: OptionGroup = new OptionGroup(parent);
    result.linkIndex = index;
    result.label = option.label;
    result.linkLabel = option.linkLabel;
    result.checked = option.checked;
    result.globalOptionLinkLabel = option.globalOptionLinkLabel;
    // this.geometryGroups = option.geometryGroups;
    // this.geometryLayers = option.geometryLayers;
    // this.optionGroups = option.optionGroups;
    result.geometryGroups = [];
    result.geometryLayers = [];
    result.show = option.show;
    result.shadowCatchers = option.shadowCatchers;
    result.shadowCasters = option.shadowCasters;
    result.lambert = option.lambert;
    result.noDisplacement = option.noDisplacement;
    if (option.groups) {
      option.groups.forEach(grpUuid => {
        result.geometryGroups.push(parent.parent.scene.geometryGroups.getByUUID(grpUuid) as any);
      })
    }
    if (option.layers) {
      option.layers.forEach(layerUuid => {
        result.geometryLayers.push(parent.parent.scene.geometryGroups.getByUUID(layerUuid) as any);
      })
    }
    result.optionGroups.deserialize(option.options.list);
    return result;
  }

  public serialize() {
    console.log(this);
    return {
      label: this.label,
      linkLabel: this.linkLabel,
      globalOptionLinkLabel: this.globalOptionLinkLabel,
      checked: this.checked,
      groups: this.serializeGeometryGroups(),
      layers: this.serializeGeometryLayers(),
      options: this.optionGroups.serialize(),
      show: this.show,
      linkIndex: this.linkIndex,          // HELPER FOR 2DK
      fullLinkLabel: this.getFullLinkNoEndingIndex(),  // HELPER FOR 2DK
      shadowCatchers: this.shadowCatchers,
      shadowCasters: this.shadowCasters,
      lambert: this.lambert,
      noDisplacement: this.noDisplacement,
    }
  }

  serializeGeometryGroups() {
    let result = [];

    this.geometryGroups.forEach(grp => {
      if (!grp) {
        return;
      }
      result.push(grp.uuid);
    })

    return result;
  }

  serializeGeometryLayers() {
    let result = [];

    if (this.geometryLayers) {
      this.geometryLayers.forEach(layer => {
        if (!layer) {
          return;
        }
        result.push(layer.uuid);
      })
    }

    return result;
  }

  public setChecked(checked: boolean) {

    this.parent.list.forEach(item => {
      if (item.linkLabel == this.linkLabel) {
        item.checked = false;
      }
    })
    this.checked = checked;
    this.parent.parent.scene.geometryGroups.ignoreShowHideGeometries = true;
    // this.parent.list.forEach(item => {
    //   if (item.linkLabel == this.linkLabel) {
    //     console.log("ITER", item.label);
    //     item.applyVisibility();
    //   }
    // });

    // this.applyVisibility();
    this.parent.parent.applyVisibility();
    this.parent.parent.scene.geometryGroups.ignoreShowHideGeometries = false;
    this.parent.parent.scene.geometryGroups.showHideGeometries();
  }

  applyVisibility() {
    this.disableVisibility(true);
    this.enableVisibility();
  }

  enableVisibility(applyCheckboxes = false) {
    function getParentVisibility(option: OptionGroup) {
      if (!option) {
        return true;
      }

      if (option.parent.parentGroup) {
        return getParentVisibility(option.parent.parentGroup);
      }

      return option.checked;
    }

    function iter(option: OptionGroup) {
      let parentVisibility = getParentVisibility(option.parent.parentGroup);
      console.log("ITER", parentVisibility, option.getFullLink());
      
      option.geometryGroups.forEach(group => {
        console.log(group.label, option.checked);
        group.visible = option.checked;
        group.layers.forEach(layer => {
          if (parentVisibility) {
            layer.v = option.checked;
          } else {
            layer.v = false;
          }
        })
      })
      option.geometryLayers.forEach(layer => {
        console.log(layer.label, option.checked);
        if (parentVisibility) {
          layer.v = option.checked;
        } else {
          layer.v = false;
        }
      })
      option.optionGroups.list.forEach(op => {
        if (op.checked) {
          iter(op);
        }
      })

    }

    let _option = this;
    if (this.linkLabel) {
      console.log(this.parent);

      let link = this.linkLabel;
      this.parent.list.forEach(option => {
        if (option.checked) {
          if (option.linkLabel == link) {
            if (applyCheckboxes && option != _option) {
              option.checked = false;
            }
          }
          iter(option);
        }
      })
    }
  }


  disableVisibility(applyCheckboxes = false) {
    function iter(option: OptionGroup) {
      option.geometryGroups.forEach(group => {
        console.log(group.label, option.checked, group.visible);
        group.visible = false;
        group.layers.forEach(layer => {
          layer.v = false;
        })
      })
      option.geometryLayers.forEach(layer => {
        console.log(layer.label, option.checked, layer.v);
        layer.v = false;
      })
      option.optionGroups.list.forEach(op => {
        iter(op);
      })

    }

    let _option = this;
    if (this.linkLabel) {
      console.log("X", this.label);

      let link = this.linkLabel;
      this.parent.list.forEach(option => {
        console.log("OP", option.label);

        // if (!option.checked) {
        // if (option.linkLabel == link) {
        //   if (applyCheckboxes && option != _option) {
        //     option.checked = false;
        //   }
        // }
        iter(option);
        // }
      })
      // if (this.optionGroups.list.length > 0) {
      //   this.optionGroups.list.forEach(item => {
      //     item.disableVisibility();
      //   })
      // }
    }
  }

  public delete() {
    this.parent.list = this.parent.list.filter(option => option !== this);
  }

  public getFullLinkNoEndingIndex() {
    let result = null;

    if (!this.linkLabel) {
      return "";
    }

    if (this.parent.parentGroup) {
      result = this.parent.parentGroup.getFullLink();
    }

    if (result) {
      result += "_";
    } else {
      result = "";
    }

    result += this.linkLabel

    return result;
  }

  getFullLink() {
    let result = null;

    if (!this.linkLabel) {
      return "";
    }

    if (this.parent.parentGroup) {
      result = this.parent.parentGroup.getFullLink();
    }

    if (result) {
      result += "_";
    } else {
      result = "";
    }

    result += this.linkLabel + this.parent.getIndexOf(this);

    return result;
  }

}