import { Injectable, OnDestroy } from '@angular/core';
import { Scene as BScene } from 'babylonjs';

import { GeometryNodes } from './geometry';
import { Materials } from './materials';
import { Environment } from '../backgrounds/backgrounds.service';

export interface ISceneService {
  getGeometryNodes();
  getMaterials();
  register(currentScene, auxScenes, currentProject, config): Promise<any>;

  selectMesh(mesh);
  onMeshSelect(item);
}


@Injectable({
  providedIn: 'root'
})
export class SceneService implements ISceneService {
  currentProject: any;

  geometryNodes: GeometryNodes;

  currentScene: BScene;
  currentEnvironment:Environment;
  scenes: BScene[] = null;

  public testTree: any[];


  constructor(
    public materials: Materials
  ) { 
    // this.init(); 
  }

  // GETTERS AND SETTERS
  public getGeometryNodes() { return this.geometryNodes; }
  public getMaterials() { return this.materials; }



  // IMPLEMENTATION
  public init() {
    this.geometryNodes = new GeometryNodes();
    this.currentScene = null;
    this.testTree = null;
    this.scenes = [];
  }

  public async register(currentScene, auxScenes, currentProject, config) {
    this.currentProject = currentProject;
    this.currentScene = currentScene;
    this.scenes.push(currentScene);
    this.scenes = this.scenes.concat(auxScenes);

    this.geometryNodes.setRootNodes(this.currentScene.rootNodes);

    this.materials.setMaterialsFromScene(this.scenes)

    console.log("geonodes:")
    console.log(this.geometryNodes)
    console.log("materials:")
    console.log(this.materials)

    if (config.background) {
      console.log("config.background:")
      console.log(config.background);
      this.currentEnvironment = new Environment(config.background);
    } else {
      console.log("config.noBackgound:")
      this.currentEnvironment = new Environment();
    }
  }

  public destroy() {
    this.geometryNodes = null;
    this.materials = null;
    this.testTree = null;
    console.log("left scenes:")
    console.log(this.scenes)
    for (let scene of this.scenes) {
      if (scene.dispose) {
        console.log("disposing scene:");
        try {
          scene.dispose();
        } catch (ex) {
          console.log("ERROR DISPOSING SCENE:");
          console.log(ex);
          
        }
      }

    }
    this.scenes = [];
    this.currentScene = null;
  }


  public selectMesh(mesh) {

  }

  // OVERRIDABLE
  serialize(): any {
    return {
      background: this.currentEnvironment.serialize()
    }
  }

  deserialize(data: any) {

  }

  setCurrentEnvironment(background:Environment) {
    this.currentEnvironment = background;
  }

  getCurrentEnvironment() {
    return this.currentEnvironment;
  }

  onMeshSelect(item) { }

}