import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  public currentProjectPath: string = "";
  public postfix;

  constructor(
    public http: HttpClient
  ) { }

  public setCurrentProjectPath(projectpath) {
    this.currentProjectPath = projectpath;
  }

  public setPostfix(postfix) {
    this.postfix = postfix;
  }

  public async deleteFile(file) {
    return await this.http.delete(this.getPath() + "/" + file).toPromise();
  }

  public async getFiles() {
    console.log(this.getPath());
    
    return await this.http.get(this.getPath()).toPromise();
  }

  public getPath() {
    return "/simplekonfiguratorprofile/projects/" + this.currentProjectPath + "/" + this.postfix;
  }

}
