import { Injectable } from '@angular/core';
import { KonfiguratorSettingsService } from '../../konfiguratorsettings/konfigurator-settings.service';
import { ISimpleKonfiguratorProfileConfig } from './simplekonfiguratorprofileconfig.interface';

@Injectable({
    providedIn: 'root'
})
export class PatchService {
    constructor() {

    }

    public patch_config(config: any) {
        this.patch_presets_20200618_Extend_Presets_With_URL_Alias(config.presets);
        this.patch_presets_20200916_Cameras_RenderZMinZmax(config);
    }

    public patch_presets(presets: any[]) {

    }

    patch_presets_20200618_Extend_Presets_With_URL_Alias(presets: any[]) {
        console.log(presets);
        
        if (presets) {
            presets.forEach(p => {
                if (!p.urlAlias) {
                    p.urlAlias = "";
                }
            })
        }
    }

    patch_presets_20200916_Cameras_RenderZMinZmax(config: ISimpleKonfiguratorProfileConfig) {
        if (!config.cameras) {
            return;
        }
        config.cameras.list.forEach(c => {
            if (!c.render) {
                c.render = {
                    zmin: 350,
                    zmax: 550,
                }
            }
        })
    }
}