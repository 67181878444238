import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'checkbox',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.css']
})
export class CheckBoxComponent implements OnInit {
  @Input() value: any;
  @Input() inputType: string;
  @Output("valueChange") valueChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onChange(ev) {
  }

  onClick() {
    if (this.inputType == 'checkbox') {
      this.value = !this.value;
    } else {
      this.value = true;
    }
    this.valueChange.emit(this.value);
  }

}
