import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';
import { GeometryGroups, Tags, GroupLayer, GeometryGroup } from 'src/app/services/scene/geometry-groups';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsComponent implements OnInit {
  @Input() activeTags: [];
  @Output() modified:EventEmitter<any> = new EventEmitter();
  groups:GeometryGroups;
  newTag:string = "";
  tagFilter:string = "";
  filterTags: string[];
  groupVisibility:any = {};

  constructor() { }

  ngOnInit() {
  }

  init(groups:GeometryGroups) {
    this.groups = groups;
    this.onAllClick();
  }

  onTagXClick(tag) {
    this.groups.removeTag(tag);
    this.modified.emit();
  }

  onAddClick() {
    this.groups.tags.addTag(this.newTag);
    this.newTag = "";
    this.modified.emit();
  }

  onGroupTagClick(group:GeometryGroup, tag:string) {
    group.tags.toggleTag(tag);
    this.modified.emit();
  }

  onLayerTagClick(layer:GroupLayer, tag:string) {
    layer.tags.toggleTag(tag);
    this.modified.emit();
  }

  onStrictClick(group:GeometryGroup) {
    group.strictFiltering = !group.strictFiltering;
    this.modified.emit();
  }

  onFilterClick(group:GeometryGroup) {
    group.filterByTags = !group.filterByTags;
    this.modified.emit();
  }

  onClearAllLayerClick(layer:GroupLayer) {
    layer.tags.tags = [];
    layer.tags.setters = [];
    this.modified.emit();
  }

  onSetAllLayerClick(layer:GroupLayer) {
    layer.tags.tags = [...this.groups.tags.tags];
    this.modified.emit();
  }

  onClearAllGroupClick(group:GeometryGroup) {
    group.layers.forEach(layer => {
      layer.tags.tags = [];
      layer.tags.setters = [];
    })
    this.modified.emit();
  }

  onSetAllGroupClick(group:GeometryGroup) {
    group.layers.forEach(layer => {
      layer.tags.tags = [...this.groups.tags.tags];
    });
    this.modified.emit();
  }

  onAllClick() {
    this.filterTags = [...this.groups.tags.tags];
  }

  onNoneClick() {
    this.filterTags = [];
  }

  isTagFiltered(tag:string) {
    return this.filterTags.indexOf(tag) >= 0;
  }

  removeFilterTag(tag:string) {
    this.filterTags = this.filterTags.filter(_tag => _tag !== tag);
  }

  toggleFilterTag(tag:string) {
    if (this.filterTags.indexOf(tag) >= 0) {
      this.filterTags = this.filterTags.filter(_tag => _tag !== tag);
    } else {
      this.filterTags.push(tag);
    }
  }

  onFilterTagClick(tag:string) {
    this.toggleFilterTag(tag);
  }

  isVisible(group: GroupLayer) {
    if (this.groupVisibility[group['label']] == undefined) {
      return true;
    }

    return this.groupVisibility[group['label']];
  }

  toggleVisibility(group: GroupLayer) {
    if (this.groupVisibility[group['label']] == undefined) {
      this.groupVisibility[group['label']] = false;
      return;
    }
    this.groupVisibility[group['label']] = !this.groupVisibility[group['label']]
  }


}
