import { Component, OnInit } from '@angular/core';
import { OrganizationsService } from 'src/app/services/organizations/organizations.service';

@Component({
  selector: 'app-organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.css']
})
export class OrganizationsListComponent implements OnInit {
  showAddDialog: boolean = false;
  addName: string = "";

  constructor(
    public organizations: OrganizationsService
  ) {

  }

  async ngOnInit() {
    this.organizations.list = await this.organizations.getAllOrganizationsList();
  }

  onOrganizationClick(organization) {
    this.organizations.setOrganization(organization);
  }

  onAddClick() {
    this.showAddDialog = true;
  }

  onCancelOkClick() {
    this.showAddDialog = false;
  }

  onAddOkClick() {
    this.organizations.addOrganization(this.addName);
    this.showAddDialog = false;
  }

  onCancelEdit() {
    this.organizations.setOrganization(null);
  }

  async onSaveEdit() {
    console.log("saveEdit");
    await this.organizations.updateOrganization();
    this.organizations.setOrganization(null);
  }


}
