import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-mapping-sample',
  templateUrl: './mapping-sample.component.html',
  styleUrls: ['./mapping-sample.component.scss']
})
export class MappingSampleComponent implements OnInit, OnChanges {
  @Input() materialEditTrigger: any;
  @Input() mapUrl : string = "";

  showCloseup: any;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    //Logic to refetch material after it's affected by editor
  }

  ngOnInit(): void {
    //Logic to fetch material
  }

  public uploadNewMaterial(): void {
    // const link = document.createElement('a');
    // link.href = this.downloadUrl;
    // link.download = 'file.txt';
    // link.click();
  }

  public deleteMaterial(): void {
    //endpoint to delete material
  }

  displayId(event: Event){
    this.showCloseup = event.target;
    console.log("SHOWCLOSEUP", this.showCloseup)
  }
}
