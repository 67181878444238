import { Injectable, ElementRef } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import { MediaRecorder } from '@types/dom-mediacapture-record';

declare var MediaRecorder: any;

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  public recording$: Observable<any>;
  public recordingObserver: Observer<any>;

  private recording: any;
  private playbackElement: ElementRef;

  recordingState:string = "idle";

  audioRecordings = [];

  constructor(
    private http:HttpClient
  ) {
    this.recording$ = new Observable((observer: Observer<any>) => {
      this.recordingObserver = observer;
    })
  }

  public setComment(comment) {
    // TODO
  }

  public getRecordingState() {
    return this.recordingState;
  }

  public async startRecording(projectpath, name) {
    this.recordingState = "recording";

    this.startRecordingAudio(projectpath, name);
  }

  public stopRecording() {
    this.recording.stop()
    this.recordingState = "idle";
  }

  public play() {
    
  }

  public async toggleRecording(projectpath, name) {
    console.log("toggleRecording", projectpath, name);
    
    switch (this.recordingState) {
      case "idle":
        await this.startRecording(projectpath, name);
        return "recording";
        break;
      case "recording":
        this.stopRecording();
        return "idle";
        break;
    }
  }

  private async startRecordingAudio(projectpath, name) {
    let stream = await navigator.mediaDevices.getUserMedia({audio: true});
    this.recording = new MediaRecorder(stream);
    this.recording.start();
    this.recording.ondataavailable = async e => {
      this.audioRecordings.push(e.data);
      if (this.recording.state == "inactive") {
        console.log("stopped");
        
        let blob = new Blob(this.audioRecordings, {type: 'audio/mpeg-3'});
        
        let url = URL.createObjectURL(blob);

        // this.playbackElement.nativeElement.src = url;
        // this.playbackElement.nativeElement.controls = true;
        // this.playbackElement.nativeElement.autoplay = true;
        
        let result = await this.saveRecording(projectpath, name, blob);
        this.recordingObserver.next(result);
      }
    }
  }

  private async saveRecording(projectpath, name, blob:Blob) {
    console.log("SAVE");
    
    console.log(blob);
    const formData = new FormData();
    formData.append('recording', blob);
    console.log("posting...");
    
    let result = await this.http.post("/simplekonfiguratorprofile/projects/" + projectpath + "/audio/" + name, formData).toPromise();
    console.log("posted ", result);
    
  }

  public setPlaybackElement(element: ElementRef) {
    this.playbackElement = element;
  }


}
