export function cleanPresetName(presetName) {
    return presetName.replace(' ', '_')
        .replace('', '')
        .replace('\'', '')
        .replace('`', '')
        .replace('~', '')
        .replace('!', '')
        .replace('@', '')
        .replace('#', '')
        .replace('$', '')
        .replace('^', '')
        .replace('&', '')
        .replace('*', '')
        .replace('(', '')
        .replace(')', '')
        .replace('=', '')
        .replace('[', '')
        .replace('{', '')
        .replace(']', '')
        .replace('}', '')
        .replace(';', '')
        .replace(':', '')
        .replace('|', '')
        .replace('<', '')
        .replace(',', '')
        .replace('>', '')
        .replace('/', '')
        .replace('?', '');
}
