import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { GlobalEventsActions } from "src/app/services/scene/simpleprofile/global-events-actions.service";
// import { GlobalEventsActionsService } from "../../services/global-events-actions.service";
// import { ProjectGlobalsService } from "../../services/project-globals.service";

@Injectable()
export class ProcessingService {
  public show = false;
  public content = [];
  public title = "Processing...";
  public projectPath = "";

  constructor(
    // private project: ProjectGlobalsService,
    private globals: GlobalEventsActions,
    private http: HttpClient
  ) {
    console.log("PROCESSING v1.0.101a")
  }

  public subscribe() {
    // console.log("SUBSCRIBE", this.globals.actions.configurator.processImages$)
    // this.globals.actions.configurator.processImages$.subscribe(script => {
    //   this.onProcess(script).then(() => {
    //   });
    // })

    // this.globals.actions.configurator.publish$.subscribe(script => {
    //   this.onProcess("publish").then(() => {
    //   });
    // })

    // this.globals.actions.processing.unp.genMaterials.subscribe((parameters) => {
    //   console.log("GENMATERIALS", parameters);
    //   this.processExternal(async () => {
    //     return await this.processUNP(this.projectPath, "genmaterials", parameters);
    //   })
    // })
    
    // this.globals.actions.processing.unp.genScenes.subscribe((parameters) => {
    //   console.log("GENSCENES", parameters);
    //   this.processExternal(async () => {
    //     return await this.processUNP(this.projectPath, "genscenes", parameters);
    //   })
    // })

    // this.globals.actions.processing.unp.submit.subscribe((parameters) => {
    //   console.log("SUBMIT", parameters);
    //   this.processExternal(async () => {
    //     return await this.processUNP(this.projectPath, "submitonly", parameters);
    //   })
    // })

    console.log("PROCESSING SUBSCRIBE")
    this.globals.actions.presets.postprocess.generate.subscribe(preset => {
      console.log("POSTPROCESS GENERATE", preset);
      this.processExternal(async () => {
        return await this.processUNP(this.projectPath, "genpresets", {
          presetList: [preset.label],
        });
      })
    })

    this.globals.actions.presets.postprocess.submit.subscribe(preset => {
      console.log("POSTPROCESS SUBMIT", preset);
      this.processExternal(async () => {
        return await this.processUNP(this.projectPath, "submitpresets", {
          presetList: [preset.label],
        });
      })
    })
  }

  public recomposeFbx(params: any) {
    return this.http.post(`/recompose-fbx`, params);
  }

  public async publish(projectPath: string) {
    return await this.http.get(`/konfigurator/projects/${projectPath}/publish`).toPromise();
  }

  public async process(projectPath: string, script: string) {
    return await this.http.get(`/konfigurator/projects/${projectPath}/scripts/${script}`).toPromise();
  }

  public async processUNP(projectPath: string, command: string, params: IUNPParameters) {
    return await this.http.post(`/konfigurator/projects/${projectPath}/unp/${command}`, params).toPromise();
  }

  public async status(projectPath: string, id) {
    return await this.http.get(`/konfigurator/projects/${projectPath}/status/${id}`).toPromise();
  }

  public async statusAll(projectPath: string) {
    return await this.http.get(`/konfigurator/projects/${projectPath}/status`).toPromise();
  }

  public close() {
    this.show = false;
  }

  async onProcess(script: string) {
    console.log('PROCESSING')
    this.title = "Processing..."
    this.show = true;
    let id = await this.process(this.projectPath, script);
    let i = setInterval(async () => {
      let status = await this.status(this.projectPath, id["id"]);
      if (!(status["status"])) {
        clearInterval(i);
        this.title = "DONE";
        this.content.push("FINISHED PROCESSING");
      } else {
        this.content = status["status"];
      }
    }, 2000);
  }

  async processExternal(cb) {
    console.log('PROCESSING')
    this.title = "Processing..."
    this.show = true;
    let id = await cb();
    let i = setInterval(async () => {
      let status = await this.status(this.projectPath, id["id"]);
      if (!(status["status"])) {
        clearInterval(i);
        this.title = "DONE";
        this.content.push("FINISHED PROCESSING");
      } else {
        this.content = status["status"];
      }
    }, 2000);
  }

  async onPublish() {
    console.log("PUBLISHING")
    this.show = true;
    let id = await this.publish(this.projectPath);
    console.log("ID", id);
    let i = setInterval(async () => {
      let status = await this.status(this.projectPath, id["id"]);
      console.log("STATUS", status);
      if (!(status["status"])) {
        clearInterval(i);
        this.content = [];
        this.close();
      } else {
        this.content = status["status"];
      }
    }, 2000);
  }
}


export interface IUNPCommand {
  command: string;
  parameters: IUNPParameters;
}


export interface IUNPParameters {
  materials?: string[];
  cameras?: string[];
  geometries?: string[];
  presetList?: string[];
}