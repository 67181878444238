import { Component, OnInit } from '@angular/core';
import { StyleSheetsService, IStylesheetTemplate } from 'src/app/services/stylesheets/style-sheets.service';
import Prism from 'prismjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-styling',
  templateUrl: './styling.component.html',
  styleUrls: ['./styling.component.css']
})
export class Styling implements OnInit {

  styleInput: string;
  editingEnabled: boolean;
  formattedCode: string;
  stylesheets: Array<IStylesheetTemplate>;

  constructor(private stylesheetsService: StyleSheetsService) {
    this.styleInput =
    `
.config-menu { }
.config-group > * { }
.config-title { }
.config-layer { }
.config-materials { }
.config-materialItem { }
    `;

    this.formattedCode = Prism.highlight(this.styleInput, Prism.languages.css, 'css');
  }

  ngOnInit() {
    this.stylesheetsService.getStyleSheets().subscribe(
      data => {
        if (!data) {
          return
        }
        this.stylesheets = (data as IStylesheetTemplate[]).map((template: IStylesheetTemplate) => {
          template.filename = template.filename.replace('.css', '');
          return template;
        });
      },
      err => console.log(err)
    );
  }

  applyStyles() {
    this.stylesheetsService.updateStyles(this.styleInput);

    this.formattedCode = Prism.highlight(this.styleInput, Prism.languages.css, 'css');

    this.editingEnabled = false;
  }

  applyTemplate(template: IStylesheetTemplate) {
    this.styleInput = template.content;
    this.applyStyles();
  }
}
