import { Injectable } from '@angular/core';
import { SimpleKonfiguratorProfileSceneService } from '../scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { Node } from 'babylonjs';

@Injectable({
  providedIn: 'root'
})
export class ShaderReplacementsService {
  shaderOverrides: any;
  shaderInfo: any;

  shaderMap: any;
  shaderList = [];
  shaderLinks = {};

  public test = "test";
  parentService: SimpleKonfiguratorProfileSceneService;

  nodeMap: any;

  constructor() { }

  public init(shaderOverrides, shaderInfo, shaderLinks, parentService) {
    console.log("init shaderOverrides:");

    this.parentService = parentService;
    this.shaderOverrides = shaderOverrides.shaderOverrides;
    this.shaderInfo = shaderInfo.SceneShaderData;
    this.shaderMap = {};

    if (!this.shaderInfo) {
      return;
    }

    for (let s of this.shaderInfo) {

      if (this.shaderMap[s.shader]) {
        this.shaderMap[s.shader].objects.push(s.object);
      } else {
        this.shaderMap[s.shader] = {
          primaryShaders: [],
          targetShaders: [],
          objects: [s.object],
          label: s.shader
        }
      }
    }

    for (let s of this.shaderOverrides) {
      if (!this.shaderMap[s.originShader]) {
        continue;
      }

      if (this.shaderMap[s.originShader].targetShaders.indexOf(s.targetShader) < 0) {
        this.shaderMap[s.originShader].targetShaders.push(s.targetShader);
      }
      if (this.shaderMap[s.originShader].primaryShaders.indexOf(s.primaryShader) < 0) {
        this.shaderMap[s.originShader].primaryShaders.push(s.primaryShader);
      }

    }

    this.shaderList = [];
    for (let s in this.shaderMap) {
      let o = this.shaderMap[s]
      this.shaderList.push(o);
    }

    this.shaderLinks = {};
    for (let sl of shaderLinks.list) {
      console.log(sl);
      
      for (let item in sl.layers) {
        this.shaderLinks[item] = sl;
      }
    }

    this.registerNodeMap(this.parentService.getRootNode()[0]);
    console.log("init shaderOverrides done");

  }

  public apply(m, material) {
    console.log(m);

    for (let o of m.objects) {
      let n = this.nodeMap[o];
      let m = this.parentService.materials.getMaterialById(material);
      for (let mesh of n.getChildMeshes()) {
        console.log(n);
        console.log(m);


        mesh.material = m.material;
      }
    }
  }

  public autoApply() {

  }

  registerNodeMap(n: Node) {
    this.nodeMap = {};
    this._registerNodeMap(n, "");
  }

  _registerNodeMap(n: Node, prefix: string) {
    if (n) {
      let name = prefix + "|" + n.name;
      this.nodeMap[name] = n;
      if (n.getChildren) {

        if (n.getChildren()) {
          for (let c of n.getChildren()) {
            this._registerNodeMap(c, name);
          }
        }
      }
    }
  }


}
