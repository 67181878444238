import { Injectable } from '@angular/core';
import { DbService } from '../DB/db.service';
import { HttpClient } from '@angular/common/http';
import { SimpleKonfiguratorProfileSceneService } from '../scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { GeometryGroup } from '../scene/geometry-groups';
import { MaterialList } from '../scene/materials';
import { OptionGroup, OptionGroupList } from '../scene/option-groups';


@Injectable({
  providedIn: 'root'
})
export class PresetsService extends DbService {

  public currentPreset = null;

  constructor(
    protected http: HttpClient,
    protected service: SimpleKonfiguratorProfileSceneService,
  ) {
    super(http);
    this.service.materialChange$.subscribe(data => {
      this.saveData();
    });
    this.service.save$.subscribe(data => {
      // this.save();
    })
  }

  public async refresh() {
    let id = null;
    if (this.currentPreset) {
      id = this.currentPreset.id
      console.log(id);
    }
    const result = await super.refresh();
    if (id != null) {
      this.currentPreset = this.list.filter(p => p.id == id)[0];
      console.log(this.currentPreset);
    }
    return result;
  }

  public async saveData() {
    console.log("PRESETS SAVE", this.currentPreset);

    if (this.currentPreset) {
      let preset = this.currentPreset;
      this.savePresetData(preset);
      this.saveOptionGroups(preset);
      // await this.savePresetData(preset);
      await this.save();
    }
  }

  public savePresetData(preset = null) {
    if (!preset) {
      preset = this.currentPreset;
    }
    console.log(preset);
    if (!preset) {
      return;
    }
    let gg = this.service.geometryGroups.getGroups();
    let data = preset.data;
    for (let g of gg) {
      for (let l of g.getLayers()) {
        // console.log(l.getFullName());
        // console.log(l.getCurrentMaterial(), l.v);
        if (l.materials.getMaterials().length)
          if (!l.getCurrentMaterial()) {
            l.setCurrentMaterial(l.materials.getMaterials()[0]);
          }
        if (!l.getPrimaryMaterial()) {
          l.setPrimaryMaterial(l.materials.getMaterials()[0]);
        }
        if (l.getCurrentMaterial() && l.getCurrentMaterial().material) {
          console.log(l.getFullName(), l.getCurrentMaterial().material.name, l.v);

          data[l.getFullName()] = {
            currentMaterial: l.getCurrentMaterial().material.name,
            visible: l.v
          }
        }
      }
    }
    preset.data = data;
    // preset.optionGroups = this.service.optionGroups.serialize();
    // await this.edit(preset);
  }

  public saveOptionGroups(preset = null) {
    let data = {};

    function iterator(grps: OptionGroupList) {

      grps.list.forEach(grp => {
        data[grp.getFullLink()] = {
          checked: grp.checked
        }

        if (grp.optionGroups.list) {
          iterator(grp.optionGroups);
        }
      })

    }

    if (!preset) {
      preset = this.currentPreset;
    }


    let og = this.service.optionGroups.optionGroupList;

    iterator(og);

    preset.optionGroups = data;
  }

  public async applyPresetData(preset = null) {
    // preset = await this.getById(preset.id);
    console.log(preset);
    this.fixMaterials();
    let DATA = preset.data;
    let gg = this.service.geometryGroups.getGroups();
    for (let g of gg) {
      for (let l of g.getLayers()) {
        // console.log(l);
        // console.log(l.getFullName());
        let data = DATA[l.getFullName()];
        if (data) {
          let material = this.service.materials.getMaterialById(
            data.currentMaterial);
          // console.log(data);
          // console.log(material);
          // console.log(data.currentMaterial);


          if (!this.service.config.options.showAdvancedMenu) {
            l.v = data.visible
          } else {
            l.v = false;
          }
          l.setCurrentMaterial(material, false);
        }
      }
    }
    if (this.service.config.options.showAdvancedMenu && preset.optionGroups) {
      this.applyOptionGroups(preset);
    }

    this.service.geometryGroups.assign();
  }

  applyOptionGroups(preset) {
    const iterator = (oglist: OptionGroupList) => {
      oglist.list.forEach(grp => {
        grp.checked = false;
        if (preset.optionGroups[grp.getFullLink()] && preset.optionGroups[grp.getFullLink()].checked) {
          console.log("SET OGRP", grp.getFullLink());
          grp.checked = true;
          if (grp.optionGroups) {
            iterator(grp.optionGroups);
          }
        }
      })
    }
    console.log(preset.optionGroups);

    iterator(this.service.optionGroups.optionGroupList);
    this.service.optionGroups.applyVisibility();
    this.service.geometryGroups.ignoreShowHideGeometries = false;
    this.service.geometryGroups.showHideGeometries();
  }

  public select(preset) {
    this.currentPreset = preset;
  }

  public async selectAll() {
    this.list.forEach(preset => {
      console.log(preset);

      preset.selected = true;
      // Object.values(preset.camera).forEach(cam => {
      //   (cam as any).selected = true;
      // })
    })
    await this.save();
  }

  public async deselectAll() {
    this.list.forEach(preset => {
      preset.selected = false;
      // Object.values(preset.camera).forEach(cam => {
      //   (cam as any).selected = false;
      // })
    })
    await this.save();
  }

  public init() {
    if (this.list && this.list.length > 0 && !this.currentPreset) {
      this.select(this.list[0]);
      console.log("APPLYING INITIAL PRESET");

      this.applyPresetData(this.currentPreset);
    }
  }

  public async save() {
    this.fixMaterials();
    this.savePresetData();
    let result = await super.save();
    return result;
  }

  public fixMaterials() {
    console.log("FIXMATERIALS");
    let map = {};
    this.service.geometryGroups.groups.forEach(grp => {
      // map[grp.getFullName()] = grp;
      grp.layers.forEach(lay => {
        map[lay.getFullName()] = lay;
      })
    })
    for (let preset of this.list) {
      let newData = {}
      for (let path in preset['data']) {
        let item = preset['data'][path]
        let element = map[path];
        if (!element) {
          continue;
        }
        // remove nonexisting geometry
        newData[path] = item;
        let materials: MaterialList = element['materials'];
        // console.log("MATERIALS", materials);

        if (materials.getByName(item['currentMaterial']) == null) {
          if (materials.getMaterials().length > 0) {
            item['currentMaterial'] = materials.getMaterials()[0].label;
          } else {
            item['currentMaterial'] = null;
          }
        }
      }
      // console.log("NEWDATA", newData);

      preset['data'] = newData;
    }
  }
}
