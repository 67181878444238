import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackgroundsService {
  // private sizes = ["128", "256", "512"];
  public sizes = ["128"];

  public currentSkyboxes: Skybox[] = [
    new Skybox(0, "---", "https://assets.babylonjs.com/environments/environmentSpecular.env", 0),
    new Skybox(1, "Studio", "https://assets.babylonjs.com/environments/studio.env", 0.3),
    new Skybox(2, "backgroundSkybox", "https://assets.babylonjs.com/environments/backgroundSkybox.dds", 0.3),
    new Skybox(3, "DefaulSpecular", "https://assets.babylonjs.com/environments/environmentSpecular.env", 0.12),
    new Skybox(4, "environment2", "/simplekonfiguratorprofile/projects/Common/Environments/environment2.env", 0.3),
    new Skybox(5, "country", "/simplekonfiguratorprofile/projects/Common/Environments/country.env", 0),
    new Skybox(6, "forest", "/simplekonfiguratorprofile/projects/Common/Environments/forest.dds", 0),
    new Skybox(7, "---", "https://assets.babylonjs.com/environments/environmentSpecular.env", 0),
    new Skybox(8, "Indoor antwerp station", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/antwerp_station_512.dds", 0),
    new Skybox(9, "Indoor beerse kitchen", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/beerse_kitchen_512.dds", 0),
    new Skybox(10, "Indoor bp atrium", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/bp_atrium_512.dds", 0),
    new Skybox(11, "Indoor bp conference room", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/bp_conference_room_512.dds", 0),
    new Skybox(12, "Indoor exhibit hall", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/exhibit_hall_512.dds", 0),
    new Skybox(13, "Indoor merksem appartment livingroom", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/merksem_appartment_livingroom_512.dds", 0),
    new Skybox(14, "---", "https://assets.babylonjs.com/environments/environmentSpecular.env", 0),
    new Skybox(15, "Outdoor dry lake bed", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/dry_lake_bed_512.dds", 0),
    new Skybox(16, "Outdoor ekeren trees road", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/ekeren_trees_road_512.dds", 0),
    new Skybox(17, "Outdoor schadowplatz", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/schadowplatz_512.dds", 0),
    new Skybox(18, "Outdoor southport beach", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/southport_beach_512.dds", 0),
    new Skybox(19, "Outdoor tears of steel bridge", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/tears_of_steel_bridge_512.dds", 0),
    new Skybox(20, "Outdoor venice sunset", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/venice_sunset_512.dds", 0),
    new Skybox(21, "Outdoor winter river", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/winter_river_512.dds", 0),
    new Skybox(22, "Infinity studio background", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/Infinisky_128.dds", 0),
    new Skybox(23, "Overpass-old", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/Overpass-old_512.dds", 0),
    new Skybox(24, "Overpass", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/Overpass_512.dds", 0),
    new Skybox(25, "Experimental", "/simplekonfiguratorprofile/projects/Common/Environments/experimental.dds", 0),
    // new Skybox(48, "---", "https://assets.babylonjs.com/environments/environmentSpecular.env", 0),
    // new Skybox(51, "Studio common", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/common_512.dds", 0),
    // new Skybox(54, "Studio studio 005", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/studio_005_512.dds", 0),
    // new Skybox(57, "Studio studio 008", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/studio_008_512.dds", 0),
    // new Skybox(60, "Studio studio 015", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/studio_015_512.dds", 0),
    // new Skybox(63, "Studio studioh 016sx", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/studioh_016sx_512.dds", 0),
    // new Skybox(66, "Studio studoh 022sx", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/studoh_022sx_512.dds", 0),

  ]

  public currentLights: Skybox[] = [
    new Skybox(28, "---", "https://assets.babylonjs.com/environments/environmentSpecular.env", 0),
    new Skybox(29, "Studio common", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/common_128.dds", 0),
    new Skybox(30, "Studio studio 005", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/studio_005_128.dds", 0),
    new Skybox(31, "Studio studio 008", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/studio_008_128.dds", 0),
    new Skybox(32, "Studio studio 015", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/studio_015_128.dds", 0),
    new Skybox(33, "Studio studioh 016sx", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/studioh_016sx_128.dds", 0),
    new Skybox(34, "Studio studoh 022sx", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/studoh_022sx_128.dds", 0),
    new Skybox(36, "Advenate", "/simplekonfiguratorprofile/projects/Common/Environments/Studio/LS_AdvenateJacket_360.dds", 0),
    // new Skybox(35, "Experimental", "/simplekonfiguratorprofile/projects/Common/Environments/experimental.dds", 0),
    new Skybox(28, "---", "https://assets.babylonjs.com/environments/environmentSpecular.env", 0),
    new Skybox(1, "Studio", "https://assets.babylonjs.com/environments/studio.env", 0.3),
    new Skybox(2, "backgroundSkybox", "https://assets.babylonjs.com/environments/backgroundSkybox.dds", 0.3),
    new Skybox(3, "DefaulSpecular", "https://assets.babylonjs.com/environments/environmentSpecular.env", 0.12),
    new Skybox(4, "environment2", "/simplekonfiguratorprofile/projects/Common/Environments/environment2.env", 0.3),
    new Skybox(5, "country", "/simplekonfiguratorprofile/projects/Common/Environments/country.env", 0),
    new Skybox(6, "forest", "/simplekonfiguratorprofile/projects/Common/Environments/forest.dds", 0),
    new Skybox(7, "---", "https://assets.babylonjs.com/environments/environmentSpecular.env", 0),
    new Skybox(8, "Indoor antwerp station", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/antwerp_station_128.dds", 0),
    new Skybox(9, "Indoor beerse kitchen", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/beerse_kitchen_128.dds", 0),
    new Skybox(10, "Indoor bp atrium", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/bp_atrium_128.dds", 0),
    new Skybox(11, "Indoor bp conference room", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/bp_conference_room_128.dds", 0),
    new Skybox(12, "Indoor exhibit hall", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/exhibit_hall_128.dds", 0),
    new Skybox(13, "Indoor merksem appartment livingroom", "/simplekonfiguratorprofile/projects/Common/Environments/Indoor/merksem_appartment_livingroom_128.dds", 0),
    new Skybox(14, "---", "https://assets.babylonjs.com/environments/environmentSpecular.env", 0),
    new Skybox(15, "Outdoor dry lake bed", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/dry_lake_bed_128.dds", 0),
    new Skybox(16, "Outdoor ekeren trees road", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/ekeren_trees_road_128.dds", 0),
    new Skybox(17, "Outdoor schadowplatz", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/schadowplatz_128.dds", 0),
    new Skybox(18, "Outdoor southport beach", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/southport_beach_128.dds", 0),
    new Skybox(19, "Outdoor tears of steel bridge", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/tears_of_steel_bridge_128.dds", 0),
    new Skybox(20, "Outdoor venice sunset", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/venice_sunset_128.dds", 0),
    new Skybox(21, "Outdoor winter river", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/winter_river_128.dds", 0),
    new Skybox(22, "Infinity studio background", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/Infinisky_128.dds", 0),
    new Skybox(23, "Overpass-old", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/Overpass-old_128.dds", 0),
    new Skybox(24, "Overpass", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/Overpass_128.dds", 0),
    new Skybox(25, "OverpassLightEXP05", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/OverpassLightEXP05_env.dds", 0),
    new Skybox(26, "OverpassLightEXP10", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/OverpassLightEXP10_env.dds", 0),
    new Skybox(27, "OverpassLightEXP15", "/simplekonfiguratorprofile/projects/Common/Environments/Outdoor/OverpassLightEXP15_env.dds", 0),

  ]

  constructor() { }

  public setConfig(project, config) {
    // const path = project.path;
    const path = project.organization + "/Common";
    let lightList = [];
    let bgList = [];
    let sidx = 35;
    let bidx = 26;
    config.environments.forEach(env => {
      this.sizes.forEach(size => {
        lightList.push(
          new Skybox(sidx, env + " " + size, "/simplekonfiguratorprofile/projects/" + path + "/Environment/Imported/" + env + "/" + size + "/" + env + ".dds", 0)
        )
        bgList.push(
          new Skybox(bidx, env + " " + size, "/simplekonfiguratorprofile/projects/" + path + "/Environment/Imported/" + env + "/" + size + "/" + env + ".dds", 0)
        )
        sidx += 1;
        bidx += 1;
      })
    })
    this.currentSkyboxes = [...bgList, ...this.currentSkyboxes];
    this.currentLights = [...lightList, ...this.currentLights];
  }

  public getCurrentSkyboxes() {
    return this.currentSkyboxes;
  }

  public getCurrentLights() {
    return this.currentLights;
  }

  public getSkyboxById(id) {
    return this.currentSkyboxes.filter(bg => bg.id == id)[0]
  }

  public getLightById(id) {
    return this.currentLights.filter(bg => bg.id == id)[0]
  }

  public createSkyboxFromSkyboxURL(url) {
    return new Skybox(0, "<custom>", url, 0.15)
  }
}

export class Skybox {
  constructor(
    public id: number,
    public name: string,
    public url: string,
    public blur: number
  ) { }
}

export class Environment {
  public backgroundType: string;
  public backgroundSkyboxId: number;
  public backgroundSkyboxURL: string;
  public backgroundSkyboxBlur: number;
  public backgroundColor1: string;
  public lightSkyboxId: number;
  public lightSkyboxURL: string;
  public lightSkyboxBlur: number;
  public lightIntensity = 1;

  constructor(props?) {
    if (props) {
      this.deserialize(props);
    } else {
      this.backgroundType = "color";
      this.backgroundColor1 = "#ffffff";
      this.lightSkyboxId = 1
      this.lightSkyboxURL = "https://assets.babylonjs.com/environments/studio.env";
      this.lightSkyboxBlur = 0.3
    }
  }

  public deserialize(background: any) {
    this.backgroundType = background.backgroundType;
    this.backgroundSkyboxId = background.backgroundSkyboxId;
    this.backgroundSkyboxURL = background.backgroundSkyboxURL;
    this.backgroundSkyboxBlur = background.backgroundSkyboxBlur;
    this.backgroundColor1 = background.backgroundColor1;
    this.lightSkyboxId = background.lightSkyboxId;
    this.lightSkyboxURL = background.lightSkyboxURL;
    this.lightSkyboxBlur = background.lightSkyboxBlur;
    if (background.lightIntensity) {
      this.lightIntensity = background.lightIntensity;
    }
  }

  public serialize() {
    return {
      backgroundType: this.backgroundType,
      backgroundSkyboxId: this.backgroundSkyboxId,
      backgroundSkyboxURL: this.backgroundSkyboxURL,
      backgroundSkyboxBlur: this.backgroundSkyboxBlur,
      backgroundColor1: this.backgroundColor1,
      lightSkyboxId: this.lightSkyboxId,
      lightSkyboxURL: this.lightSkyboxURL,
      lightSkyboxBlur: this.lightSkyboxBlur,
      lightIntensity: this.lightIntensity,
    }
  }
}