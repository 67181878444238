import { UploaderService } from './uploader.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class EnvironmentService extends UploaderService {
  constructor(
    public http: HttpClient
  ) {
    super(http);
    this.setPostfix("ImportedEnvironments");
  }

  public setCurrentProjectPath(projectpath) {
    this.currentProjectPath = projectpath.split("/")[0] + "/Common";
  }

  public async deleteFile(file) {
    return await super.deleteFile(file);
  }

  public async getFiles() {
    return await super.getFiles();
  }
}