import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MaterialList } from 'src/app/services/scene/materials';
import { CombinedService } from 'src/app/services/materials/combined/combined.service';

@Component({
  selector: 'app-materials-select',
  templateUrl: './materials-select.component.html',
  styleUrls: ['./materials-select.component.css']
})
export class MaterialsSelectComponent implements OnInit {
  @Input() data: MaterialList;
  @Input() thumbnails;

  filter: string = "";

  constructor(
    public materials: CombinedService
  ) { }

  ngOnInit() {
  }

}
