import { Injectable, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GeometryGroups, GroupLayer } from '../scene/geometry-groups';

@Injectable({
  providedIn: 'root'
})
export class DragDropService {
  modification:EventEmitter<any>;
  geometryGroups:GeometryGroups;

  constructor() { }

  init(modification:EventEmitter<any>, geometryGroups:GeometryGroups ) {
    this.modification = modification;
    this.geometryGroups = geometryGroups;
  }


  materialDrop(event:CdkDragDrop<string[]>, layer:GroupLayer) {
    console.log(event);

    if (!event.item.data) { return }
    if (!event.item.data.type) { return }
    if (event.item.data.type != 'material') { return }

    let ev:any = window.event;
    let name:any = event.item.element.nativeElement.attributes.getNamedItem('material').value;
    let material = null;
    let index = -1;
    event.previousContainer.data.forEach((item:any, idx:number) => {
      if (item.material.name == name) {
        material = item;
        index = idx;
      }
    })
    let isIn = false;
    event.container.data.forEach((item:any) => {
      if (item.material.name == name) {
        isIn = true
      }
    })
    if (!isIn) {
      if (event.container.data.length == 0) {
      }
      event.container.data.push(material);
      layer.fixMaterials();
      this.modification.emit();
      if (!ev.ctrlKey) {
        if (material) {
          event.previousContainer.data.splice(index, 1);
        }
      }
    }
  }

  materialExit(event) {
  }

  layerDrop(event:CdkDragDrop<any[]>) {
    console.log(event);
    moveItemInArray(this.geometryGroups.groups, 
      event.previousIndex, 
      event.currentIndex)
  }

}
