import { Injectable } from '@angular/core';
import { ProjectsService } from '../../projects/projects.service';
import { MaterialsService } from '../materials.service';
import { MaterialList } from '../material-list';

@Injectable({
  providedIn: 'root'
})
export class CombinedService extends MaterialList {

  constructor(
    public projects: ProjectsService,
    public materials: MaterialsService,
  ) { 
    super(projects, materials);
  }


  public deselectAll() {
  }

  public setMaterials(urls, materials) {
    this.allFiles = materials;
    this.selectedFiles = [];
    this.allFiles.forEach(m => {
      urls.forEach(u => {
        this.selectedFiles.push(m);
      });
    });
    this.refreshTags();
    this.filter();
  }

  public async init() {

  }

  
}
