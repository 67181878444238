import { Injectable } from "@angular/core";

@Injectable()
export class MaterialSettings {
  public setSettingsFromMaterial(materialEditSettings: any, material: any) {
    console.log("MAT", material);
    console.log("SETT", materialEditSettings);

    if (!material) {
      return;
    }

    materialEditSettings.alpha.value              = material.alpha;
    materialEditSettings.specularPower.value      = material.specularPower;
    materialEditSettings.metallic.value           = material.metallic;
    materialEditSettings.metallicRoughness.value  = material.roughness;
    materialEditSettings.metallicIor.value        = material.refractionIndex;
    materialEditSettings.clearCoatIntensity.value = material.clearCoat.intensity;
    materialEditSettings.clearCoatRoughness.value = material.clearCoat.roughness;
    materialEditSettings.clearCoatIor.value       = material.clearCoatRefraction;
    materialEditSettings.bumpMapLevel.value       = material.bumpTexture.level;

    materialEditSettings.wireframe.value          = material.wireframe;
    materialEditSettings.pointCloud.value         = material.pointCloud;
    materialEditSettings.useMetallic.value        = material.useMetallnessFromMetallicTexture;
    materialEditSettings.metallicIor.value        = material.refractionIndex;
    materialEditSettings.metallicReflectanceColor.value = material.reflectivityColor.toHexString();
    materialEditSettings.useClearCoat.value       = material.useClearCoat;
    materialEditSettings.useTransparency.value    = material.alphaMode;
    materialEditSettings.transparencyMode.value   = material.transparencyMode;
    materialEditSettings.alphaMode.value          = material.alphaMode;

    if (material.diffuseColor) {
      materialEditSettings.diffuseColor.value       = material.diffuseColor.toHexString();
    }
    if (material.specularColor) {
      materialEditSettings.specularColor.value      = material.specularColor.toHexString();
    }
    materialEditSettings.specularPower.value      = material.specularPower;
    if (material.emissiveColor) {
      materialEditSettings.emissiveColor.value      = material.emissiveColor.toHexString();
    }
    materialEditSettings.parallaxMapping.value    = material.useParallax;
    // paralaxBias
  }


  public applyMaterialFromSettings(material: any, materialEditSettings: any) {
    console.log("MAT", material);
    console.log("SETT", materialEditSettings);

    if (!materialEditSettings) {
      return;
    }

    if (materialEditSettings.alpha.enabled) {
      material.alpha                = materialEditSettings.alpha.value;
    }
    if (materialEditSettings.specularPower.enabled) {
      material.specularPower        = materialEditSettings.specularPower.value;
    }
    if (materialEditSettings.metallic.enabled) {
      material.metallic             = materialEditSettings.metallic.value;
    }
    if (materialEditSettings.metallicRoughness.enabled) {
      material.roughness            = materialEditSettings.metallicRoughness.value;
    }
    if (materialEditSettings.metallicIor.enabled) {
      material.refractionIndex      = materialEditSettings.metallicIor.value;
    }
    if (materialEditSettings.clearCoatIntensity.enabled) {
      material.clearCoat.intensity  = materialEditSettings.clearCoatIntensity.value;
    }
    if (materialEditSettings.clearCoatRoughness.enabled) {
      material.clearCoat.roughness  = materialEditSettings.clearCoatRoughness.value;
    }
    if (materialEditSettings.clearCoatIor.enabled) {
      material.clearCoatRefraction  = materialEditSettings.clearCoatIor.value;
    }
    if (materialEditSettings.bumpMapLevel.enabled) {
      material.bumpTexture.level    = materialEditSettings.bumpMapLevel.value;
    }

    if (materialEditSettings.wireframe.enabled) {
      material.wireframe           = materialEditSettings.wireframe.value;
    }
    if (materialEditSettings.useMetallic.enabled) {
      material.useMetallnessFromMetallicTexture  = materialEditSettings.useMetallic.value;
    }
    if (materialEditSettings.metallicIor.enabled) {
      material.refractionIndex     = materialEditSettings.metallicIor.value;
    }
    if (materialEditSettings.metallicReflectanceColor.enabled) {
      material.reflectivityColor   = BABYLON.Color3.FromHexString(materialEditSettings.metallicReflectanceColor.value);
    }
    if (materialEditSettings.useClearCoat.enabled) {
      material.useClearCoat        = materialEditSettings.useClearCoat.value;
    }
    if (materialEditSettings.useTransparency.enabled) {
      material.alphaMode           = materialEditSettings.useTransparency.value;
    }
    if (materialEditSettings.transparencyMode.enabled) {
      material.transparencyMode    = materialEditSettings.transparencyMode.value;
    }
    if (materialEditSettings.alphaMode.enabled) {
      material.alphaMode           = materialEditSettings.alphaMode.value;
    }
    if (materialEditSettings.diffuseColor.enabled) {
      material.diffuseColor        = BABYLON.Color3.FromHexString(materialEditSettings.diffuseColor.value);
    }
    if (materialEditSettings.specularColor.enabled) {
      material.specularColor       = BABYLON.Color3.FromHexString(materialEditSettings.specularColor.value);
    }
    if (materialEditSettings.specularPower.enabled) {
      material.specularPower       = materialEditSettings.specularPower.value;
    }
    if (materialEditSettings.emissiveColor.enabled) {
      material.emissiveColor       = BABYLON.Color3.FromHexString(materialEditSettings.emissiveColor.value);
    }
    if (materialEditSettings.parallaxMapping.enabled) {
      material.useParallax         = materialEditSettings.parallaxMapping.value;
    }
  }

}
