import { Injectable } from "@angular/core";
import { OptionGroupsService } from "../optiongroups/option-groups.service";
import { GeometryGroups, GroupLayer } from "../scene/geometry-groups";
import { Material, MaterialList, Materials } from "../scene/materials";
import { GlobalEventsActions } from "../scene/simpleprofile/global-events-actions.service";


export interface IMaterialLinkAssignment {
  link: string;
  materials: Material[];
  current: Material;
  primary: Material;

  addMaterials(materials: Material[]);
  setCurrentMaterial(material: Material);
  setPrimaryMaterial(material: Material);
}

@Injectable()
export class MaterialLinksService {

  public materialLinkAssignments: IMaterialLinkAssignment[] = [];

  constructor(
    private options: OptionGroupsService,
    private groups: GeometryGroups,
    private materialsService: Materials,
    private globals: GlobalEventsActions
  ) {

  }

  public deserialize(config: any) {
    console.log("MATERIAL LINKS DESERIALIZE", config.materialLinkAssignments);

    if (config.materialLinkAssignments) {
      this.materialLinkAssignments = config.materialLinkAssignments.map(ma => {
        const assignent = new MaterialLinkAssignment(
          ma.link,
          ma.materials.map(m => {
            return this.materialsService.materials.getByName(m);
          }),
          this.groups,
          this.globals,
          this.materialsService);
        assignent.current = this.materialsService.materials.getByName(ma.current);
        assignent.primary = this.materialsService.materials.getByName(ma.primary);
        return assignent;
      })
      this.options.materialLinkLabels.forEach(mll => {
        if (this.materialLinkAssignments.filter(ma => ma).find(ma => ma.link == mll.value)) {

        } else {
          let link = new MaterialLinkAssignment(
            mll.value,
            null,
            this.groups, 
            this.globals, 
            this.materialsService)
          this.materialLinkAssignments.push(link);
          console.log("NEW LINK", link)
        }
      })
    } else {
      this.materialLinkAssignments = this.options.materialLinkLabels.map(l => {
        if (l.value == null) {
          return;
        }

        let link = new MaterialLinkAssignment(
          l.value,
          null,
          this.groups, 
          this.globals, 
          this.materialsService);
        console.log("NEW LINK", link)
        return link;
      });
    }
  }

  public serialize() {
    // filter those that are undefined
    return this.materialLinkAssignments.filter(ma => ma).map(ma => {
      if (ma.materials.find(m => !m)) {
        console.log("ERRORS", ma.materials.filter(m => !m));
      }
      return {
        link: ma.link,
        materials: ma.materials.filter(m => m).map(m => m.material.name),
        current: ma.current ? ma.current.material.name : null,
        primary: ma.primary ? ma.primary.material.name : null
      }
    })
  }


  public getAssignment(link: string) {
    if (link == null) {
      return
    }

    // filter those that are undefined
    let assignment = this.materialLinkAssignments.filter(ma => ma).find(ma => ma.link == link);

    if (!assignment) {
      assignment = new MaterialLinkAssignment(
        link,
        null,
        this.groups, 
        this.globals, this.materialsService);
    }

    return assignment;
  }

  
  public setCurrent(material: Material, link: string) {
    this.materialLinkAssignments.forEach(ma => {
      if (ma.link == link) {
        ma.current = material;
      }
    })
  }


  public setPrimary(material: Material, link: string) {
    this.materialLinkAssignments.forEach(ma => {
      if (ma.link == link) {
        ma.primary = material;
      }
    })
  }


}


export class MaterialLinkAssignment implements IMaterialLinkAssignment {
  // public link: string = null;
  // public materials: Material[] = [];
  public current: Material = null;
  public primary: Material = null;

  public layer: GroupLayer;


  constructor(
    public link: string,
    public materials: Material[],
    private groups: GeometryGroups,
    private globals: GlobalEventsActions,
    private materialsService: Materials,
  ) {
    console.log("SUBSCRIBE MLA", this)

    this.globals.events.materials.links.assigned$.subscribe(layer => {
      if (this.link && (this.link == layer.materialLink)) {
        console.log("ASSIGNING TO", this, layer)
        layer.currentMaterial = this.current;
        layer.primaryMaterial = this.primary;
        layer.materials.materials = [...this.materials];
        layer.assign();
        this.globals.actions.materials.refreshAllGroups$.next();
      }
      if (this.link) {
        this.setFirstLayer();
      }
    })

    this.setFirstLayer();
  }


  setFirstLayer() {
    if (this.materials) {
      return;
    }

    this.layer = null;
    const materialSet = new Set<Material>();


    this.groups.groups.forEach(grp => {
      grp.layers.forEach(lay => {
        if (lay.materialLink == this.link) {
          if (!this.layer) {
            this.layer = lay;
          }
          lay.materials.materials.forEach(m => {
            materialSet.add(m);
          })
        }
      })
    })

    console.log("SET FIRST LAYER", this.layer, this.link);


    this.materials = Array.from(materialSet);
    this.current = this.layer ? this.layer.getCurrentMaterial() : null;
    this.primary = this.layer ? this.layer.getPrimaryMaterial() : null;
  }



  public addMaterials(materials: Material[]) {
    // this.service.materialAdd$.next(this.layer);
    this.materials = materials;

    console.log("MATS", this.materials)

    if (!materials.includes(this.current)) {
      this.current = materials[0];
    }

    if (!materials.includes(this.primary)) {
      this.primary = materials[0];
    }

    this.setMaterialsToLayers(materials)

  }

  setMaterialsToLayers(materials: Material[]) {
    this.groups.assignMaterialsToLink(
      this.link,
      materials,
      this.current,
      this.primary
    )
  }

  public setCurrentMaterial(material: Material) {
    this.current = material;
    this.groups.setMaterialToLink(this.link, material)
    this.groups.assign();
  }

  public setPrimaryMaterial(material: Material) {
    this.current = material;
    this.primary = material;
    this.groups.setPrimaryMaterialToLink(this.link, material)
    this.groups.assign();
  }

}

