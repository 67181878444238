import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ViewerComponent } from '../../components/viewer/viewer.component';
import { MaterialGroupsComponent } from '../../components/material-groups/material-groups.component';
import { Test2Service } from 'src/app/services/__OLD/test2/test2.service';
import { SceneService, ISceneService } from 'src/app/services/__OLD/scene/scene.service';
import { Group } from 'src/app/services/__OLD/material-groups/material-groups.service';
import { LoaderService, ILoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-test2',
  templateUrl: './test2.component.html',
  styleUrls: ['./test2.component.css']
})
export class Test2Component implements OnInit, OnDestroy {
  @ViewChild("viewer", { static: true }) viewer: ViewerComponent;
  @ViewChild("groups", { static: true }) groups: MaterialGroupsComponent;
  @ViewChild("debug", { static: true }) debug: ElementRef;

  test2Service: Test2Service;
  scene: SceneService;
  loader: ILoaderService;

  currentColor:any;
  noColors:boolean = false;
  
  constructor(test2Service:Test2Service, scene:SceneService, loader:LoaderService) { 
    this.test2Service = test2Service;
    this.scene = scene;
    this.loader = loader;
    this.currentColor = scene.currentColor;
  }

  ngOnInit() {
    this.viewer.setOnMeshClick((mesh) => {
      this.scene.selectMesh(mesh);
    });

    this.groups.onSetNodeClick = (group) => {
      this.onSetNodeClick(group);
    }

    this.groups.onSetMatClick = (group) => {
      this.onSetMatClick(group);
    }

    this.groups.onRGBClick = (group) => {
      this.onRGBClick(group);
    }

    this.groups.onNodeClick = (group, node) => {
      this.onNodeClick(group, node);
    }
    this.groups.onMaterialClick = (group, material) => {
      this.onMaterialClick(group, material);
    }
    this.groups.onColorClick = (group, color) => {
      this.onColorClick(group, color);
    }
    this.groups.onSelClick = (group) => {
      this.onSelClick(group);
    }

    this.loader.load({}).then((config) => {
      this.viewer.init({
        sceneURL: config.sceneURL,
        materialSceneURLs: config.materialSceneURLs,
        skyboxURL: config.skyboxURL
      }).then((data) => {
        console.log("loaded2")
        console.log(data);
    
        this.scene.register(data.currentScene, data.auxScenes)
    
        this.viewer.getCurrentScene().meshes.forEach(mesh => {
          if (mesh.material) {
            (<any>mesh.material).default = true;
          }
        });
      });
    })
  }

  ngOnDestroy() {
    console.log("destroy")
  }

  onViewerSceneLoaded(data) {
  }

  onTableExpandClick(node) {
    this.scene.expandNode(node);
  }

  getChildren = (node) => {
    return node.nodes;
  }


  onShowHide(node) {
    node.visible = !node.visible
    node.node.setEnabled(node.visible)
  }

  onMaterialExpandClick(material) {
    this.scene.selectMaterial(material)
  }

  onAssignClick() {
    this.scene.setNoColors(this.noColors);
    this.scene.assignGlobal();
  }


  onSetNodeClick(group:Group) {
    console.log(group);
    var nodes = this.scene.getSelectedNodes();
    console.log(nodes);
    group.setNodes(nodes);
  }

  onSetMatClick(group:Group) {
    console.log(group);
    var materials = this.scene.getSelectedMaterials();
    console.log(materials);
    group.setMaterials(materials);
  }

  onRGBClick(group:Group) {
    var colors = group.colors;
    colors.push({
      type: "color",
      check: false,
      visible: true,
      name: this.currentColor.r + " " + this.currentColor.g + " " + this.currentColor.b,
      expand: false,
      level: 0,
      color: {
        r: this.currentColor.r,
        g: this.currentColor.g,
        b: this.currentColor.b,
      }
    })
    console.log(colors)
    group.setColors(colors)
  }

  onNodeClick(group, node) {
    this.scene.unselectNodeFromGroup(group, node);
  }

  onMaterialClick(group, material) {
    this.scene.selectGroupMaterial(group, material);
  }

  onColorClick(group, color) {
    this.scene.selectGroupColor(group, color);
  }

  onDeselectAllClick() {
    this.scene.deselectAllNodes();
  }

  onSelectedItemClick(node) {
    this.scene.deselectNode(node);
  }

  onSelClick(group) {
    this.scene.selectNodesFromGroup(group);
  }
}
