import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {

  constructor(
    public http: HttpClient
  ) { }

  
  public async getList(organization) {
    return this.http.get("/simplekonfiguratorprofile/projects/collections/" + organization).toPromise();
  }

  public async add(organization, collection, projectpath) {
    console.log("ADD ", organization, collection, projectpath);
    
    return this.http.post("/simplekonfiguratorprofile/projects/collections/" + organization, {
      organization,
      collection,
      projectpath
    }).toPromise();
  }

  public async addCollection(organization, collection) {
    console.log("ADD COLLECTION", organization, collection);
    
    return this.http.post("/simplekonfiguratorprofile/projects/collections/" + organization, {
      organization,
      collection
    }).toPromise();
  }

  public async remove(organization, collection, projectpath) {
    console.log("REMOVE ", organization, collection, projectpath);
    
    return this.http.delete("/simplekonfiguratorprofile/projects/collections/" + collection + "/" + projectpath).toPromise();
  }

  public async removeCollection(organization, collection) {
    console.log("REMOVE COLLECTION", organization, collection);
    
    return this.http.delete("/simplekonfiguratorprofile/projects/collections/" + organization + "/" + collection).toPromise();
  }


}

export class Collection {
  public label: string;
  public path: string;
  public projects: string[];
}