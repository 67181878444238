import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ISimpleKonfiguratorProfileConfig } from '../scene/simpleprofile/simplekonfiguratorprofileconfig.interface';


export interface ILoaderService {
  getSceneURL();

  getMaterialSceneURLs();

  getSkyboxURL();

  load(props:any);
}



@Injectable({
  providedIn: 'root'
})
export class LoaderService implements ILoaderService {
  PREFIX = "/simplekonfiguratorprofile/projects";
  projectURL:string = ""
  materialSceneURLs:string[];
  sceneURL:string;
  skyboxURL:string;
  outlinerURL:string;

  config: ISimpleKonfiguratorProfileConfig;


  constructor(private http: HttpClient) {
  }

  public setProjectURL(url:string) {
    console.log("SET PROJECT:")
    console.log(url)
    this.projectURL = url;
  }


  public getSceneURL() { return this.sceneURL; }

  public getMaterialSceneURLs() { return this.materialSceneURLs; }

  public getSkyboxURL() { return this.skyboxURL; }

  public getOrganizationURL() {
    return this.PREFIX + '/' + this.projectURL.split("/")[0]
  }

  public getURL() {
    return this.PREFIX + '/' + this.projectURL
  }

  public getGlobalURL() {
    return this.PREFIX
  }

  public getViewerURL() {
    console.log(window.location.href);
    
    if (window.location.href.startsWith("http://localhost:420")) {
      return "http://localhost:4201/?project=" + this.projectURL;
    }
    if (window.location.href.startsWith("192.168.10.200:3007")) {
      return "http://192.168.10.200:3008/?project=" + this.projectURL;
    }
    return "https://3dv.tronog-engine.com/?project=" + this.projectURL;
  }

  async load(props:any) {
    try {
      let options = {};
      if (props.materialQuality) {
        options = {
          headers: {
            'X-3DK-material-quality': props.materialQuality
          }
        }
      }
      this.config = await this.http.get(this.getURL() + "/config.json", options ).toPromise() as ISimpleKonfiguratorProfileConfig;
    } catch(e) {
      console.log(e);
    }
    console.log("SCENE URL:");
    
    console.log(this.config.sceneURL);
    
    if (!props.lod) {
      this.sceneURL = this.getURL() + "/" + this.config.sceneURL;
    } else {
      this.sceneURL = this.getURL() + "/" + this.config.sceneURL.replace("Model/","Model/" + props.lod + "/");
      // this.sceneURL = this.getURL()
    }
    console.log("ORG URL", this.getOrganizationURL());

    this.materialSceneURLs = this.config.materialSceneURLs.map(
      url => url.startsWith('/Common/') ? this.getGlobalURL() + "/" + url :
        url.startsWith("/Organization/") ?  this.getOrganizationURL() + "/" + url.replace("/Organization/", "Common/") : this.getURL() + "/" + url
    );

    this.materialSceneURLs = this.materialSceneURLs.map(
      url => url.replace("_Original.", "_"+props.materialQuality+".")
    );

    console.log("MATERIALS", this.materialSceneURLs);
    

    

    // this.materialSceneURLs = this.config.materialSceneURLs.map(
    //   url => url[0] === '/' ? this.getGlobalURL() + "/" + url : this.getURL() + "/" + url
    // );

    this.skyboxURL = this.config.skyboxURL;
    this.outlinerURL = this.getURL() + "/" + this.config.outlinerURL;

    // let result = {
    //   sceneURL: this.sceneURL,
    //   materialSceneURLs: this.materialSceneURLs,
    //   skyboxURL: this.skyboxURL,
    //   ...this.config
    // };

    // console.log(result);

    // return result;
    
    
    return {
      ...this.config,
      sceneURL: this.sceneURL,
      materialSceneURLs: this.materialSceneURLs,
      skyboxURL: this.skyboxURL,
      // geometryGroups: this.config.geometryGroups,
      // background: this.config.background,
      // specular: this.config.specular,
      // options: this.config.options,
      // screenshot: this.config.screenshot,
      // cameras: this.config.cameras,
      // shaderOverrides: this.config.shaderOverrides,
      // shaderInfo: this.config.shaderInfo,
      // shaderLinks: this.config.shaderLinks,
      // postprocess: this.config.postprocess,
      // environments: this.config.environments,
      // optionGroups: this.config.optionGroups,
      // konfiguratorSettings: this.config.konfiguratorSettings,
      // optionGroupLinks: this.config.optionGroupLinks,
      // materialLinkLabels: this.config.materialLinkLabels,
      // materialRelations: this.config.materialRelations,
      // materialGeometryRelations: this.config.materialGeometryRelations,
    };
  }

  async save(props:any) {
    let data:any = {...this.config, ...props};
    return this.http.post(this.getURL() + '/config.json', data).toPromise();
  }

  async getPreconfigured() {
    return await this.http.get(this.getURL() + "/import.json").toPromise();
  }

  async generateLodAsync(organization, project) {
    console.log(organization);
    console.log(project);
    let url = this.getURL() + "/lod";
    url = url.replace("//", "/");
    return this.http.get(url).toPromise();
  }

  async checkLod(organization, project, id) {
    return this.http.get(this.getURL() + "/" + organization + "/" + project + "/lod/" + id).toPromise();
  }

}

