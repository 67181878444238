import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { CamerasService, CameraItem } from '../cameras/cameras.service';
import { OptionGroup } from '../scene/option-groups';

@Injectable({
  providedIn: 'root'
})
export class RenderService {
  public resolutionChange$ = new Subject();

  public geometries = new Set();
  public materials = new Set();

  private projectPath: string = "";
  public settings: any = {
    quality: 'LQ',
    resolution: '720x1280',
    format: 'EXR',
    renderElements: true,
    konfigurator: {}
  }
  public settings2DK: any = {
    quality: 'LQ',
    resolution: '720x1280',
    format: 'EXR',
    renderElements: true,
    konfigurator: {}
  }


  constructor(
    private http: HttpClient
  ) {
    // this.getRenderSettings();
  }

  public async setProjectPath(projectPath: string) {
    console.log("RENDER setProjectPath", projectPath)
    this.projectPath = projectPath;
    await Promise.all([
      this.getRenderSettings(),
      this.getRenderSettings2DK()
    ]);
  }

  async renderDefaultPresets() {
    await this.http.get(`/simplekonfiguratorprofile/projects/${this.projectPath}/renderdefaultpresets`).toPromise();
  }

  async renderAllPresets() {
    await this.http.get(`/simplekonfiguratorprofile/projects/${this.projectPath}/renderallpresets`).toPromise();
  }

  async renderSelective(preset_name: string = null, cameraLabel: string = null) {
    if (!preset_name && !cameraLabel) {
      console.log(preset_name, cameraLabel);
      
      await this.http.get(`/simplekonfiguratorprofile/projects/${this.projectPath}/renderselective`).toPromise();
    } else {
      let params = null;
      if (preset_name) {
        params = "preset=" + preset_name;
      }
      if (cameraLabel) {
        if (params) {
          params += "&";
        }
        params += "camera=" + cameraLabel;
      }
      await this.http.get(`/simplekonfiguratorprofile/projects/${this.projectPath}/renderselective?${params}`).toPromise();
    }
  }

  async generateSelective(preset_name) {
    if (!preset_name) {
      await this.http.get(`/simplekonfiguratorprofile/projects/${this.projectPath}/generateselective`).toPromise();
    } else {
      await this.http.get(`/simplekonfiguratorprofile/projects/${this.projectPath}/generateselective?preset=${preset_name}`).toPromise();
    }
  }

  async getRenderSettings(): Promise<any> {
    this.settings = await this.http.get(`/simplekonfiguratorprofile/projects/${this.projectPath}/rendersettings`).toPromise();
    if (this.settings.renderElements == undefined) {
      this.settings.renderElements = true;
    }
    if (this.settings.lighting == undefined) {
      this.settings.lighting = "Cubical";
    }
  }

  async getRenderSettings2DK(): Promise<any> {
    this.settings2DK = await this.http.get(`/simplekonfiguratorprofile/projects/${this.projectPath}/rendersettings2dk`).toPromise();
    if (this.settings2DK.renderElements == undefined) {
      this.settings2DK.renderElements = true;
    }
    if (this.settings2DK.lighting == undefined) {
      this.settings2DK.lighting = "Cubical";
    }
  }

  public async setRenderSettings(data): Promise<any> {
    await this.http.post(`/simplekonfiguratorprofile/projects/${this.projectPath}/rendersettings`, data).toPromise();
  }

  public async setRenderSettings2DK(data): Promise<any> {
    await this.http.post(`/simplekonfiguratorprofile/projects/${this.projectPath}/rendersettings2dk`, data).toPromise();
  }

  public async save() {
    await Promise.all([
      await this.http.post(`/simplekonfiguratorprofile/projects/${this.projectPath}/rendersettings`, this.settings).toPromise(),
      await this.http.post(`/simplekonfiguratorprofile/projects/${this.projectPath}/rendersettings2dk`, this.settings2DK).toPromise(),
    ]);
  }


  async renderFromAdvancedMenu(filters: {color?: Set<any>, option?: Set<OptionGroup>} = null) {
    let params = null;
    if (filters) {
      if (filters.color) {
        if (!params) {
          params = "?color="
        }
        params += Array.from(filters.color).join(",")
      }
      if (filters.option) {
        if (!params) {
          params = "?"
        } else {
          params += "&"
        }
        params += "option=" + Array.from(filters.option).map(o => o.getFullLink()).join(",");
      }
    }


    let url = `/simplekonfiguratorprofile/projects/${this.projectPath}/renderadvanced` + (params ? params : '');
    console.log("RENDERADVANCED", url);
    
    await this.http.get(url).toPromise();
  }


  async renderFromAdvancedMenuV2(filters: {color?: Set<any>, option?: Set<OptionGroup>} = null) {
    let params = null;
    if (filters) {
      if (filters.color) {
        if (!params) {
          params = "?color="
        }
        params += Array.from(filters.color).join(",")
      }
      if (filters.option) {
        if (!params) {
          params = "?"
        } else {
          params += "&"
        }
        params += "option=" + Array.from(filters.option).map(o => o.getFullLink()).join(",");
      }
    }


    let url = `/simplekonfiguratorprofile/projects/${this.projectPath}/renderadvanced_v2` + (params ? params : '');
    console.log("RENDERADVANCED", url);
    
    await this.http.get(url).toPromise();
  }

}
