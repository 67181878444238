import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MaterialsService } from '../materials/materials.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  public PREFIX = "/simplekonfiguratorprofile/projects";

  private _projects: Project[] = []
  private _materials: any = [];


  public map: any;
  public currentProject: any;

  constructor(
    private http: HttpClient,
    public materials: MaterialsService
  ) {
    this.materials.setPrefix(this.PREFIX);
  }

  public get projects() {
    return this._projects;
  }

  public async load() {
    try {
      console.log("PROJECTS load", this.PREFIX);
      this._projects = await this.http.get(this.PREFIX).toPromise() as Project[];
      console.log("PROJECTS loaded");
      this.map = {};
      this._projects.forEach(prj => {
        this.map[prj.path] = prj;
      })
      
    } catch (e) {
      console.log(e);
    }
  }

  public getProjectByName(name: string) {
    return this._projects.filter(project => project.name === name);
  }

  public getProjectByPathName(path: string) {
    console.log("PROJECTS getProjectByPathName", path);
    return this._projects.filter(project => project.path === path);
  }

  public async create(project: any) {
    project.path = this.sanitize(project.organization) + "/" + this.sanitize(project.name);
    console.log(project);
    let isIn = false;
    this._projects.forEach(prj => {
      if (prj.path == project.path) {
        isIn = true;
      }
    });
    if (!isIn) {
      this._projects = await this.http.post(this.PREFIX, project).toPromise() as Project[];
      return project;
    } else {
      throw new Error("PathAlreadyExists");
    }
  }


  public setCurrentProjectByPathName(name: string) {
    this.currentProject = this.getProjectByPathName(name)[0];
  }

  sanitize(path: string) {
    let chars = "~!@#$%^&*()_+[]{}';/.,<|\\\":?><> "
    let result = path;
    let oldresult = result;
    for (let c of chars) {
      while (true) {
        oldresult = result;
        result = result.replace(c, '');
        if (oldresult == result) {
          break;
        }
      }
    }
    return result;
  }

  async getModelFiles(project) {
    if (project) {
      return await this.http.get(this.PREFIX + "/" + project.path + "/Model").toPromise();
    } else {
      return { files: null };
    }
  }

  async getMaterialsFiles(project) {
    if (project) {
      return await this.http.get(this.PREFIX + "/" + project.path + "/Materials").toPromise();
    } else {
      return { files: null };
    }
  }

  // async getOrganizationalMaterialsFiles(project) {
  //   if (project) {
  //     return await this.http.get(this.PREFIX + "/" + project.path.split("/")[0] + "/Common/Materials").toPromise();
  //   } else {
  //     return { files: null };
  //   }
  // }

  async deleteModel(project, file) {
    return await this.http.delete(this.PREFIX + "/" + project.path + "/Model/" + file).toPromise();
  }

  async deleteMaterial(project, file) {
    return await this.http.delete(this.PREFIX + "/" + project.path + "/Materials/" + file).toPromise();
  }

  async deleteProject(project) {
    this._projects = await this.http.delete(this.PREFIX + "/" + project.path).toPromise() as Project[];
    this.currentProject = null;
    return this._projects;
  }

  async archiveProject(project) {
    let result: any = await this.http.patch(this.PREFIX + "/" + project.path, { "action": "archive" }).toPromise();
    console.log("RESULT:", result)
    this._projects = result;
    return await this.load();
  }

  async renameProject(project) {
    this._projects = await this.http.get(this.PREFIX + "/" + project.path + '/rename/' + project.name).toPromise() as Project[];
    return this._projects;
  }
}

export class Project {
  public created: Created;
  public name: string;
  public organization: string;
  public path: string;
  public type: string;
  public archived?:boolean = false;
}

export class Created {
  public user: number;
  public name: string;
  public date: string;
}
