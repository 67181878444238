import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DragDropService } from 'src/app/services/dragdrop/drag-drop.service';
import { IMaterialLinkAssignment, MaterialLinksService } from 'src/app/services/materiallinks/material-links.service';
import { OptionGroupsService } from 'src/app/services/optiongroups/option-groups.service';
import { GeometryGroups, GroupLayer } from 'src/app/services/scene/geometry-groups';
import { Material, MaterialList, Materials } from 'src/app/services/scene/materials';
import { GlobalEventsActions } from 'src/app/services/scene/simpleprofile/global-events-actions.service';
import { SimpleKonfiguratorProfileSceneService } from 'src/app/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';

@Component({
  selector: 'app-material-strip',
  templateUrl: './material-strip.component.html',
  styleUrls: ['./material-strip.component.css']
})
export class MaterialStripComponent implements OnInit {
  @Input() link: string;


  @Output() materialAddClick: EventEmitter<any> = new EventEmitter();
  @Output() matLabel: EventEmitter<any> = new EventEmitter();
  @Output() refreshAllGroups: EventEmitter<any> = new EventEmitter();
  @Output() modification: EventEmitter<any> = new EventEmitter();
  @Output() layerMaterialClick: EventEmitter<any> = new EventEmitter();
  @Output() layerMaterialDblClick: EventEmitter<any> = new EventEmitter();


  layer: GroupLayer;

  assignment: IMaterialLinkAssignment;


  constructor(
    public dragdrop: DragDropService,
    public materialLinks: MaterialLinksService,
    public service: SimpleKonfiguratorProfileSceneService,
    private materialsService: Materials,
    private globals: GlobalEventsActions,
  ) {
  }


  ngOnInit() {
    if (!this.link) {
      console.log("NO LINK")
      return;
    }

    this.assignment = this.materialLinks.getAssignment(this.link);


  }




  onMaterialAddClick() {
    this.materialsService.materials.materials.forEach(mat => {
      mat.checked = false;
      if (this.assignment.materials.includes(mat)) {
        mat.checked = true;
      }
    })

    // this.assignment.addMaterials(this.service.getMaterials().materials)

    this.service.materialAdd$.next(null);
    this.service.materialsSelectCB = () => {
      const materials = this.service.getMaterials().materials.materials.filter(mat => mat.checked);
      
      this.assignment.addMaterials(materials);

      this.service.materialsSelectCB = null;
      this.globals.actions.materials.refreshAllGroups$.next();
    }

  }


  onMaterialDblClick(material, layer: GroupLayer) {
    this.assignment.setPrimaryMaterial(material);
    this.globals.actions.materials.refreshAllGroups$.next();
  }


  onMaterialClick(material, layer: GroupLayer) {
    this.assignment.setCurrentMaterial(material);
    this.globals.actions.materials.refreshAllGroups$.next();
  }

}
