import { Injectable, ElementRef, Output, EventEmitter, NgZone } from '@angular/core';
import * as BABYLON from 'babylonjs';
import "babylonjs-loaders";
import { ArcRotateCamera, FramingBehavior, Camera } from 'babylonjs';
import { StandardMaterial } from 'babylonjs';
import { CustomLoadingScreen } from 'src/app/components/loading/loading.component';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PostprocessService } from '../postprocess/postprocess.service';
import { BackgroundsService } from '../backgrounds/backgrounds.service';



export interface IEngineService {
  init(params);

  destroy();

  getEngine();

  setBackgroundSkybox(backgroundUrl: string, blur)

  setSpecular(backgroundUrl: string, blur, lightIntensity: number)

  setBackgroundTransparent()

  stopLoop();

  startLoop();

  getCurrentScene();

  getAuxScenes();

  render();

  enableRender();

  disableRender();

  onSceneLoad();

  onMeshClick(mesh, picking);
}


@Injectable({
  providedIn: 'root'
})
export class EngineService implements IEngineService {
  public resize$: Subject<any> = new Subject<any>();
  public canvas: ElementRef;

  public initialized$: Subject<any> = new Subject<any>();
  public zone: NgZone;

  engine: BABYLON.Engine;
  currentScene: BABYLON.Scene;
  camera: ArcRotateCamera;
  currentPluginName: any;
  currentSkybox: any;
  auxScenes: BABYLON.Scene[] = [];
  assetsManager: BABYLON.AssetsManager;

  sceneURL: string;
  materialSceneURLs: string[];
  skyboxURL: string;
  skyboxBlur: number = 0.15;
  specularURL: string;
  lightIntensity: number = 1;

  _render: boolean = true;
  _onPlugin: any;
  _runRenderLoop: boolean = false;
  _mouseDownTime: Date;

  defaultShader: StandardMaterial;
  ignoreClickTimeout: boolean; // IS CAMERA FROZEN

  public loadingEmitter$: Subject<boolean>;
  public postprocess: PostprocessService;

  constructor(
    private http: HttpClient,
  ) {
    this._onPlugin = BABYLON.SceneLoader.OnPluginActivatedObservable.add((plugin: any) => {
      this.currentPluginName = plugin.name;
    });

    this.loadingEmitter$ = new Subject();
    this.resize$.subscribe({
      next: data => this.onResize(data)
    })
  }

  public destroy() {
    console.log("engine destroy")
    if (this.auxScenes) {
      for (let scene of this.auxScenes) {
        if (scene.dispose) {
          console.log("disposing aux scene")
          scene.dispose();
        }
      }
    }
    if (this.currentScene) {
      if (this.currentScene.dispose) {
        console.log("disposing current scene")
        try {
          this.currentScene.dispose();
        } catch (e) {
          console.log(e);
        }
      }
    }
    if (this.engine) {
      console.log("disposing engine")
      try {
        this.engine.dispose();
      } catch (e) {
        console.log(e);
      }
    }
    this.currentScene = null;
    this.auxScenes = [];
    this.engine = null;
    BABYLON.SceneLoader.OnPluginActivatedObservable.remove(this._onPlugin);
  }

  public getEngine() { return this.engine; }

  public getCurrentScene() { return this.currentScene; }

  public getAuxScenes() { return this.auxScenes; }




  public async init(params) {
    this.sceneURL = params.sceneURL;
    this.materialSceneURLs = params.materialSceneURLs;
    this.skyboxURL = params.skyboxURL;

    this.canvas = params.canvas;
    this.engine = new BABYLON.Engine(this.canvas.nativeElement, true);

    this.engine.loadingScreen = new CustomLoadingScreen('', this.loadingEmitter$);

    // enable if memory leak is detected again
    // this.engine.enableOfflineSupport = false;

    // BABYLON.GLTFFileLoader.IncrementalLoading = false;

    // let gltf = await this.http.get(params.sceneURL).toPromise();

    let scene = await this.loadMainScene(this.sceneURL);

    this.loadingEmitter$.next(true);

    // LOAD AUXILIARY SCENES (MATERIAL SCENES)
    var promises = [];
    for (let auxScene of this.materialSceneURLs) {
      promises.push(this.loadAuxScene(auxScene));
    }
    let scenes = await Promise.all(promises)
    this.auxScenes = scenes;
    this.onSceneLoad();
    this.initialized$.next();
    return {
      currentScene: this.currentScene,
      auxScenes: this.auxScenes,
      // gltf: gltf
    }
  }

  loadTest() { }

  async loadAuxScene(URL) {
    var rootUrl = BABYLON.Tools.GetFolderPath(URL);
    var fileName = BABYLON.Tools.GetFilename(URL);
    // console.log("engine loadAuxScene")
    // console.log(URL)
    // console.log(rootUrl)
    // console.log(fileName)
    let legitNodes = this.currentScene.rootNodes.length;
    try {
      let scene = await BABYLON.SceneLoader.ImportMeshAsync('', rootUrl, fileName, this.currentScene)

      // remove all nodes added during import of materials
      this.currentScene.rootNodes.splice(legitNodes, this.currentScene.rootNodes.length - legitNodes)
      return scene;
    } catch (ex) {
      // throw new Error(ex);
      console.error("!!! ERROR loading material", URL);
    }
  }

  async loadMainScene(assetUrl) {
    var rootUrl = BABYLON.Tools.GetFolderPath(assetUrl);
    var fileName = BABYLON.Tools.GetFilename(assetUrl);
    // console.log("engine loadMainScene")
    // console.log(assetUrl)
    // console.log(rootUrl)
    // console.log(fileName)
    try {
      let scene = await BABYLON.SceneLoader.LoadAsync(rootUrl, fileName, this.engine)
      // console.log("rootNodes:")
      // console.log(scene.rootNodes);
      // console.log("meshes:")
      // console.log(scene.meshes);
      // console.log("geometries:")
      // console.log(scene.geometries);
      // console.log("materials:")
      // console.log(scene.materials);
      // console.log("textures:")
      // console.log(scene.textures);
      if (this.currentScene) {
        // console.log("disposing current scene")
        this.currentScene.dispose();
      }

      this.currentScene = scene;
      this.assetsManager = new BABYLON.AssetsManager(scene);
      // this.currentScene.debugLayer.show({enablePopup:true});
      this.setDefaultMaterial(scene);

      console.log("SCENE CAMERAS:");
      console.log(scene.cameras);

      if (scene.cameras.length > 0) {
        while (scene.cameras.length) {
          console.log("REMOVE CAMERA:");
          console.log(scene.cameras[0]);
          scene.removeCamera(scene.cameras[0]);
        }
      }

      this.setDefaultCamera(scene);
      this.setDefaultEnvironment(scene);
      this.sceneLoaded({ name: fileName }, scene);
      this.setEvents();

      // res(scene);
      scene.whenReadyAsync().then(() => {
        this.startLoop();
        setTimeout(() => {
          this.stopLoop();
        }, 3000)
      });
      scene.stopAllAnimations();
      return scene;

    } catch (reason) {
      // console.log(reason);
      // rej(reason)
      this.sceneError({ name: fileName }, null, reason.message || reason);
      throw new Error(reason);
    }
  }

  setDefaultCamera(currentScene) {
    currentScene.createDefaultCamera(true);
    // console.log(this.currentPluginName)
    const camera = (<ArcRotateCamera>currentScene.activeCamera)
    console.log("MIN MAX Z:");
    console.log(camera.minZ);
    console.log(camera.maxZ);


    if (this.currentPluginName === "gltf") {
      // glTF assets use a +Z forward convention while the default camera faces +Z. Rotate the camera to look at the front of the asset.
      camera.alpha += Math.PI;
    }
    camera.useFramingBehavior = true;
    var framingBehavior = (<FramingBehavior>camera.getBehaviorByName("Framing"));
    framingBehavior.framingTime = 0;
    framingBehavior.elevationReturnTime = -1;

    if (currentScene.meshes.length) {
      var worldExtends = currentScene.getWorldExtends();
      camera.lowerRadiusLimit = null;
      framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max);
    }
    //// camera.fovMode = BABYLON.Camera.FOVMODE_HORIZONTAL_FIXED;
    camera.attachControl(this.canvas.nativeElement);

    this.setCamera(camera);
  }

  setCamera(camera) {
    // camera.attachControl(this.canvas.nativeElement);
    camera.pinchPrecision = 200 / camera.radius;
    camera.upperRadiusLimit = 5 * camera.radius;

    camera.wheelDeltaPercentage = 0.01;
    camera.pinchDeltaPercentage = 0.0003;
    camera.inertia = 0.5;
    camera.angularSensibilityX = 200;
    camera.angularSensibilityY = 200;
    this.camera = camera;
  }

  setDefaultEnvironment(currentScene) {
    if (this.currentPluginName === "gltf") {
      if (!currentScene.environmentTexture) {
        currentScene.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(this.skyboxURL, currentScene);
      }
      this.currentSkybox = currentScene.createDefaultSkybox(currentScene.environmentTexture, true, (currentScene.activeCamera.maxZ - currentScene.activeCamera.minZ) / 2, 0.17, false);
      console.log("ORIGINAL EXPOSURE", currentScene.environmentTexture.level);
      
      currentScene.environmentTexture.level = this.lightIntensity;
    }
    else {
      currentScene.createDefaultLight();
    }
  }

  setDefaultMaterial(scene) {
    this.defaultShader = new StandardMaterial("<default>", scene);
  }

  public setBackgroundSkybox(backgroundUrl: string, blur) {
    this.skyboxURL = backgroundUrl;
    this.skyboxBlur = blur;
    this.applyBackgroundSkybox();
    this.applySpecular();
    setTimeout(() => {
      this.render();
    }, 0.5)
  }

  public applyBackgroundSkybox() {
    if (this.currentScene.environmentTexture) {
      this.currentScene.environmentTexture.dispose();
    }
    this.currentScene.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(this.skyboxURL, this.currentScene);
    if (this.currentSkybox) {
      this.currentSkybox.dispose();
    }
    this.currentSkybox =
      this.currentScene.createDefaultSkybox(
        this.currentScene.environmentTexture,
        true,
        (this.currentScene.activeCamera.maxZ - this.currentScene.activeCamera.minZ) / 2,
        this.skyboxBlur,
        false);
  }

  public setSpecular(backgroundUrl: string, blur, lightIntensity: number) {
    // console.log("setSpecular");
    this.specularURL = backgroundUrl;
    this.lightIntensity = lightIntensity;
    this.applySpecular();
    this.render();
  }

  public applySpecular() {
    // console.log(this.specularURL);
    if (!this.specularURL) {
      return;
    }

    if (this.currentScene.environmentTexture) {
      this.currentScene.environmentTexture.dispose();
    }
    this.currentScene.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(this.specularURL, this.currentScene);
    // this.currentScene.environmentTexture = new BABYLON.HDRCubeTexture(this.specularURL.replace(".dds", ".hdr"), this.currentScene, 128, false, true, false, true);
    this.currentScene.environmentTexture.level = this.lightIntensity;
  }

  public setBackgroundTransparent() {
    try {
      this.currentSkybox.dispose();
    } catch (ex) { }
    this.currentScene.clearColor = new BABYLON.Color4(0, 0, 0, 0);
    this.render();
  }

  setEvents() {
    this.currentScene.onPointerDown = (ev, picking, evt) => {
      // this.postprocess.setSuperSampling(4)
      this._mouseDownTime = new Date();
      this.enableRender();
      this.startLoop();
    }
    // this.currentScene.onPointerMove = (ev, picking, evt) => {}
    this.currentScene.onPointerUp = (ev, picking, evt) => {
      let now = new Date();
      if (((now.getTime() - this._mouseDownTime.getTime()) < 250) || (this.ignoreClickTimeout)) {
        var el = this.currentScene.pick(this.currentScene.pointerX, this.currentScene.pointerY);
        // console.log(el.pickedMesh);

        this.onMeshClick(el.pickedMesh, picking)
      }
      this.disableRender();
    }
    this.currentScene.onPointerObservable.add((ev, es) => {
      if (ev.type == 8) {
        let _ev: WheelEvent = <any>ev.event;
        this.disableRender();
        this.render();
      }
    });
  }

  public stopLoop() {
    if (this.engine) {
      console.log("stopLoop")
      this._runRenderLoop = false;
      this.engine.stopRenderLoop();
    }
  }

  public startLoop() {
    if (!this.engine) {
      return;
    }
    if (this._runRenderLoop) {
      return;
    }
    console.log("startLoop")
    this._runRenderLoop = true;
    this.zone.runOutsideAngular(() => {
      console.log("outside")
      this.engine.runRenderLoop(() => {
        this.currentScene.render();
      });
    })
  }

  public simpleRender() {
    if (this.currentScene) {
      this.currentScene.render();
    }
  }

  public render() {
    if (!this.engine) {
      return;
    }
    this.startLoop()
    setTimeout(() => {
      if (!this._render) {
        this.stopLoop();
      }
    }, 3000)
  }

  public enableRender() {
    this._render = true;
  }

  public disableRender() {
    // console.log("disableRender")
    this._render = false;
    setTimeout(() => {
      if (!this._render) {
        this.stopLoop();
      }
    }, 3000)
  }

  public freezeCamera() {
    this.ignoreClickTimeout = true;
    this.camera.detachControl(this.canvas.nativeElement);
  }

  public unfreezeCamera() {
    this.ignoreClickTimeout = false;
    this.camera.attachControl(this.canvas.nativeElement);
  }


  onResize(data) {
    if (this.getEngine()) {
      this.getEngine().resize();
      this.render();
    }
  }

  public setExposure(level:number) {
    this.lightIntensity = level;
    this.currentScene.environmentTexture.level = level;
  }

  public sceneLoaded(sceneFile, babylonScene) { }

  public sceneError(sceneFile, babylonScene, message) { }

  public onMeshClick(mesh, picking) { }

  public onSceneLoad() { }


}
