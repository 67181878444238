//  DEPRECATED ON 20191210
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { PresetsService } from 'src/app/services/presets/presets.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { ImageViewerService } from 'src/app/services/imageviewer/image-viewer.service';

@Component({
  selector: 'app-presets',
  templateUrl: './presets.component.html',
  styleUrls: ['./presets.component.css']
})
export class PresetsComponent implements OnInit {
  @ViewChild('edit', {static: false}) edit: ElementRef;
  @Output() change = new EventEmitter<any>();

  editedItem = null;
  editedItemOriginalLabel = "";

  public list: any[] = [];
  private projectPath: string;

  constructor(
    public presets: PresetsService,
    private projects: ProjectsService,
    public imageViewer: ImageViewerService
  ) { }

  async ngOnInit() {
  }

  public setProject(projectpath) {
    this.projectPath = projectpath;
    this.presets.setUrl('/simplekonfiguratorprofile/projects/'
      + projectpath
      + '/presets');
    this.refresh();
  }

  public init() {
    this.presets.init();
  }

  public async refresh() {
    this.presets.refresh();
  }

  public async onAddClick() {
    let result = await this.presets.add({
      id: -1,
      label: "",
      name: "",
      data: {}
    });
    console.log(result);
    await this.refresh();
  }

  public async onDeleteClick(item) {
    if (confirm("Are you sure you want to delete preset: " + item.label)) {
      let result = await this.presets.delete(item.id);
      await this.refresh();
    }
  }

  public onPresetClick(item) {
    this.presets.applyPresetData(item);
    this.change.emit();
  }

  public onSaveClick(item) {
    this.presets.savePresetData(item);
  }

  public async save() {
    await this.presets.saveData();
  }

  onEditClick(item) {

  }

  onViewRendersClick(item) {
    console.log(item);
    console.log(this.projects.currentProject);

    // window.open('http://192.168.10.211:3002/#/design/pages/main1?path=T:/' + this.projectPath + '/Post/' + item.name);
    window.open(this.imageViewer.getUrlFromPreset(item, "presets"));
  }

  setEdited(item) {
    this.editedItem = item;
    this.editedItemOriginalLabel = item.label;
    setTimeout(() => {
      this.edit.nativeElement.focus();
      this.edit.nativeElement.select();
    }, 100);
  }

  isEdited(item) {
    if (this.isEdited) {
      return (this.editedItem === item);
    }
    return false;
  }

  async onLabelKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      await this.presets.edit(this.editedItem);
      this.editedItem = null;
    }

    if (event.key === 'Escape') {
      this.editedItem.label = this.editedItemOriginalLabel;
      this.editedItem = null;
    }
  }

  onLabelBlur() {
    this.editedItem.label = this.editedItemOriginalLabel;
    this.editedItem = null;
  }

}
