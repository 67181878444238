import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MaterialList } from 'src/app/services/materials/material-list';

@Component({
  selector: 'app-dropdowns',
  templateUrl: './dropdowns.component.html',
  styleUrls: ['./dropdowns.component.css']
})
export class DropdownsComponent implements OnInit {
  @Input() materials: MaterialList;
  @Output() tagChange = new EventEmitter<any>();


  constructor(
  ) { }

  ngOnInit() {
  }

  onTagClick(num) {
    this.tagChange.emit(num);
  }

  onSelect(item: any) {
    console.log(this.materials.multiSelectedItems);
    this.materials.filter();
  }
  onSelectAll(items: any) {
    this.materials.multiSelectedItems = items;
    console.log(this.materials.multiSelectedItems);
    this.materials.filter();
  }
  onDeSelectAll(items: any) {
    this.materials.multiSelectedItems = items;
    console.log(this.materials.multiSelectedItems);
    this.materials.filter();
  }
  onDeSelect(items: any) {
    console.log(this.materials.multiSelectedItems);
    this.materials.filter();  
  }
  onMultiKeyDown($event) {
    console.log($event);
    
  }
}
