import { Injectable } from '@angular/core';
import { Vector3, AbstractMesh, MeshBuilder, PBRMaterial, Texture, Scene } from 'babylonjs';

@Injectable({
  providedIn: 'root'
})
export class LogoService {
  decal: AbstractMesh;
  decalMat: PBRMaterial;

  constructor() { }

  CreateDecal(mesh: AbstractMesh, position: Vector3, logo: Texture) {
    if (this.decal) {
      this.decal.dispose();
    }

    this.decal = MeshBuilder.CreateDecal('logoDecal', mesh, { position });

    if (!this.decalMat) {
      this.decalMat = new PBRMaterial('DecalMaterial', logo.getScene());
      this.decalMat.zOffset = -0.1;
      this.decalMat.albedoTexture.hasAlpha = true;
    }

    this.decalMat.albedoTexture = logo;
  }
}
