import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {
  @Input() project: any = {};
  @Input() active: string = "";
  @Output() advanced: EventEmitter<any> = new EventEmitter();
  @Output() burgerMenu: EventEmitter<any> = new EventEmitter();
  @Output() shaderReplacement: EventEmitter<any> = new EventEmitter();
  @Output() tagsTab: EventEmitter<any> = new EventEmitter();
  @Output() tagFull = new EventEmitter<any>();
  adv: boolean = false;
  @Input() currentUser:{
    email: string,
    given_name: string,
    family_name: string,
  };

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onMenuClick(item: string) {
    let _ = {
      'root': () => {
        if (this.active === "root") {
          return
        }
        if (!this.project) {
          this.router.navigateByUrl("/")
        } else {
          this.router.navigateByUrl("/?project=" + this.project.path)
        }
      },
      'konfigurator': () => {
        if (this.active === "konfigurator") {
          return
        }
        if (this.project) {
          this.router.navigateByUrl("/?project=" + this.project.path)
        }
      }
    }[item]();
  }

  onAdvClick(event) {
    setTimeout(() => {
      this.advanced.emit(this.adv);
    }, 1);
  }

  onBurgerMenuClick() {
    if (!this.project) {
      this.router.navigateByUrl("/")
    } else {
      this.router.navigateByUrl("/?project=" + this.project.path)
    }
    // this.burgerMenu.emit();
  }

  onShaderReplacementClick() {

  }

  onTagsTabClick() {
    this.tagsTab.emit()
  }

  onTagFullClick() {
    this.tagFull.emit();
  }

}
