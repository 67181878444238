import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MaterialEditComponent } from "./material-edit.component";
import { MaterialEditService } from "./material-edit.service";
import { CheckboxMaterialSettingComponent } from './checkbox-material-setting/checkbox-material-setting.component';
import { MaterialSettingsDataService } from "./material-settings-data.service";
import { MappingSampleComponent } from './mapping-sample/mapping-sample.component';


@NgModule({
  declarations: [
    MaterialEditComponent,
    CheckboxMaterialSettingComponent,
    MappingSampleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  providers: [
    MaterialEditService,
    MaterialSettingsDataService,
  ],
  exports: [
    MaterialEditComponent
  ]
})
export class MaterialEditModule {

}
