import { Component, OnInit } from '@angular/core';
import { MaterialGroupsService } from "../../services/__OLD/material-groups/material-groups.service";

@Component({
  selector: 'app-material-groups',
  templateUrl: './material-groups.component.html',
  styleUrls: ['./material-groups.component.css']
})
export class MaterialGroupsComponent implements OnInit {

  constructor(public materialGroupsService:MaterialGroupsService) {
  }

  ngOnInit() {
  }

  onAddClick() {
    this.materialGroupsService.newGroup();
  }

  onDeleteClick(group) {
    this.materialGroupsService.deleteGroupByReference(group);
  }


  // OVERRIDABLE
  onSetMatClick(group) {}

  // OVERRIDABLE
  onSetNodeClick(group) {}

  // OVERRIDABLE
  onRGBClick(group) {}

  // OVERRIDABLE
  onNodeClick(group, node) {}

  // OVERRIDABLE
  onMaterialClick(group, material) {}

  // OVERRIDABLE
  onColorClick(group, color) {}

  // OVERRIDABLE
  onSelClick(group, color) {}

}
