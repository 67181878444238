import { Component, OnInit, Input, ViewChild, SimpleChanges, SimpleChange, OnChanges, Output, EventEmitter } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { PBRMaterial } from 'babylonjs';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Uploader } from '../uploader/uploader';
import { MaterialService } from 'src/app/services/uploader/material.service';

enum TextureType {
  Undefined,
  Albedo,
  Ambient,
  Normal,
  Emissive,
  Lightmap,
  MetallicRoughness,
}

@Component({
  selector: 'app-material-builder',
  templateUrl: './material-builder.component.html',
  styleUrls: ['./material-builder.component.css'],
  providers: [ MaterialService ]
})
export class MaterialBuilderComponent extends Uploader implements OnInit {


  constructor(
    public service: MaterialService,
    public projects: ProjectsService,
    public loader: LoaderService) {
    super(service, projects,loader);
    this.setPostfix("ImportedMaterials");

  }



  ngOnInit() {
    console.log("MB init");
  }


  handle(textureName: string) {
    const importedTexture = {
      filename: textureName,
    };

    // this.files.push(importedTexture);
  }

  async deleteFile(index: number) {
    this.files.splice(index, 1);
  }

}

