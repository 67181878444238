import { Component, OnInit } from '@angular/core';
import { AclService } from 'src/app/services/acl/acl.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  public showNewUserDialog: boolean = false;
  public newUser: string = "";

  objectKeys = Object.keys;

  constructor(
    public acl: AclService,
    public projects: ProjectsService
  ) { }

  ngOnInit() {
  }

  async onRemoveClick(user) {
    if (this.projects.currentProject) {
      console.log(user);
      
      await this.acl.removeUser(this.projects.currentProject.path, user);
    }
  }

  onCancelClick() {
    this.showNewUserDialog = false;
  }

  async onOkClick() {
    if (this.newUser.indexOf("@") >= 0) {
      if (this.projects.currentProject) {
        await this.acl.addUser(this.projects.currentProject.path, this.newUser);
        this.showNewUserDialog = false;
      }
    }

  }

  onAddClick() {
    this.showNewUserDialog = true;
  }


}
