import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, HostListener, OnDestroy, Input, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { EngineService } from '../../services/engine/engine.service';
import * as BABYLON from 'babylonjs';
import { Subscription, Observable, Subject } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { PostprocessService } from 'src/app/services/postprocess/postprocess.service';


export interface IViewerComponent {
  init(props:{
    sceneURL: string,
    materialSceneURLs: string,
    skyboxURL: string
  });

  getRootNodes();

  getAuxScenes();

  getCurrentScene():BABYLON.Scene;

  setOnMeshClick(cb);
}


@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeOut', [
      transition(':leave', [
        animate('.5s', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ViewerComponent implements OnInit, OnDestroy {
  @ViewChild('gl', { static: true }) canvas: ElementRef;
  @Output("onSceneLoad") onSceneLoad: EventEmitter<any> = new EventEmitter();
  @Input() whiteLoadBG: boolean = false;

  engine: EngineService;

  loadingSubscription: Subscription;
  loadingFlag: boolean;




  constructor(
    public zone: NgZone,
    engine: EngineService,
    private postprocess: PostprocessService) {
    this.loadingFlag = true;
    this.engine = engine;
    this.engine.zone = zone;

    this.engine.onSceneLoad = () => {
      console.log('Scene Loaded');
      this.onSceneLoad.emit({
        currentScene: this.engine.getCurrentScene(),
        auxScenes: this.engine.getAuxScenes()
      });
    };

  }

  ngOnInit() {
    console.log(typeof this.engine.getEngine())
    this.loadingSubscription = this.engine.loadingEmitter$.subscribe(
      isLoading => this.loadingFlag = isLoading
    );

  }

  ngOnDestroy() {
    console.log("Viewer destroy:");
    
    this.engine.destroy();
    // this.canvas.nativeElement.
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

  public init(props:{
      sceneURL: string,
      materialSceneURLs: string,
      skyboxURL: string
    }) {
    return this.engine.init({
      canvas: this.canvas,
      sceneURL: props.sceneURL,
      materialSceneURLs: props.materialSceneURLs,
      skyboxURL: props.skyboxURL
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.engine) {
      this.engine.resize$.next({event, canvas: this.canvas});
    }
  }

  public getRootNodes() {
    return this.engine.getCurrentScene().rootNodes;
  }

  public getAuxScenes() {
    return this.engine.getAuxScenes();
  }

  public getCurrentScene():BABYLON.Scene {
    return this.engine.getCurrentScene();
  }

  public setOnMeshClick(cb) {
    this.engine.onMeshClick = cb;
  }

  onMouseOut() {
    this.engine.disableRender()
  }

  onMouseOver() {
    this.engine.enableRender();
  }

  render() {
    this.engine.render();
  }

  click() {

  }

  setBackgroundSkybox(backgroundUrl:string, blur:number) {
    this.engine.setBackgroundSkybox(backgroundUrl, blur);
  }

  setBackgroundTransparent() {
    this.engine.setBackgroundTransparent();
  }

  setSpecular(backgroundUrl:string, blur:number, lightIntensity: number) {
    this.engine.setSpecular(backgroundUrl, blur, lightIntensity);
  }

  public capture(cb) {
    BABYLON.Tools.CreateScreenshotUsingRenderTarget(this.engine.engine, this.engine.camera, {width:400, height:200}, cb)  }

}
