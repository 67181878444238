import { Node as Node } from 'babylonjs';
import {  MaterialList } from './materials'

export class GeometryNodes {
  rawRootNodes: Node[];
  nodes: GeometryNode[];

  public setRootNodes(nodes: any) {
    this.rawRootNodes = nodes;
  }

  selectMesh(mesh) {

  }

  getNewGeometryNodeTree() {
    return this.getNewGeometryNodeTreeIterator(this.rawRootNodes);
  }

  getNewGeometryNodeTreeIterator(nodes: Node[]) {
    let list:GeometryNode[] = [];
    for (let node of nodes) {
      list.push()
    }
    return list;
  }

  public getTree():GeometryNode[] {
    return this.getTreeIterator(this.rawRootNodes, 0);
  }

  getTreeIterator(rootNodes: any[], level:number) {
    var nodes = [];
    for (let node of rootNodes) {
      nodes.push({
        type: "node",
        check: false,
        visible: true,
        name: node.name,
        nodes: this.getTreeIterator(node.getDescendants(true), level+1),
        expand: false,
        node: node,
        materials: [],
        level: level
      });
    }
    return nodes;
  }

  public getNodesFromTree(tree:any[]) {
    let list = [];

    this.getNodesFromTreeIterator(tree, list);

    return list;
  }

  getNodesFromTreeIterator(nodes:any[], list:any[]) {
    for (let node of nodes) {
      if (node.check) {
        list.push(node.node);
      }
      if (node.nodes.length > 0) {
        this.getNodesFromTreeIterator(node.nodes, list);
      }
    }
  }

}

export class GeometryNode {
  constructor(
    public label: string,
    public node: Node,
    public nodes: GeometryNodes[],
  ) {

  }
}