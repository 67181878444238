import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ScreenshotService } from 'src/app/services/screenshot/screenshot.service';
import { ViewerComponent } from '../../viewer/viewer.component';

@Component({
  selector: 'app-screenshot',
  templateUrl: './screenshot.component.html',
  styleUrls: ['./screenshot.component.css']
})
export class ScreenshotComponent implements OnInit {
  @Output() capture:EventEmitter<any> = new EventEmitter();
  captureUrl:any;
  viewer:ViewerComponent;
  @Input() visible: boolean = false;

  constructor(
    private screenshot:ScreenshotService
  ) { }

  ngOnInit() {
  }

  public init(viewer, projectName, config) {
    this.screenshot.load(viewer, projectName, config);
    this.captureUrl = this.screenshot.captureUrl;
    this.viewer = viewer;
  }

  public setScreenshot(image) {
    
  }

  onCaptureClick() {
    this.capture.emit();
    this.viewer.engine.startLoop();
    setTimeout(() => {
      this.screenshot.capture((data) => {
        this.captureUrl = data;
        this.capture.emit(data);
      });
      setTimeout(() => {
        this.viewer.engine.stopLoop();
      }, 500);
    }, 500);
  }

}
