import { Injectable, ElementRef, Output, EventEmitter } from '@angular/core';
import { EngineService } from '../engine/engine.service';
import { GeometryGroups, getMeshFullName } from '../scene/geometry-groups';
import { Mesh, MeshBuilder, StandardMaterial, Color3 } from 'babylonjs';

@Injectable({
  providedIn: 'root'
})
export class PinsService {
  @Output() pinModified:EventEmitter<any> = new EventEmitter();
  buttonSize: string = "72px"
  config: any;
  pinConfig:any;
  scene:any; 
  camera:any;
  engine:EngineService;
  pinWidth = 10;
  pinHeight = 10;
  geometryGroups:GeometryGroups;
  currentComment:any = null;
  picking:boolean = false;

  sphere:Mesh;
  material:StandardMaterial;
  selectedMaterial:StandardMaterial;


  constructor() { }

  public init(engine:EngineService, config:any, pinConfig:any, geometryGroups:GeometryGroups) {
    console.log("PinsService.init()");
    if (engine && config && pinConfig) {
      this.geometryGroups = geometryGroups;
      this.engine = engine;
      this.scene = engine.currentScene;
      this.camera = this.scene.activeCamera;

      this.pinConfig = pinConfig;
      this.config = config;

      this.sphere = MeshBuilder.CreateSphere("__3dk__comment", {
        segments: 32,
        diameter: 0.03,
      }, this.scene);
      this.material = new StandardMaterial("hotspotmaterial", this.scene);
      this.material.disableLighting = true;
      this.material.emissiveColor = new Color3(1, 0, 0);
      this.material.alpha = 0.5;
      this.selectedMaterial = new StandardMaterial("hotspotmaterial", this.scene);
      this.selectedMaterial.disableLighting = true;
      this.selectedMaterial.emissiveColor = new Color3(1, 0, 0);
      this.sphere.isVisible = false;
      this.sphere.material = this.material;
    
      let onMeshClick = engine.onMeshClick;

      engine.onMeshClick = (mesh, picking) => {
        onMeshClick(mesh, picking);
        if (this.picking) {
          console.log(picking.pickedPoint);
          
          this.pinConfig.mesh = mesh;
          this.pinConfig.pickedPoint = picking.pickedPoint
          console.log("customonpointerdown");
          if (picking.hit) {
            this.showPin();
            this.setPin(mesh, picking.pickedPoint);
          }
          this.onPinModified(this.currentComment);
          this.pinModified.emit(this.currentComment);
          this.engine.unfreezeCamera();
        } else {
          this.drawPin();
        }
        this.picking = false;
      }

      let onMouseMove = this.scene.onPointerMove
      this.scene.onPointerMove = (ev, picking, evt) => {
        if (onMouseMove) {
          onMouseMove(ev, picking, evt);
        }
        this.drawPin();
      }
    }


  }


  showPin() {
    this.sphere.isVisible = true;
    this.engine.simpleRender();
  }

  hidePin() {
    this.sphere.isVisible = false;
    this.engine.simpleRender();
  }

  drawPin() {
    if (this.pinConfig.pickedPoint) {
      this.sphere.position = this.pinConfig.pickedPoint;
    }
  }

  loadPin() {
    if (this.currentComment.pin) {
      this.setPin(this.geometryGroups.meshMap[this.currentComment.pin.mesh], 
        this.currentComment.pin.point);
    } else {
      this.hidePin();
    }
  }

  setPin(mesh, pickedPoint) {
    this.pinConfig.pickedPoint = pickedPoint;
    this.pinConfig.mesh = mesh;
    this.currentComment.pin = {
      mesh:getMeshFullName(mesh),
      point:this.pinConfig.pickedPoint
    }
    // mesh.position.x = this.pinConfig.pickedPoint.x;
    // mesh.position.y = this.pinConfig.pickedPoint.y;
    this.drawPin();
  }

  public pick() {
    if (this.currentComment) {
      this.picking = true;
      this.engine.freezeCamera();
    }
  }

  public setCurrentComment(comment:any) {
    console.log(comment);
    this.currentComment = comment;
    this.loadPin();
    this.drawPin();
  }

  public onPinModified(comment) {}
}
