import { OrganizationalMaterialsService } from 'src/app/services/materials/organizational/organizational-materials.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { MaterialList } from 'src/app/services/materials/material-list';
import { Input } from '@angular/core';

export class MaterialListComponent {
    @Input() title: string;
    
    constructor(
        public materials: MaterialList,
        public projects: ProjectsService
      ) { }
    
    onDeselectAllMaterials() {
        this.materials.deselectAll();
    }

    onMaterialClick(file) {
        this.materials.select(file);
    }

    onTagClick(id) {
        console.log("ONTAGCLICK", id, this.materials.selectedTags);
        this.materials.filter();
        this.materials.refreshTags();
    }

}