import {
  Component,
  OnInit,
  NgZone,
  ElementRef,
  EventEmitter,
  ViewChild,
  Output,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  ChangeDetectorRef,
  AfterViewChecked
} from '@angular/core';
import {RenderService} from 'src/app/services/render/render.service';
import {PresetsService} from 'src/app/services/presets/presets.service';
import {CamerasService, CameraItem} from 'src/app/services/cameras/cameras.service';
import {BackgroundsService} from 'src/app/services/backgrounds/backgrounds.service';
import {ImageViewerService} from 'src/app/services/imageviewer/image-viewer.service';
import {EngineService} from 'src/app/services/engine/engine.service';
import {SimpleKonfiguratorProfileSceneService} from 'src/app/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';
import {KonfiguratorSettingsService} from 'src/app/services/konfiguratorsettings/konfigurator-settings.service';
import {OptionGroups, OptionGroup} from 'src/app/services/scene/option-groups';
import {OptionGroupsService} from 'src/app/services/optiongroups/option-groups.service';
import {Materials} from 'src/app/services/scene/materials';
import {GeometryGroups} from 'src/app/services/scene/geometry-groups';
import {MaterialPreviewsService} from 'src/app/materialpreviews/material-previews.service';
import { GlobalEventsActions } from 'src/app/services/scene/simpleprofile/global-events-actions.service';

import {QUALITY_3DK, QUALITY_2DK, RESOLUTION_3DK, RESOLUTION_2DK, getResolution2DK, getResolution3DK} from '../../../data/resolutions';


@Component({
  selector: 'app-rendering',
  templateUrl: './rendering.component.html',
  styleUrls: ['./rendering.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderingComponent implements OnInit, OnChanges {
  @ViewChild('edit', {static: false}) edit: ElementRef;
  @Output() change = new EventEmitter<any>();
  @Input() editMode: string = 'presets'; // presets, presetsedit, advancedrendering
  // changeDetection: ChangeDetectionStrategy.OnPush

  cameraList: CameraItem[];

  public currentLights: any;
  public currentBackgrounds: any;

  editedItem = null;
  editedItemOriginalLabel = '';

  currentResolution = 'hd';
  currentLandscape = false;

  public QUALITY_3DK = QUALITY_3DK;
  public QUALITY_2DK = QUALITY_2DK;
  public RESOLUTION_3DK = [];
  public RESOLUTION_2DK = [];


  constructor(
    public render: RenderService,
    public presets: PresetsService,
    public cameras: CamerasService,
    public bg: BackgroundsService,
    public imageViewer: ImageViewerService,
    public engine: EngineService,
    public scene: SimpleKonfiguratorProfileSceneService,
    public konfiguratorSettings: KonfiguratorSettingsService,
    public optionService: OptionGroupsService,
    public geometryGroups: GeometryGroups,
    public previews: MaterialPreviewsService,
    private ref: ChangeDetectorRef,
    public globals: GlobalEventsActions,
  ) {
    console.log('RENGERING create');
    this.render.resolutionChange$.subscribe(data => {

    });
  }

  async ngOnInit() {
    console.log('RENGERING ngOnInit');
    console.log("PRESET LIST", this.presets.list);
    
    this.currentBackgrounds = this.bg.getCurrentSkyboxes();
    this.currentLights = this.bg.getCurrentLights();

    this.refreshCameras();

    this.RESOLUTION_2DK = await getResolution2DK();
    this.RESOLUTION_3DK = await getResolution3DK();
  }

  refreshCameras() {
    console.log('REFRESHCAMERAS');
    this.presets.list.forEach(preset => {
      this.cameras.list.forEach(cam => {
        if (!preset.camera[cam.label]) {
          preset.camera[cam.label] = {
            selected: false,
            quality: '',
            resolution: '',
            light: '',
            lightURL: '',
            background: '',
            format: ''
          };
        }
      });
    });
  }

  ngOnChanges() {
    console.log('RENGERING ngOnChanges');
  }

  // ngAfterViewChecked() {
  //   console.log("RENGERING ngAfterViewChecked");
  // }


  public getCamera(preset, camera) {
    // console.log(camera, preset);

    const result = new CameraPreset(
      preset,
      camera,
      this.presets);


    return result;
  }

  public getPresetCameraLightName(camera) {
    // console.log(camera, camera.light.split("/").slice(-1)[0])
    return camera.light.split('/').slice(-1)[0].replace('.dds', '');
    // return camera.light;
  }

  public setPresetCameraLightName(camera, $event) {
    let newName = $event.target.value;
    if (newName == '') {
      camera.light = '';
      return;
    }
    // /simplekonfiguratorprofile/projects/Douchebags/Common/Environment/Imported/LS_360_Hugger_30L_32F_F01/128/LS_360_Hugger_30L_32F_F01.dds
    camera.light = '/simplekonfiguratorprofile/projects/' + this.scene.currentProject.organization + '/Common/Environment/Imported/' + newName + '/128/' + newName + '.dds';
    console.log(camera.light);
  }


  async onRenderDefaultPresetsClick() {
    await this.render.renderDefaultPresets();
  }

  async onRenderAllPresetsClick() {
    await this.render.renderAllPresets();
  }

  async onRenderSelectiveClick() {
    // await this.render.setRenderSettings(this.render.settings);
    await this.render.save();
    await this.render.renderSelective(null);
  }

  async onPrenderPresetClick(preset) {
    if (preset != this.presets.currentPreset) {
      this.onPresetClick(preset);
    }
    this.scene.save();
    // await this.render.setRenderSettings(this.render.settings);
    await this.render.save();
    await this.render.renderSelective(preset['label']);
  }

  async onRenderPresetCameraClick(preset, camera: CameraItem) {
    if (preset != this.presets.currentPreset) {
      this.onPresetClick(preset);
    }
    await this.scene.save();
    // await this.render.setRenderSettings(this.render.settings);
    await this.render.save();
    await this.render.renderSelective(preset['label'], camera.label);
  }

  async onGenerateMaClick(preset) {
    this.onPresetClick(preset);
    this.scene.save();
    // await this.render.setRenderSettings(this.render.settings);
    await this.render.save();
    await this.render.generateSelective(preset['label']);
  }

  onImageViewerClick(preset) {
    window.open(this.imageViewer.getUrlFromPreset(preset, 'presets'));
  }

  public async onDeleteClick(item) {
    if (confirm('Do you want to delete preset ' + item.label)) {
      let result = await this.presets.delete(item.id);
      await this.refresh();
    }
  }

  public async onAddClick(presetToCopy: any = null) {
    let cameras = {};
    this.cameras.list.forEach(cam => {
      if (!cameras[cam.label]) {
        cameras[cam.label] = {
          selected: false,
          quality: '',
          resolution: '',
          light: '',
          lightURL: '',
          background: '',
          format: ''
        };
      }
    });

    let result = await this.presets.add({
      id: -1,
      label: "",
      name: "",
      data: {},
      camera: cameras,
    });
    // this.refreshCameras();

    console.log(result);
    console.log(cameras);
    await this.refresh();
    // this.presets.list.forEach(preset => {
    //   if (preset.id == result['id']) {
    //     this.presets.savePresetData(preset);
    //     this.presets.select(preset);
    //   }
    // });
  }

  public async onCopyClick() {
    const selectedPresets = this.presets.list.filter(x => x.selected);
    if (selectedPresets.length < 1) {
      return;
    }
    for (let preset of selectedPresets) {
      await this.presets.add({
        id: -1,
        label: '',
        name: '',
        data:  preset.data ?  preset.data : {},
        camera:  preset.camera ?  preset.camera : {},
        optionGroups:  preset.optionGroups ?  preset.optionGroups : {},
      });
    }
    await this.refresh();
  }

  public onSaveClick() {
    this.presets.saveData();
  }

  public onPresetClick(item) {
    this.presets.applyPresetData(item);
    this.presets.select(item);
    this.engine.render();
    this.change.emit();
  }

  setEdited(item) {
    this.editedItem = item;
    this.editedItemOriginalLabel = item.label;
    setTimeout(() => {
      this.edit.nativeElement.focus();
      this.edit.nativeElement.select();
    }, 100);
  }

  isEdited(item) {
    if (this.isEdited) {
      return (this.editedItem === item);
    }
    return false;
  }

  onLabelBlur() {
    if (!this.editedItem) {
      return;
    }
    // this.editedItem.label = this.editedItemOriginalLabel;
    // this.editedItem = null;
  }

  async onLabelKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      await this.presets.edit(this.editedItem);
      this.editedItem = null;
    }

    if (event.key === 'Escape') {
      this.editedItem.label = this.editedItemOriginalLabel;
      this.editedItem = null;
    }
  }

  public async refresh() {
    this.presets.refresh();
  }

  public onResolutionChange($event) {
    console.log('onResolutionChange', $event.target.value);
    this.render.resolutionChange$.next();
    this.change.emit();
  }

  public onSelectAll() {
    this.presets.selectAll();
  }

  public onDeselectAll() {
    this.presets.deselectAll();
  }

  public onRenderFromAdvancedMenuClick() {
    this.scene.save$.next();
    setTimeout(() => {
      this.render.renderFromAdvancedMenu();
    }, 1000);
  }

  public onRenderFromAdvancedMenuSelectedClick() {
    this.scene.save$.next();
    setTimeout(() => {
      this.render.renderFromAdvancedMenu({
        color: this.render.materials,
        option: this.render.geometries
      });
    }, 1000);
  }

  public onRenderFromAdvancedMenuSelectedV2Click() {
    this.scene.save$.next();
    setTimeout(() => {
      this.render.renderFromAdvancedMenuV2({
        color: this.render.materials,
        option: this.render.geometries
      });
    }, 1000);
  }

  public onSetCameraSelected(camera, $event) {
    console.log(camera);
    console.log($event.target.checked);
    this.presets.list.forEach(p => {
      for (let ckey in p.camera) {
        let cam: any = p.camera[ckey];
        if (ckey == camera.label) {
          cam.selected = $event.target.checked;
        }
      }
    });
    this.change.emit();
  }

  public onSetCameraLight(camera, $event) {
    console.log($event.target.value);
    this.presets.list.forEach(p => {
      console.log(p.camera);
      for (let ckey in p.camera) {
        let cam: any = p.camera[ckey];
        if (ckey == camera.label) {
          cam.light = $event.target.value;
        }
      }
    });
    this.change.emit();

  }

  public onLightingChange($event) {
    console.log($event);

  }

  public onFormatChange($event) {

  }

  onRenderClick(material: string) {
    // this.render.renderFromAdvancedMenu({
    //   color: material
    // })
    if (this.render.materials.has(material)) {
      this.render.materials.delete(material);
    } else {
      this.render.materials.add(material);
    }
  }

  onEditModeClick(mode) {
    this.editMode = mode;
    console.log(this.editMode);

  }

  async onRenderSelected() {
    await this.scene.save();
    // await this.render.setRenderSettings(this.render.settings);
    await this.render.save();
    await this.presets.save();
    await this.render.renderSelective();
  }

  onPresetNukeGenerate(preset) {
    console.log("onPresetNukeGenerate", preset);
    this.globals.actions.presets.postprocess.generate.next(preset);
  }


  onPresetNukeSubmit(preset) {
    console.log("onPresetNukeSubmit", preset);
    this.globals.actions.presets.postprocess.submit.next(preset);
  }


}

class CameraPreset {
  constructor(
    private _preset,
    private _camera,
    private _presets: PresetsService) {
    if (!this._preset.camera) {
      this._preset.camera = {};
    }
    if (!this._preset.camera[this._camera.label]) {
      console.log(this._camera.label);

      this._preset.camera[this._camera.label] = {
        selected: false,
        quality: '',
        resolution: '',
        light: '',
        lightURL: '',
        background: '',
        format: ''
      };
    }
  }

  get selected() {
    return this._preset.camera[this._camera.label].selected;
  }

  set selected(selected) {
    this._preset.camera[this._camera.label].selected = selected;
    // this._presets.edit(this._preset);
  }


  get quality() {
    return this._preset.camera[this._camera.label].quality;
  }

  set quality(quality) {
    this._preset.camera[this._camera.label].quality = quality;
    // this._presets.edit(this._preset);
  }


  get light() {
    return this._preset.camera[this._camera.label].light;
  }

  set light(light) {
    console.log(light);

    this._preset.camera[this._camera.label].light = light;
    // this._presets.edit(this._preset);
  }


  get background() {
    return this._preset.camera[this._camera.label].background;
  }

  set background(background) {
    this._preset.camera[this._camera.label].background = background;
    // this._presets.edit(this._preset);
  }


  get resolution() {
    return this._preset.camera[this._camera.label].resolution;
  }

  set resolution(resolution) {
    this._preset.camera[this._camera.label].resolution = resolution;
    // this._presets.edit(this._preset);
  }


  get format() {
    return this._preset.camera[this._camera.label].format;
  }

  set format(format) {
    this._preset.camera[this._camera.label].format = format;
    // this._presets.edit(this._preset);
  }


}
