import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Environment, BackgroundsService } from 'src/app/services/backgrounds/backgrounds.service';
import { PostprocessService } from 'src/app/services/postprocess/postprocess.service';
import { EngineService } from 'src/app/services/engine/engine.service';

@Component({
  selector: 'app-bg-light',
  templateUrl: './bg-light.component.html',
  styleUrls: ['./bg-light.component.css']
})
export class BgLightComponent implements OnInit {
  @Output() backgroundChange: EventEmitter<Environment> = new EventEmitter();
  @Output() specularChange: EventEmitter<Environment> = new EventEmitter();
  project: any;
  config: any;
  @Input() visible: boolean = false;

  backgroundLabel: string = "";
  lightsLabel: string = "";

  showCustomBackground: boolean = false;
  showCustomSpecular: boolean = false;
  currentEnvironment: Environment = new Environment();

  constructor(
    public backgrounds: BackgroundsService,
    public engine: EngineService,
    public postprocess: PostprocessService
  ) { }

  ngOnInit() {
  }

  load(project, config) {
    // console.log("projectproperties load:");
    // console.log(project);
    // console.log(config);


    this.project = project;
    this.config = config;
    if (this.config.background) {
      this.currentEnvironment = new Environment(this.config.background);
    } else {
      this.currentEnvironment = new Environment();
    }
    console.log(project, config);
    
    this.backgrounds.setConfig(project, config);
  }

  onBackgroundClick() {
    this.backgroundChange.emit(this.currentEnvironment);
  }

  onLightsClick() {
    this.backgroundChange.emit(this.currentEnvironment);
  }

  onBackgroundOptionClick() {
    this.currentEnvironment.backgroundType = "skybox";
    let bg = this.backgrounds.getSkyboxById(this.currentEnvironment.backgroundSkyboxId);
    this.currentEnvironment.backgroundSkyboxId = bg.id;
    this.currentEnvironment.backgroundSkyboxURL = bg.url;
    this.currentEnvironment.backgroundSkyboxBlur = bg.blur;
    // console.log(this.currentEnvironment);

    this.backgroundChange.emit(this.currentEnvironment);
  }

  onSpecularOptionClick() {
    let bg = this.backgrounds.getLightById(this.currentEnvironment.lightSkyboxId);
    console.log("LIGHTCHANGE", this.currentEnvironment.lightSkyboxId, bg.id, bg.url);
    this.currentEnvironment.lightSkyboxId = bg.id;
    this.currentEnvironment.lightSkyboxURL = bg.url;
    this.currentEnvironment.lightSkyboxBlur = bg.blur;
    this.specularChange.emit(this.currentEnvironment);
  }

  onColorChange() {
    this.backgroundChange.emit(this.currentEnvironment);
  }

  onFormChange() {
    // console.log("onFormChange");
    this.backgroundChange.emit(this.currentEnvironment);
  }

  onLightIntensityChange($event) {
    this.engine.setExposure($event.target.value);
    this.engine.render();
    this.specularChange.emit(this.currentEnvironment);
  }

  onExposureChange($event) {
    this.postprocess.exposure = $event.target.value;
    this.postprocess.updateExposure();
  }

}
