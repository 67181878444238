import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public usersList: any;
  public user: any;
  
  constructor(private http:HttpClient) { }

  public async getCurrent() {
    let result = await this.http.get("/users/current").toPromise();
    return result;
  }

  public async getAllUsersList() {
    let result = <any>await this.http.get("/users/all").toPromise();
    this.usersList = result;
    return result;
  }

  public setUser(user) {
    this.user = user;
  }

  public async getUserByEmail(email) {
    let list:[] = await <any>this.getAllUsersList();
    return list.filter(item => item['email'] == email)[0];
  }

  public async addUser(email) {
    let result = await this.http.post("/users", {"email":email}).toPromise();
    console.log(result);
  }

  public async updateUser(user?) {
    if (user) {
      let result = await this.http.post("/users", user).toPromise();
      console.log(result);
    } else {
      let result = await this.http.post("/users", this.user).toPromise();
      console.log(result);
    }
    this.getAllUsersList();
    
  }

}
