import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SimpleKonfiguratorProfileSceneService } from 'src/app/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { OptionGroupList, OptionGroup } from 'src/app/services/scene/option-groups';

@Component({
  selector: 'app-advanced-sub-menu',
  templateUrl: './advanced-sub-menu.component.html',
  styleUrls: [
    './advanced-sub-menu.component.css'
  ]
})
export class AdvancedSubMenuComponent implements OnInit {
  @Input() optionGroupList: OptionGroupList;
  @Input() prefix: string = "";
  @Output() modification = new EventEmitter<any>();

  constructor(
    public scene: SimpleKonfiguratorProfileSceneService
  ) {

  }

  ngOnInit() {
    if (!this.optionGroupList) {
      this.optionGroupList = this.scene.optionGroups.optionGroupList;
    }
  }

  onOptionClick(option: OptionGroup) {
    option.setChecked(true);
    if (option.globalOptionLinkLabel) {
      this.scene.optionGroups.setGlobalLink(option.globalOptionLinkLabel, option.linkIndex);
    }
    this.modification.emit();
  }

}
