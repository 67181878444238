import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
import { GeometryGroup, GroupLayer } from 'src/app/services/scene/geometry-groups';
import { OptionGroup, OptionGroups, OptionGroupList } from 'src/app/services/scene/option-groups';
import { SimpleKonfiguratorProfileSceneService } from 'src/app/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { OptionGroupsService } from 'src/app/services/optiongroups/option-groups.service';
import { RenderService } from 'src/app/services/render/render.service';
import { IOptionGroup } from 'src/app/services/scene/simpleprofile/simplekonfiguratorprofileconfig.interface';

@Component({
  selector: 'app-option-groups',
  templateUrl: './option-groups.component.html',
  styleUrls: ['./option-groups.component.css']
})
export class OptionGroupsComponent implements OnInit {

  @Input() optionGroupList: OptionGroupList;
  @Input() showHeaders: boolean = true;
  @Input() showAssignments = true;
  @Input() showRendering = false;

  @Output() modification: EventEmitter<any> = new EventEmitter();
  @Output() refreshAllGroups: EventEmitter<any> = new EventEmitter();
  @ViewChild("optionEdit", { static: false }) optionEdit: ElementRef;

  // IN USE TO DnD FROM GEOMETRY GROUPS TO OPTION GROUPS ONLY
  @Input() drag: boolean = false;
  @Input() dragGroup: GeometryGroup = null;
  @Input() dragLayer: GroupLayer = null;
  @Input() dragOption: OptionGroup = null;
  @Input() dragOverItem: any = null;
  @Input() showMaterialLinkLabels = true;
  @Input() showLongNames: boolean = false;

  @Output() renderOption: EventEmitter<OptionGroup> = new EventEmitter();

  currentEditingItem: any = null;
  currentEditingValue: string;

  public optionGroups: OptionGroups;



  constructor(
    public scene: SimpleKonfiguratorProfileSceneService,
    public optionService: OptionGroupsService,
    public render: RenderService
  ) { 
    this.optionGroups = this.scene.optionGroups;
  }

  ngOnInit() {
  }

  onOptionMouseDown(option) {
    this.optionService.drag = true;
    this.optionService.dragOption = option;
    this.optionService.dragGroup = null;
    this.optionService.dragLayer = null;
  }

  onOptionMouseUp(option: OptionGroup) {
    console.log("MOUSEUP0.1", option, this.drag, this.dragGroup, this.dragLayer, this.dragOption);
    console.log("MOUSEUP0.2", this.optionService.drag, this.optionService.dragGroup, this.optionService.dragLayer, this.optionService.dragOption);

    if (this.optionService.drag) {
      console.log("MOUSEUP2", option, this.optionService.dragGroup, this.optionService.dragLayer);
      if (!this.optionService.dragGroup && !this.optionService.dragLayer && (this.optionService.dragOption != option)) {
        console.log("MOUSEUP2", option, this.optionService.dragOption);
        this.optionService.dragOption.parent.list =
          this.optionService.dragOption.parent.list.filter(opt => opt !== this.optionService.dragOption);
        // option.optionGroups.list = [...this.optionService.dragOption.optionGroups.list, ...option.optionGroups.list];
        // option.optionGroups.list.forEach(opt => {
        //   opt.parent = option.optionGroups;
        // })
        // option.geometryGroups = [...this.optionService.dragOption.geometryGroups, ...option.geometryGroups];
        // option.geometryLayers = [...this.optionService.dragOption.geometryLayers, ...option.geometryLayers];
        option.optionGroups.list = [...option.optionGroups.list, this.optionService.dragOption];
        option.optionGroups.list.forEach(opt => {
          opt.parent = option.optionGroups;
        })
      }

      if (this.optionService.dragGroup && (this.optionService.dragOption != option)) {
        if (option == this.optionService.dragOption) {
          return
        }
        if (this.optionService.dragOption) {
          this.optionService.dragOption.geometryGroups =
            this.optionService.dragOption.geometryGroups.filter(grp => grp !== this.optionService.dragGroup);
        }
        option.geometryGroups.push(this.dragGroup);
        this.modification.emit();
      }
      if (this.optionService.dragLayer && (this.optionService.dragOption != option)) {
        if (option == this.optionService.dragOption) {
          return
        }
        if (this.optionService.dragOption) {
          this.optionService.dragOption.geometryLayers =
            this.optionService.dragOption.geometryLayers.filter(lay => lay !== this.optionService.dragLayer);
        }
        option.geometryLayers.push(this.optionService.dragLayer);
        this.modification.emit();
      }
      this.optionService.drag = false;
      this.optionService.dragGroup = null;
      this.optionService.dragLayer = null;
      this.optionService.dragOption = null;
    } else {
      console.log("MOUSEUP1", option, this.dragGroup, this.dragLayer);

      if (this.dragGroup) {
        option.geometryGroups.push(this.dragGroup);
        this.modification.emit();
      }
      if (this.dragLayer) {
        option.geometryLayers.push(this.dragLayer);
        this.modification.emit();
      }
      this.drag = false;
      this.dragGroup = null;
      this.dragLayer = null;
      this.dragOption = null;
    }
  }

  onOptionGroupMouseUp() {
    this.optionService.dragOption.parent.list =
      this.optionService.dragOption.parent.list.filter(opt => opt !== this.optionService.dragOption);
    this.optionGroupList.list = [...this.optionGroupList.list, this.optionService.dragOption];
    this.optionGroupList.list.forEach(opt => {
      opt.parent = this.optionGroupList;
    })
    this.optionService.drag = false;
    this.optionService.dragOption = null;
    this.optionService.dragGroup = null;
    this.optionService.dragLayer = null;
    this.optionGroupList.list.sort((a, b) => {
      if (a.linkLabel > b.linkLabel) {
        return 1;
      }
      if (a.linkLabel == b.linkLabel) {
        return 0;
      }
      if (a.linkLabel < b.linkLabel) {
        return -1;
      }
    })
  }


  public onOptionCheckClick(option: OptionGroup) {
    option.setChecked(!option.checked)
    if (option.globalOptionLinkLabel) {
      this.scene.optionGroups.setGlobalLink(option.globalOptionLinkLabel, option.linkIndex);
    }
    this.refreshAllGroups.emit();
  }

  onOptionDoubleClick(option) {
    this.currentEditingItem = option;
    this.currentEditingValue = option.label;
    setTimeout(() => {
      console.log(this.optionEdit);

      this.optionEdit.nativeElement.focus();
      this.optionEdit.nativeElement.select();
    }, 0)
  }

  onOptionEditingKeyUp($event) {
    if ($event.key == "Escape") {
      this.currentEditingValue = "";
      this.currentEditingItem = null;
    }
    if ($event.key == "Enter") {
      this.currentEditingItem.label = this.currentEditingValue;
      this.currentEditingItem = null;
    }
  }

  onOptionGroupLinkClick(option: OptionGroup, link) {
    option.linkLabel = link;
    this.modification.emit();
  }

  onGlobalOptionLinkClick(option: OptionGroup, link) {
    option.globalOptionLinkLabel = link;
    this.optionGroups.registerAll();
    this.modification.emit();
  }

  onDeleteGroupClick(option: OptionGroup) {
    option.delete()
    this.modification.emit();
    
    // if ((option.geometryGroups.length == 0) &&
    //   (option.geometryLayers.length == 0) &&
    //   (option.optionGroups.list.length == 0)) {
    //   option.delete()
    //   this.modification.emit();
    // } else {
    //   alert("Deleting non empty group si forbidden");
    // }
  }

  public onAddSubGroupClick(option: OptionGroup) {
    console.log(this.optionGroups);
    if (this.optionGroups) {
      this.optionGroups.addSubGroup(option);
    } else {
      this.scene.optionGroups.addSubGroup(option);
    }
  }

  public onDuplicateSubGroupClick(option: OptionGroup) {
    console.log(this.optionGroups);
    if (this.optionGroups) {
      this.optionGroups.duplicateSubGroup(option);
    }
  }


  onRemoveGroupClick(option, group) {
    if (this.optionGroups) {
      this.optionGroups.removeGroup(option, group);
    } else {
      this.scene.optionGroups.removeGroup(option, group);
    }
  }

  onRemoveLayerClick(option, layer) {
    if (this.optionGroups) {
      this.optionGroups.removeLayer(option, layer);
    } else {
      this.scene.optionGroups.removeLayer(option, layer);
    }
  }

  onAddOptionGroup() {
    if (this.optionGroupList) {
      console.log("ADD1");
      this.optionGroupList.add();
    } else {
      console.log("ADD2");
      console.log(this.scene.optionGroups, this.scene.optionGroups.optionGroupList);
      if (!this.scene.optionGroups.optionGroupList) {
        this.scene.optionGroups.optionGroupList = new OptionGroupList(this.scene.optionGroups);
      }
      this.scene.optionGroups.optionGroupList.add();
    }
    this.modification.emit();
  }

  canShow() {
    if (this.optionGroupList.list.length > 0) {
      return true;
    }
    return false;
  }

  onGroupMouseDown(option, group) {
    this.optionService.drag = true;
    this.optionService.dragGroup = group;
    this.optionService.dragOption = option;
    console.log("MOUSEDOWN", this.dragGroup, this.dragLayer);
    this.modification.emit();
  }

  onLayerMouseDown(option, layer) {
    this.optionService.drag = true;
    this.optionService.dragLayer = layer;
    this.optionService.dragOption = option;
    console.log("MOUSEDOWN", this.dragGroup, this.dragLayer);
    this.modification.emit();
  }


  onGroupMouseUp(option, group) {
    console.log("MOUSEUP", this.optionService.dragGroup, this.optionService.dragLayer);
    if (this.dragGroup == group) {
      return
    }
    this.onOptionMouseUp(option);
    this.optionService.drag = false;
    this.optionService.dragGroup = null;
    this.optionService.dragOption = null;
  }

  onLayerMouseUp(option, layer) {
    console.log("MOUSEUP", this.optionService.dragGroup, this.optionService.dragLayer);
    if (this.dragLayer == layer) {
      return
    }
    this.onOptionMouseUp(option);
    this.optionService.drag = false;
    this.optionService.dragLayer = null;
    this.optionService.dragOption = null;
  }

  onRenderClick(option) {
    // this.render.renderFromAdvancedMenu({
    //   option: option.getFullLink()
    // })
    if (this.render.geometries.has(option)) {
      this.render.geometries.delete(option);
    } else {
      this.render.geometries.add(option);
    }
    console.log("GEOS", this.render.geometries);
    
  }

  onShadowCatcherClick(layer: GroupLayer, optionGroup: IOptionGroup) {
    if (!optionGroup.shadowCatchers) {
      optionGroup.shadowCatchers = [];
    }
    if (optionGroup.shadowCatchers.indexOf(layer.uuid) >= 0) {
      optionGroup.shadowCatchers = optionGroup.shadowCatchers.filter(o => o != layer.uuid);
    } else {
      optionGroup.shadowCatchers.push(layer.uuid);
    }
    console.log("SC", optionGroup.shadowCatchers);
    
  }

  onLambertClick(layer: GroupLayer, optionGroup: IOptionGroup) {
    if (!optionGroup.lambert) {
      optionGroup.lambert = [];
    }
    if (optionGroup.lambert.indexOf(layer.uuid) >= 0) {
      optionGroup.lambert = optionGroup.lambert.filter(o => o != layer.uuid);
    } else {
      optionGroup.lambert.push(layer.uuid);
    }
    console.log("L", optionGroup.lambert);
    
  }

  onShadowCatcherRCClick(layer: GroupLayer, optionGroup: IOptionGroup) {
    if (!optionGroup.shadowCasters) {
      optionGroup.shadowCasters = [];
    }
    if (optionGroup.shadowCasters.indexOf(layer.uuid) >= 0) {
      optionGroup.shadowCasters = optionGroup.shadowCasters.filter(o => o != layer.uuid);
    } else {
      optionGroup.shadowCasters.push(layer.uuid);
    }
    console.log("SC RC", optionGroup.shadowCasters);
    
  }

  isLayerShadowCatcher(layer: GroupLayer, optionGroup: IOptionGroup) {
    if (!optionGroup.shadowCatchers) {
      optionGroup.shadowCatchers = [];
    }
    return optionGroup.shadowCatchers.indexOf(layer.uuid) >= 0;
  }

  isLambert(layer: GroupLayer, optionGroup: IOptionGroup) {
    if (!optionGroup.lambert) {
      optionGroup.lambert = [];
    }
    return optionGroup.lambert.indexOf(layer.uuid) >= 0;
  }

  isLayerReceivingShadows(layer: GroupLayer, optionGroup: IOptionGroup) {
    if (!optionGroup.shadowCasters) {
      optionGroup.shadowCasters = [];
    }
    return optionGroup.shadowCasters.indexOf(layer.uuid) >= 0;
  }

  onShadowCatcherNoDisplacementClick(layer: GroupLayer, optionGroup: IOptionGroup) {
    if (!optionGroup.noDisplacement) {
      optionGroup.noDisplacement = [];
    }
    if (optionGroup.noDisplacement.indexOf(layer.uuid) >= 0) {
      optionGroup.noDisplacement = optionGroup.noDisplacement.filter(o => o != layer.uuid);
    } else {
      optionGroup.noDisplacement.push(layer.uuid);
    }
    console.log("NoD", optionGroup.noDisplacement);
    
  }


  isLayerShadowCatcherNoDisplacement(layer: GroupLayer, optionGroup: IOptionGroup) {
    if (!optionGroup.noDisplacement) {
      optionGroup.noDisplacement = [];
    }
    return optionGroup.noDisplacement.indexOf(layer.uuid) >= 0;
  }

}
