import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StyleSheetsService {
  readonly styles$: BehaviorSubject<string>;

  constructor(private http: HttpClient) {
    this.styles$ = new BehaviorSubject('');
  }

  getStyleSheets() {
    return this.http.get('/simplekonfiguratorprofile/stylesheets');
  }

  updateStyles(styles: string) {
    this.styles$.next(styles);
  }

}

export interface IStylesheetTemplate {
  filename: string;
  content: string;
}
