import { Component, OnInit, Input } from '@angular/core';
import { ILoadingScreen } from 'babylonjs';
import { EngineService } from 'src/app/services/engine/engine.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  @Input() displayLogo = true;
  @Input() isOverlay = false;
  @Input() whiteBG = false;

  constructor() { }

  ngOnInit() {
  }

}

export class CustomLoadingScreen implements ILoadingScreen {

  public loadingEmitter$: Subject<boolean>;
  public loadingUIBackgroundColor: string;

  constructor(public loadingUIText: string, loadingEmitter$: Subject<boolean>) {
    this.loadingEmitter$ = loadingEmitter$;
  }

  public displayLoadingUI() {
    this.loadingEmitter$.next(true);
  }

  public hideLoadingUI() {
    this.loadingEmitter$.next(false);

  }
}
