import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AclService {
  public userlist = []

  constructor(
    private http:HttpClient
  ) { }

  public async getUsersForProject(projectpath) {
    let result = <any> (await this.http.get("/acl/project/" + projectpath).toPromise());
    console.log(result);
    this.userlist = result;
    return result;
  }

  public async removeUser(projectpath, user) {
    let result = <any> (await this.http.delete("/acl/project/" + projectpath + "/" + user).toPromise());
    this.userlist = result;
    return result;
    // this.userlist = this.userlist.filter(_user => user==_user ? null : user);
  }

  public async addUser(projectpath, user) {
    let result = <any> (await this.http.post("/acl/project/" + projectpath + "/" + user, {}).toPromise());
    this.userlist = result;
    return result
    // this.userlist.push(user);
  }
}
