import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
  showAddDialog: boolean = false;
  addEmail: string = "";

  constructor(
    public users: UsersService
  ) {

  }

  async ngOnInit() {
    this.users.usersList = await this.users.getAllUsersList();
  }

  onUserClick(user) {
    this.users.setUser(user);
  }

  onAddClick() {
    this.showAddDialog = true;
  }

  onCancelOkClick() {
    this.showAddDialog = false;
  }

  onAddOkClick() {
    if (this.addEmail.indexOf("@") >= 0) {
      this.users.addUser(this.addEmail);
      this.showAddDialog = false;
    }

  }

  onCancelEdit() {
    this.users.setUser(null);
  }

  async onSaveEdit() {
    console.log("saveEdit");
    await this.users.updateUser();
    this.users.setUser(null);
  }


}
