import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaterialSettingsDataService {
  private originalValues: Map<string, any> = new Map<string, any>();
  private savedValues: Map<string, any> = new Map<string, any>();

  constructor() {}

  setOriginalValue(key: string, value: any): void {
    this.originalValues.set(key, value);
  }

  getOriginalValue(key: string): any {
    return this.originalValues.get(key);
  }

  clearOriginalValue(key: string): void {
    this.originalValues.delete(key);
  }

  clearAllOriginalValues(): void {
    this.originalValues.clear();
  }

  setSavedValue(key: string, value: any): void {
    this.savedValues.set(key, value);
  }

  getSavedValue(key: string): any {
    return this.savedValues.get(key);
  }

  clearSavedValue(key: string): void {
    this.savedValues.delete(key);
  }

  clearAllSavedValues(): void {
    this.savedValues.clear();
  }

}
