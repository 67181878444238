import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { OrganizationalMaterialsService } from 'src/app/services/materials/organizational/organizational-materials.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { MaterialListComponent } from '../material-list';

@Component({
  selector: 'app-organizational-materials',
  templateUrl: './organizational-materials.component.html',
  styleUrls: ['./organizational-materials.component.css']
})
export class OrganizationalMaterialsComponent extends MaterialListComponent implements OnInit {
  @ViewChild('rename', {static: false}) renameEl: ElementRef;
  @Output() rename: EventEmitter<any> = new EventEmitter<any>();

  public currentRenamingFile: string = null;
  public oldFileName: string = null;
  public newFileName: string = null;

  public actionVisible = false;
  public actionTitle = "Processing";
  public actionBody = ""

  constructor(
    public materials: OrganizationalMaterialsService,
    public projects: ProjectsService,
  ) { 
    super(materials, projects);
  }

  ngOnInit() {
  }

  onDoubleClick(file) {
    console.log("RENAME", file);
    this.startRenaming(file);
  }

  onKeyDown($event: KeyboardEvent) {
    if ($event.key == "Escape") {
      this.cancelRenaming();
    }
    if ($event.key == "Enter") {
      this.completeRenaming();
    }
  }

  startRenaming(file) {
    this.currentRenamingFile = file;
    this.oldFileName = file;
    this.newFileName = file;
    setTimeout(() => {
      this.renameEl.nativeElement.focus();
      this.renameEl.nativeElement.select();
    }, 0)
  }

  cancelRenaming() {
    this.currentRenamingFile = null;
    this.newFileName = null;
  }

  async completeRenaming() {
    this.showActionModal("Processing", "Renaming materials...");
    this.currentRenamingFile = null;
    await this.materials.rename(this.oldFileName, this.newFileName);
    this.newFileName = null;
    this.rename.emit();
    this.hideActionModal();
  }

  onBlur() {
    this.cancelRenaming();
  }

  showActionModal(title, body) {
    this.actionTitle = title;
    this.actionBody = body;
    this.actionVisible = true;
  }

  hideActionModal() {
    this.actionVisible = false;
  }

  getPath() {
    return this.projects.currentProject.organization;
  }

}
