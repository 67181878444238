import { Component, OnInit } from '@angular/core';
import { ProcessingService } from '../processing.service';

@Component({
  selector: 'lib-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.css']
})
export class ProcessingComponent implements OnInit {

  constructor(
    public service: ProcessingService
  ) {
    console.log("LIB-PROCESSING COMPONENT")
  }

  ngOnInit() {
    this.service.subscribe();
  }

  onCloseClick() {
    this.service.close();
  }

}
