import { Injectable } from '@angular/core';
import { cleanPresetName } from "../namecleaner/namecleaner"
import { ProjectsService } from '../projects/projects.service';

@Injectable({
  providedIn: 'root'
})
export class ImageViewerService {
  projectPath: string = null;

  constructor(
    public projects: ProjectsService
  ) { }

  public setProjectPath(projectPath: string) {
    this.projectPath = projectPath;
  }

  public getUrlFromPreset(presetData, mode?) {
    if (!mode) {
      return '/view/#/design/pages/main1?path=T:/' + this.projects.currentProject.path + '/Post/Presets/' + cleanPresetName(presetData.label);
    } 
    if (mode == 'presets') {
      return '/view/#/design/pages/main1?path=T:/' + this.projects.currentProject.path + '/Post/Presets&mode=presets';
    }
  }

  public open() {
    let url = '/view/#/design/pages/main1?path=T:/' + this.projects.currentProject.path + '/Post/Presets&mode=presets';
    window.open(url);
  }
}
