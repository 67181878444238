import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PostprocessService } from 'src/app/services/postprocess/postprocess.service';
import { SimpleKonfiguratorProfileSceneService } from 'src/app/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';

@Component({
  selector: 'app-fx',
  templateUrl: './fx.component.html',
  styleUrls: ['./fx.component.css']
})
export class FxComponent implements OnInit {
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(
    public service: SimpleKonfiguratorProfileSceneService
  ) { }

  ngOnInit() {
    console.log("POSTPROCESS onInit");
    
  }

  onToggleFXAAClick() {
    this.service.postprocess.toggleFXAA();
    this.change.emit();
  }

  onToggleSSAOClick() {
    this.service.postprocess.toggleSSAO();
    this.change.emit();
  }

  onToggleBloomClick() {
    this.service.postprocess.toggleBloom();
    this.change.emit();
  }


  onButtonClick() {
    console.log(this.service.postprocess.fxaaEnabled);
    console.log(this.service.postprocess.ssaoEnabled);
    console.log(this.service.postprocess.bloomEnabled);
    
  }
}
