import { Directive, AfterContentInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {

  @Input() public appAutoFocus: boolean;

  public constructor(private el: ElementRef) {
  }


  public ngAfterContentInit() {
      setTimeout(() => {
        this.el.nativeElement.focus();
        this.el.nativeElement.select();
      }, 100);
  }

}
