import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { BackgroundsService, Environment } from 'src/app/services/backgrounds/backgrounds.service';
import { PostprocessService } from 'src/app/services/postprocess/postprocess.service';
import { LogoService } from 'src/app/services/logo/logo.service';

@Component({
  selector: 'app-project-properties',
  templateUrl: './project-properties.component.html',
  styleUrls: ['./project-properties.component.css']
})
export class ProjectPropertiesComponent implements OnInit {
  project: any;
  config: any;
  @Output() explosion: EventEmitter<Environment> = new EventEmitter();
  @Input() visible: boolean = false;


  showIframe = false;

  constructor(
    private projects: ProjectsService,
    public logoService: LogoService
  ) { }

  ngOnInit() {
  }

  load(project, config) {
    // console.log("projectproperties load:");
    // console.log(project);
    // console.log(config);


    this.project = project;
    this.config = config;

  }


  onOpenInViewerClick() {
    if (window.location.hostname.indexOf("localhost") >= 0) {
        // console.log(window.location)
        let url = window.location.protocol + "//" + window.location.hostname + ":4201/?project=" + this.project.path;
        // console.log(url)
        window.open(url)
    } else if (window.location.hostname.indexOf("192.168.") >= 0) {
      // console.log(window.location)
      let url = window.location.protocol + "//" + window.location.hostname + ":3008/?project=" + this.project.path;
      // console.log(url)
      window.open(url)
  } else {
      // console.log(window.location)
      let url = "https://3dv.tronog-engine.com/?project=" + this.project.path;
      // console.log(url)
      window.open(url)
    }
  }


  onExplosionClick() {
    this.explosion.emit();
  }

  onOrigClick() {
    window.location.href = "/simple?project=" + this.project.path;
  }

  onLod1Click() {
    window.location.href = "/simple?project=" + this.project.path + "&lod=LOD1";
  }

  onLod2Click() {
    window.location.href = "/simple?project=" + this.project.path + "&lod=LOD2";
  }

  onLod3Click() {
    window.location.href = "/simple?project=" + this.project.path + "&lod=LOD3";
  }

  onKonfiguratorClick() {
    window.open('/konfigurator/?id=' + this.projects.currentProject.path);
  }


}
