import { Injectable } from '@angular/core';
import { OptionGroupsService } from '../optiongroups/option-groups.service';
import { ISimpleKonfiguratorProfileConfig } from '../scene/simpleprofile/simplekonfiguratorprofileconfig.interface';
import { Material } from '../scene/materials';

@Injectable({
  providedIn: 'root'
})
export class MaterialRelationsService {

  public list: MaterialRelation[] = [];
  public sortedList;

  public cleaned = false;

  config: ISimpleKonfiguratorProfileConfig;

  constructor(
  ) { }

  public serialize(config) {
    config.materialRelations = this.list.map(mr => {
      return {
        linkLeft: mr.linkLeft,
        linkRight: mr.linkRight,
        materialLeft: mr.materialLeft,
        materialRight: mr.materialRight,
        visible: mr.visible,
        default: mr.default,
      };
    });
  }

  public deserialize(config: ISimpleKonfiguratorProfileConfig) {
    this.list = [];
    this.config = config;
    if (config.materialRelations) {
      config.materialRelations.forEach(mr => {
        let item = new MaterialRelation();
        item.linkLeft = mr.linkLeft;
        item.linkRight = mr.linkRight;
        item.materialLeft = mr.materialLeft;
        item.materialRight = mr.materialRight;
        item.visible = mr.visible;
        item.default = mr.default;
        this.list.push(item);
      })
    }
    console.log("MRLIST", this.list, config.materialRelations);
    this.removeUnused();
    this.sortedList = this.getSorted();
  }


  public clean() {
    if (this.cleaned) {
      return;
    }

    this.cleaned = true;
  }

  public set(mat: MaterialRelation) {
    console.log("SETMAT", mat);

    let items = this.list.filter(mr =>
    ((mr.linkLeft == mat.linkLeft) &&
      (mr.linkRight == mat.linkRight) &&
      (mr.materialLeft == mat.materialLeft) &&
      (mr.materialRight == mat.materialRight)));

    if (!items.length) {
      this.list.push(mat);
    } else {
      if (!mat.visible && !mat.default) {
        console.log(mat);

        this.list = this.list.filter(mr =>
          !((mr.linkLeft == mat.linkLeft) &&
            (mr.linkRight == mat.linkRight) &&
            (mr.materialLeft == mat.materialLeft) &&
            (mr.materialRight == mat.materialRight)));
      } else {
        items[0].default = mat.default;
        items[0].visible = mat.visible;
      }
    }
    console.log(this.list);
    this.sortedList = this.getSorted();

  }

  public getSorted() {
    console.log("GETSORTED");

    let result = [...this.list];
    result.sort((a, b) => {
      if (a.linkLeft < b.linkLeft) {
        return -1;
      }
      if (a.linkLeft > b.linkLeft) {
        return 1;
      }
      if (a.linkRight < b.linkRight) {
        return -1;
      }
      if (a.linkRight > b.linkRight) {
        return 1;
      }
      if (a.materialLeft < b.materialLeft) {
        return -1;
      }
      if (a.materialLeft > b.materialLeft) {
        return 1;
      }
      if (a.materialRight < b.materialRight) {
        return -1;
      }
      if (a.materialRight > b.materialRight) {
        return 1;
      }
      return 0;
    })

    return result;
  }

  removeUnused() {
    let links = {};

    if (!this.config.geometryGroups) {
      return;
    }

    if (this.config.materialLinkAssignments) {
      this.config.materialLinkAssignments.forEach(ma => {
        links[ma.link] = ma.materials;
      })
    } else {
      this.config.geometryGroups.forEach(gg => {
        gg.layers.forEach(lay => {
          if (!links[lay.materialLink]) {
            links[lay.materialLink] = [];
          }
          lay.materials.forEach(mat => {
            links[lay.materialLink].push(mat);
          })
        })
      })
    }

    console.log("MAT LINKS", links);

    let newList = [];

    this.config.materialRelations.forEach(mr => {
      if (links[mr.linkLeft] && links[mr.linkLeft].includes(mr.materialLeft)) {
        if (links[mr.linkRight] && links[mr.linkRight].includes(mr.materialRight)) {
          newList.push(mr);
        }
      }
    })

    console.log("NEW MR", newList);
    this.config.materialRelations = newList;
    this.list = newList;
  }
}

export class MaterialRelation {
  public linkLeft: string;
  public linkRight: string;
  public materialLeft: string;
  public materialRight: string;
  public visible: boolean = false;
  public default: boolean = false;
}