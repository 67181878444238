import { Injectable } from '@angular/core';
import { CamerasService, CameraItem } from '../cameras/cameras.service';
import { BackgroundsService } from '../backgrounds/backgrounds.service';
import { SimpleKonfiguratorProfileSceneService } from '../scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { EnvironmentService } from '../uploader/environment.service';

@Injectable({
  providedIn: 'root'
})
export class KonfiguratorSettingsService {
  public scene: SimpleKonfiguratorProfileSceneService;
  public cameras: KonfiguratorCameras;


  constructor(
    public cameraService: CamerasService,
    public backgrounds: BackgroundsService
  ) {

  }

  public serialize() {
    return {
      cameras: this.cameras.serialize()
    }
  }

  public deserialize(obj) {
    console.log("KONFIGURATORSETTINGS", obj);
    
    if (!obj) {
      console.log("KONFIGURATORSETTINGS NULL", obj);
      obj = {
        cameras: null
      }
    }
    this.cameras = KonfiguratorCameras.deserialize(obj.cameras, this);
  }

  
  registerCameraEvents() {
    this.cameraService.cameraAdd$.subscribe(camera => {
      this.cameras.add(
        true, 
        camera.label, 
        this.scene.currentEnvironment.lightSkyboxURL);
    })

    this.cameraService.cameraChange$.subscribe(cameras => {
      this.cameras.fix(cameras);
    })

    this.cameraService.cameraDelete$.subscribe(camera => {
      this.cameras.delete(camera.label);
    })

    this.cameraService.cameraMove$.subscribe(camera => {

    });

    this.cameraService.camerasImport$.subscribe(cameras => {
      this.cameras.fix(cameras);
    })
  }
}



class KonfiguratorCameras {
  public list: KonfiguratorCamera[] = [];

  constructor(public parent: KonfiguratorSettingsService) {}

  public static deserialize(obj, parent) {
    let result = new KonfiguratorCameras(parent);
    if (obj) {
      result.list = [];
      obj.forEach(camera => {
        result.list.push(KonfiguratorCamera.deserialize(camera));
      })
    } else {
      result.list = [];
    }
    result.fix(result.parent.cameraService.list);

    console.log("CAMERAS", result.list);
    

    return result;
  }


  public get(cameraLabel: string) {
    return this.list.filter(c => c.cameraLabel == cameraLabel)[0];
  }

  public set(cameraLabel: string, lightUrl: string) {
    let camera = this.get(cameraLabel);

    if (camera) {
      camera.lightUrl = lightUrl;
    }
  }

  public delete(cameraLabel: string) {
    this.list = this.list.filter(c => c.cameraLabel != cameraLabel);
  }

  public add(checked:boolean, cameraLabel: string, lightUrl:string) {
    let camera = this.get(cameraLabel);

    if (!camera) {
      this.list.push(new KonfiguratorCamera(checked, cameraLabel, lightUrl));
    }
  }

  public fix(cameraList: CameraItem[]) {
    let newCameras = [];
    let missingCameras = [];

    cameraList.forEach(camera => {
      let item = this.get(camera.label);

      if (!item) {
        newCameras.push(camera.label);
      }
    })

    this.list.forEach(cameraSetting => {
      let item = cameraList.filter(camera => camera.label == cameraSetting.cameraLabel);

      if (item.length <= 0) {
        missingCameras.push(cameraSetting.cameraLabel);
      }
    })

    missingCameras.forEach(missing => {
      this.delete(missing);
    })

    newCameras.forEach(camera => {
      console.log("LIGHT ID", this.parent.scene.currentEnvironment.lightSkyboxId);
      console.log(this.parent.backgrounds.getCurrentLights());
      
      this.add(
        true, 
        camera, 
        this.parent.scene.currentEnvironment.lightSkyboxURL);
    })
  }

  public serialize() {
    return this.list.map(camera => camera.serialize())
  }

}

class KonfiguratorCamera {
  public constructor(
    public checked: boolean,
    public cameraLabel: string,
    public lightUrl: string
  ) {}

  public static  deserialize(obj) {
    if (obj) {
      return new KonfiguratorCamera(
        obj.checked,
        obj.cameraLabel,
        obj.lightUrl);
    }
  }


  public serialize() {
    return {
      checked: this.checked,
      cameraLabel: this.cameraLabel,
      lightUrl: this.lightUrl
    }
  }

}
