import { Injectable } from '@angular/core';
import { GeometryGroup, GroupLayer, GeometryGroups } from '../scene/geometry-groups';
import { OptionGroup } from '../scene/option-groups';
import { ISimpleKonfiguratorProfileConfig } from '../scene/simpleprofile/simplekonfiguratorprofileconfig.interface';
import { Material } from '../scene/materials';

@Injectable({
  providedIn: 'root'
})
export class OptionGroupsService {

  public geometryGroups: GeometryGroups;

  public optionGroupLinks = [
    { label: '---', value: null },
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
    { label: 'F', value: 'F' },
    { label: 'G', value: 'G' },
    { label: 'H', value: 'H' },
    { label: 'I', value: 'I' },
    { label: 'J', value: 'J' },
    { label: 'K', value: 'K' },
    { label: 'L', value: 'L' },
    { label: 'M', value: 'M' },
    { label: 'N', value: 'N' },
    { label: 'O', value: 'O' },
    { label: 'P', value: 'P' },
    { label: 'Q', value: 'Q' },
    { label: 'R', value: 'R' },
    { label: 'S', value: 'S' },
    { label: 'T', value: 'T' },
    { label: 'U', value: 'U' },
    { label: 'V', value: 'V' },
    { label: 'W', value: 'W' },
    { label: 'X', value: 'X' },
    { label: 'Y', value: 'Y' },
    { label: 'Z', value: 'Z' }
  ]

  public materialLinkLabels = [
    { label: '---', value: null },
    { label: 'I', value: 'I' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' },
    { label: 'V', value: 'V' },
    { label: 'VI', value: 'VI' },
    { label: 'VII', value: 'VII' },
    { label: 'VIII', value: 'VIII' },
    { label: 'IX', value: 'IX' },
    { label: 'X', value: 'X' },
    { label: 'XI', value: 'XI' },
    { label: 'XII', value: 'XII' },
    { label: 'XIII', value: 'XIII' },
    { label: 'XIV', value: 'XIV' },
    { label: 'XV', value: 'XV' },
    { label: 'XVI', value: 'XVI' },
    { label: 'XVII', value: 'XVII' },
    { label: 'XVIII', value: 'XVIII' },
    { label: 'XIX', value: 'XIX' },
    { label: 'XX', value: 'XX' },
    { label: 'XXI', value: 'XXI' },
    { label: 'XXII', value: 'XXII' },
    { label: 'XXIII', value: 'XXIII' },
    { label: 'XXIV', value: 'XXIV' },
    { label: 'XXV', value: 'XXV' },
    { label: 'XXVI', value: 'XXVI' },
    { label: 'XXVII', value: 'XXVII' },
    { label: 'XXVIII', value: 'XXVIII' },
    { label: 'XXIX', value: 'XXIX' },
    { label: 'XXX', value: 'XXX' },
    { label: 'XXXI', value: 'XXXI' },
    { label: 'XXXII', value: 'XXXII' },
    { label: 'XXXIII', value: 'XXXIII' },
    { label: 'XXXIV', value: 'XXXIV' },
    { label: 'XXXV', value: 'XXXV' },
    { label: 'XXXVI', value: 'XXXVI' },
    { label: 'XXXVII', value: 'XXXVII' },
    { label: 'XXXVIII', value: 'XXXVIII' },
    { label: 'XXXIX', value: 'XXXIX' },
    { label: 'XL',      value: 'XL' },
    { label: 'XLI',     value: 'XLI' },
    { label: 'XLII',    value: 'XLII' },
    { label: 'XLIII',   value: 'XLIII' },
    { label: 'XLIV',    value: 'XLIV' },
    { label: 'XLV',     value: 'XLV' },
    { label: 'XLVI',    value: 'XLVI' },
    { label: 'XLVII',   value: 'XLVII' },
    { label: 'XLVIII',  value: 'XLVIII' },
    { label: 'XLIX',    value: 'XLIX' },
    { label: 'L',       value: 'L' },
    { label: 'LI',      value: 'LI' },
    { label: 'LII',     value: 'LII' },
    { label: 'LIII',    value: 'LIII' },
    { label: 'LIV',     value: 'LIV' },
    { label: 'LV',      value: 'LV' },
    { label: 'LVI',     value: 'LVI' },
    { label: 'LVII',    value: 'LVII' },
    { label: 'LVIII',   value: 'LVIII' },
    { label: 'LIX',     value: 'LIX' },
    { label: 'LX',      value: 'LX' },
    { label: 'LXI',     value: 'LXI' },
    { label: 'LXII',    value: 'LXII' },
    { label: 'LXIII',   value: 'LXIII' },
    { label: 'LXIV',    value: 'LXIV' },
    { label: 'LXV',     value: 'LXV' },
    { label: 'LXVI',    value: 'LXVI' },
    { label: 'LXVII',   value: 'LXVII' },
    { label: 'LXVIII',  value: 'LXVIII' },
    { label: 'LXIX',    value: 'LXIX' },
    
    { label: 'LXX',      value: 'LXX' },
    { label: 'LXXI',     value: 'LXXI' },
    { label: 'LXXII',    value: 'LXXII' },
    { label: 'LXXIII',   value: 'LXXIII' },
    { label: 'LXXIV',    value: 'LXXIV' },
    { label: 'LXXV',     value: 'LXXV' },
    { label: 'LXXVI',    value: 'LXXVI' },
    { label: 'LXXVII',   value: 'LXXVII' },
    { label: 'LXXVIII',  value: 'LXXVIII' },
    { label: 'LXXIX',    value: 'LXXIX' },
    { label: 'LXXX',     value: 'LXXX' },
    { label: 'LXXXI',    value: 'LXXXI' },
    { label: 'LXXXII',   value: 'LXXXII' },
    { label: 'LXXXIII',  value: 'LXXXIII' },
    { label: 'LXXXIV',   value: 'LXXXIV' },
    { label: 'LXXXV',    value: 'LXXXV' },
    { label: 'LXXXVI',   value: 'LXXXVI' },
    { label: 'LXXXVII',  value: 'LXXXVII' },
    { label: 'LXXXVIII', value: 'LXXXVIII' },
    { label: 'LXXXIX',   value: 'LXXXIX' },
    { label: 'XC',       value: 'XC' },
    { label: 'XCI',      value: 'XCI' },
    { label: 'XCII',     value: 'XCII' },
    { label: 'XCIII',    value: 'XCIII' },
    { label: 'XCIV',     value: 'XCIV' },
    { label: 'XCV',      value: 'XCV' },
    { label: 'XCVI',     value: 'XCVI' },
    { label: 'XCVII',    value: 'XCVII' },
    { label: 'XCVIII',   value: 'XCVIII' },
    { label: 'XCIX',     value: 'XCIX' },
    { label: 'C',        value: 'C' },

  ]

  public globalOptionLinks = [
    { label: '---', value: null },
    { label: 'I', value: 'I' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' },
    { label: 'V', value: 'V' },
    { label: 'VI', value: 'VI' },
    { label: 'VII', value: 'VII' },
    { label: 'VIII', value: 'VIII' },
    { label: 'IX', value: 'IX' },
    { label: 'X', value: 'X' },
    { label: 'XI', value: 'XI' },
    { label: 'XII', value: 'XII' },
    { label: 'XIII', value: 'XIII' },
    { label: 'XIV', value: 'XIV' },
    { label: 'XV', value: 'XV' },
    { label: 'XVI', value: 'XVI' },
    { label: 'XVII', value: 'XVII' },
    { label: 'XVIII', value: 'XVIII' },
    { label: 'XIX', value: 'XIX' },
    { label: 'XX', value: 'XX' },
    { label: 'XXI', value: 'XXI' },
    { label: 'XXII', value: 'XXII' },
    { label: 'XXIII', value: 'XXIII' },
    { label: 'XXIV', value: 'XXIV' },
    { label: 'XXV', value: 'XXV' },
    { label: 'XXVI', value: 'XXVI' },
    { label: 'XXVII', value: 'XXVII' },
    { label: 'XXVIII', value: 'XXVIII' },
    { label: 'XXIX', value: 'XXIX' },
    { label: 'XXX', value: 'XXX' },
    { label: 'XXXI', value: 'XXXI' },
    { label: 'XXXII', value: 'XXXII' },
    { label: 'XXXIII', value: 'XXXIII' },
    { label: 'XXXIV', value: 'XXXIV' },
    { label: 'XXXV', value: 'XXXV' },
    { label: 'XXXVI', value: 'XXXVI' },
    { label: 'XXXVII', value: 'XXXVII' },
    { label: 'XXXVIII', value: 'XXXVIII' },
    { label: 'XXXIX', value: 'XXXIX' },
    { label: 'XL',      value: 'XL' },
    { label: 'XLI',     value: 'XLI' },
    { label: 'XLII',    value: 'XLII' },
    { label: 'XLIII',   value: 'XLIII' },
    { label: 'XLIV',    value: 'XLIV' },
    { label: 'XLV',     value: 'XLV' },
    { label: 'XLVI',    value: 'XLVI' },
    { label: 'XLVII',   value: 'XLVII' },
    { label: 'XLVIII',  value: 'XLVIII' },
    { label: 'XLIX',    value: 'XLIX' },
    { label: 'L',       value: 'L' },
    { label: 'LI',      value: 'LI' },
    { label: 'LII',     value: 'LII' },
    { label: 'LIII',    value: 'LIII' },
    { label: 'LIV',     value: 'LIV' },
    { label: 'LV',      value: 'LV' },
    { label: 'LVI',     value: 'LVI' },
    { label: 'LVII',    value: 'LVII' },
    { label: 'LVIII',   value: 'LVIII' },
    { label: 'LIX',     value: 'LIX' },
    { label: 'LX',      value: 'LX' },
    { label: 'LXI',     value: 'LXI' },
    { label: 'LXII',    value: 'LXII' },
    { label: 'LXIII',   value: 'LXIII' },
    { label: 'LXIV',    value: 'LXIV' },
    { label: 'LXV',     value: 'LXV' },
    { label: 'LXVI',    value: 'LXVI' },
    { label: 'LXVII',   value: 'LXVII' },
    { label: 'LXVIII',  value: 'LXVIII' },
    { label: 'LXIX',    value: 'LXIX' },
    
    { label: 'LXX',      value: 'LXX' },
    { label: 'LXXI',     value: 'LXXI' },
    { label: 'LXXII',    value: 'LXXII' },
    { label: 'LXXIII',   value: 'LXXIII' },
    { label: 'LXXIV',    value: 'LXXIV' },
    { label: 'LXXV',     value: 'LXXV' },
    { label: 'LXXVI',    value: 'LXXVI' },
    { label: 'LXXVII',   value: 'LXXVII' },
    { label: 'LXXVIII',  value: 'LXXVIII' },
    { label: 'LXXIX',    value: 'LXXIX' },
    { label: 'LXXX',     value: 'LXXX' },
    { label: 'LXXXI',    value: 'LXXXI' },
    { label: 'LXXXII',   value: 'LXXXII' },
    { label: 'LXXXIII',  value: 'LXXXIII' },
    { label: 'LXXXIV',   value: 'LXXXIV' },
    { label: 'LXXXV',    value: 'LXXXV' },
    { label: 'LXXXVI',   value: 'LXXXVI' },
    { label: 'LXXXVII',  value: 'LXXXVII' },
    { label: 'LXXXVIII', value: 'LXXXVIII' },
    { label: 'LXXXIX',   value: 'LXXXIX' },
    { label: 'XC',       value: 'XC' },
    { label: 'XCI',      value: 'XCI' },
    { label: 'XCII',     value: 'XCII' },
    { label: 'XCIII',    value: 'XCIII' },
    { label: 'XCIV',     value: 'XCIV' },
    { label: 'XCV',      value: 'XCV' },
    { label: 'XCVI',     value: 'XCVI' },
    { label: 'XCVII',    value: 'XCVII' },
    { label: 'XCVIII',   value: 'XCVIII' },
    { label: 'XCIX',     value: 'XCIX' },
    { label: 'C',        value: 'C' },

  ]

  public map = {};

  // FOR USE IN DnD BETWEEN OPTION GROUPS ONLY
  public drag: boolean = false;
  public dragGroup: GeometryGroup = null;
  public dragLayer: GroupLayer = null;
  public dragOption: OptionGroup = null;
  public dragOverItem: any = null;

  constructor(
  ) {
    this.refreshMap();

  }

  public getMaterialLink(link) {
    return this.materialLinkLabels.find(m => m.value == link);
  }

  public getGeometryLink(link) {
    return this.globalOptionLinks.find(m => m.value == link);
  }

  refreshMap() {
    this.map = {};
    this.materialLinkLabels.forEach(m => {
      this.map[m.value] = m;
    });
  }

  public serialize(config: ISimpleKonfiguratorProfileConfig) {
    config.optionGroupLinks = this.optionGroupLinks;
    config.materialLinkLabels = this.materialLinkLabels;
    config.globalOptionLinks = this.globalOptionLinks as any;
  }

  public deserialize(config: ISimpleKonfiguratorProfileConfig) {
    if (config.globalOptionLinks) {
      this.globalOptionLinks = config.globalOptionLinks;
    }

    if (config.optionGroupLinks) {
      this.optionGroupLinks = config.optionGroupLinks;
    }

    if (config.materialLinkLabels) {
      let mll = config.materialLinkLabels;

      this.materialLinkLabels.forEach(link => {
        if (mll.find(_link => _link.value == link.value)) {
          // ignore
        } else {
          mll.push(link);
        }
      })

      this.materialLinkLabels = mll;
    }

    this.refreshMap();
  }

  public getMaterialLinkLabels() {
    console.log("materialLinkLabels", this.materialLinkLabels);
    return this.materialLinkLabels;
  }

}
