import { Component, OnInit, Input, ViewChild, SimpleChanges, SimpleChange, OnChanges, Output, EventEmitter } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { PBRMaterial } from 'babylonjs';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Uploader } from 'src/app/components/uploader/uploader';
import { UploaderService } from 'src/app/services/uploader/uploader.service';
import { EnvironmentService } from 'src/app/services/uploader/environment.service';

@Component({
  selector: 'app-environments',
  templateUrl: './environments.component.html',
  styleUrls: ['./environments.component.css'],
  providers: [ EnvironmentService ]
})
export class EnvironmentsComponent extends Uploader implements OnInit {
  constructor(
    public service: EnvironmentService,
    public projects: ProjectsService,
    public loader: LoaderService) {
    super(service, projects, loader);
    this.setPostfix('ImportedEnvironments');
    this.setLabel("Drop HDR here");
    
  }


  ngOnInit() {
    console.log('ENV init');
    super.ngOnInit();
    
  }

}
