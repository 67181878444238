import { Node, Mesh, Vector3 } from 'babylonjs';
import { GeometryNode } from '../../services/scene/geometry';
import { MaterialList, Material, Materials } from './materials';
import { SimpleKonfiguratorProfileSceneService } from './simpleprofile/simple-konfigurator-profile-scene.service';
import { FormControlDirective } from '@angular/forms';
import { Hotspot } from '../hotspots/hotspots.service';
import { OptionGroup, OptionGroups } from './option-groups';

import * as uuid from 'uuid';
import { group } from '@angular/animations';
import { UuidService } from '../uuid/uuid.service';
import { Subject } from 'rxjs';
import { MaterialRelationsService } from '../materialrelations/material-relations.service';
import { Injectable } from '@angular/core';
import { MaterialLinksService } from '../materiallinks/material-links.service';



@Injectable({
  providedIn: 'root'
})
export class GeometryGroups {
  groups: GeometryGroup[] = [];
  layersById: {};
  lastId: number = 0;
  nodeMap: {} = {};
  meshMap: {} = {};
  uidMap = {};
  rootNodes: any[];
  tags: Tags = new Tags();
  scene: SimpleKonfiguratorProfileSceneService;
  // public optionGroups: OptionGroups;
  ignoreShowHideGeometries: boolean = false;

  materialLinkMap: { [id: string]: Material[] } = {};
  unassignedMaterials: Material[] = [];
  currentlySelectedLinkMap: { [id: string]: Material } = {};

  public materialChange$: Subject<{ link: string, material: Material }> = new Subject();

  public activeTags: string[] = [];

  public materialLinkLabels = [
    { label: '---', value: null },
    { label: 'I', value: 'I' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' },
    { label: 'V', value: 'V' },
    { label: 'VI', value: 'VI' },
    { label: 'VII', value: 'VII' },
    { label: 'VIII', value: 'VIII' },
    { label: 'IX', value: 'IX' },
    { label: 'X', value: 'X' },
    { label: 'XI', value: 'XI' },
    { label: 'XII', value: 'XII' },
    { label: 'XIII', value: 'XIII' },
    { label: 'XIV', value: 'XIV' },
    { label: 'XV', value: 'XV' },
    { label: 'XVI', value: 'XVI' },
    { label: 'XVII', value: 'XVII' },
    { label: 'XVIII', value: 'XVIII' },
    { label: 'XIX', value: 'XIX' },
    { label: 'XX', value: 'XX' },
    { label: 'XXI', value: 'XXI' },
    { label: 'XXII', value: 'XXII' },
    { label: 'XXIII', value: 'XXIII' },
    { label: 'XXIV', value: 'XXIV' },
    { label: 'XXV', value: 'XXV' },
  ]

  constructor(
    // public uuid: UuidService
    public relations: MaterialRelationsService,
    public materials: Materials
  ) { }

  public getGroups() { return this.groups; }

  public add(node: Node) {
    let result = new GeometryGroup(this, node);
    result.uuid = uuid.v4();
    result.id = this.getNextID();
    result.label = "Group" + result.id;
    this.groups.push(result);
    return result;
  }

  public getByUUID(uuid) {
    for (let group of this.groups) {
      if (group.uuid == uuid) {
        return group;
      }
      for (let layer of group.layers) {
        if (layer.uuid == uuid) {
          return layer;
        }
      }
    }
  }

  getNextID() {
    var id = 0;
    this.groups.forEach((grp) => id = (grp.id >= id ? id + 1 : id));
    return id;
  }

  clear() {
    this.groups = [];
  }



  // specific to FBX2GLTF exporter, it always creates
  // __root__
  //   RootNode
  //     <outliner node 1>
  //     <outliner node 2>
  //     ...
  importFromNodes(rootNodes: Node[]) {
    const oldNodes = {};
    const uuidMap = {};

    this.groups.forEach(grp => {
      grp.getLayers().forEach(lay => {
        if (lay.ref && lay.ref.parent && lay.ref.parent.parent) {
          let name = "|__root__|RootNode|" +
            fbxClean(lay.ref.parent.parent.name) + "|" +
            fbxClean(lay.ref.parent.name) + "|" +
            fbxClean(lay.ref.name);
          console.log(name);
          oldNodes[name] = lay;
        }
      })
    })

    this.rootNodes = rootNodes;
    this.groups = []
    let ungrouped = [];
    let map = {}
    console.log(rootNodes);


    rootNodes.forEach(rootNode => {
      console.log("ROOTNODE " + rootNode.name);

      let nodes = rootNode.getChildren(null, true);
      if (nodes.length == 0) {
        if (!rootNode.name.startsWith("__3dk__") &&
          rootNode.name != "default camera") {
          ungrouped.push(rootNode);
          if (!rootNode.parent) {
            console.log("!rootNode.parent");
          }
        }
      } else {
        nodes.forEach((node) => {
          let nodes = node.getChildren(null, true);
          let grp = node;
          if (nodes.length == 0) {
            // let _node = group.add(node.name, node);
            if (!rootNode.name.startsWith("__3dk__") &&
              rootNode.name != "default camera") {
              ungrouped.push(node);
              if (!node.parent) {
                console.log("!node.parent");
                node.parent = rootNode;
              }
            }
          } else {
            let group = this.add(node);
            group.label = node.name;
            nodes.forEach((node) => {
              // let name = "|__root__|RootNode|" + rootNode.name + "|" + grp.name + "|" + node.name;
              let newName = "|__root__|RootNode|" + fbxClean(rootNode.name) + "|" + fbxClean(grp.name) + "|" + fbxClean(node.name);
              const oldNode: GroupLayer = oldNodes[newName];
              if (oldNode) {
                console.log("EXISTING", newName);

                oldNode.ref = node;
                group.addExisting(oldNode);
                uuidMap[oldNode.uuid] = oldNode;
              } else {
                console.log("NEW", newName);
                let _node = group.add(node.name, node);
                uuidMap[_node.uuid] = _node;
              }
            });
          }
        })
      }
    })
    if (ungrouped.length > 0) {
      let group = this.add(null);
      ungrouped.forEach(node => {
        if (map[this.getFullName(node)]) {
          let l = group.add(node.name, node);
          l.uid = node.uniqueId;
          map[this.getFullName(node)].uid = map[this.getFullName(node)].ref.uniqueId;
          console.log(l.uid);
          console.log(map[this.getFullName(node)].uid);
          console.log(l);
          console.log(map[this.getFullName(node)]);
        } else {
          let l = group.add(node.name, node);
          map[this.getFullName(node)] = l;
        }
      })
      console.log(group);

    }
    this.refreshMaterialLinkMap();
  }


  importFromNodesFlat(rootNodes: Node[]) {
    this.rootNodes = rootNodes;
    this.groups = []
    let ungrouped = [];
    console.log(rootNodes);

    rootNodes.forEach(rootNode => {
      let nodes = rootNode.getChildren(null, true);
      if (nodes.length == 0) {
        if (!rootNode.name.startsWith("__3dk__") &&
          rootNode.name != "default camera") {
          ungrouped.push(rootNode);
          if (!rootNode.parent) {
            console.log("!rootNode.parent");
          }
        }
      } else {
        nodes.forEach((node) => {
          let nodes = node.getChildren(null, true);
          if (nodes.length == 0) {
            // let _node = group.add(node.name, node);
            if (!rootNode.name.startsWith("__3dk__") &&
              rootNode.name != "default camera") {
              ungrouped.push(node);
              if (!node.parent) {
                console.log("!node.parent");
                node.parent = rootNode;
              }
            }
          } else {
            nodes.forEach((node) => {
              ungrouped.push(node);
            });
          }
        })
      }
    })
    if (ungrouped.length > 0) {
      let group = this.add(null);
      ungrouped.forEach(node => {
        group.add(getMeshFullName(node)
          .replace("|__root__", "")
          .replace("RootNode", "")
          .replace("|", ""), node);
      })
      console.log(group);

    }
  }

  importFroPreconfigured(rootNodes: Node[], groups) {
    this.rootNodes = rootNodes;
    this.groups = []
    let ungrouped = [];
    console.log(rootNodes);

    rootNodes.forEach(rootNode => {
      let nodes = rootNode.getChildren(null, false);
      nodes.forEach((node) => {
        let nodes = node.getChildren(null, true);
        if (!rootNode.name.startsWith("__3dk__") &&
          rootNode.name != "default camera") {
          ungrouped.push(node);
        }
      })
    })
    console.log(groups);

    if (ungrouped.length > 0) {
      let group = this.add(null);
      ungrouped.forEach(node => {
        let name = getMeshFullName(node)
        name = name.replace("|__root__", "")
        name = name.replace("|RootNode", "")

        if (groups.indexOf(name) > 0) {
          group.add(name, node);
        }
      })
      console.log(group);
      // this.hideUnregisteredNodes();
    }
  }

  hideUnregisteredNodes() {
    console.log("hideUnregisteredNodes");

    let names: string[] = [];
    console.log(this.groups);

    this.groups.forEach(grp => {
      grp.getLayers().forEach(layer => {
        let layerName = layer.getFullName();

        layerName = layerName.replace("|__root__", "")
        layerName = layerName.replace("|RootNode", "")
        // console.log(layerName);
        names.push(layerName);
      })
    })
    this.rootNodes.forEach(rootNode => {
      let nodes = rootNode.getChildren(null, false);
      nodes.forEach((node) => {
        node.isVisible = false;
        let name = getMeshFullName(node)
        name = name.replace("|__root__", "")
        name = name.replace("|RootNode", "")
        names.forEach(n => {
          if (name.startsWith(n)) {
            node.isVisible = true;
          }
        })
      })
    })
  }

  getRootNodes() {
    return this.rootNodes;
  }

  setRootNodes(rootNodes: Node[]) {
    this.rootNodes = rootNodes;
    let result = {};
    this.uidMap = {};
    rootNodes.forEach(rootNode => {
      console.log(rootNode.name);

      let rootName = rootNode.name;
      // let nodes = rootNode.getChildren(null, true);
      // nodes.forEach((node) => {
      //   result[this.getFullName(node)] = node;

      //   let nodes = node.getChildren(null, true);
      //   nodes.forEach((_node) => {
      //     result[this.getFullName(_node)] = _node;
      //   });
      // });
      result[this.getFullName(rootNode)] = rootNode;
      this.uidMap[rootNode.uniqueId] = rootNode;
      let nodes = rootNode.getChildren(null, false);
      nodes.forEach((node) => {
        this._setRootNodes(node, result);
      });
    })
    console.log(result);
    console.log(rootNodes);
    console.log("setRootNode");
    this.nodeMap = result;
    rootNodes.forEach(rootNode => {
      let meshes = rootNode.getChildren(null, false);
      console.log(meshes);

      meshes.forEach(mesh => {
        this.meshMap[getMeshFullName(mesh)] = mesh;
        this.uidMap[mesh.uniqueId] = mesh;
      })
      this.uidMap[rootNode.uniqueId] = rootNode;
    });
    console.log(this.meshMap);
    console.log(this.uidMap);

  }

  _setRootNodes(node: Node, result: {}) {
    result[this.getFullName(node)] = node;
    this.uidMap[node.uniqueId] = node;
    let children = node.getChildren(null, true);
    if (children.length > 0) {
      children.forEach(node => {
        this._setRootNodes(node, result);
      })
    }
  }

  showHideGeometries() {
    if (this.ignoreShowHideGeometries) {
      return;
    }
    this.groups.forEach(group => {
      group.layers.forEach(_layer => {
        if (_layer.ref) {
          if (!this.scene.options.showAdvancedMenu) {
            if (!(_layer.v && group._visible)) {
              // console.log("DISABLE1", _layer);
              _layer.ref.setEnabled(_layer.v && group._visible);
            }
          }
          if (this.scene.options.showAdvancedMenu) {
            if (!_layer.v) {
              // console.log("DISABLE2", _layer);
              _layer.ref.setEnabled(_layer.v);
            }
          }
        }
      });
    });
    this.groups.forEach(group => {
      group.layers.forEach(_layer => {
        if (_layer.ref) {
          if (!this.scene.options.showAdvancedMenu) {
            if (_layer.v && group._visible) {
              // console.log("ENABLE1", _layer);
              _layer.ref.setEnabled(_layer.v && group._visible);
            }
          }
          if (this.scene.options.showAdvancedMenu) {
            if (_layer.v) {
              // console.log("ENABLE2", _layer);
              _layer.ref.setEnabled(_layer.v);
            }
          }
        }
      });
    });
  }

  public getNodeByFullName(name: string) {
    return this.nodeMap[name];
  }

  public serialize() {
    let result = [];

    this.groups.forEach(grp => {
      let layers = [];
      grp.layers.forEach(layer => {
        let _result = {
          uuid: layer.uuid,
          currentMaterial: (layer.getCurrentMaterial() ? layer.getCurrentMaterial().material.name : null),
          primaryMaterial: (layer.getPrimaryMaterial() ? layer.getPrimaryMaterial().material.name : null),
          materials: [],
          type: null,
          visible: layer.v,
          visibleInMenu: layer.visibleInMenu,
          hotspot: layer.hotspot,
          label: layer.label,
          nodePath: layer.getFullName(),
          tags: layer.tags.tags,
          settertags: layer.tags.setters,
          uid: layer.uid,
          materialLink: layer.materialLink,
          // vrayObjectProperty: layer.vrayObjectProperty,
        };

        layer.materials.materials.forEach(material => {
          _result.materials.push(material.material.name)
        })
        layers.push(_result);
      })
      result.push({
        uuid: grp.uuid,
        type: grp.type,
        label: grp.label,
        layers: layers,
        visible: grp._visible,
        nodePath: grp.getFullName(),
        tags: grp.tags.tags,
        settertags: grp.tags.setters,
        filterByTags: grp.filterByTags,
        strictFiltering: grp.strictFiltering,
      })
    });
    return result;
  }

  public deserialize(config, scene: SimpleKonfiguratorProfileSceneService) {
    this.scene = scene;
    this.scene.geometryGroups.ignoreShowHideGeometries = true;
    // gltf.meshes.forEach(mesh => {
    //   this.uidMap[mesh.uniqueId] = mesh;
    // })
    if (config.geometryGroups) {
      console.log(config.geometryGroups);
      config.geometryGroups.forEach(grp => {
        // console.log(grp.nodePath);
        // console.log(this.nodeMap[grp.nodePath]);

        if (grp.nodePath) {
          var group = new GeometryGroup(this, this.nodeMap[grp.nodePath]);
        } else {
          var group = new GeometryGroup(this, null);
        }
        if (!grp.uuid) {
          grp.uuid = uuid.v4();
        }
        group.uuid = grp.uuid;
        group.type = grp.type;
        group.label = grp.label;
        group.strictFiltering = grp.strictFiltering;
        group.filterByTags = grp.filterByTags;
        if (grp.visible === undefined) {
          group._visible = true;
        } else {
          group._visible = grp.visible
        }
        if (grp.tags) {
          group.tags.tags = grp.tags
          group.tags.setters = grp.settertags
          grp.tags.forEach(tag => {
            this.tags.addTag(tag);
          })
          if (group.tags.tags) {
            group.tags.tags.sort();
          }
          if (group.tags.setters) {
            group.tags.setters.sort();
          }
        }
        this.groups.push(group);
        grp.layers.forEach(layer => {
          let node = this.nodeMap[layer.nodePath]
          if (layer.uid >= 0) {
            console.log(layer.uid);

            node = this.uidMap[layer.uid]
          }
          // console.log(layer.nodePath);
          // console.log(node);
          let _layer: GroupLayer = group.add(
            layer.label,
            node);
          if (!layer.uuid) {
            layer.uuid = uuid.v4();
          }
          _layer.uuid = layer.uuid;
          _layer.v = layer.visible;
          _layer.visibleInMenu = layer.visibleInMenu;
          _layer.hotspot = layer.hotspot;
          _layer.label = layer.label;
          _layer.uid = layer.uid;
          _layer.materialLink = layer.materialLink;
          // _layer.vrayObjectProperty = layer.vrayObjectProperty;
          _layer.materials = new MaterialList();
          if (layer.tags) {
            _layer.tags.tags = layer.tags
            layer.tags.forEach(tag => {
              this.tags.addTag(tag);
            })
            _layer.tags.setters = layer.settertags
            if (!_layer.tags.setters) {
              _layer.tags.setters = [];
            }
          }
          layer.materials.forEach(mat => {
            let material = scene.materials.getMaterialById(mat);
            _layer.materials.push(material);
            if (layer.primaryMaterial == mat) {
              _layer.setPrimaryMaterial(material);
              _layer.setCurrentMaterial(material, false);
              _layer.assign();
            }
          })
          if (_layer.tags.tags) {
            _layer.tags.tags.sort();
          }
          if (_layer.tags.setters) {
            _layer.tags.setters.sort();
          }
        })
      })
      // console.log(this.groups)
    }
    // this.hideUnregisteredNodes();
    this.scene.geometryGroups.ignoreShowHideGeometries = false;
    this.showHideGeometries();
    this.refreshMaterialLinkMap();
  }

  public assign() {
    this.groups.forEach(group => {
      group.assign();
    })
  }

  getFullName(node) {
    if (node) {
      // let groupName = this.ref.name;
      // let modelName = this.ref.parent.name;
      // let result = "|" + modelName + "|" + groupName;
      let result = this._getFullName(node)
      // console.log(result);
      return result;
    } else {
      return null;
    }
  }

  _getFullName(node: any) {
    if (node) {
      if (node.parent) {
        return this._getFullName(node.parent) + "|" + fbxClean(node.name)
      } else {
        return "|" + fbxClean(node.name)
      }
    }
    return "";
  }

  dispose() {
    this.groups.forEach(group => {
      group.layers.forEach(layer => layer.parent = null);
      group.layers = null;
    });
  }

  showAll() {
    this.groups.forEach(grp => {
      grp.layers.forEach(layer => {
        layer.v = true;
      })
    })
    this.showHideGeometries();
  }

  public importMaterials(materialList: MaterialList, clear: boolean = true) {
    this.groups.forEach(grp => {
      grp.importMaterials(materialList, clear);
    })
  }

  public clearMaterials() {
    this.groups.forEach(grp => {
      grp.clearMaterials();
    })
  }

  public selectMesh(mesh: Mesh) {
    let result = null;
    this.checkRefs();
    try {
      this.groups.forEach(group => {
        group.layers.forEach(layer => {
          if (layer.ref) {
            let meshes = layer.ref.getChildMeshes(false);

            if (meshes.length == 0) {
              if (mesh == layer.ref) {
                result = layer;
                throw "";
              }
              if (this.getFullName(layer.ref) == this.getFullName(mesh)) {
                console.log(this.getFullName(layer.ref));

                result = layer;
                throw "";
              }
            } else {
              meshes.forEach(_mesh => {
                if (mesh === _mesh) {
                  result = layer;
                  throw "";
                }
              })
            }
          } else {
            console.log("ERROR: UNDEFINED LAYER.REF");
            console.log(layer);
          }
        })
      })
    } catch (ex) { }
    return result;

  }

  checkRefs() {
    console.log("checkRefs:");

    let meshes = [];
    this.rootNodes.forEach(node => {
      node.getChildMeshes(false).forEach(mesh => {
        meshes.push(mesh);
      })
    })
    this.groups.forEach(group => {
      group.layers.forEach(layer => {
        let match = null;
        meshes.forEach(mesh => {
          if (mesh == layer.ref) {
            match = mesh;
          }
        })
        if (!match) {
          layer.getFullName();
        }
      })
    })
  }

  public removeTag(tag) {
    this.tags.removeTag(tag);
    this.groups.forEach(grp => {
      grp.tags.removeTag(tag);
      grp.layers.forEach(layer => {
        layer.tags.removeTag(tag);
      })
    })
  }

  public refreshTags(deep = 0) {
    let change = false;
    let _v;
    this.activeTags = [];
    this.groups.forEach(g => {
      g.layers.forEach(l => {
        if (l.v) {
          l.tags.setters.forEach(t => {
            if (this.activeTags.indexOf(t) < 0) {
              this.activeTags.push(t);
            }
          })
        }
      })
    })
    console.log("activeTags:");
    console.log(this.activeTags);
    this.groups.forEach(g => {
      let _switchActiveLayer = false;
      if (g.filterByTags) {
        g.layers.forEach(l => {
          _v = l.visibleInMenu
          // l.v = false;
          l.visibleInMenu = false;
          let cnt = 0;
          l.tags.tags.forEach(t => {
            if (l.tags.setters.indexOf(t) >= 0) {
              return;
            }

            if (this.activeTags.indexOf(t) >= 0) {
              cnt += 1;
            }
          })

          if (g.strictFiltering) {
            if (cnt >= this.activeTags.length) {
              // l.v = true;
              l.visibleInMenu = true;
            }
          } else {
            if (cnt > 0) {
              // l.v = true;
              l.visibleInMenu = true;
            }
          }
          if (_v != l.visibleInMenu) {
            console.log(_v + " " + l.visibleInMenu);

            console.log(l);
            l.v = l.visibleInMenu;
            _switchActiveLayer = true;
            change = true;
          }
        })
      }
      // if active layer has to be switched
      if (_switchActiveLayer) {
        let first = true;
        g.layers.forEach(l => {
          if (l.visibleInMenu && first) {
            l.v = true;
            first = false;
          }
        })
      }
    })
    if (change && (deep < 3)) {
      console.log("repeat refreshTags");
      this.refreshTags(deep + 1);
    } else {
      this.showHideGeometries();
    }
  }

  public refreshMaterialLinkMap() {
    console.log("REFRESH MATERIAL LINK MAP", this.scene.materialLinks.materialLinkAssignments)
    this.currentlySelectedLinkMap = {};
    this.materialLinkMap = {}

    let unassigned = new Set();

    if (this.scene.materialLinks.materialLinkAssignments) {
      this.scene.materialLinks.materialLinkAssignments.filter(ma => ma).forEach( ma => {
        this.materialLinkMap[ma.link] = ma.materials;
      })
    } else {
      this.groups.forEach(gg => {
        gg.layers.forEach(lay => {
          if (lay.materialLink) {
            this.currentlySelectedLinkMap[lay.materialLink] = lay.currentMaterial;
            this.materialLinkMap[lay.materialLink] = lay.materials.materials;
          } else {
            lay.materials.materials.forEach(m => {
              unassigned.add(m);
            })
          }
        })
      })
      this.unassignedMaterials = Array.from(unassigned) as Material[];
    }
  }

  public getMaterialsForLink(link: string): Material[] {
    const layers: Material[] = [];

    this.getGroups().forEach(gg => {
      gg.layers.filter(lay => lay.materialLink == link)
        .forEach(lay => {
          lay.materials.getMaterials().forEach(mat => {
            if (layers.indexOf(mat) < 0) {
              layers.push(mat);
            }
          })
        })
    })

    return layers;
  }

  public setMaterialToLink(link: string, material: Material) {
    this.groups.forEach(group => {
      group.layers.forEach(layer => {
        if (layer.materialLink == link) {
          layer.currentMaterial = material;
        }
      });
    });

    this.materialChange$.next({ link, material });
    this.refreshMaterialLinkMap();
  }

  public setPrimaryMaterialToLink(link: string, material: Material) {
    this.groups.forEach(group => {
      group.layers.forEach(layer => {
        if (layer.materialLink == link) {
          layer.currentMaterial = material;
          layer.primaryMaterial = material;
        }
      });
    });

    this.materialChange$.next({ link, material });
    this.refreshMaterialLinkMap();
  }

  public assignMaterialsToLink(link: string, materials: Material[], current: Material, primary: Material) {
    this.groups.forEach(grp => {
      grp.layers.filter(lay => lay.materialLink == link).forEach(lay => {
        lay.materials.materials = [...materials];
        lay.currentMaterial = current;
        lay.primaryMaterial = primary;
        lay.assign();
      })
    })
  }
}

export class GeometryGroup {
  id: number = 0;
  public uuid: string = "";
  public label: string;
  layers: GroupLayer[] = [];
  type: string = 'inclusive';
  _visible: boolean = true;
  public tags: Tags = new Tags();
  public filterByTags: boolean = false;
  public strictFiltering: boolean = false;


  constructor(public parent: GeometryGroups, public ref: Node) {
  }

  public assign() {
    this.layers.forEach(layer => {
      layer.assign();
    })
  }

  public getLayers() { return this.layers; }

  public add(label, ref) {
    let result = new GroupLayer(
      this.parent.lastId,
      label,
      true,
      {
        visible: false,
        x: 0,
        y: 0,
        z: 0,
      },
      ref,
      this);
    result.uuid = uuid.v4();
    result.materialLink = "";
    result.hotspot = {
      visible: false,
      x: 0,
      y: 0,
      z: 0,
    }
    this.parent.lastId += 1;
    this.layers.push(result);
    return result;
  }

  public addExisting(layer: GroupLayer) {
    this.parent.lastId += 1;
    this.layers.push(layer);
  }

  public setLabel(label: string) {
    this.label = label;
  }

  public importLayers(nodes: GeometryNode[]) {
    this.layers = [];
    for (let node of nodes) {
      this.layers.push(new GroupLayer(
        this.parent.lastId,
        node.label,
        true,
        {
          visible: false,
          x: 0,
          y: 0,
          z: 0,
        },
        node.node,
        this
      ))
      this.parent.lastId = + 1;
    }
  }

  setType(type: string) {
    this.type = type;
  }

  public getFullName() {
    if (this.ref) {
      // let groupName = this.ref.name;
      // let modelName = this.ref.parent.name;
      // let result = "|" + modelName + "|" + groupName;
      let result = this._getFullName(this.ref)
      return result;
    } else {
      return null;
    }
  }

  _getFullName(node: any) {
    if (node) {
      if (node.parent) {
        if (node.name == "TransferNode") {
          return this._getFullName(node.parent)
        }
        return this._getFullName(node.parent) + "|" + fbxClean(node.name)
      } else {
        return "|" + fbxClean(node.name)
      }
    }
    return "";
  }

  visibleInMenu() {
    let result = false;
    this.layers.forEach(layer => {
      if (layer.visibleInMenu) {
        result = true;
      }
    });
    return result;
  }

  delete() {
    let i = this.parent.groups.indexOf(this);
    if (i >= 0) {
      this.parent.groups.splice(i, 1);
    }
  }

  set visible(visible: boolean) {
    this._visible = visible;
    this.parent.showHideGeometries();
  }

  get visible() {
    return this._visible;
  }

  public clearMaterials() {
    this.layers.forEach(layer => {
      layer.clearMaterials();
    })
  }

  public importMaterials(materialList: MaterialList, clear: boolean = true) {
    this.layers.forEach(layer => {
      layer.importMaterials(materialList, clear);
    })
  }

}

export class GroupLayer {
  public uuid: string;
  private _v: boolean;
  public materials: MaterialList;
  public currentMaterial: Material = null;
  public primaryMaterial: Material = null;
  public tags: Tags = new Tags();
  public uid: number = -1;
  public materialLink: string = "";
  // public vrayObjectProperty: boolean = false;

  constructor(
    public id: number,
    public label: string,
    public visibleInMenu: boolean,
    public hotspot: {
      visible: boolean,
      x: number,
      y: number,
      z: number,
    } = {
        visible: false,
        x: 0,
        y: 0,
        z: 0,
      },
    public ref: Node,
    public parent: GeometryGroup) {

    this.materials = new MaterialList();
    if (this.parent.type == "exclusive") {
      if (this.parent.layers.length == 0) {
        this._v = true;
      } else {
        this._v = false;
      }
    }
    if (this.parent.type == "inclusive") {
      this._v = true;
    }

  }

  set v(v: boolean) {
    if (this.parent.type == 'exclusive') {
      if (v == true) {
        this.parent.layers.forEach(item => item._v = false)
      }
      this._v = v;
    }
    if (this.parent.type == "inclusive") {
      this._v = v;
    }

    this.parent.parent.showHideGeometries();
  }

  get v() { return this._v; }

  isMaterialInList(material) {
    return this.materials.materials.indexOf(material) >= 0;
  }

  public clearMaterials() {
    this.materials = new MaterialList();
    if (this.materialLink) {
      this.parent.parent.groups.forEach(grp => {
        grp.layers.forEach(lay => {
          if (this.materialLink == lay.materialLink) {
            lay.materials = new MaterialList();
          }
        })
      })
    }
  }

  public importMaterials(materialList: MaterialList, clear: boolean = true) {
    console.log(materialList);

    if (clear) {
      console.log("clear");

      this.clearMaterials();
    }

    var currentMaterial = null;
    var primaryMaterial = null;
    var result = false;
    materialList.materials.forEach(material => {
      if (material.checked) {
        if (!clear) {
          if (this.materials.materials.indexOf(material) < 0) {
            this.addMaterial(material);
          }
        } else {
          this.addMaterial(material);
        }
        if (this.currentMaterial === material) {
          currentMaterial = material;
        }
        if (this.primaryMaterial === material) {
          primaryMaterial = material;
        }
      }
    });
    if (!currentMaterial) {
      this.currentMaterial = this.materials.materials[0];
      result = true;
    }
    if (!primaryMaterial) {
      this.primaryMaterial = this.materials.materials[0];
      result = true;
    }
    return result;
  }

  addMaterial(material) {
    this.materials.push(material);
    if (this.materialLink) {
      this.parent.parent.groups.forEach(grp => {
        grp.layers.forEach(lay => {
          if (this.materialLink == lay.materialLink) {
            lay.materials.push(material);
          }
        });
      });
    }
    this.parent.parent.refreshMaterialLinkMap();
  }


  public setCurrentMaterial(material: Material, recurse = true) {
    function iterate(link, root) {
      console.log("ITERATE", link);

      let corrections = [];
      root.relations.list.forEach(rel => {
        if ((rel.linkLeft == link) &&
          (material.label == rel.materialLeft) &&
          (rel.default == true)) {
          console.log("SETREL", rel);
          corrections.push(rel.linkRight);
          root.setMaterialToLink(rel.linkRight,
            root.materials.getMaterialById(rel.materialRight))
        }

        corrections.forEach(link => {
          iterate(link, root);
        });
      });
    }

    if (!this.materialLink) {
      this.currentMaterial = material;
      if (this.parent.type == 'unified') {
        this.parent.layers.forEach(layer => layer.currentMaterial = material)
      }
    } else {
      let link = this.materialLink;
      this.currentMaterial = material;

      if (!recurse) {
        return;
      }
      this.parent.parent.setMaterialToLink(link, material)


      iterate(this.materialLink, this.parent.parent);

    }
  }

  public getCurrentMaterial() {
    return this.currentMaterial;
  }

  public isCurrentMaterial(material) {
    return this.currentMaterial === material;
  }

  public setPrimaryMaterial(material) {
    if (!this.materialLink) {
      this.primaryMaterial = material;
      if (this.parent.type == 'unified') {
        this.parent.layers.forEach(layer => layer.primaryMaterial = material)
      }
    } else {
      let link = this.materialLink;
      this.primaryMaterial = material;
      this.parent.parent.groups.forEach(group => {
        group.layers.forEach(layer => {
          if (layer.materialLink == link) {
            layer.primaryMaterial = material;
          }
        })
      });
    }
  }

  public getPrimaryMaterial() {
    return this.primaryMaterial;
  }

  public isPrimaryMaterial(material) {
    return this.primaryMaterial === material;
  }

  public getMaterials() {
    let result: MaterialList = new MaterialList();
    console.log("linkmap", this.parent.parent.currentlySelectedLinkMap);
    let rules = this.parent.parent.relations.list
      .filter(rel => (rel.linkRight == this.materialLink) && this.parent.parent.currentlySelectedLinkMap[rel.linkLeft] &&
        (this.parent.parent.currentlySelectedLinkMap[rel.linkLeft].label == rel.materialLeft));

    if (rules.length) {
      result.materials = [];

      this.materials.materials.forEach(mat => {
        rules.forEach(r => {
          if (mat.label == r.materialRight) {
            result.materials.push(mat);
          }
        })
      });

      return result;

    } else {
      return this.materials;
    }
  }

  public getFullName() {
    let result = this._getName(this.ref, "|");
    return result;
  }

  public getElementIDName() {
    let result = this._getName(this.ref, "");
    return result;
  }

  _getName(node: any, separator: string) {
    if (node) {
      if (node.parent) {
        if (node.name == "TransferNode") {
          return this._getName(node.parent, separator)
        }
        return this._getName(node.parent, separator) + separator + fbxClean(node.name)
      } else {
        return separator + fbxClean(node.name)
      }
    }
    return "";
  }

  public assign() {
    let materials = [];
    if (!this.ref) {
      return;
    }
    var meshes = this.ref.getChildMeshes(false);
    if (meshes.length == 0) {
      this.colorMesh(this.ref, this.getCurrentMaterial());
    } else {
      if (meshes) {
        meshes.forEach((mesh) => {
          this.colorMesh(mesh, this.getCurrentMaterial());
        })
      }
    }
  }

  public removeMaterial(material) {
    this.materials.remove(material);
    if (this.materialLink) {
      this.parent.parent.groups.forEach(grp => {
        grp.layers.forEach(lay => {
          if (this.materialLink == lay.materialLink) {
            lay.materials.remove(material);
          }
        })
      })
    }
    this.parent.parent.refreshMaterialLinkMap();
  }

  colorMesh(mesh, material) {
    if (!mesh) {
      return
    }
    if (material) {
      if (mesh.material) {
        if (mesh.material.name != "Default_Material") {
          // mesh.material.dispose();
        }
      }
      // mesh.material = material.material.clone();    // ?? why did we clone?
      mesh.material = material.material;
      mesh.material.backFaceCulling = false;
    }
    // mesh.material.albedoColor.r = color.r;
    // mesh.material.albedoColor.g = color.g;
    // mesh.material.albedoColor.b = color.b;
    // node.materials = materials;
  }

  fixMaterials() {
    if (this.materials.materials.length == 1) {
      this.currentMaterial = this.materials.materials[0];
      this.primaryMaterial = this.materials.materials[0];
      this.assign();
    }
    if (this.materials.materials.length > 1) {
      if (this.materials.materials.indexOf(this.primaryMaterial) < 0) {
        this.primaryMaterial = this.materials.materials[0];
      }
      if (this.materials.materials.indexOf(this.currentMaterial) < 0) {
        this.currentMaterial = this.materials.materials[0];
      }
      this.assign();
    }
  }

  moveTo(group: GeometryGroup) {
    this.delete();
    group.layers.push(this);
    this.parent = group
  }

  delete() {
    let i = this.parent.layers.indexOf(this);
    if (i >= 0) {
      this.parent.layers.splice(i, 1);
    }
  }


  isVisible(i: number) {
    if (this.parent.type == "unified") {
      if (i == 0) {
        return true;
      }
      return false;
    }
    return this.visibleInMenu;
  }


}

export function getMeshFullName(mesh: any): string {
  return "|" + _getMeshFullName(mesh);
}

function _getMeshFullName(mesh: any): string {
  if (mesh.parent) {
    return _getMeshFullName(mesh.parent) + "|" + fbxClean(mesh.name);
  } else {
    return fbxClean(mesh.name);
  }
}

export class Tags {
  public tags: string[] = [];
  public setters: string[] = [];

  constructor() { }

  toggleTag(tag: string) {
    let ti = this.tags.indexOf(tag);
    let si = this.setters.indexOf(tag)

    if (this.tags.indexOf(tag) < 0) {
      this.addTag(tag);
    } else {
      if (this.setters.indexOf(tag) < 0) {
        this.addSetter(tag);
      } else {
        this.removeTag(tag);
        this.removeSetter(tag);
      }
    }
    if (this.tags) {
      this.tags.sort();
    }
    if (this.setters) {
      this.setters.sort();
    }
  }

  addTag(tag: string) {
    if (this.tags.indexOf(tag) < 0) {
      this.tags.push(tag);
    }
    if (this.tags) {
      this.tags.sort();
    }
  }

  addSetter(tag: string) {
    if (this.setters.indexOf(tag) < 0) {
      this.setters.push(tag);
    }
    if (this.setters) {
      this.setters.sort();
    }
  }

  removeTag(tag: string) {
    let pos = this.tags.indexOf(tag);
    if (pos >= 0) {
      this.tags.splice(pos, 1);
    }
  }

  removeSetter(tag: string) {
    let pos = this.setters.indexOf(tag);
    if (pos >= 0) {
      this.setters.splice(pos, 1);
    }
  }

  hasTag(tag: string) {
    return (this.tags.indexOf(tag) >= 0);
  }

  isSetterTag(tag: string) {
    return (this.setters.indexOf(tag) >= 0);
  }

}

function fbxClean(name: string) {
  let oldName = name
  while (true) {
    if ("0123456789".indexOf(name[0]) >= 0) {
      name = "FBXASC0" + name.charCodeAt(0) + name.slice(1);
    }
    name = name
      .replace(" ", "FBXASC032")
      .replace("\"", "FBXASC092")
      .replace("'", "FBXASC039")
      .replace("`", "FBXASC096")
      .replace("~", "FBXASC126")
      .replace("!", "FBXASC033")
      .replace("@", "FBXASC064")
      .replace("#", "FBXASC035")
      .replace("$", "FBXASC036")
      .replace("^", "FBXASC094")
      .replace("&", "FBXASC038")
      .replace("*", "FBXASC042")
      .replace("(", "FBXASC040")
      .replace(")", "FBXASC041")
      .replace("-", "FBXASC045")
      .replace("=", "FBXASC061")
      .replace("[", "FBXASC091")
      .replace("{", "FBXASC123")
      .replace("]", "FBXASC093")
      .replace("}", "FBXASC125")
      .replace(";", "FBXASC059")
      .replace(":", "FBXASC058")
      .replace("<", "FBXASC060")
      .replace(",", "FBXASC044")
      .replace(">", "FBXASC062")
      .replace("/", "FBXASC047")
      .replace("?", "FBXASC063");

    if (name == oldName) {
      return name;
    }

    oldName = name;
  }
}
