import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  public list: any;
  public organization: any;
 
  constructor(private http:HttpClient) { }


  public async getAllOrganizationsList() {
    let result = <any>await this.http.get("/organizations/all").toPromise();
    this.list = result;
    return result;
  }

  public setOrganization(organization) {
    this.organization = organization;
  }

  public async getOrganizationByName(name) {
    let list:[] = await <any>this.getAllOrganizationsList();
    return list.filter(item => item['name'] == name)[0];
  }

  public async addOrganization(name) {
    let result = await this.http.post("/organizations", {"name":name}).toPromise();
    console.log(result);
    return await this.getAllOrganizationsList();
  }

  public async updateOrganization(organization?) {
    if (organization) {
      let result = await this.http.post("/organizations", organization).toPromise();
      console.log(result);
    } else {
      let result = await this.http.post("/organizations", this.organization).toPromise();
      console.log(result);
    }
    return await this.getAllOrganizationsList();
    
  }

}
