import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as BABYLON from 'babylonjs';
import "babylonjs-loaders";
import { ArcRotateCamera, FramingBehavior } from 'babylonjs';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class TestComponent implements OnInit {
  @ViewChild('gl', { static: true }) canvas: ElementRef;

  engine: any;
  scene: any;
  currentScene: BABYLON.Scene;
  currentPluginName: any;
  skyboxPath = "https://assets.babylonjs.com/environments/environmentSpecular.env";
  currentSkybox: any;
  auxScenes: BABYLON.Scene[];

  constructor() { }

  ngOnInit() {
    this.engine = new BABYLON.Engine(this.canvas.nativeElement, true);

    // BABYLON.GLTFFileLoader.IncrementalLoading = false;
    BABYLON.SceneLoader.OnPluginActivatedObservable.add((plugin: any) => {
      this.currentPluginName = plugin.name;
      if (this.currentPluginName === "gltf") {
        plugin.onValidatedObservable.add((results) => {
          if (results.issues.numErrors > 0) {
            // debugLayerEnabled = true;
          }
        });
      }
    });
    // this.loadMainFromURL("/assets/LeatherJacket_ivan_out/LeatherJacket_ivan.gltf");
    this.loadTest();
  }

  loadTest() {
    this.scene = new BABYLON.Scene(this.engine);
    var camera = new BABYLON.ArcRotateCamera("Camera",
      Math.PI / 2, Math.PI / 2, 2, new BABYLON.Vector3(0, 0, 5), this.scene);
    camera.attachControl(this.canvas.nativeElement, true);
    var light1 = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(1, 1, 0), this.scene);
    var light2 = new BABYLON.PointLight("light2", new BABYLON.Vector3(0, 1, -1), this.scene);
    var sphere = BABYLON.MeshBuilder.CreateSphere("sphere", { diameter: 2 }, this.scene);

    this.engine.runRenderLoop(() => {
      this.scene.render();
    });
  }

  onResize(event) {
    this.engine.resize();
  }

  loadOfflineScene(URL) {
    var rootUrl = BABYLON.Tools.GetFolderPath(URL);
    var fileName = BABYLON.Tools.GetFilename(URL);
    console.log(URL)
    console.log(rootUrl)
    console.log(fileName)
    BABYLON.SceneLoader.LoadAsync(rootUrl, fileName, this.engine).then((scene) => {
      console.log(scene);
    });
  }

  loadMainFromURL(assetUrl) {
    var rootUrl = BABYLON.Tools.GetFolderPath(assetUrl);
    var fileName = BABYLON.Tools.GetFilename(assetUrl);
    console.log(assetUrl)
    console.log(rootUrl)
    console.log(fileName)
    BABYLON.SceneLoader.LoadAsync(rootUrl, fileName, this.engine).then((scene: BABYLON.Scene) => {
      console.log(scene.rootNodes);
      console.log(scene.meshes);
      console.log(scene.geometries);
      console.log(scene.materials);
      console.log(scene.textures);
      if (this.currentScene) {
        this.currentScene.dispose();
      }

      this.currentScene = scene;
      this.setDefaultCamera();
      this.setDefaultLights();
      this.sceneLoaded({ name: fileName }, scene);
      this.setEvents();

      scene.whenReadyAsync().then(() => {
        this.engine.runRenderLoop(() => {
          scene.render();
        });
      });
    }).catch((reason) => {
      console.log(reason);
      this.sceneError({ name: fileName }, null, reason.message || reason);
    });
  };

  setDefaultCamera() {
    this.currentScene.createDefaultCamera(true);
    console.log(this.currentPluginName)
    var camera = (<ArcRotateCamera>this.currentScene.activeCamera)
    if (this.currentPluginName === "gltf") {
      // glTF assets use a +Z forward convention while the default camera faces +Z. Rotate the camera to look at the front of the asset.
      camera.alpha += Math.PI;
    }
    camera.useFramingBehavior = true;
    var framingBehavior = (<FramingBehavior>camera.getBehaviorByName("Framing"));
    framingBehavior.framingTime = 0;
    framingBehavior.elevationReturnTime = -1;

    if (this.currentScene.meshes.length) {
      var worldExtends = this.currentScene.getWorldExtends();
      camera.lowerRadiusLimit = null;
      framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max);
    }
    camera.pinchPrecision = 200 / camera.radius;
    camera.upperRadiusLimit = 5 * camera.radius;

    camera.wheelDeltaPercentage = 0.01;
    camera.pinchDeltaPercentage = 0.01;
    camera.attachControl(this.canvas.nativeElement);
  }

  setDefaultLights() {
    if (this.currentPluginName === "gltf") {
      if (!this.currentScene.environmentTexture) {
        this.currentScene.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(this.skyboxPath, this.currentScene);
      }

      this.currentSkybox = this.currentScene.createDefaultSkybox(this.currentScene.environmentTexture, true, (this.currentScene.activeCamera.maxZ - this.currentScene.activeCamera.minZ) / 2, 0.3, false);
    }
    else {
      this.currentScene.createDefaultLight();
    }
  }

  setEvents() {
    this.currentScene.onPointerMove = (ev, picking, evt) => {
      var el = this.currentScene.pick(this.currentScene.pointerX, this.currentScene.pointerY);
    }
    this.currentScene.onPointerUp = (ev, picking, evt) => {
      var el = this.currentScene.pick(this.currentScene.pointerX, this.currentScene.pointerY);
      console.log(el)
    }
  }

  sceneLoaded(sceneFile, babylonScene) {

  }

  sceneError(sceneFile, babylonScene, message) {

  }

}
