import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { v4 as uuid } from 'uuid';


@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  user: any = {
    email: 'ivan.maglica@tronog.com'
  };
  project: any;

  constructor(private http: HttpClient) { }

  public setProject(project: any) {
    console.log("setProject")
    console.log(project);
    
    this.project = project;
  }

  public setUser(user: any) {
    if (user) {
      this.user = user;
    }
  }

  public async getComments() {
    let result: any = await this.http.get("/simplekonfiguratorprofile/projects/" + this.project.path + "/comments/").toPromise();
    result.forEach(comment => {
      if (!comment.id) {
        comment.id = uuid();
      }
    })
    return result;
  }

  public async saveComments(comments) {
    return await this.http.post("/simplekonfiguratorprofile/projects/" + this.project.path + "/comments/", comments).toPromise();
  }


  public async postComment(comment) {
    console.log(comment);
    let newComment = {
      "id": uuid(),
      "userName": this.user.email,
      "comment": comment,
      "timestamp": new Date().toISOString()
    }
    let comments: any = await this.http.get("/simplekonfiguratorprofile/projects/" + this.project.path + "/comments/").toPromise();
    comments.push(newComment);
    await this.saveComments(comments);
    return newComment;
  }

  public async deletePost(id) {
    let comments = await this.getComments();
    comments = comments.filter(comment => {
      console.log(id);
      if (comment.comments) {
        comment.comments = comment.comments.filter(c => {
          console.log(c.id);
          if (c.id != id) {
            
            return true;
          } 
        })
      }
      console.log(comment.id);
      
      if (comment.id != id) {
        
        return true;
      } else {
        console.log("false");

      }
    });
    console.log(comments);
    
    return await this.saveComments(comments);
  }

  public async modifyPost(id, comment) {
    let comments = await this.getComments();
    comments = comments.map(_comment => {
      if (_comment.comments) {
        _comment.comments = _comment.comments.map(subcomment => {
          if (subcomment.id !== id) {
            return subcomment;
          } else {
            return comment
          }

        })
      }
      if (_comment.id !== id) {
        return _comment;
      } else {
        return comment;
      }
    });
    return await this.saveComments(comments);
  }

  public async replyToComment(currentComment, newComment) {
    let comments = await this.getComments();
    console.log(comments);
    
    comments.forEach(comment => {
      if (comment.id == currentComment.id) {
        if (!comment.comments) {
          comment.comments = []
        }
        comment.comments.push({
          "id": uuid(),
          "userName": this.user.email,
          "comment": newComment,
          "timestamp": new Date().toISOString()
        })
      }
    })
    await this.saveComments(comments);
    return comments;
  }

}
