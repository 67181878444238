import { Injectable, ElementRef } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import { MediaRecorder } from '@types/dom-mediacapture-record';
// import adapter from "webrtc-adapter";


// Video and audio simultaneous recording still not working (only captures audio output)
// https://paul.kinlan.me/screen-recorderrecording-microphone-and-the-desktop-audio-at-the-same-time/

declare var MediaRecorder: any;

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  public recording$: Observable<any>;
  public recordingObserver: Observer<any>;

  private recording: any;
  private playbackElement: ElementRef;

  recordingState:string = "idle";

  videoRecordings = [];

  constructor(
    private http:HttpClient
  ) {
    this.recording$ = new Observable((observer: Observer<any>) => {
      this.recordingObserver = observer;
    })
  }

  public setComment(comment) {
    // TODO
  }

  public getRecordingState() {
    return this.recordingState;
  }

  public async startRecording(projectpath, name) {
    this.recordingState = "recording";
    this.recordingObserver.next(this.recordingState);

    this.startRecordingVideo(projectpath, name);
  }

  public stopRecording() {
    this.recording.stop()
    this.recordingState = "idle";
    this.recordingObserver.next(this.recordingState);
  }

  public play() {
    
  }

  public async toggleRecording(projectpath, name) {
    switch (this.recordingState) {
      case "idle":
        await this.startRecording(projectpath, name);
        break;
      case "recording":
        this.stopRecording();
        break;
    }
  }

  private async startRecordingVideo(projectpath, name) {
    const md = (navigator.mediaDevices) as any;
    console.log("MD", md);
    
    
    let stream = await md.getDisplayMedia({audio: {
      deviceId: "default",
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100
    }, "video":true});
    this.recording = new MediaRecorder(stream);
    this.recording.start();
    this.recording.ondataavailable = async e => {
      this.videoRecordings.push(e.data);
      if (this.recording.state == "inactive") {
        console.log("stopped");
        
        let blob = new Blob(this.videoRecordings, {type: 'video/mp4'});
        
        let url = URL.createObjectURL(blob);

        // this.playbackElement.nativeElement.src = url;
        // this.playbackElement.nativeElement.controls = true;
        // this.playbackElement.nativeElement.autoplay = true;
        
        let result = await this.saveRecording(projectpath, name, blob);
        this.recordingObserver.next(result);
      }
    }
  }

  private async saveRecording(projectpath, name, blob:Blob) {
    console.log("SAVE");
    
    console.log(blob);
    const formData = new FormData();
    formData.append('recording', blob);
    console.log("posting...");
    
    let result = await this.http.post("/simplekonfiguratorprofile/projects/" + projectpath + "/video/" + name, formData).toPromise();
    console.log("posted ", result);
    
  }

  public setPlaybackElement(element: ElementRef) {
    this.playbackElement = element;
  }


}
