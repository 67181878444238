import { Injectable } from '@angular/core';
import { EngineService } from '../engine/engine.service';
import { SSAO2RenderingPipeline, DefaultRenderingPipeline, PassPostProcess, Camera } from 'babylonjs';

@Injectable({
  providedIn: 'root'
})
export class PostprocessService {

  ssaoEnabled = false;
  shadowsEnabled = false;
  bloomEnabled = false;
  fxaaEnabled = false;
  supersamplingEnabled = false;
  exposure: number = 0;

  pp1: any = null;
  pp2: any = null;
  pp3: any = null;
  pp4: any = null;
  currentSize: number = null;

  private ssaoRenderingPipeline: SSAO2RenderingPipeline;
  public defaultRenderingPipeline: DefaultRenderingPipeline;

  constructor(public engineService: EngineService) {
    /* document.addEventListener('keydown', e => {
      if (e.key === 'Pause') {
        this.engineService.currentScene.debugLayer.show({ enablePopup: true, embedMode: true, overlay: true });
      }
    }); */

    this.engineService.postprocess = this;
    this.engineService.loadingEmitter$.subscribe(
      isLoading => {
        if (isLoading && this.engineService.currentScene) {
          console.log('Init post process pipelines', this.engineService.currentScene);
          this.initPipelines();
        }
      }

    );
  }

  initPipelines() {
    console.log("initPipelines");
    
    // this.ssaoEnabled = false;
    // this.shadowsEnabled = false;
    // this.bloomEnabled = false;
    // this.fxaaEnabled = false;

    if (SSAO2RenderingPipeline.IsSupported) {
      const ssaoRatio = {
        ssaoRatio: 0.5, // Ratio of the SSAO post-process, in a lower resolution
        blurRatio: 0.5// Ratio of the combine post-process (combines the SSAO and the scene)
      };
      const ssao = new SSAO2RenderingPipeline(
        'ssao2',
        this.engineService.currentScene,
        ssaoRatio
      );
      ssao.radius = 4;
      ssao.totalStrength = 1.6;
      ssao.expensiveBlur = true;
      ssao.samples = 16;
      ssao.maxZ = 250;
      ssao.minZAspect = .15;
      this.ssaoRenderingPipeline = ssao;
    }
    this.defaultRenderingPipeline = new DefaultRenderingPipeline(
      'default', // The name of the pipeline
      true, // Do you want HDR textures ?
      this.engineService.currentScene // The scene instance
    );

    this.defaultRenderingPipeline.fxaaEnabled = false;
    // this.defaultRenderingPipeline.samples = 16;

    this.defaultRenderingPipeline.bloomEnabled = false;
    this.defaultRenderingPipeline.bloomWeight = .5;
    this.defaultRenderingPipeline.bloomKernel = 42;
    this.defaultRenderingPipeline.bloomScale = .5;
    this.defaultRenderingPipeline.bloomThreshold = .85;
    this.defaultRenderingPipeline.imageProcessing.colorCurvesEnabled = true;




    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      // some code..
    } else {
      this.initSuperSampling();
      this.defaultRenderingPipeline.samples = 4;
      setTimeout(() => {
        this.toggleFXAA();
        setTimeout(() => {
          this.toggleFXAA();
        }, 500);
      }, 500);
      // this.setSuperSampling(4);
    }
  }

  public serialize() {
    return {
      fxaaEnabled: this.fxaaEnabled,
      ssaoEnabled: this.ssaoEnabled,
      bloomEnabled: this.bloomEnabled,
      exposure: this.exposure
    };
  }

  public deserialize(properties) {
    console.log("PP deserialize:");

    console.log(properties);

    if (properties.fxaaEnabled) { this.fxaaEnabled = properties.fxaaEnabled; this.updateFXAA(); }
    if (properties.ssaoEnabled) { this.ssaoEnabled = properties.ssaoEnabled; this.updateSSAO(); }
    if (properties.bloomEnabled) { this.bloomEnabled = properties.bloomEnabled; this.updateBloom(); }
    if (properties.exposure) { this.exposure = properties.exposure; this.updateExposure(); }  
  }

  public initSuperSampling() {
    if (navigator.userAgent.match(/iPad/i) != null) {
      return
    }
    console.log("INIT SUPERSAMPLING");


    var camera: any = this.engineService.currentScene.activeCamera;

    // this.pp4 = new BABYLON.PassPostProcess("scale_pass8", 8.0, camera, BABYLON.Texture.BILINEAR_SAMPLINGMODE);
    this.pp3 = new BABYLON.PassPostProcess("scale_pass4", 4.0, camera, BABYLON.Texture.BILINEAR_SAMPLINGMODE);
    this.pp2 = new BABYLON.PassPostProcess("scale_pass2", 2.0, camera, BABYLON.Texture.BILINEAR_SAMPLINGMODE);
    this.pp1 = new BABYLON.PassPostProcess("scale_pass1", 1.0, camera, BABYLON.Texture.BILINEAR_SAMPLINGMODE);

  }
  public setSuperSampling(size: number) {
    console.log("SET SUPERSAMPLING:");
    console.log(size);
    
    
    const postprocessManager = this.engineService.currentScene.postProcessRenderPipelineManager
    if (size == 8 && this.currentSize == 4) {
      // this.engineService.currentScene.activeCamera.detachPostProcess(this.pp4)
      this.engineService.currentScene.activeCamera.detachPostProcess(this.pp3)
      this.engineService.currentScene.activeCamera.detachPostProcess(this.pp2)
      this.engineService.currentScene.activeCamera.detachPostProcess(this.pp1)
      this.engineService.currentScene.activeCamera.attachPostProcess(this.pp4)
      this.engineService.currentScene.activeCamera.attachPostProcess(this.pp3)
      this.engineService.currentScene.activeCamera.attachPostProcess(this.pp2)
      this.engineService.currentScene.activeCamera.attachPostProcess(this.pp1)
    }
    if (size == 4 && this.currentSize == 8) {
      this.engineService.currentScene.activeCamera.detachPostProcess(this.pp4)
    }
    this.currentSize = size;
    this.defaultRenderingPipeline.samples = 4;
    setTimeout(() => {
      this.toggleFXAA();
      setTimeout(() => {
        this.toggleFXAA();
      }, 100);
    }, 100);

}

  toggleSSAO() {
    console.log("toggleSSAO");
    this.ssaoEnabled = !this.ssaoEnabled;
    this.updateSSAO();
  }

  updateSSAO() {
    console.log("updateSSAO");
    if (this.ssaoRenderingPipeline) {
      try {
        const postprocessManager = this.engineService.currentScene.postProcessRenderPipelineManager;
        if (!this.ssaoEnabled) {
          postprocessManager.detachCamerasFromRenderPipeline('ssao2', this.engineService.currentScene.activeCamera);
        }
        else {
          postprocessManager.attachCamerasToRenderPipeline('ssao2', this.engineService.currentScene.activeCamera);
        }
      } catch (e) {
        console.log("SSAO ERROR");

      }
    }
  }

  toggleFXAA() {
    console.log("toggleFXAA");

    this.fxaaEnabled = !this.fxaaEnabled;
    this.updateFXAA();
  }

  updateFXAA() {
    console.log("updateFXAA");
    console.log(this.fxaaEnabled);
    console.log(this.defaultRenderingPipeline);
    console.log(this.defaultRenderingPipeline.fxaaEnabled);


    if (this.defaultRenderingPipeline) {
      this.defaultRenderingPipeline.fxaaEnabled = this.fxaaEnabled;
    }
  }

  toggleBloom() {
    console.log("toggleBloom");
    this.bloomEnabled = !this.bloomEnabled;
    this.updateBloom();
  }

  updateBloom() {
    console.log("updateBloom");
    if (this.defaultRenderingPipeline) {
      this.defaultRenderingPipeline.bloomEnabled = this.bloomEnabled;
    }
  }

  public updateExposure() {
    console.log("updateExposure");
    if (this.defaultRenderingPipeline) {
      this.defaultRenderingPipeline.imageProcessing.colorCurvesEnabled = true;
      this.defaultRenderingPipeline.imageProcessing.colorCurves.globalExposure = this.exposure;
    }
  }

  toggleShadows() {

  }

}
