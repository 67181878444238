import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OrganizationsService } from 'src/app/services/organizations/organizations.service';

@Component({
  selector: 'app-organizations-edit',
  templateUrl: './organizations-edit.component.html',
  styleUrls: ['./organizations-edit.component.css']
})
export class OrganizationsEditComponent implements OnInit {
  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();
  
  constructor(
    public organizations: OrganizationsService
  ) { }

  ngOnInit() {
  }

  onSaveClick() {
    console.log("save");
    this.save.emit();
  }

  onCancelClick() {
    this.cancel.emit();
  }
}
