import { Injectable } from '@angular/core';
import * as BABYLON from 'babylonjs';


export interface ISceneService {
  selectMesh(mesh);

  register(currentScene, auxScenes);

  expandNode(node);

  assign(nodes, mats, material, color, checkCheck?);

  selectMaterial(material);

  assignGlobal();

  getSelectedNodes();

  getSelectedMaterials();

  deselectAllNodes();

  deselectNode(node);

  selectNodesFromGroup(group);

  unselectNodeFromGroup(group, node);

  selectGroupMaterial(group, material);

  selectGroupColor(group, color);

  setNoColors(noColors);
}


@Injectable({
  providedIn: 'root'
})
export class SceneService implements ISceneService {

  public rootNodes: any[] = [];
  public flatNodes: any[] = [];
  public allNodes: any[] = [];

  public materials: any[] = [];
  public flatMaterials: any[] = [];

  currentScene: BABYLON.Scene;
  auxScenes: BABYLON.Scene[] = [];

  currentMaterial = null;
  noColors: boolean = false;

  public currentColor = {
    r: "1",
    g: "1",
    b: "1",
  }

  constructor() { }

  public selectMesh(mesh) {
    this.allNodes.forEach((node) => {
      if (!node) { return }
      if (!mesh) { return }
      if (node.name == mesh.name) {
        node.check = true;
      } else {
        // node.check = false;
      }
    });
  }

  public selectMaterial(material) {
    material.check = !material.check;
    this.currentMaterial = material;
  }

  public register(currentScene, auxScenes) {
    this.currentScene = currentScene;
    this.auxScenes = auxScenes;
    this.rootNodes = this.recurseNodes(this.currentScene.rootNodes, 0)
    let result = this.flattenRoot(this.rootNodes);
    this.flatNodes = result.flat;
    this.allNodes = result.all;

    for (var mesh of this.auxScenes[0].meshes) {
      mesh.isVisible = false;
      if (!mesh.material) {
        continue
      }
      var mat = mesh.material;
      var item = {
        type: "material",
        check: false,
        name: mat.name,
        material: mat,
        nodes: []
      };
      this.materials.push(item);
      this.flatMaterials.push(item);
    }
  }

  public expandNode(node) {
    node.expand = !node.expand;
    let result = this.flattenRoot(this.rootNodes);
    this.flatNodes = result.flat;
  }

  public assignGlobal() {
    this.assign(this.allNodes, this.materials, null, this.currentColor, true);
  }

  public assign(nodes, mats, material, color, checkCheck:boolean = false) {
    let materials = [];
    mats.forEach((mat) => {
      if (checkCheck && !mat.check) {
        return
      }
      materials.push(mat);

    });

    nodes.forEach((node) => {
      if (checkCheck && !node.check) {
        return;
      }
      var meshes = node.node.getChildMeshes(false);
      if (meshes) {
        meshes.forEach((mesh) => {
          this.colorMesh(node, mesh, materials, material, color);
        })
      }
      var mesh = this.currentScene.getMeshByName(node.name);
      this.colorMesh(node, mesh, materials, material, color);
    })
  }

  colorMesh(node, mesh, materials, material, color) {
    if (!mesh) {
      return
    }
    if (!materials.length) {
      return;
    }
    if (mesh.material) {
      mesh.material.dispose();
    }
    if (material) {
      mesh.material = material.material.clone();
    } else {
      mesh.material = materials[0].material.clone();
    }
    if (!this.noColors) {
      mesh.material.albedoColor.r = color.r;
      mesh.material.albedoColor.g = color.g;
      mesh.material.albedoColor.b = color.b;
    }
    node.materials = materials;
  }

  recurseNodes(rootNodes: any[], level:number) {
    var nodes = [];
    for (let node of rootNodes) {
      nodes.push({
        type: "node",
        check: false,
        visible: true,
        name: node.name,
        nodes: this.recurseNodes(node.getDescendants(true), level+1),
        expand: false,
        node: node,
        materials: [],
        level: level
      });
    }
    return nodes;
  }

  flattenRoot(nodes: any[]) {
    var flat = []; 
    var all = [];
    this.flattenRootWorker(nodes, flat, all);
    return { flat, all };
  }

  flattenRootWorker(nodes: any[], flat: any[], all: any[]) {
    for (var node of nodes) {
      if (flat) {
        flat.push(node);
      }
      all.push(node);
      if (node.expand) {
        if (node.nodes) {
          this.flattenRootWorker(node.nodes, flat, all);
        } else {
        }
      } else {
        this.flattenRootWorker(node.nodes, null, all);
      }
    }
  }

  public getSelectedNodes() {
    return this.allNodes.filter((node) => node.check == true ? node : undefined);
  }

  public getSelectedMaterials() {
    return this.materials.filter((material) => material.check == true ? material : undefined);
  }

  public deselectAllNodes() {
    this.allNodes.forEach((node) => {
      node.check = false;
    })
  }

  public selectNodesFromGroup(group) {
    this.allNodes.forEach((_node) => {
      _node.check = false;
    });
    group.nodes.forEach((_node) => {
      _node.check = true;
    });
    
  }

  public unselectNodeFromGroup(group, node) {
    group.nodes = group.nodes.filter((_node) => {
      if (_node === node) {
        return;
      }
      return _node;
    })
  }

  public selectGroupMaterial(group, material) {
    group.currentMaterial = material
    this.assign(group.nodes, group.materials, group.currentMaterial, this.currentColor);
  }

  public selectGroupColor(group, color) {
    this.currentColor.r = color.color.r;
    this.currentColor.g = color.color.g;
    this.currentColor.b = color.color.b;
    this.assign(group.nodes, group.materials, group.currentMaterial, this.currentColor);
  }

  public deselectNode(node) {
    node.check = false;
  }

  public setNoColors(noColors) {
    this.noColors = noColors;
  }

}
