import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy } from '@angular/core';
import { Material } from 'external/rtc-3dk-v2-module/services/scene/materials';
import { Subject } from 'rxjs';

// import { CommonModule } from '@angular/common';
// import { FormsModule } from '@angular/forms';

// import { services } from 'src/environments/globals'
import { MaterialEditService } from './material-edit.service';
import { MaterialSettingsDataService } from './material-settings-data.service';
import { MaterialSettings } from 'external/rtc-3dk-v2-module/services/materials/material-settings';

// import { Material, Materials } from 'external/rtc-3dk-v2-module/services/scene/materials';
// import { SimpleKonfiguratorProfileSceneService  } from 'external/rtc-3dk-v2-module/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';
// import { GroupLayer } from 'external/rtc-3dk-v2-module/services/scene/geometry-groups';

@Component({
  selector: 'app-material-edit',
  // standalone: true,                      // needs to be importable in angularv8 in legacy 3DK
  // imports: [CommonModule, FormsModule],
  templateUrl: './material-edit.component.html',
  styleUrls: ['./material-edit.component.scss']
})
export class MaterialEditComponent implements OnInit, OnChanges, OnDestroy {
  @Output() updateMaterial = new EventEmitter();
  @Output() materialChange = new EventEmitter<any>();  // Material

  @Input() currentGroupLayer?: any;  // GroupLayer
  @Input() thumbnails: any;

  public search = "";
  public selectedMaterialObject: Material | any;

  public materialList: any[] = [];  // Material
  public scene: any;    // SimpleKonfiguratorProfileSceneService
  private materialService: any;   // Materials

  public maps: any = ["COL", "SpecularGlossMap", "BUMP"] // List of used maps
  public mapUrls: any = [];

  //INITIAL VALUES FOR EDITING VARIABLES
  public materialEditSettings = this.createNewMaterialSetting();

  private subs: Subject<any>[] = [];

  constructor(
    private service: MaterialEditService,
    private settingsDataService: MaterialSettingsDataService,
    private settings: MaterialSettings,
  ) {

    this.scene = this.service.scene
    this.materialService = this.service.materials;

    console.log("MAT/E GET SCENE", this.service.scene);
    console.log("MAT/E GET MATSERVICE", this.service.materials);

    this.materialList = this.materialService && this.materialService.materials.materials;
    console.log("MAT/E MATLIST", this.materialList);

    this.subs.push(this.scene.materialChange$.subscribe((data: any) => {
      console.log("MATCHANGE", data);
      // this.currentMaterial = data;
      this.onMaterialChange();
    }));

    this.subs.push(this.scene.layerChange$.subscribe((layer: any) => {
      console.log("LAYER SELECTED", layer);
      this.currentGroupLayer = layer;
      this.filter();
    }))
  }

  createNewMaterialSetting() {
    return {
      wireframe: {
        value: false,
        enabled: null,
        babylonSettingName: 'wireframe',
      },
      pointCloud: {
        value: false,
        enabled: null,
        babylonSettingName: 'pointCloud',
      },
      useTransparency: {
        value: false,
        enabled: null,
        babylonSettingName: 'alphaMode',
      },
      alpha: {
        value: 1,
        enabled: false,
        babylonSettingName: 'alpha',
      },
      transparencyMode: {
        value: 0,
        enabled: false,
        babylonSettingName: 'transparencyMode',
      },
      alphaMode: {
        value: 0,
        enabled: false,
        babylonSettingName: 'alphaMode',
      },
      useMetallic: {
        value: false,
        enabled: null,
        babylonSettingName: 'useMetallnessFromMetallicTexture',
      },
      metallic: {
        value: 0.5,
        enabled: false,
        babylonSettingName: 'metallic',
      },
      metallicRoughness: {
        value: 0.5,
        enabled: false,
        babylonSettingName: 'roughness',
      },
      metallicIor: {
        value: 1.5,
        enabled: false,
        babylonSettingName: 'refractionIndex',
      },
      metallicReflectanceColor: {
        value: '#ffffff',
        enabled: false,
        babylonSettingName: 'reflectivityColor',
      },
      useClearCoat: {
        value: false,
        enabled: null,
        babylonSettingName: 'useClearCoat',
      },
      clearCoatIntensity: {
        value: 0,
        enabled: false,
        babylonSettingName: 'clearCoat.intensity',
      },
      clearCoatRoughness: {
        value: 0,
        enabled: false,
        babylonSettingName: 'clearCoat.roughness',
      },
      clearCoatIor: {
        value: 1.5,
        enabled: false,
        babylonSettingName: 'clearCoatRefraction',
      },
      bumpMapLevel: {
        value: 0,
        enabled: false,
        babylonSettingName: 'bumpTexture.level',
      },
      disableLighting: {
        value: false,
        enabled: null,
        babylonSettingName: 'disableLighting',
      },
      diffuseColor: {
        value: '#ffffff',
        enabled: false,
        babylonSettingName: 'diffuseColor',
      },
      specularColor: {
        value: '#ffffff',
        enabled: false,
        babylonSettingName: 'specularColor',
      },
      specularPower: {
        value: 50,
        enabled: false,
        babylonSettingName: 'specularPower',
      },
      emissiveColor: {
        value: '#ffffff',
        enabled: false,
        babylonSettingName: 'emissiveColor',
      },
      parallaxMapping: {
        value: false,
        enabled: null,
        babylonSettingName: 'useParallax',
      },
      parallaxScaleBlias: {
        value: 2,
        enabled: false,
        babylonSettingName: 'parallaxScaleBias',
      },
    }
  }

  ngOnChanges(): void {
    this.onMaterialChange();
  }

  ngOnInit(): void {
    this.filter();
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => {
      s.unsubscribe();
    })
  }

  private getState() {
    return this.materialEditSettings
  }

  private onMaterialChange() {
    if (this.currentGroupLayer) {
      this.filter();


      // this.materialEditSettings.alpha.value = this.selectedMaterialObject.material.alpha;
      // this.materialEditSettings.specularPower.value = this.selectedMaterialObject.material.specularPower;
      // this.materialEditSettings.metallic.value = this.selectedMaterialObject.material.metallic;
      // this.materialEditSettings.metallicRoughness.value = this.selectedMaterialObject.material.roughness;
      // this.materialEditSettings.metallicIor.value = this.selectedMaterialObject.material.refractionIndex;
      // this.materialEditSettings.clearCoatIntensity.value = this.selectedMaterialObject.material.clearCoat.intensity;
      // this.materialEditSettings.clearCoatRoughness.value = this.selectedMaterialObject.material.clearCoat.roughness;
      // this.materialEditSettings.clearCoatIor.value = this.selectedMaterialObject.material.clearCoatRefraction;
      // this.materialEditSettings.bumpMapLevel.value = this.selectedMaterialObject.material.bumpTexture.level;

      if (!this.settingsDataService.getOriginalValue(this.currentGroupLayer.currentMaterial.label)) {
        // this.settingsService.setOriginalValue(this.currentGroupLayer.currentMaterial.label, structuredClone(this.materialEditSettings))
        const clone = JSON.parse(JSON.stringify(this.materialEditSettings));
        this.settingsDataService.setOriginalValue(this.currentGroupLayer.currentMaterial.label, clone)
        this.settingsDataService.setSavedValue(this.currentGroupLayer.currentMaterial.label, clone)
      }
    }
  }

  private filter() {
    if (!this.currentGroupLayer) {
      return;
    }
    // this.materialList = this.currentGroupLayer.getMaterials().getMaterials()
    this.selectedMaterialObject = this.materialList.find((item) => this.currentGroupLayer && this.currentGroupLayer.isCurrentMaterial(item))
    // .filter(m =>
    //   m.label.toLowerCase().includes(this.search.toLowerCase()))
    this.materialEditSettings = this.service.get(this.selectedMaterialObject.material.name);
    console.log("SMO", this.selectedMaterialObject)
    console.log("MES", this.materialEditSettings)
    if (!this.materialEditSettings) {
      this.materialEditSettings = this.createNewMaterialSetting();
      this.settings.setSettingsFromMaterial(this.materialEditSettings, this.selectedMaterialObject.material);
      this.service.set(this.selectedMaterialObject.material.name, this.materialEditSettings);
      console.log("MES2", this.materialEditSettings)
    }
    this.mapUrls =  this.getMapsUrls(this.selectedMaterialObject.material.name , this.maps)
  }

  public onSettingSwitch(event: any, settingName: string, babylonSetting: string = ''): void {
    const materialEditSettings = this.materialEditSettings as any;
    if (!event.target.checked) {
      this.setSavedValues(settingName);
      //
      const origValue = this.settingsDataService.getOriginalValue(this.currentGroupLayer.currentMaterial.label)[settingName].value;
      materialEditSettings[settingName].enabled = false;
      materialEditSettings[settingName].value = origValue;

      this.setSelectedMaterialSetting(origValue, settingName, babylonSetting);
    } else {
      const savedValue = this.settingsDataService.getSavedValue(this.currentGroupLayer.currentMaterial.label)[settingName].value;
      materialEditSettings[settingName].enabled = true;
      materialEditSettings[settingName].value = savedValue;

      this.setSelectedMaterialSetting(savedValue, settingName, babylonSetting);
    }
    this.updateMaterial.emit(true);
  }

  private setSavedValues(settingName: string): void {
    const savedValue = JSON.parse(JSON.stringify(this.settingsDataService.getSavedValue(this.currentGroupLayer.currentMaterial.label)));
    const savedSettingOfValue = {
      ...savedValue[settingName],
      value: (this.materialEditSettings as any)[settingName].value
    };
    this.settingsDataService.setSavedValue(this.currentGroupLayer.currentMaterial.label, { ...savedValue, [settingName]: savedSettingOfValue })
  }

  private setSelectedMaterialSetting(value: any, settingName: string, babylonSetting: string = ''): void {
    if (!!babylonSetting) {
      this.selectedMaterialObject.material[babylonSetting] = value;
    } else {
      this.selectedMaterialObject.material[settingName] = value;
    }
  }

  public resetGroupToDefault(group: string[]): void {
    const materialEditSettings = this.materialEditSettings as any;
    group.forEach((item) => {
      const origValue = this.settingsDataService.getOriginalValue(this.selectedMaterialObject.label)[item];
      materialEditSettings[item].enabled = origValue.enabled;
      materialEditSettings[item].value = origValue.value;
      this.selectedMaterialObject.material[origValue.babylonSettingName] = origValue.value;
    });
    this.updateMaterial.emit(true);
  }

  //------------------ GENERAL ------------------->

  //babylonSettingName: 'wireframe'
  public wireframeChange(event: any): void {
    console.log(event.target.checked);
    if (this.materialEditSettings && this.materialEditSettings.wireframe && this.materialEditSettings.wireframe.value && 
      this.selectedMaterialObject && this.selectedMaterialObject.material && this.selectedMaterialObject.material.wireframe) {
      this.materialEditSettings.wireframe.value = event.target.checked;
      this.selectedMaterialObject.material.wireframe = event.target.checked;
      this.updateMaterial.emit(true);
    }
  }

  // babylonSettingName: 'pointCloud'
  public pointCloudChange(event: any): void {
    console.log(event.target.checked);
    this.materialEditSettings.pointCloud.value = event.target.checked;
    this.selectedMaterialObject.material.pointCloud = event.target.checked;
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'disableLighting'
  public disableLightingChange(event: any): void {
    console.log(event.target.checked);
    this.selectedMaterialObject.material.disableLighting = event.target.checked;
    this.updateMaterial.emit(true);
  }
  //------------------ GENERAL END ------------------->

  //------------------ METALLIC ------------------->
  // babylonSettingName: 'useMetallnessFromMetallicTexture'
  public useMetallicChange(event: any): void {
    console.log(event.target.checked);
    this.materialEditSettings.useMetallic.value = event.target.checked;
    this.selectedMaterialObject.material.useMetallnessFromMetallicTexture = event.target.checked;
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'metallic'
  public metallicChange(value: any, min?: any, max?: any, isNumInput = false): void {
    if (isNumInput) {
      const input = (value.target as HTMLInputElement).value;
      const numericInput = parseInt(input, 10);
      const isValid = !isNaN(numericInput) && numericInput >= min && numericInput <= max;
      if (!isValid) {
        (value.target as HTMLInputElement).value = min;
        this.materialEditSettings.metallic.value = +min;
        this.selectedMaterialObject.material.metallic = min;
      } else {
        this.materialEditSettings.metallic.value = +input;
        this.selectedMaterialObject.material.metallic = input;
      }
    } else {
      this.materialEditSettings.metallic.value = value;
      this.selectedMaterialObject.material.metallic = value;
    }
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'roughness'
  public roughnessChange(value: any, min?: any, max?: any, isNumInput = false): void {
    if (isNumInput) {
      const input = (value.target as HTMLInputElement).value;
      const numericInput = parseInt(input, 10);
      const isValid = !isNaN(numericInput) && numericInput >= min && numericInput <= max;
      if (!isValid) {
        (value.target as HTMLInputElement).value = min;
        this.materialEditSettings.metallicRoughness.value = +min;
        this.selectedMaterialObject.material.roughness = min;
      } else {
        this.materialEditSettings.metallicRoughness.value = +input;
        this.selectedMaterialObject.material.roughness = input;
      }
    } else {
      this.materialEditSettings.metallicRoughness.value = value;
      this.selectedMaterialObject.material.roughness = value;
    }
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'refractionIndex'
  public iosChange(value: any, min?: any, max?: any, isNumInput = false): void {
    if (isNumInput) {
      const input = (value.target as HTMLInputElement).value;
      const numericInput = parseInt(input, 10);
      const isValid = !isNaN(numericInput) && numericInput >= min && numericInput <= max;
      if (!isValid) {
        (value.target as HTMLInputElement).value = min;
        this.materialEditSettings.metallicIor.value = +min;
        this.selectedMaterialObject.material.refractionIndex = min;
      } else {
        this.materialEditSettings.metallicIor.value = +input;
        this.selectedMaterialObject.material.refractionIndex = input;
      }
    } else {
      this.materialEditSettings.metallicIor.value = value;
      this.selectedMaterialObject.material.refractionIndex = value;
    }
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'reflectivityColor'
  public reflectanceColorChange(event: any): void {
    console.log(event.target.value);
    const color = BABYLON.Color3.FromHexString(event.target.value);
    this.materialEditSettings.metallicReflectanceColor.value = event.target.value;

    this.selectedMaterialObject.material.reflectivityColor = color;
    this.updateMaterial.emit(true);
  }
  //------------------ METALLIC END ------------------->

  //------------------ CLEAR COAT ------------------->
  // babylonSettingName: 'useClearCoat'
  public useClearCoatChange(event: any): void {
    console.log(event.target.checked);
    this.materialEditSettings.useClearCoat.value = event.target.checked;
    this.selectedMaterialObject.material.useClearCoat = event.target.checked;
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'clearCoat.intensity'
  public clearCoatIntensityChange(value: any, min?: any, max?: any, isNumInput = false): void {
    if (isNumInput) {
      const input = (value.target as HTMLInputElement).value;
      const numericInput = parseInt(input, 10);
      const isValid = !isNaN(numericInput) && numericInput >= min && numericInput <= max;
      if (!isValid) {
        (value.target as HTMLInputElement).value = min;
        this.materialEditSettings.clearCoatIntensity.value = +min;
        this.selectedMaterialObject.material.clearCoat.intensity = min;
      } else {
        this.materialEditSettings.clearCoatIntensity.value = +input;
        this.selectedMaterialObject.material.clearCoat.intensity = input;
      }
    } else {
      this.materialEditSettings.clearCoatIntensity.value = value;
      this.selectedMaterialObject.material.clearCoat.intensity = value;
    }
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'clearCoat.roughness'
  public clearCoatRoughnessChange(value: any, min?: any, max?: any, isNumInput = false): void {
    if (isNumInput) {
      const input = (value.target as HTMLInputElement).value;
      const numericInput = parseInt(input, 10);
      const isValid = !isNaN(numericInput) && numericInput >= min && numericInput <= max;
      if (!isValid) {
        (value.target as HTMLInputElement).value = min;
        this.materialEditSettings.clearCoatRoughness.value = +min;
        this.selectedMaterialObject.material.clearCoat.roughness = min;
      } else {
        this.materialEditSettings.clearCoatRoughness.value = +input;
        this.selectedMaterialObject.material.clearCoat.roughness = input;
      }
    } else {
      this.materialEditSettings.clearCoatRoughness.value = value;
      this.selectedMaterialObject.material.clearCoat.roughness = value;
    }
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'clearCoatRefraction'
  public clearCoatIorChange(value: any, min?: any, max?: any, isNumInput = false): void {
    if (isNumInput) {
      const input = (value.target as HTMLInputElement).value;
      const numericInput = parseInt(input, 10);
      const isValid = !isNaN(numericInput) && numericInput >= min && numericInput <= max;
      if (!isValid) {
        (value.target as HTMLInputElement).value = min;
        this.materialEditSettings.clearCoatIor.value = +min;
        this.selectedMaterialObject.material.clearCoatRefraction = min;
      } else {
        this.materialEditSettings.clearCoatIor.value = +input;
        this.selectedMaterialObject.material.clearCoatRefraction = input;
      }
    } else {
      this.materialEditSettings.clearCoatIor.value = value;
      this.selectedMaterialObject.material.clearCoatRefraction = value;
    }
    this.updateMaterial.emit(true);
  }
  //------------------ CLEAR COAT END ------------------->

  //------------------ BUMP MAP ------------------->
  // babylonSettingName: 'bumpTexture.level'
  public bumpMapLevelChange(value: any, min?: any, max?: any, isNumInput = false): void {
    if (isNumInput) {
      const input = (value.target as HTMLInputElement).value;
      const numericInput = parseInt(input, 10);
      const isValid = !isNaN(numericInput) && numericInput >= min && numericInput <= max;
      if (!isValid) {
        (value.target as HTMLInputElement).value = min;
        this.materialEditSettings.bumpMapLevel.value = +min;
        this.selectedMaterialObject.material.bumpTexture.level = min;
      } else {
        this.materialEditSettings.bumpMapLevel.value = +input;
        this.selectedMaterialObject.material.bumpTexture.level = input;
      }
    } else {
      this.materialEditSettings.bumpMapLevel.value = value;
      this.selectedMaterialObject.material.bumpTexture.level = value;
    }
    this.updateMaterial.emit(true);
  }
  //------------------ BUMP MAP END ------------------->

  //------------------ TRASNSPARENCY ------------------->

  //babylonSettingName: 'alphaMode'
  public useTransparencyChange(event: any): void {
    console.log(event.target.checked);
    this.materialEditSettings.useTransparency.value = event.target.checked;
    console.log(this.materialEditSettings);

    this.selectedMaterialObject.material.alphaMode = event.target.checked ? BABYLON.Engine.ALPHA_COMBINE : BABYLON.Engine.ALPHA_DISABLE;;
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'alpha'
  public updateAlpha(value: any, min?: any, max?: any, isNumInput = false): void {
    console.log(value, 'zapp');
    if (isNumInput) {
      const input = (value.target as HTMLInputElement).value;
      const numericInput = parseInt(input, 10);
      const isValid = !isNaN(numericInput) && numericInput >= min && numericInput <= max;
      if (!isValid) {
        (value.target as HTMLInputElement).value = min;
        this.materialEditSettings.alpha.value = min;
      } else {
        this.materialEditSettings.alpha.value = +input;
        this.selectedMaterialObject.material.alpha = input;
      }
    } else {
      this.materialEditSettings.alpha.value = value;
      this.selectedMaterialObject.material.alpha = value;
    }
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'transparencyMode'
  public transparencyModeChange(event: any): void {
    console.log(event.target.value);
    this.materialEditSettings.transparencyMode.value = parseInt(event.target.value)
    this.selectedMaterialObject.material.transparencyMode = parseInt(event.target.value);
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'alphaMode'
  public alphaModeChange(event: any): void {
    console.log(event.target.value);
    this.materialEditSettings.alphaMode.value = parseInt(event.target.value)
    this.selectedMaterialObject.material.alphaMode = parseInt(event.target.value);
    this.updateMaterial.emit(true);
  }
  //------------------ TRASNSPARENCY END ------------------->

  //------------------ LIGHTING & COLORS ------------------->
  // babylonSettingName: 'diffuseColor'
  public diffuseChange(event: any): void {
    console.log(event.target.value);
    const color = BABYLON.Color3.FromHexString(event.target.value);
    this.materialEditSettings.diffuseColor.value = event.target.value;
    this.selectedMaterialObject.material.diffuseColor = color;
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'specularColor'
  public specularColorChange(event: any): void {
    console.log(event.target.value);
    const color = BABYLON.Color3.FromHexString(event.target.value);
    this.materialEditSettings.specularColor.value = event.target.value;
    this.selectedMaterialObject.material.specularColor = color;
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'specularPower'
  public specularPowerChange(value: any, min?: any, max?: any, isNumInput = false): void {
    if (isNumInput) {
      const input = (value.target as HTMLInputElement).value;
      const numericInput = parseInt(input, 10);
      const isValid = !isNaN(numericInput) && numericInput >= min && numericInput <= max;
      if (!isValid) {
        (value.target as HTMLInputElement).value = min;
        this.materialEditSettings.specularPower.value = +min;
        this.selectedMaterialObject.material.specularPower = min;
      } else {
        this.materialEditSettings.specularPower.value = +input;
        this.selectedMaterialObject.material.specularPower = input;
      }
    } else {
      this.materialEditSettings.specularPower.value = value;
      this.selectedMaterialObject.material.specularPower = value;
    }
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'emissiveColor'
  public emissiveColorChange(event: any): void {
    console.log(event.target.value);
    const color = BABYLON.Color3.FromHexString(event.target.value);
    this.materialEditSettings.emissiveColor.value = event.target.value;
    this.selectedMaterialObject.material.emissiveColor = color;
    this.updateMaterial.emit(true);
  }
  //------------------ LIGHTING & COLORS END ------------------->

  //------------------ Parallax ------------------->
  // babylonSettingName: 'useParallax'
  public parallaxChange(event: any): void {
    console.log(event.target.checked);
    this.materialEditSettings.parallaxMapping.value = event.target.checked;
    this.selectedMaterialObject.material.useParallax = event.target.checked;
    this.updateMaterial.emit(true);
  }

  // babylonSettingName: 'parallaxScaleBias'
  public parallaxBiasChange(value: any, min?: any, max?: any, isNumInput = false): void {
    if (isNumInput) {
      const input = (value.target as HTMLInputElement).value;
      const numericInput = parseInt(input, 10);
      const isValid = !isNaN(numericInput) && numericInput >= min && numericInput <= max;
      if (!isValid) {
        (value.target as HTMLInputElement).value = min;
        const newScale = min / 100;
        const newBias = min / 500;
        this.materialEditSettings.parallaxScaleBlias.value = min;
        this.selectedMaterialObject.material.parallaxScaleBias = new BABYLON.Vector2(newScale, newBias);
      } else {
        const newScale = +input / 100;
        const newBias = +input / 500;
        this.materialEditSettings.parallaxScaleBlias.value = +input;
        this.selectedMaterialObject.material.parallaxScaleBias = new BABYLON.Vector2(newScale, newBias);
      }
    } else {
      const newScale = value / 100;
      const newBias = value / 500;
      this.materialEditSettings.parallaxScaleBlias.value = value;
      this.selectedMaterialObject.material.parallaxScaleBias = new BABYLON.Vector2(newScale, newBias);
    }
    this.updateMaterial.emit(true);
  }
  //------------------ Parallax ------------------->


  async onSaveClick() {
    const name = this.selectedMaterialObject.label;
    // console.log(this.selectedMaterialObject);
    const result = await this.service.save(
      this.selectedMaterialObject.label,
      this.getState());
  }


  //------------------ Maps ------------------->
  public getMapsUrls(materialName: string, mapList: []) {
    const urlList: any = [];
    mapList.forEach((item : string) => {
        const url :string = "/simplekonfiguratorprofile/projects/Tronog/Common/Materials/"+materialName+"/Babylon/../512/"+materialName+"_"+item+".jpg"
      urlList[item]= url;
    })
    console.log("MAP Urls", urlList)
    return urlList
  }

//------------------ materialChange for dropdown ------------------->
  public onDropdownMaterialClick(selectedMaterial : Material) {
    const selectedValue = selectedMaterial.label
    const selected = this.materialList.find((item) => item.label === selectedValue)
    this.selectedMaterialObject = selected;
    console.log('Selected material:', selected);
    this.materialEditSettings = this.service.get(this.selectedMaterialObject.material.name);
    if (this.materialEditSettings) {
      this.settings.setSettingsFromMaterial(this.materialEditSettings, this.selectedMaterialObject.material);
    }
    this.materialChange.emit(selected);
  }
}

