import { Component, ElementRef, Input, ViewChild, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import * as THREE from "three";
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fbx-viewer',
  templateUrl: './exporter-view.component.html',
  styleUrls: ['./exporter-view.component.css'],
})
export class ExporterViewComponent implements OnInit, AfterViewInit, OnDestroy {
  // @ViewChild('renderCanvas', {static: false}) renderCanvas: ElementRef<HTMLCanvasElement>;
  // @Input()
  fbxUrl: string = '';

  @ViewChild('canvas', {static: false}) private canvasRef: ElementRef;

  //* Stage Properties

  @Input() public fieldOfView: number = 50;

  @Input('nearClipping') public nearClippingPane: number = 0.1;

  @Input('farClipping') public farClippingPane: number = 15000;

  //? Scene properties
  private camera: THREE.PerspectiveCamera;

  private controls: OrbitControls;

  private ambientLight: THREE.AmbientLight;

  private light1: THREE.PointLight;

  private light2: THREE.PointLight;

  private light3: THREE.PointLight;

  private light4: THREE.PointLight;

  private model: any;

  private directionalLight: THREE.DirectionalLight;

  //? Helper Properties (Private Properties);

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private loaderGLTF = new GLTFLoader();
  private loaderFBX = new FBXLoader();

  private renderer: THREE.WebGLRenderer;

  private scene: THREE.Scene;

  /**
   *Animate the model
   *
   * @private
   * @memberof ModelComponent
   */
  private animateModel() {
    if (this.model) {
      this.model.rotation.z += 0.005;
    }
  }

  /**
   *create controls
   *
   * @private
   * @memberof ModelComponent
   */
  private createControls = () => {
    // const renderer = new CSS2DRenderer();
    // const elem = document.getElementById("canvas");
    // const elemContainer = document.getElementById("pr-container");
    // renderer.setSize(elem.offsetWidth, elem.offsetHeight);
    // renderer.domElement.style.position = 'absolute';
    // renderer.domElement.style.bottom = '0px';
    // console.log(renderer);
    // elemContainer.appendChild(renderer.domElement);
    // this.controls = new OrbitControls(this.camera, renderer.domElement);
    // // this.controls.autoRotate = true;
    // this.controls.enableZoom = true;
    // this.controls.enablePan = false;
    // this.controls.update();
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.25;
    this.controls.screenSpacePanning = true;
    this.controls.maxPolarAngle = Math.PI;
    this.controls.update();
  };

  /**
   * Create the scene
   *
   * @private
   * @memberof CubeComponent
   */
  private createScene() {
    // //* Scene
    // this.scene = new THREE.Scene();
    // this.scene.background = new THREE.Color(0xd4d4d8)
    // this.loaderFBX.load(this.fbxUrl, (gltf: any) => {
    //   this.model = gltf.children[0];
    //   console.log(gltf.children[0]);
    //   var box = new THREE.Box3().setFromObject(this.model);
    //   box.getCenter(this.model.position); // this re-sets the mesh position
    //   this.model.position.multiplyScalar(-1);
    //   this.scene.add(gltf);
      

    //   // this.model = gltf.scene.children[0];
    //   // console.log(this.model);
    //   // var box = new THREE.Box3().setFromObject(this.model);
    //   // box.getCenter(this.model.position); // this re-sets the mesh position
    //   // this.model.position.multiplyScalar(-1);
    //   // this.scene.add(this.model);
    // });
    // //*Camera
    // let aspectRatio = this.getAspectRatio();
    // this.camera = new THREE.PerspectiveCamera(
    //   this.fieldOfView,
    //   aspectRatio,
    //   this.nearClippingPane,
    //   this.farClippingPane
    // )
    // this.camera.position.x = 1000;
    // this.camera.position.y = 1000;
    // this.camera.position.z = 1000;
    // this.ambientLight = new THREE.AmbientLight(0x00000, 100);
    // this.scene.add(this.ambientLight);
    // this.directionalLight = new THREE.DirectionalLight(0xffdf04, 0.4);
    // this.directionalLight.position.set(0, 1, 0);
    // this.directionalLight.castShadow = true;
    // this.scene.add(this.directionalLight);
    // this.light1 = new THREE.PointLight(0x4b371c, 10);
    // this.light1.position.set(0, 100, 200);
    // this.scene.add(this.light1);
    // this.light2 = new THREE.PointLight(0x4b371c, 10);
    // this.light2.position.set(500, 100, 0);
    // this.scene.add(this.light2);
    // this.light3 = new THREE.PointLight(0x4b371c, 10);
    // this.light3.position.set(0, 100, -500);
    // this.scene.add(this.light3);
    // this.light4 = new THREE.PointLight(0x4b371c, 10);
    // this.light4.position.set(-500, 300, 500);
    // this.scene.add(this.light4);

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xd4d4d8);
    // this.fbxUrl = './assets/ShirtConfigurator.fbx'
    this.loaderFBX.load(this.fbxUrl, (gltf: any) => {
      this.model = gltf.children[0];
      var box = new THREE.Box3().setFromObject(this.model);
      box.getCenter(this.model.position);
      this.model.position.multiplyScalar(-1);
      this.scene.add(gltf);
    });

    let aspectRatio = this.getAspectRatio();
    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      aspectRatio,
      this.nearClippingPane,
      this.farClippingPane
    );
    this.camera.position.set(1000, 1000, 1000);

    this.ambientLight = new THREE.AmbientLight(0x00000, 100);
    this.scene.add(this.ambientLight);
    this.directionalLight = new THREE.DirectionalLight(0xffdf04, 0.4);
    this.directionalLight.position.set(0, 1, 0);
    this.directionalLight.castShadow = true;
    this.scene.add(this.directionalLight);
    this.light1 = new THREE.PointLight(0x4b371c, 10);
    this.light1.position.set(0, 100, 200);
    this.scene.add(this.light1);
    this.light2 = new THREE.PointLight(0x4b371c, 10);
    this.light2.position.set(500, 100, 0);
    this.scene.add(this.light2);
    this.light3 = new THREE.PointLight(0x4b371c, 10);
    this.light3.position.set(0, 100, -500);
    this.scene.add(this.light3);
    this.light4 = new THREE.PointLight(0x4b371c, 10);
    this.light4.position.set(-500, 300, 500);
    this.scene.add(this.light4);
  }

  private getAspectRatio() {
    return this.canvas.clientWidth / this.canvas.clientHeight;
  }

  /**
 * Start the rendering loop
 *
 * @private
 * @memberof CubeComponent
 */
  private startRenderingLoop() {
    //* Renderer
    // Use canvas element in template
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true });
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    let component: ExporterViewComponent = this;
    (function render() {
      component.renderer.render(component.scene, component.camera);
      // component.animateModel();
      requestAnimationFrame(render);
    }());
  }

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnDestroy(): void {
    let component: ExporterViewComponent = this;
    component.renderer.dispose();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const url = params['q'];
      console.log(url);
      this.fbxUrl = atob(url);
      console.log(this.fbxUrl);
    });
    // const encoded =  btoa(url);
    // const decoded =  atob(encoded);   
  }

  ngAfterViewInit() {
    this.createScene();
    this.startRenderingLoop();
    this.createControls();
  }
}
