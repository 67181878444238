import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { MaterialsService } from 'src/app/services/materials/materials.service';
import { UsersService } from 'src/app/services/users/users.service';
import { OrganizationsService } from 'src/app/services/organizations/organizations.service';
import { AclService } from 'src/app/services/acl/acl.service';
import { EnvironmentService } from 'src/app/services/uploader/environment.service';
import { TexturePreviewGeneratorComponent } from 'src/app/components/texture-preview-generator/texture-preview-generator.component';
import { OrganizationalMaterialsService } from 'src/app/services/materials/organizational/organizational-materials.service';
import { GlobalMaterialsService } from 'src/app/services/materials/global/global-materials.service';
import { ImageViewerService } from 'src/app/services/imageviewer/image-viewer.service';
import { SidebarListService, VisibleItem, SidebarCollection } from 'src/app/services/projects/sidebar-list.service';
import { CollectionsService } from 'src/app/services/collections/collections.service';
import { FavouritesService } from 'src/app/services/favourites/favourites.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  providers: [EnvironmentService]
})
export class ProjectsComponent implements OnInit {
  @ViewChild('myPondModel', { static: false }) myPondModel: any;
  @ViewChild('myPondMaterials', { static: false }) myPondMaterials: any;
  @ViewChild('texturepreviewgenerator', { static: false }) texturepreviewgenerator: TexturePreviewGeneratorComponent;

  public openedSection = 'intro';

  public newProjectName;
  public newProjectOrganization = 'Tronog';

  public pondModelOptions: any = {};
  public pondMaterialOptions: any = {};

  public materialQuality = '512';

  generatingLOD = false;
  LOD = null;
  lodChecker: any = null;
  screenshot: any;

  drag: boolean = false;

  constructor(public projects: ProjectsService,
    public materials: MaterialsService,
    private route: ActivatedRoute,
    private router: Router,
    public loader: LoaderService,
    private users: UsersService,
    public organizations: OrganizationsService,
    private acl: AclService,
    public environment: EnvironmentService,
    public organizationalMaterials: OrganizationalMaterialsService,
    public globalMaterials: GlobalMaterialsService,
    public imageViewer: ImageViewerService,
    public sidebarList: SidebarListService,
    public collections: CollectionsService,
    public favourites: FavouritesService
  ) {

  }

  modelFiles: any[] = [];
  materialFiles: any[] = [];

  currentUser: {
    email: string,
    given_name: string,
    family_name: string,
  };

  showRename = false;

  ngOnInit() {

    this.route.queryParamMap.subscribe(async (d: any) => {
      console.log(d);
      const data = await Promise.all([
        this.projects.load(),
        this.users.getCurrent(),
        this.organizations.getAllOrganizationsList()
      ]);

      console.log(data);
      await this.sidebarList.init(this.projects.projects);

      this.currentUser = data[1] as any;

      const params = d.params;
      console.log(params);
      if (params.project) {
        console.log('project:');
        console.log(params.project);
        const project = params.project;
        this.projects.setCurrentProjectByPathName(project);
        this.refreshModelFiles();
        this.refreshMaterialsFiles();
        this.openedSection = 'open_project';
        this.setPondOptions();
      }
      if (params.user) {
        console.log('project:');
        console.log(params.user);
        const username = params.user;
        const user = await this.users.getUserByEmail(username);
        console.log(user);
        this.users.setUser(user);
        this.openedSection = 'userslist';
      }
    });
  }

  async onProjectClick(project) {
    console.log('onclick');
    console.log(this.projects.PREFIX + '/' + project.path);
    this.imageViewer.setProjectPath(project.path);
    this.loader.setProjectURL(project.path);
    const config: any = await this.loader.load({});
    if (config.screenshot) {
      this.screenshot = config.screenshot;
    } else {
      this.screenshot = null;
    }
    await this.acl.getUsersForProject(project.path);
    this.setCurrentProject(project);
    this.openedSection = 'open_project'
    this.refreshModelFiles();
    this.refreshMaterialsFiles();
    this.environment.setCurrentProjectPath(project.organization + "/Common");
    this.setPondOptions();
  }

  async refreshModelFiles() {
    this.modelFiles = (await this.projects.getModelFiles(this.projects.currentProject) as any).files;
  }

  async refreshMaterialsFiles() {
    console.log('refreshMaterialsFiles');

    const result: any = await this.projects.getMaterialsFiles(this.projects.currentProject);
    this.materialFiles = result.files;
    const urls = result.materialSceneURLs;
    this.organizationalMaterials.setMaterials(result.materialSceneURLs, result.organization);
    this.globalMaterials.setMaterials(result.materialSceneURLs, result.common);
  }

  setPondOptions() {
    if (this.projects.currentProject) {
      this.pondModelOptions = {
        class: 'my-filepond',
        multiple: true,
        labelIdle: 'Drop files here',
        allowRevert: false,
        server: {
          url: this.projects.PREFIX + '/' + this.projects.currentProject.path + '/Model',
        },
      };
      this.pondMaterialOptions = {
        class: 'my-filepond',
        multiple: true,
        labelIdle: 'Drop files here',
        allowRevert: false,
        server: {
          url: this.projects.PREFIX + '/' + this.projects.currentProject.path + '/Materials',
        },
      };
    } else {
      this.pondModelOptions = {
        class: 'my-filepond',
        multiple: true,
        labelIdle: '',
        allowRevert: false,
        server: {
          url: '',
        },
      };
      this.pondMaterialOptions = {
        class: 'my-filepond',
        multiple: true,
        labelIdle: '',
        allowRevert: false,
        server: {
          url: '',
        },
      };
    }
  }

  onProjectDblClick(project) {
    console.log('ondblclick');
    this.openProject(project);
  }

  setCurrentProject(project) {
    console.log("PROJECT set currentproject", project);

    this.projects.currentProject = project;
  }

  openProject(project, viewer = false) {
    if (project.type == 'simple_tronog') {
      // this.router.navigateByUrl('/simple?project=' + project.path);

      // NEEDED TO FORCE VIEWER UNIQUEIDs FOR MESHES TO GO FROM 0
      // WHEN REOPENING PROJECTS
      let viewerSuffix = "";
      if (viewer) {
        viewerSuffix = "&ha=13"
      }
      if (this.materialQuality === '') {
        window.open('/simple?project=' + project.path + viewerSuffix);
      } else {
        window.open('/simple?project=' + project.path + '&materialquality=' + this.materialQuality + viewerSuffix);
      }
    }
  }

  openProjectLogic(project, viewer = false) {
    if (project.type == 'simple_tronog') {
      // this.router.navigateByUrl('/simple?project=' + project.path);

      // NEEDED TO FORCE VIEWER UNIQUEIDs FOR MESHES TO GO FROM 0
      // WHEN REOPENING PROJECTS
      let viewerSuffix = "";
      if (viewer) {
        viewerSuffix = "&ha=13"
      }
      if (this.materialQuality === '') {
        window.open('/logic/simple?project=' + project.path + viewerSuffix);
      } else {
        window.open('/logic/simple?project=' + project.path + '&materialquality=' + this.materialQuality + viewerSuffix);
      }
    }
  }

  onNewProjectClick() {
    this.openedSection = 'new_project'
    this.projects.currentProject = null;
  }

  onOpenClick(projectpath?) {
    if (projectpath) {
      this.projects.currentProject = this.projects.map[projectpath];
    }
    this.openProject(this.projects.currentProject);
  }

  onOpenNewClick(projectpath?) {
    if (projectpath) {
      this.projects.currentProject = this.projects.map[projectpath];
    }
    this.openProjectLogic(this.projects.currentProject);
  }

  onOpenInViewerClick(projectpath?) {
    if (projectpath) {
      this.projects.currentProject = this.projects.map[projectpath];
    }
    this.openProject(this.projects.currentProject, true);
    // if (window.location.hostname.indexOf('localhost') >= 0) {
    //   console.log(window.location);
    //   const url = window.location.protocol + '//' + window.location.hostname + ':4201/?project=' + this.projects.currentProject.path;
    //   console.log(url);
    //   window.open(url);
    // } else if (window.location.hostname.indexOf('192.168.') >= 0) {
    //   console.log(window.location);
    //   const url = window.location.protocol + '//' + window.location.hostname + ':3008/?project=' + this.projects.currentProject.path;
    //   console.log(url);
    //   window.open(url);
    // } else {
    //   console.log(window.location);
    //   const url = 'https://3dv.tronog-engine.com/?project=' + this.projects.currentProject.path;
    //   console.log(url);
    //   window.open(url);
    // }
  }

  onOpenInImageViewerClick(projectpath?) {
    if (projectpath) {
      this.projects.currentProject = this.projects.map[projectpath];
    }
    this.imageViewer.open();
  }

  async onCreateClick() {
    try {
      const project = await this.projects.create({
        name: this.newProjectName,
        type: 'simple_tronog',
        organization: this.newProjectOrganization,
        created: {
          user: 1,
          name: 'Ivan Maglica',
          date: '4.4.2019 16:29:26'
        }
      });
      this.onProjectClick(project);
    } catch (ex) {
      if (ex.message == 'PathAlreadyExists') {
        alert('Project path already exists. Please choose different name.');
      }
    }
  }


  pondModelHandleInit() {
    console.log('FilePond has initialised', this.myPondModel);
  }

  pondModelHandleAddFile(event: any) {
    console.log('A file was added', event);
  }

  async onDeleteModelClick(file) {
    this.modelFiles = (await this.projects.deleteModel(this.projects.currentProject, file) as any).files;
  }

  pondModelProcessFile(event: any) {
    console.log('A file was processed', event);
    console.log(event.file.filename);

    this.refreshModelFiles();
    this.myPondModel.removeFile(event.file.id);
  }

  pondMaterialHandleInit() {
    console.log('FilePond has initialised', this.myPondModel);
  }

  pondMaterialHandleAddFile(event: any) {
    console.log('A file was added', event);
  }

  async onDeleteMaterialClick(file) {
    this.materialFiles = (await this.projects.deleteMaterial(this.projects.currentProject, file) as any).files;
  }

  pondMaterialProcessFile(event: any) {
    console.log('A file was processed', event);
    console.log(event.file.filename);

    this.refreshMaterialsFiles();
    this.myPondMaterials.removeFile(event.file.id);
  }

  onDeleteProjectClick() {
    if (confirm('Are you sure you want to delete the project? This process is irreversible!')) {
      this.projects.deleteProject(this.projects.currentProject);
    }
  }

  async onArchiveProjectClick() {
    await this.projects.archiveProject(this.projects.currentProject);
    if (!this.projects.currentProject.archived) {
      this.projects.currentProject.archived = true;
    } else {
      this.projects.currentProject.archived = false;
    }
    setTimeout(() => {
      this.sidebarList.refresh();
    }, 1);

  }

  onRenameProjectClick() {
    this.showRename = true;
  }

  onRenameOkClick() {
    this.showRename = false;
    this.projects.renameProject(this.projects.currentProject);
  }

  onRenameCancelClick() {
    this.showRename = false;
  }

  async onLODClick() {
    this.LOD = await this.loader.generateLodAsync(this.projects.currentProject.organization, this.projects.currentProject.name);
    this.generatingLOD = true;
    this.lodChecker = setInterval(async () => {
      const data = await this.loader.checkLod(this.projects.currentProject.organization, this.projects.currentProject.name, this.LOD.id);
      console.log('LOD');
      console.log(this.LOD);
      console.log(data);
      console.log(data[this.LOD]);

      if (!data[this.LOD.id]) {
        clearInterval(this.lodChecker);
        this.generatingLOD = false;
      }
    }, 1000);
  }

  onGeneratedMaterial() {
    console.log('generatedMaterial');

    this.refreshMaterialsFiles();
  }

  onUsersClick() {
    this.openedSection = 'userslist';
  }

  onOrganizationsClick() {
    this.openedSection = 'organizationslist';

  }

  onThumbClick() {
    this.texturepreviewgenerator.show();
  }

  onOrganizationalMaterialRename() {
    this.refreshMaterialsFiles();
  }

  onKonfiguratorClick(projectpath?) {
    if (projectpath) {
      this.projects.currentProject = this.projects.map[projectpath];
    }
    window.open('/konfigurator/?id=' + this.projects.currentProject.path);
  }


  onKonfiguratorEditorClick(projectpath?) {
    if (projectpath) {
      this.projects.currentProject = this.projects.map[projectpath];
    }
    window.open('/konfigurator/?id=' + this.projects.currentProject.path + "&mode=edit&draft=true");
  }


  onKonfiguratorNewEditorClick(projectpath?) {
    if (projectpath) {
      this.projects.currentProject = this.projects.map[projectpath];
    }
    window.open('/admin/?id=' + this.projects.currentProject.path + "&draft=true");
  }

  onItemClick(item: VisibleItem) {
    console.log(item);
    if (item.type !== 'project') {
      this.sidebarList.selectItem(item);

    } else {
      this.onProjectClick(item.project);
    }
  }

  onItemDblClick(item: VisibleItem) {
    console.log(item);

  }

  onArchivedClick() {
    console.log("onArchivedClick()", this.sidebarList.showArchived);
    setTimeout(() => {
      this.sidebarList.refresh();
    }, 1);
  }

  onItemMouseDown(item: VisibleItem) {
    console.log(item);

    this.drag = true;

    if (item.type == 'project') {
      this.sidebarList.dragItem = item;
    }
  }

  async onItemMouseUp(item: VisibleItem) {
    console.log(item);
    console.log(this.sidebarList.dragItem);
    console.log(item.organization);
    this.drag = false;
    if (item.type == 'collection') {
      if (this.sidebarList.dragItem && (this.sidebarList.dragItem.organization == item.organization)) {
        let list = await this.collections.add(item.organization.label, item.collection.path, this.sidebarList.dragItem.project.path);
        console.log(list);
        this.sidebarList.loadCollection(this.sidebarList.openedOrganization, list);
        this.sidebarList.refresh()
      }
    }
    this.sidebarList.dragItem = null;
  }

  onNewCollectionClick(item: VisibleItem) {
    console.log(item);

    this.sidebarList.newCollectionItem = item;
    // this.sidebarList.refresh();
  }

  async onAddCollectionClick() {
    let data = await this.collections.addCollection(this.sidebarList.openedOrganization.label, this.sidebarList.newCollectionText)
    console.log(data);

    this.sidebarList.newCollectionItem = null;
    this.sidebarList.reload();
  }

  onSearchKeyDown($event: KeyboardEvent) {
    if ($event.key == "Enter") {
      this.sidebarList.refresh();
    }
  }

  onSearchClick() {
    this.sidebarList.refresh();
  }

  async onRemoveFromCollectionClick(item: VisibleItem, $event) {
    setTimeout(async () => {
      let data = await this.collections.remove(item.organization.label, item.collection.path, item.project.path)
      console.log(data);
      this.sidebarList.loadCollection(this.sidebarList.openedOrganization, data);
      this.sidebarList.refresh();

    }, 1);
    console.log("stop1");
    $event.stopPropagation();
  }

  async onRemoveCollectionClick(item: VisibleItem, $event) {
    setTimeout(async () => {
      let data = await this.collections.removeCollection(item.organization.label, item.collection.path)
      console.log(data);
      this.sidebarList.loadCollection(this.sidebarList.openedOrganization, data);
      this.sidebarList.refresh();

    }, 1);
    console.log("stop2");
    $event.stopPropagation();
  }

  onCloseClick() {
    this.openIntro();
  }


  onCancelCollectionClick() {
    this.sidebarList.newCollectionItem = null;

  }

  openIntro() {
    this.openedSection = 'intro';
    this.projects.currentProject = null;

  }

  onFavouriteClick(item, $event) {
    this.favourites.toggle(item.project.path);
    console.log("stop3");
    $event.stopPropagation();
  }

  onFavouriteItemClick(projectpath?) {
    console.log(projectpath);

    let items = projectpath.split("/");

    this.sidebarList.sidebarlist.forEach(org => {
      console.log(org.label == items[0], org.label, items[0]);
      let opened = false;
      if (org.label == items[0]) {
        org.collections.forEach(coll => {
          coll.projects.forEach(prj => {
            console.log(prj.path == projectpath, prj.path, projectpath);

            if (prj.path == projectpath) {
              this.sidebarList.openedOrganization = org;
              this.sidebarList.openedCollection = coll;
              this.sidebarList.viewAllProjects = false;
              this.onProjectClick(prj);
              this.sidebarList.refresh();
              opened = true;
            }
          })
        })
      }
      if (!opened) {
        org.collections.forEach(coll => {
          if (org.label == items[0]) {
            this.sidebarList.openedOrganization = org;
            this.sidebarList.openedCollection = null;
            this.sidebarList.viewAllProjects = true;
            this.onProjectClick(this.projects.map[projectpath]);
            this.sidebarList.refresh();
          }
        });
      }
    })
  }

}
