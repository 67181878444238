import { ProjectsService } from '../projects/projects.service';
import { MaterialsService } from './materials.service';

export class MaterialList {
  public selectedFiles: any[] = [];
  public allFiles: any[] = [];
  public filteredFiles = [];
  public selectedTags = ["", "", ""];
  public allTags = [
    [{ label: "--", value: "" }],
    [{ label: "--", value: "" }],
    [{ label: "--", value: "" }],
  ];
  public filterLabel = "";

  public multiDropdownList = [];
  public multiSelectedItems = [];
  public multiDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    enableCheckAll: false
    // allowSearchFilter: true
  };

  constructor(
    public projects: ProjectsService,
    public materials: MaterialsService,

  ) { }

  refreshTags() {
    let allTags = [
      new Set(),
      new Set(),
      new Set()
    ]
    this.allTags = [
      [{ label: "--", value: "" }],
      [{ label: "--", value: "" }],
      [{ label: "--", value: "" }],
    ];
    this.multiDropdownList = [];
    this.allFiles.forEach(f => {
      let name: string = f;
      if (f.startsWith("Standard_")) {
        name = name.replace("Standard_", "");
      }
      let items = name.split("_");
      allTags[0].add(items[0]);

      let add1 = true;
      let add2 = true;
      if (this.selectedTags[0] != "") {
        if (this.selectedTags[0] != items[0]) {
          add1 = false;
          add2 = false;
        }
      }
      if (add1) {
        allTags[1].add(items[1]);
      }


      if (this.selectedTags[1] != "") {
        if (this.selectedTags[1] != items[1]) {
          add2 = false;
        }
      }
      if (add2) {
        allTags[2].add(items[2]);
      }
    })
    allTags[0].forEach(tag => {
      this.allTags[0].push({
        label: tag as string,
        value: tag as string
      });
      this.multiDropdownList.push({
        item_id: tag,
        item_text: tag
      })
    })
    allTags[1].forEach(tag => {
      this.allTags[1].push({
        label: tag as string,
        value: tag as string
      });
    })
    allTags[2].forEach(tag => {
      this.allTags[2].push({
        label: tag as string,
        value: tag as string
      });
    })
  }


  isIncluded(file) {
    return this.selectedFiles.indexOf(file) >= 0;
  }


  select(file) {
    console.log(file);

    if (this.isIncluded(file)) {
      console.log('remove');

      this.selectedFiles = this.selectedFiles.filter(mat => mat !== file);
    } else {
      console.log('add');
      this.selectedFiles.push(file);
    }
    console.log(this.selectedFiles);
    this.setProjectOrganizationMaterials();
  }

  setProjectOrganizationMaterials() {
    // this.materials.setProjectCommonMaterials(this.projects.currentProject.path, this.selectedFiles);
    // this.materials.setProjectOrganizationMaterials(this.projects.currentProject.path, this.selectedFiles);
  }

  filter() {
    this.filteredFiles = [];
    console.log("ALLFILES", this.allFiles)
    this.allFiles.forEach(f => {
      let name = f;
      let isOk = true;
      if (name.startsWith("Standard_")) {
        name = name.replace("Standard_", "");
      }
      let items = name.split("_");
      let filters = this.filterLabel.toLowerCase().split(" ")

      try {
        if (this.selectedTags[0] != "") {
          if (items[0] != this.selectedTags[0]) {
            isOk = false;
          }
        }
      } catch (error) {
        isOk = false;
      }

      try {
        if (this.multiSelectedItems.length > 0) {
          let ok = false;
          this.multiSelectedItems.forEach(selected => {
            if (selected.item_id == items[0]) {
              ok = true;
            }
          })
          if (!ok) {
            isOk = false;
          }
        }
      } catch (e) {
        
      }

      // try {
      //   if (this.selectedTags[1] != "") {
      //     if (items[1] != this.selectedTags[1]) {
      //       isOk = false;
      //     }
      //   }
      // } catch (error) {
      //   isOk = false;
      // }

      // try {
      //   if (this.selectedTags[2] != "") {
      //     if (items[2] != this.selectedTags[2]) {
      //       isOk = false;
      //     }
      //   }
      // } catch (error) {
      //   isOk = false;
      // }

      if (this.filterLabel != "") {
        filters.forEach(f => {
          if (name.toLowerCase().indexOf(f) < 0) {
            isOk = false;
          }
        })
      }

      if (isOk) {
        this.filteredFiles.push(f);
      }
    })
  }

  public deselectAll() { }

  public setMaterials(urls, materials) { }

}