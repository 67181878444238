import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MaterialsService {
  private PREFIX: string = "";

  constructor(private http:HttpClient) { }

  public getPrefix() {
    return this.PREFIX;
  }

  public setPrefix(prefix: string) {
    this.PREFIX = prefix;
  }

  public async getProjectMaterials(projectName: string) {
    return await this.http.get(this.PREFIX + "/" + projectName + "/Materials").toPromise();
  }
  
  public async setProjectCommonMaterials(projectName: string, materials: string[]) {
    return await this.http.post(this.PREFIX + "/" + projectName + "/CommonMaterials", materials).toPromise();
  }  

  public async setProjectOrganizationMaterials(projectName: string, materials: string[]) {
    return await this.http.post(this.PREFIX + "/" + projectName + "/OrganizationMaterials", materials).toPromise();
  }

  public async rename(projectName: string, oldName: string, newName: string) {
    console.log("RENAMING", projectName, oldName, newName);
    return await this.http.get(this.PREFIX + "/" + projectName + "/Materials/" + oldName + "/rename/" + newName).toPromise();
  }
}
