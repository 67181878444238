import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Project } from '../projects/projects.service';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {
  public list: string[];
  public projects: Project[];
  public projectMap: any;

  constructor(
    public http: HttpClient
  ) {
    this.load();
  }

  async load() {
    this.list = [];
    let data = window.localStorage.getItem('favourites');
    console.log(data);

    if (data) {
      this.list = JSON.parse(data);
    }
    console.log("FAVOURITES", this.list);
    await this.get();
    console.log("FAV PROJECTS", this.projects);
    
  }

  save() {
    window.localStorage.setItem('favourites', JSON.stringify(this.list));
  }

  async get() {
    let promises = [];
    this.list.forEach(projectpath => {
      promises.push(this.http.get("/simplekonfiguratorprofile/projects/" + projectpath + "/config.json").toPromise());
    });
    this.projects = await Promise.all(promises);
    this.projectMap = {};
    for (let i=0; i<this.projects.length; i++) {
      this.projectMap[this.list[i]] = this.projects[i];
    }
    return this.projects;
  }

  async fetch(projectpath) {
    if (!this.projectMap[projectpath]) {
      let prj = await this.http.get("/simplekonfiguratorprofile/projects/" + projectpath + "/config.json").toPromise();
      this.projectMap[projectpath] = prj;
      this.projects.push(prj as Project);
    }
  }

  public set(projectpath: string) {
    this.list = this.list.filter(prjpath => prjpath != projectpath);
    this.list.push(projectpath);
    // if (this.list.length > 5) {
    //   this.list.splice(0, 1);
    // }
    this.save();
  }
  
  public async toggle(projectpath: string) {
    console.log("TOGGLE", projectpath);
    
    if (this.isIncluded(projectpath)) {
      this.list = this.list.filter(prjpath => prjpath != projectpath);
    } else {
      this.list.push(projectpath);
      await this.fetch(projectpath);
    }
    // if (this.list.length > 5) {
    //   this.list.splice(0, 1);
    // }
    this.save();
  }

  public isIncluded(projectpath: string) {
    if (this.list.indexOf(projectpath) >= 0) {
      return true;
    }
    return false;
  }
}
