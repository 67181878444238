import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AudioService } from 'src/app/services/recording/audio.service';
import { VideoService } from 'src/app/services/recording/video.service';

@Component({
  selector: 'app-recording',
  templateUrl: './recording.component.html',
  styleUrls: ['./recording.component.css']
})
export class RecordingComponent implements OnInit {
  audioSrc = "/simplekonfiguratorprofile/projects/Tronog/test5/audio/recording1.mp3";
  videoSrc = "/simplekonfiguratorprofile/projects/Tronog/test5/video/recording1.mp4";

  public audioRecordingStateLabel: string = " ";
  public videoRecordingStateLabel: string = " ";

  constructor(
    private audioService: AudioService,
    private videoService: VideoService,
    ) {
      this.registerAudioOnRecording();
      this.registerVideoOnRecording();
    }

  ngOnInit() {

  }

  registerAudioOnRecording() {
    this.audioService.recording$.subscribe(data => {
      switch (data) {
        case "recording":
          this.audioRecordingStateLabel = "recording";
          break;
        case "idle":
          this.audioRecordingStateLabel = " ";
          break;
      }
    })
  }

  registerVideoOnRecording() {
    this.videoService.recording$.subscribe(data => {
      switch (data) {
        case "recording":
          this.videoRecordingStateLabel = "recording";
          break;
        case "idle":
          this.videoRecordingStateLabel = " ";
          break;
      }
    })
  }

  onAudioRecordClick() {
    this.audioService.toggleRecording("Tronog/test5", "recording1");
  }

  onAudioPlayClick() {

  }

  onVideoRecordClick() {
    this.videoService.toggleRecording("Tronog/test5", "recording1");
  }

  onVideoPlayClick() {

  }



}
