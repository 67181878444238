import { Component, OnInit, ViewChild } from '@angular/core';
import { TexturePreviewService } from 'src/app/services/texturepreview/texture-preview.service';
import { TexturePreviewComponent } from '../texture-preview/texture-preview.component';
import { ProjectsService } from 'src/app/services/projects/projects.service';

@Component({
  selector: 'app-texture-preview-generator',
  templateUrl: './texture-preview-generator.component.html',
  styleUrls: ['./texture-preview-generator.component.css']
})
export class TexturePreviewGeneratorComponent implements OnInit {
  @ViewChild("preview", {static: true}) preview: TexturePreviewComponent;

  modalVisible = false;
  available = null;

  constructor(
    public projects: ProjectsService,
    public service: TexturePreviewService
  ) { }

  ngOnInit() {
    this.service.setComponent(this.preview);
  }

  onOkClick() {
    this.modalVisible = false;
  }

  onCancelClick() {
    this.modalVisible = false;
  }

  public show() {
    this.modalVisible = true;
  }

  public async onGenerateClick() {
    this.service.setInterval();
    await this.service.generate();
    this.service.clearInterval();
  }

  async onSaveClick() {
  }

  async onCheckClick() {
    
  }

}
