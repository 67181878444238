import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CamerasService } from 'src/app/services/cameras/cameras.service';
import { EngineService } from 'src/app/services/engine/engine.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { ViewFinderService } from 'src/app/services/viewfinder/view-finder.service';

@Component({
  selector: 'app-cameras',
  templateUrl: './cameras.component.html',
  styleUrls: ['./cameras.component.css']
})
export class CamerasComponent implements OnInit {
  @Output() camera: EventEmitter<any> = new EventEmitter();

  @ViewChild("newlabel", { static: true }) newlabel: ElementRef;

  alpha: any = {
    minValue: 50,
    maxValue: 200,
    options: {
      floor: -Math.PI * 2,
      ceil: +Math.PI * 2,
      step: 0.1
    }
  }

  beta: any = {
    minValue: 50,
    maxValue: 200,
    options: {
      floor: -Math.PI * 2,
      ceil: +Math.PI * 2,
      step: 0.1
    }
  }

  radius: any = {
    minValue: 50,
    maxValue: 200,
    options: {
      floor: 0,
      ceil: 50,
      step: 0.1
    }
  }

  minZ: number = 0;


  constructor(
    public cameras: CamerasService,
    public projects: ProjectsService,
    public viewfinder: ViewFinderService
  ) {
    this.cameras.onDeserialize = () => {
      this.onDeserialize();
    }
  }

  ngOnInit() {
    console.log("cameras ready");
    this.cameras.init();
  }

  onDeserialize() {
    console.log("onCamerasDeserialize");
    this.alpha.minValue = this.cameras.alphaMin;
    this.alpha.maxValue = this.cameras.alphaMax;
    this.beta.minValue = this.cameras.betaMin;
    this.beta.maxValue = this.cameras.betaMax;
    this.radius.minValue = this.cameras.radiusMin;
    this.radius.maxValue = this.cameras.radiusMax;
    this.minZ = this.cameras.minZ;

  }

  onDeleteClick(camera) {
    this.cameras.delete(camera);
    this.camera.emit();
  }

  onCameraClick(camera) {
    this.cameras.move(camera);
    this.camera.emit();
  }

  onSaveClick(camera) {
    this.cameras.save(camera);
    this.camera.emit();
  }

  onAddClick() {
    this.cameras.add();
    this.camera.emit();
  }

  async onImportClick() {
    await this.cameras.import(this.projects.currentProject.path, () => {
      this.camera.emit();
    });
    this.camera.emit();
  }

  onCameraDblClick(camera) {
    this.cameras.renameStart();
    setTimeout(() => {
      let el = <HTMLInputElement>document.getElementById("newlabel");
      el.focus();
      el.select();
    }, 100);
  }

  onKeyDown(event) {
    console.log(event);
    if (event.key == 'Enter') {
      this.cameras.renameEnd();
    }
  }

  onAlphaUserChange(event) {
    console.log(event);
    this.alpha.minValue = event.value;
    this.alpha.maxValue = event.highValue;
    this.cameras.setAlpha(
      this.alpha.minValue,
      this.alpha.maxValue);
    this.camera.emit();
  }

  onBetaUserChange(event) {
    console.log(event);
    this.beta.minValue = event.value;
    this.beta.maxValue = event.highValue;
    this.cameras.setBeta(
      this.beta.minValue,
      this.beta.maxValue)
  }

  onRadiusUserChange(event) {
    console.log(event);
    this.radius.minValue = event.value;
    this.radius.maxValue = event.highValue;
    this.cameras.setRadius(
      this.radius.minValue,
      this.radius.maxValue)
  }

  onMinZChange(event) {
    console.log(event);
    this.cameras.setMinZ(this.minZ);
    this.camera.emit();
  }

  onPortraitClick(camera) {
    camera.landscape = false;
    this.cameras.move(camera);
    this.camera.emit();
  }

  onLandscapeClick(camera) {
    camera.landscape = true;
    this.cameras.move(camera);
    this.camera.emit();
  }

  onGltfClick() {
    this.cameras.importGltf(this.projects.currentProject.path)
  }


}
