import { Injectable, ElementRef, Input } from '@angular/core';
import { Observer, Observable, Subject } from 'rxjs';
import { EngineService } from '../engine/engine.service';
import { RenderService } from '../render/render.service';

@Injectable({
  providedIn: 'root'
})
export class ViewFinderService {
  public resize$: Subject<any> = new Subject<any>();
  public update$: Subject<any> = new Subject<any>();
  canvas: ElementRef;
  visible = true;

  public offset = {
    top: 36,
    left: 0,
  }

  public factor = {
    width: 0.95,
    height: 0.95
  }

  public position = {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  }

  public resolutions = [
    { i: 0,  x: 720,  y: 1280, label: "" },
    { i: 1,  x: 1280, y: 720,  label: "" },
    { i: 2,  x: 360, y: 640,  label: "" },
    { i: 3,  x: 640, y: 360,  label: "" },
    { i: 4,  x: 1000, y: 1000, label: "" },
    { i: 5,  x: 1500, y: 1500, label: "" },
    { i: 6,  x: 2000, y: 2000, label: "" },
    { i: 7,  x: 1080, y: 1920, label: "" },
    { i: 8,  x: 1920, y: 1080, label: "" },
    { i: 9,  x: 1280, y: 1920, label: "" },
    { i: 10,  x: 1920, y: 1280, label: "" },
    { i: 11,  x: 1080, y: 2400, label: "" },
    { i: 12, x: 2400, y: 1080, label: "" },
    { i: 13, x: 2400, y: 1350, label: "" },
    { i: 14, x: 4000, y: 4000, label: "" },
    { i: 15, x: 2480, y: 3508, label: "" },
    { i: 16, x: 3508, y: 2480, label: "" },
    { i: 17, x: 4096, y: 4096, label: "" },
    // 720 x1280
    // 1280x720
    // 1000x1000
    // 1500x1500
    // 2000x2000
    // 1080x1920
    // 1920x1080
    // 1280x1920
    // 1920x1280
    // 1080x2400
    // 2400x1080
    // 4000x4000
    // 2480x3508
    // 3508x2480
    // 4096x4096
  ]
  public _resolution = {
    i: 0,
    x: 1080,
    y: 1920
  }
  public resolutionIndex = 0;

  constructor(
    private engine: EngineService,
    public rendering: RenderService
  ) {
    this.resize$.subscribe(data => {
      console.log('RESIZE$', data);
      this.update$.pipe(data);
    });
    this.engine.resize$.subscribe({
      next: data => this.onResize(data)
    })
    this.engine.initialized$.subscribe({
      next: data => this.onEngineInitialized(data)
    })
    this.rendering.resolutionChange$.subscribe(data => {
      console.log("RESOLUTION CHANGE", this.rendering.settings);

      switch (this.rendering.settings.resolution) {
        case '720x1280':
          this.resolution = 0;
          break;
        case '1280x720':
          this.resolution = 1;
          break;
        case '360x640':
          this.resolution = 2;
          break;
        case '640x360':
          this.resolution = 3;
          break;
        case '1000x1000':
          this.resolution = 4;
          break;
        case '1500x1500':
          this.resolution = 5;
          break;
        case '2000x2000':
          this.resolution = 6;
          break;
        case '1080x1920':
          this.resolution = 7;
          break;
        case '1920x1080':
          this.resolution = 8;
          break;
        case '1280x1920':
          this.resolution = 9;
          break;
        case '1920x1280':
          this.resolution = 10;
          break;
        case '1080x2400':
          this.resolution = 11;
          break;
        case '2400x1080':
          this.resolution = 12;
          break;
        case '2400x1350':
          this.resolution = 13;
          break;
        case '4000x4000':
          this.resolution = 14;
          break;
        case '2480x3508':
          this.resolution = 15;
          break;
        case '3508x2480':
          this.resolution = 16;
          break;
        case '4096x4096':
          this.resolution = 17;
          break;
      }
    })
  }

  public setCanvas(canvas: ElementRef) {
    this.canvas = canvas;
  }

  public setFromCamera(camera) {
    if (!camera.landscape) {
      switch (this.resolution) {
        case 1:
          this.resolution = 0
          break;
        case 3:
          this.resolution = 2;
          break
      }
    } else {
      switch (this.resolution) {
        case 0:
          this.resolution = 1
          break;
        case 2:
          this.resolution = 3;
          break
      }
    }

  }

  @Input()
  set resolution(index: any) {
    this.resolutionIndex = index;
    this._resolution = this.resolutions[index];
    this.onResize(null);
    console.log("CURRENT RESOLUTION", this._resolution);

  }

  get resolution() {
    return this.resolutionIndex;
  }

  onResize(data: any) {
    let { left, top, width, height } = this.engine.canvas.nativeElement;
    let rwidth = (height / 2);
    let rheight = (rwidth * (this._resolution.y / this._resolution.x)) * this.factor.height;
    rwidth = rwidth * this.factor.width;

    this.position = {
      left: (width / 2) - (rwidth / 2) + (this.offset.left),
      top: (height / 2) - (rheight / 2) + (this.offset.top),
      width: rwidth,
      height: rheight,
    }

    console.log("resize", {
      c: { left, top, width, height },
      r: { rwidth, rheight },
      p: this.position,
      re: this._resolution
    });

  }

  onEngineInitialized(data) {
    this.canvas = this.engine.canvas;
    this.onResize(null);
  }
}
