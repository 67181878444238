import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MaterialRelation, MaterialRelationsService } from 'src/app/services/materialrelations/material-relations.service';
import { MaterialPreviewsService } from 'src/app/materialpreviews/material-previews.service';
import { GeometryGroups } from 'src/app/services/scene/geometry-groups';
import { OptionGroupsService } from 'src/app/services/optiongroups/option-groups.service';
import { SimpleKonfiguratorProfileSceneService } from 'src/app/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { Material } from 'src/app/services/scene/materials';
import { MaterialLinkAssignment, MaterialLinksService } from 'src/app/services/materiallinks/material-links.service';

@Component({
  selector: 'app-material-relations-section',
  templateUrl: './material-relations-section.component.html',
  styleUrls: ['./material-relations-section.component.css']
})
export class MaterialRelationsSectionComponent implements OnInit {
  private _currentMaterilalLinkLeft = null;
  private _currentMaterilalLinkRight = null;
  @Input() labelLeft;
  @Input() labelRight;
  public currentLeftMaterials = [];
  public currentRightMaterials: MaterialRelation[] = [];
  public _currentLeftMaterial: Material = null;
  @Output() public leftMaterial: EventEmitter<Material> = new EventEmitter();

  @Input() filteredRightLinks = null;

  @Input()
  set currentMaterilalLinkLeft(link: string) {
    // console.log("currentMaterilalLinkLeft", link);

    this._currentMaterilalLinkLeft = link;
    this.changeLeft(link);
  }

  @Input()
  set currentMaterilalLinkRight(link: string) {
    // console.log("currentMaterilalLinkRight", link);

    this._currentMaterilalLinkRight = link;
    this.changeRight(link);
  }

  @Input()
  set currentLeftMaterial(mat) {
    if (!mat) {
      return;
    }
    // console.log("currentLeftMaterial", mat);

    this.selectLeft(mat, false);
  }


  constructor(
    public service: SimpleKonfiguratorProfileSceneService,
    public geometryGroups: GeometryGroups,
    public optionGroups: OptionGroupsService,
    public relations: MaterialRelationsService,
    public previews: MaterialPreviewsService,
    private materialLinks: MaterialLinksService,
  ) { }

  ngOnInit() {
  }

  public changeLeft(link) {
    // console.log("THUMBS", this.previews.thumbnails);


    // this.currentLeftMaterials = this.geometryGroups.getMaterialsForLink(link);
    this.currentLeftMaterials = this.materialLinks.materialLinkAssignments.find(ma => ma.link == link).materials;
    if (this._currentMaterilalLinkLeft == this._currentMaterilalLinkRight) {
      this._currentMaterilalLinkRight = null;
      this.currentRightMaterials = null;
    }
    // this.filteredRightLinks = this.getRightMaterialLinks();
    // console.log(this.currentLeftMaterials);
    this._currentLeftMaterial = this.currentLeftMaterials[0];
    // console.log("this._currentLeftMaterial", this._currentLeftMaterial);

    this.leftMaterial.emit(this.currentLeftMaterials[0])
  }


  public changeRight(link) {
    this.refreshRightList();
  }

  refreshRightList() {
    // console.log(this._currentMaterilalLinkRight);

    let list = this.geometryGroups.getMaterialsForLink(this._currentMaterilalLinkRight);
    if (this.materialLinks.materialLinkAssignments) {
      list = this.materialLinks.getAssignment(this._currentMaterilalLinkRight).materials;
    }
    this.currentRightMaterials = [];
    list.forEach(m => {
      let item;
      const items = this.relations.list.filter(mr =>
        (mr.linkLeft == this._currentMaterilalLinkLeft) &&
        (mr.linkRight == this._currentMaterilalLinkRight) &&
        (this._currentLeftMaterial && (mr.materialLeft == this._currentLeftMaterial.label)) &&
        (mr.materialRight == m.label))
      if (!items.length) {
        item = new MaterialRelation();
        item.linkLeft = this._currentMaterilalLinkLeft;
        item.linkRight = this._currentMaterilalLinkRight;
        item.materialLeft = this._currentLeftMaterial ? this._currentLeftMaterial.label : '';
        item.materialRight = m.label;
      } else {
        item = items[0];
      }
      this.currentRightMaterials.push(item);
    })
    // console.log(this.currentRightMaterials);
  }

  public selectLeft(mat, emit = true) {
    // console.log("SELECTLEFT", mat);

    this._currentLeftMaterial = mat;
    // this.filteredRightLinks = this.getRightMaterialLinks();
    if (this._currentMaterilalLinkRight) {
      this.refreshRightList();
    }
    if (emit) {
      this.leftMaterial.emit(mat);
    }
  }

  public onRightCheckChange(mat: MaterialRelation) {
    this.relations.set(mat);
    this.service.modified$.next();
  }


  public onLeftMaterialClick(mat) {
    this.selectLeft(mat);
  }

  public onMatClick(mat: MaterialRelation) {
    mat.visible = !mat.visible;
    this.relations.set(mat);
  }


}
