import { Component, OnInit } from '@angular/core';
import { ViewFinderService } from 'src/app/services/viewfinder/view-finder.service';

@Component({
  selector: 'app-view-finder',
  templateUrl: './view-finder.component.html',
  styleUrls: ['./view-finder.component.css']
})
export class ViewFinderComponent implements OnInit {


  public resolution = 0

  constructor(
    public viewfinder: ViewFinderService
  ) {
    this.viewfinder.update$.subscribe({
      next: data => this.onUpdate(data)
    })
  }

  ngOnInit() {
  }

  onUpdate(data) {
    console.log("onUpdate", data);
    
  }

}
