import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RelationsMaterialGeometryService {
  public selected = {}

  constructor() { }


  public isSelected(currentMaterilalLinkLeft, materialLabel, linkLabel) {
    if (this.selected) {
      if (this.selected[currentMaterilalLinkLeft] !== undefined) {
        if (this.selected[currentMaterilalLinkLeft][materialLabel] !== undefined) {
          if (this.selected[currentMaterilalLinkLeft][materialLabel][linkLabel]) {
            return true;
          }
        }
      }
    }

    return false;
  }

  public select(currentMaterilalLinkLeft, materialLabel, linkLabel) {
    if (!this.selected[currentMaterilalLinkLeft]) {
      this.selected[currentMaterilalLinkLeft] = {}
    }
    if (!this.selected[currentMaterilalLinkLeft][materialLabel]) {
      this.selected[currentMaterilalLinkLeft][materialLabel] = {}
    }
    if (this.selected[currentMaterilalLinkLeft][materialLabel][linkLabel]) {
      console.log("DELETE")
      delete this.selected[currentMaterilalLinkLeft][materialLabel][linkLabel]
    } else {
      console.log("ADD")
      this.selected[currentMaterilalLinkLeft][materialLabel][linkLabel] = {
        disabled: true
      }
    }
  }

  public serialize(config) {
    config.materialGeometryRelations = {
      selected: this.selected
    }
    console.log("RMG SERIALIZE", config.materialGeometryRelations);

  }

  public deserialize(config) {
    console.log("RMG DESERIALIZE?", config);
    if (config.materialGeometryRelations) {
      console.log("RMG DESERIALIZE", config.materialGeometryRelations);
      this.selected = config.materialGeometryRelations.selected
    }
  }
}
