import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ViewerComponent } from '../../components/viewer/viewer.component';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { GeometryNode } from 'src/app/services/scene/geometry';
import { SimpleKonfiguratorProfileSceneService } from 'src/app/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { GroupLayer, GeometryGroup } from 'src/app/services/scene/geometry-groups';
import { GeometryGroupsComponent } from 'src/app/components/geometry-groups/geometry-groups.component';
import { TexturePreviewComponent } from 'src/app/components/texture-preview/texture-preview.component';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { ProjectPropertiesComponent } from 'src/app/components/project-properties/project-properties.component';
import { Environment } from 'src/app/services/backgrounds/backgrounds.service';
import { CommentsComponent } from 'src/app/components/comments/comments/comments.component';
import { PinsService } from 'src/app/services/pins/pins.service';
import { HotspotsService, Hotspot, PINWIDTH } from 'src/app/services/hotspots/hotspots.service';
import { ExplosionService } from 'src/app/services/explosion/explosion.service';
import { TagsComponent } from 'src/app/components/tags/tags/tags.component';
import { BgLightComponent } from 'src/app/components/bg-light/bg-light.component';
import { ScreenshotComponent } from 'src/app/components/screenshot/screenshot/screenshot.component';
import { ShaderReplacementsComponent } from 'src/app/components/shader-replacements/shader-replacements.component';
import { RenderService } from 'src/app/services/render/render.service';
import { PresetsComponent } from 'src/app/components/presets/presets.component';
import { ViewFinderService } from 'src/app/services/viewfinder/view-finder.service';
import { ImageViewerService } from 'src/app/services/imageviewer/image-viewer.service';
import { CombinedService } from 'src/app/services/materials/combined/combined.service';
import { KonfiguratorSettingsService } from 'src/app/services/konfiguratorsettings/konfigurator-settings.service';
import { MaterialRelationsService } from 'src/app/services/materialrelations/material-relations.service';
import { RelationsMaterialGeometryService } from 'src/app/services/relations/relations-material-geometry.service';
import { MaterialLinksService } from 'src/app/services/materiallinks/material-links.service';
import { Subject } from 'rxjs';
import { GlobalEventsActions } from 'src/app/services/scene/simpleprofile/global-events-actions.service';
import { ProcessingService } from 'src/app/modules/processing/processing.service';
import { take, tap } from 'rxjs/operators';
import { MaterialEditService } from 'external/rtc-3dk-v2-module/modules/material-edit/material-edit.service';
import { Materials } from 'src/app/services/scene/materials';



@Component({
  selector: 'app-simple-konfigurator-profile',
  templateUrl: './simple-konfigurator-profile.component.html',
  styleUrls: ['./simple-konfigurator-profile.component.css']
})
export class SimpleKonfiguratorProfileComponent implements OnInit, OnDestroy {
  @ViewChild("viewer", { static: true }) viewer: ViewerComponent;
  @ViewChild("groups", { static: true }) groups: GeometryGroupsComponent;
  @ViewChild("preview", { static: true }) preview: TexturePreviewComponent;
  @ViewChild("projectProperties", { static: true }) projectProperties: ProjectPropertiesComponent;
  @ViewChild("screenshot", { static: true }) screenshot: ScreenshotComponent;
  @ViewChild("comments", { static: true }) comments: CommentsComponent;
  @ViewChild("tags", { static: true }) tags: TagsComponent;
  @ViewChild("bglight", { static: true }) bglight: BgLightComponent;
  @ViewChild("urlElement", { static: true }) urlElement: ElementRef;
  @ViewChild("shaderreplacement", { static: true }) shaderreplacement: ShaderReplacementsComponent;
  @ViewChild("presets", { static: true }) presets: PresetsComponent;

  public scene: SimpleKonfiguratorProfileSceneService;
  loader: LoaderService;
  testTree: GeometryNode[];
  PINWIDTH = PINWIDTH;

  labelsVisible: boolean = false;
  materialsVisible: boolean = false;
  currentGroupLayer: GroupLayer;
  currentGroup:GeometryGroup;
  currentProject:any;
  simpleMenuContent: any;
  config:any;
  params:any;
  url:string = "";
  downloadMatRequestItems = [];
  downloadLoader = false;
  downloadMatUrl = '';
  previewMatUrl = '';
  decodedQuerry = '';

  lastClick:Date = new Date();
  modified:boolean = false;
  geometryGroupsContent:any = {};
  advanced:boolean = false;

  thumbnails:any = {};
  labelsData:any = [];
  tagFullscreen:boolean = false;

  activeSidebar:string = "mat_editor";
  // activeSidebar:string = "project";
  sidebarVisible:boolean = true;
  uiHidden:boolean = false;
  showExplosionInViewer:boolean = true;
  materialLabel: string = "";
  pinConfig = {
    visible: false,
    mesh: null,
    pickedPoint: null,
    top: "0px",
    left: "0px"
  }


  constructor(
      scene: SimpleKonfiguratorProfileSceneService,
      loader: LoaderService,
      protected route: ActivatedRoute,
      public projects: ProjectsService,
      public pins: PinsService,
      public hotspots: HotspotsService,
      public explosion: ExplosionService,
      public render: RenderService,
      public imageViewer: ImageViewerService,
      public combinedMaterials: CombinedService,
      public konfiguratorSettings: KonfiguratorSettingsService,
      public materialRelations: MaterialRelationsService,
      public materials: Materials,
      public materialGeometryRelations: RelationsMaterialGeometryService,
      public globals: GlobalEventsActions,
      public processing: ProcessingService,
      public materialEditorService: MaterialEditService,
      private router: Router,
      ) {
    this.scene = scene;
    this.loader = loader;
    this.scene.onMeshSelect = (item) => {
      this.onMeshSelect(item);
    }
    this.scene.onHotspotSelect = (hs:Hotspot) => {
      this.onHotspotClick(hs);
    }

    this.scene.materialAdd$.subscribe(layer => {
      this.onMaterialAddClick(layer);
    })

    this.globals.actions.materials.refreshAllGroups$.subscribe(() => {
      this.refreshAllGroups();
    })

    this.materialEditorService.scene = this.scene;
    this.materialEditorService.materials = this.materials;
  }

  ngOnInit() {
    this.assignEvents();
    console.log("aaa");
    this.route.queryParams.subscribe(async params => {
      let props:any = {}
      this.params = params;
      if (params.ha) {
        this.uiHidden = true;
        this.sidebarVisible = false;
      }
      if (params.project) {
        if (params.materialquality) {
          props.materialQuality = params.materialquality;
        } else {
          props.materialQuality = "512";
        }
        this.loader.setProjectURL(params.project)
        this.url = this.loader.getViewerURL();
        console.log("current project:")
        await this.projects.load();
        this.currentProject = this.projects.getProjectByPathName(this.params.project)[0];
        this.projects.setCurrentProjectByPathName(this.params.project);
        this.comments.setProject(this.currentProject);
        this.presets.setProject(this.params.project);
        this.render.setProjectPath(this.params.project);
        this.imageViewer.setProjectPath(this.params.project);
        console.log(this.currentProject);
        console.log(params.project);
        this.downloadMatRequestItems = params.project.split('/');
        this.processing.projectPath = params.project;
        this.materialEditorService.setProjectPath(
          params.project);
    
      }
      if (params.lod) {
        props.lod = params.lod
      }
      try{
        await this.load(props);
      } catch (e) {
        console.log(e);
      }
      this.scene.config = this.config;
      // this.tags.init(this.groups.groups);
      console.log("cfg");
      console.log(this.config);
      
      this.scene.shaderReplacement.init(this.config.shaderOverrides,this.config.shaderInfo, this.config.shaderLinks, this.scene);
      // this.shaderreplacement.init(this.config, this.scene.shaderReplacement);
      // this.render.setProjectPath(params.project);
      // this.presets.init();
      this.materialEditorService.init(
        params['project'],
        this.config.materialSceneURLs,
        this.scene.scenes
      );
    });

    this.scene.save$.subscribe(async data => {
      console.log("SAVE SUBJECT CALLED...");
      this.modified = false;
      await this.__save();
    })

    this.scene.modified$.subscribe(() => {
      this.modified = true;
    })
  }

  ngOnDestroy() {
    this.scene.destroy();
  }

  async copyToClipboardPeview() {
    console.log(this.previewMatUrl);
    
    await navigator.clipboard.writeText(this.previewMatUrl).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  navgateToPreview() {
    this.router.navigate(['/preview'], {queryParams: {q: this.decodedQuerry}});
  }

  onDownloadMaterialsClick() {
    console.log('donwloadMaterials CLicked');
    const req = {
      organization: this.downloadMatRequestItems[0],
      project: this.downloadMatRequestItems[1],
      layerList: [
        "Shirt|COLLAR_Cutaway"
      ]
    }

    this.downloadLoader = true;

    console.log(this.scene);
    const res = this.getCheckedLabels(this.scene.optionGroups.optionGroupList);
    console.log(res);

    const resp = this.getNodeNames(this.scene.geometryGroups.nodeMap, res);
    console.log(resp);

    req.layerList = Array.from(resp) as Array<string>;
    
    console.log(req);

    this.processing.recomposeFbx(req).pipe(
      take(1),
      tap(() => this.downloadLoader = false)
    ).subscribe((res: any) => {
      console.log("recompose", res);
      if (res.status) {
        // this.downloadMatUrl = 'http://192.168.10.216:3004';
        this.downloadMatUrl = 'http://192.168.10.200:3007';
        this.downloadMatUrl += res.link;

        console.log('Not decoded URL:', this.downloadMatUrl);
        this.decodedQuerry =  btoa(this.downloadMatUrl);
        this.previewMatUrl = window.location.host + '/preview?q=' + btoa(this.downloadMatUrl);
        console.log(this.decodedQuerry);
        console.log(this.previewMatUrl);
      }
    });
    
  }

  getCheckedLabels(objArray) {
    let result = new Set();

    function findChecked(obj) {
      if (obj.checked === true) {
        result.add(obj.label);
      }

      if (obj.optionGroups.list && Array.isArray(obj.optionGroups.list)) {
        obj.optionGroups.list.forEach((child) => {

          findChecked(child);
        });
      }
    }

    objArray.list.forEach((obj) => {
      findChecked(obj);
    });

    return result;
  }

  getNodeNames(mapObj, mapedSet) {
    let result = new Set();

    Object.keys(mapObj).forEach((entryMap: any) => {
      Array.from(mapedSet).forEach((entrySet: any) => {
        if (entryMap.split('|').length >= 5 && entryMap.split('|')[4].includes(entrySet)) {
          result.add(entryMap.split('|')[3] + '|' + entryMap.split('|')[4]);
        }
      });
    });

    return result;
  }

  fromEntries<T>(entries: [keyof T, T[keyof T]][]): T {
    return entries.reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      <T>{}
    );
  }

  onViewerSceneLoaded(data) {
  }

  onMouseMove($event) {
    this.hotspots.show();
  }

  async load(props:any) {
    let data;
    this.scene.init();
    try {
      
      this.config = await this.loader.load(props)
      console.log("config:");
      console.log(this.config);

      // HAS TO BE NON-PARALLEL TO PRESERVE UNIQUEIDs OF MESHES
      data = await this.viewer.init({
          sceneURL: this.config.sceneURL,
          materialSceneURLs: this.config.materialSceneURLs,
          skyboxURL: this.config.skyboxURL
        })
      console.log("PROJECT:", this.currentProject);
      let thumbs = await this.preview.checkAll(this.currentProject.path);
      await this.preview.init({
          sceneURL: "/simplekonfiguratorprofile/projects/Common/TexturePreview/Sphere/Model/cubeSphere.gltf",
          materialSceneURLs: this.config.materialSceneURLs,
          skyboxURL: "https://assets.babylonjs.com/environments/environmentSpecular.env",
          thumbnails: thumbs
        })
      console.log(data.gltf);
      
      await this.scene.register(data.currentScene, data.auxScenes, this.currentProject, this.config)
      // this.debugDump();
      this.pins.init(this.viewer.engine, this.config, this.pinConfig, this.scene.geometryGroups);
      this.thumbnails = this.preview.getThumbnails();
      this.scene.thumbnails = this.thumbnails;

      // data has to be passed to geometry-groups component this way
      // to trigger refresh
      this.refreshGeometryGroups();
      this.groups.content = this.geometryGroupsContent;
      this.groups.registerGroupsForDND();
      // this.setLabelsData();
      this.projectProperties.load(this.currentProject, this.config)
      this.projectProperties.project = this.currentProject;
      this.bglight.load(this.currentProject, this.config);
      this.explosion.init(this.scene);
      this.screenshot.init(this.viewer, this.currentProject, this.config)
      this.konfiguratorSettings.deserialize(this.config.konfiguratorSettings)
      this.materialRelations.deserialize(this.config);
      this.materialGeometryRelations.deserialize(this.config);
      // this.combinedMaterials.init();
      this.applyBackground();
      this.applySpecular();
      this.refreshSimpleMenu();
      this.processing.subscribe();
      setTimeout(() => {
        this.hotspots.register(this.viewer.engine, this.scene.getGeometryGroups());
        this.scene.cameras.setDefault();
        // this.debugDump();
      }, 1000)
      console.log("load end")
    } catch (ex) {
      console.log(ex);
      console.trace();
    }

  }

  public destroy() {
    console.log("DESTROY:");
  }

  async __save() {
    this.modified = false;
    let config = {
      formatVersion: "SIMPLE/20190621",
      screenshot: this.config.screenshot,
      ...this.scene.serialize()
    };
    this.materialRelations.serialize(config)
    this.materialGeometryRelations.serialize(config);
    await Promise.all([
      this.presets.save(),
      this.loader.save(config),
      this.render.save(),
    ])
  }

  async save() {
    await this.scene.save();
  }

  assignEvents() {
    this.viewer.setOnMeshClick((mesh) => {
      this.scene.selectMesh(mesh);
    });
  }

  debugDump() {
    this.scene.geometryGroups.groups.forEach(grp => {
      console.log("DEBUG DUMP", grp.label, grp.getFullName());
      grp.layers.forEach(lay => {
        console.log("DEBUG DUMP", lay.label, lay.getFullName());
      })
    })
  }

  onMaterialAddClick(groupLayer: GroupLayer) {
    if (groupLayer) {
      this.setCurrentLayer(groupLayer)
      this.selectMaterial(this.currentGroupLayer);
    } else {
      this.materialsVisible = true;
    }
  }

  onMaterialToAllGroupsClick(event) {
    this.setCurrentLayer(null);
    this.selectMaterial();
  }

  onLayerChange(layer:GroupLayer) {
    this.setCurrentLayer(layer);
  }

  onCommentTabClick() {
    this.comments.reload();
  }

  onPinModified(comment) {
  }

  onPresetsTabClick() {
    
  }

  selectMaterial(groupLayer?:GroupLayer) {
    let materials = this.scene.getMaterials().materials;
    materials.materials.forEach(material => {
      material.checked = false;
      if (groupLayer && groupLayer.isMaterialInList(material)) {
        material.checked = true;
      }
    })
    this.materialsVisible = true;
  }

  setCurrentLayer(layer:GroupLayer) {
    if (layer) {
      if (layer.parent.type == 'unified') {
        this.currentGroupLayer = layer.parent.layers[0];
        this.groups.currentGroupLayer = this.currentGroupLayer;
        return;
      }
    }
    this.currentGroupLayer = layer;
    this.groups.currentGroupLayer = this.currentGroupLayer;
  }

  setCurrentGroup(group:GeometryGroup) {
    this.currentGroup = group;
  }

  refreshSimpleMenu() {
    this.simpleMenuContent = {
      data: this.scene.geometryGroups
    }
  }

  refreshHotspots() {
    this.hotspots.refresh();
  }


  refreshGeometryGroups() {
    this.geometryGroupsContent = {
      thumbnails:this.thumbnails,
      groups:this.scene.geometryGroups
    }
  }


  onMaterialsSelectOkClick() {
    this.materialsVisible = false;

    if (!this.scene.materialsSelectCB) {
      if (this.currentGroupLayer) {
        console.log("ASSIGN1", this.scene.getMaterials().materials)
        if (this.currentGroupLayer.importMaterials(this.scene.getMaterials().materials)) {
          this.scene.assign(this.currentGroupLayer);
          this.viewer.render();
        }
      } else if (this.currentGroup) {
        console.log("ASSIGN2", this.scene.getMaterials().materials)
        this.currentGroup.importMaterials(this.scene.getMaterials().materials, false);
        this.currentGroup.assign();
      } else {
        console.log("ASSIGN3", this.scene.getMaterials().materials)
        this.scene.getGeometryGroups().importMaterials(this.scene.getMaterials().materials, false)
        this.scene.getGeometryGroups().assign();
      }
      this.geometryGroupsContent = {
        groups: this.geometryGroupsContent.groups,
        thumbnails: this.geometryGroupsContent.thumbnails
      }
      this.refreshSimpleMenu();
      this.setModified()
      this.viewer.render();
    } else {
      this.scene.materialsSelectCB();
    }
  }


  onMaterialsSelectCancelClick() {
    this.materialsVisible = false;
  }

  onLayerMaterialClick(layer) {
    this.scene.assign(layer);
    this.viewer.render();
    this.refreshSimpleMenu();
  }

  onLayerMaterialDblClick(layer) {
    this.scene.assign(layer);
    this.viewer.render();
    this.refreshSimpleMenu();
    this.setModified()
  }

  onRefreshAllGroups(event) {
    this.refreshAllGroups();
  }

  refreshAllGroups() {
    this.viewer.render();
    this.refreshSimpleMenu();
    this.setModified()
  }

  onMeshSelect(item:GroupLayer) {
    // console.log(this.currentGroupLayer);
    console.log(item.getFullName());
    
    
    let now = new Date();
    this.setCurrentLayer(item);
    this.groups.currentGroupLayer = this.currentGroupLayer;
    this.groups.selected = [this.currentGroupLayer]
    // console.log(now.getTime() - this.lastClick.getTime())
    if (document.getElementById("gg_" + item.getElementIDName())) {
      document.getElementById("gg_" + item.getElementIDName()).scrollIntoView();
    }
    if (document.getElementById("sm_" + item.getElementIDName())) {
      document.getElementById("sm_" + item.getElementIDName()).scrollIntoView();
    }

    // DBLCLICK
    if (now.getTime() - this.lastClick.getTime() < 750) {
      let ev:any = window.event
      if (!ev.ctrlKey) {
        this.selectMaterial(this.currentGroupLayer)
      } else {
        item.v = false;
      }
    }
    this.lastClick = now;
    console.log(item)
  }

  onHotspotClick(hs:Hotspot) {
    this.onMeshSelect(hs.getLayer());
  }

  onGeometryGroupsModification() {
    this.viewer.render();
    this.refreshSimpleMenu();
    this.hotspots.selectLayer(this.currentGroupLayer);
    this.refreshHotspots();
    this.setModified()
  }

  onTagsModification(event) {
    this.setModified()
  }

  onShaderReplacementModification(event) {
    this.setModified()
  }

  onModification(event) {
    this.setModified()
  }

  onSimpleMenuModification() {
    this.viewer.render();
    this.refreshGeometryGroups();
    this.hotspots.selectLayer(this.currentGroupLayer);
    this.refreshHotspots();
    this.setModified()
  }

  onLabelsClick() {
    this.labelsVisible = true;
  }

  onLabelsEditorCancelClick() {
    this.labelsVisible = false;
  }

  onLabelsEditorOkClick() {
    this.labelsVisible = false;
    this.setModified()
  }

  setLabelsData() {
    this.labelsData = [];

    this.scene.geometryGroups.groups.forEach(gg => {
      this.labelsData.push({
        level:0,
        item: gg
      })

      gg.layers.forEach(layer => {
        this.labelsData.push({
          level:1,
          item: layer
        })
      })
    })

    return this.labelsData;
  }


  onBackgroundChange(environment:Environment) {
    console.log(environment);

    this.scene.setCurrentEnvironment(environment);
    this.applyBackground();
    this.setModified()
  }

  onSpecularChange(environment:Environment) {
    this.scene.setCurrentEnvironment(environment);
    this.applySpecular();
    this.setModified()
  }

  setModified() {
    this.modified = true;
  }

  onSaveClick() {
    this.save();
  }

  onGroupAdd() {

  }

  onCurrentLayerChange(layer:GroupLayer) {
    this.setCurrentLayer(layer);
    this.refreshSimpleMenu();
    this.hotspots.selectLayer(this.currentGroupLayer);
    this.refreshHotspots();
    this.hotspots.show();
  }

  onMaterialToGroupClick(group:GeometryGroup) {
    this.setCurrentLayer(null);
    this.setCurrentGroup(group);
    this.selectMaterial();
  }

  applyBackground() {
    console.log("applyBG");
    
    if (!this.scene.getCurrentEnvironment()) {
      return
    }
    if (this.scene.getCurrentEnvironment().backgroundType !== "skybox") {
      console.log("apply background nonskybox");
      console.log(this.scene.getCurrentEnvironment());
      
      this.viewer.setBackgroundTransparent();
    } else {
      console.log("apply background skybox");
      this.viewer.setBackgroundSkybox(this.scene.getCurrentEnvironment().backgroundSkyboxURL, this.scene.getCurrentEnvironment().backgroundSkyboxBlur);
    }
  }

  applySpecular() {
    if (!this.scene.getCurrentEnvironment()) {
      return
    }
    console.log("SET LIGHT INTENSITY", this.scene.currentEnvironment.lightIntensity);
    
    this.viewer.setSpecular(this.scene.getCurrentEnvironment().lightSkyboxURL, this.scene.getCurrentEnvironment().lightSkyboxBlur, this.scene.currentEnvironment.lightIntensity);
  }

  async importFromOutliner(event) {
    this.setModified();
    if (event.ctrlKey && event.shiftKey) {
      let data = await this.loader.getPreconfigured();
      console.log(data);
      
      await this.scene.importFromPreconfigured(data);
      this.viewer.render();
    }
    if (event.ctrlKey && !event.shiftKey) {
      this.scene.importFromOutlinerFlat();
    }
    if (!event.ctrlKey && !event.shiftKey){
      this.scene.importFromOutliner();
    }
    this.currentGroupLayer = null;
    this.hotspots.register(this.viewer.engine, this.scene.getGeometryGroups());
  }



  // TODO: check on mac
  @HostListener('document:keydown', ['$event'])
  onKeyDown($event) {
    let charCode = String.fromCharCode($event.which).toLowerCase();
    console.log(charCode);
    console.log(event);
    
    
    if ($event.ctrlKey && charCode === 's') {
      $event.preventDefault();
      console.log('Ctrl + S pressed');
      this.save();
    }
    if ($event.ctrlKey && $event.shiftKey && charCode === 'a') {
      let groups = this.scene.getGeometryGroups();
      groups.showAll();
      this.viewer.render();
    }
    if ($event.ctrlKey && $event.key === ' ') {
      this.rotateCamera();
    }
    if ($event.ctrlKey && $event.key === 'ArrowLeft') {
      this.onCameraLeftClick();
    }
    if ($event.ctrlKey && $event.key === 'ArrowRight') {
      this.onCameraRightClick();
    }
  }



  public onSidebarShow($event) {
    this.sidebarVisible=true;
    setTimeout(() => {
      this.viewer.onResize($event)
    },10)
  }

  public onSidebarHide($event) {
    this.sidebarVisible=false;
    setTimeout(() => {
      this.viewer.onResize($event)
    },10)
  }

  onClrMaterialsOnAllGroupsClick() {
    this.scene.getGeometryGroups().clearMaterials();
  }

  onClrMaterialsOnGroupClick(group:GeometryGroup) {
    group.clearMaterials();
  }

  onLayerPinSetClick(layer) {
    console.log(layer);
    this.hotspots.pickPosition(layer);
  }

  // onHotspotClick(hotspot:Hotspot) {
  //   this.scene.selectMesh(hotspot.getMesh())
  // }

  onExplosion() {
    this.explosion.onExplosion();
    this.setModified();
  }

  onTagsTabClick() {
    this.activeSidebar = 'tags'
  }

  onCopyClick() {
    let el = this.urlElement.nativeElement;
    console.log(el);
    el.select();
    
    document.execCommand('copy')
    console.log(el.value);
    

  }

  onCaptureClick(data) {
    this.config.screenshot = data;
    this.setModified();
  }

  onTagFullClick() {
    this.tagFullscreen = !this.tagFullscreen;
  }

  onCameraChange() {
    this.setModified();
    this.viewer.engine.disableRender();
    this.viewer.render();
  }

  rotateCamera() {
    console.log("rotate");
    
    this.scene.cameras.nextCamera();
    this.onCameraChange();
  }

  onCameraLeftClick() {
    this.scene.cameras.previousCamera();
    this.onCameraChange();
  }

  onCameraRightClick() {
    this.scene.cameras.nextCamera();
    this.onCameraChange();
  }

  onShaderReplacementClick() {
    
  }

  onMaterialClick(material) {
    console.log(material);
    this.scene.shaderReplacement.apply(material.id, material.material);
    this.viewer.render();
  }

  onFxChange() {
    this.viewer.render();
    this.setModified();
  }

  onSelectAllVisibleInMenu(event) {
    this.refreshSimpleMenu();
  }

  onHideAllHotspots(event) {
    this.refreshHotspots();
  }

  onAdvancedClick(data) {
    console.log(data);
    this.advanced = data;
  }

  onBurgerMenu() {

  }

  onPresetsChange() {
    this.viewer.render();
  }

  onMaterialHover(material) {
    if (material) {
      this.materialLabel = material.label
    } else {
      this.materialLabel = "";
    }
  }

  onRenderingChange() {
    this.modified = true;
  }

  getGeometryGroupsOptions() {
    // return {
    //   filterButton: true,
    //   excIncUniButton: true,
    //   extendedButton: true,
    //   clrButton: true,
    //   plusGroupButton: true,
  
    //   layerCheckboxes: true,
    //   shaders: true,
  
    //   addGroup: true,
    //   setMaterialToAll: true,
    //   clearMaterialsButton: true,
    //   clearLayout: true,
    //   importLayout: true,
    // }
  
    if (this.activeSidebar == "geometry") {
      return {
        filterButton: false,
        excIncUniButton: false,
        extendedButton: false,
        clrButton: true,
        plusGroupButton: true,
    
        layerCheckboxes: false,
        shaders: true,
    
        addGroup: false,
        setMaterialToAll: true,
        clearMaterialsButton: true,
        clearLayout: false,
        importLayout: false,
        groupCheckbox: false,
      }
    }
    if (this.activeSidebar == "menu") {
      return {
        filterButton: true,
        excIncUniButton: true,
        extendedButton: true,
        clrButton: false,
        plusGroupButton: false,
    
        layerCheckboxes: true,
        shaders: false,
    
        addGroup: true,
        setMaterialToAll: false,
        clearMaterialsButton: false,
        clearLayout: true,
        importLayout: true,
        groupCheckbox: true,
      }
    }

    return {
      filterButton: true,
      excIncUniButton: true,
      extendedButton: true,
      clrButton: true,
      plusGroupButton: true,
  
      groupCheckbox: true,
      layerCheckboxes: true,
      shaders: true,
  
      addGroup: true,
      setMaterialToAll: true,
      clearMaterialsButton: true,
      clearLayout: true,
      importLayout: true,
    }
  }

}
