import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-labels-editor',
  templateUrl: './labels-editor.component.html',
  styleUrls: ['./labels-editor.component.css']
})
export class LabelsEditorComponent implements OnInit {
  @Input() data:any = [];

  constructor() { }

  ngOnInit() {
  }

}
