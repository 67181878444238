import { Injectable } from '@angular/core';
import { SimpleKonfiguratorProfileSceneService } from '../scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { Vector3, AbstractMesh, Animation, Quaternion, QuadraticEase, EasingFunction } from 'babylonjs';
import { EngineService } from '../engine/engine.service';

@Injectable({
  providedIn: 'root'
})
export class ExplosionService {

  scene: SimpleKonfiguratorProfileSceneService;

  targets: {
    targetMesh: AbstractMesh,
    from: Vector3,
    to: Vector3
  }[];

  targetCenter: Vector3;
  explosionActive = false;

  constructor(public engineService: EngineService) { }

  init(scene: SimpleKonfiguratorProfileSceneService) {
    this.scene = scene;
  }

  onExplosion() {

    this.engineService.startLoop();

    this.explosionActive = !this.explosionActive;

    if (this.explosionActive) {
      const meshes = this.scene.currentScene.getActiveMeshes();
      this.targets = [];
      // console.log('start explosion');

      const bounds = this.scene.currentScene.getWorldExtends(m => {
        console.log(meshes.data.indexOf(m) > -1);
        return meshes.data.indexOf(m) > -1;
      });

      this.targetCenter = new Vector3(
        (bounds.max.x + bounds.min.x) / 2,
        (bounds.max.y + bounds.min.y) / 2,
        (bounds.max.z + bounds.min.z) / 2
      );
      // console.log('Estimated Center: ', this.targetCenter);
      // console.log('Scene scale: ', this.targetCenter.length());

      meshes.data.forEach(mesh => {
        if (mesh.name !== '__root__') {
          const target = this.calculateTargets(mesh);
          this.scene.currentScene.beginDirectAnimation(
            mesh, [createPositionAnimation(Vector3.Zero(), target.to)],
            0, 100, false, 6
          );

          this.targets.push(target);
        }
      });
    }
    else {
      // console.log('revert explosion', this.targets.length);
      this.targets.forEach(target => {
        this.scene.currentScene.beginDirectAnimation(
          target.targetMesh, [createPositionAnimation(target.to, target.from)],
          0, 100, false, 6
        );
      });
    }

  }

  calculateTargets(mesh: AbstractMesh) {
    const target = {
      targetMesh: mesh,
      from: null,
      to: null
    };
    const meshCenterLocal = mesh.getBoundingInfo().boundingBox.center;
    const centerToMesh = meshCenterLocal.subtract(this.targetCenter).normalize();
    target.from = mesh.position;
    target.to = centerToMesh.scale(this.targetCenter.length() / 2);
    // console.log(mesh.name, target.from, target.to);

    return target;
  }
}

function createPositionAnimation(from: Vector3, to: Vector3): Animation {
  const anim = new Animation('anim', 'position', 30, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE);
  const keys = [{
    frame: 0,
    value: from
  }, {
    frame: 30,
    value: to
  }];
  anim.setKeys(keys);

  const easingFunction = new QuadraticEase();
  easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
  anim.setEasingFunction(easingFunction);

  return anim;
}

