import { Injectable } from '@angular/core';
import { ViewerComponent } from 'src/app/components/viewer/viewer.component';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService {
  viewer: ViewerComponent;
  projectName:any;
  config:any;
  captureUrl:any;

  constructor() { }

  public load(viewer, projectName, config) {
    console.log("screenshot.load");
    this.viewer = viewer
    this.config = config;
    this.projectName = projectName;
    if (config.screenshot) {
      this.captureUrl = config.screenshot
      console.log(config.screenshot);
      
    }
  }

  public capture(cb) {
    console.log("onCaptureClick");
    this.viewer.capture((data) => {
      cb(data);
    });
  }
}
