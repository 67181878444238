import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { GeometryGroups, GroupLayer, GeometryGroup } from '../../services/scene/geometry-groups';
import { DragDropService } from 'src/app/services/dragdrop/drag-drop.service';
import { OptionGroup } from 'src/app/services/scene/option-groups';
import { SimpleKonfiguratorProfileSceneService } from 'src/app/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { OptionGroupsService } from 'src/app/services/optiongroups/option-groups.service';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalEventsActions } from 'src/app/services/scene/simpleprofile/global-events-actions.service';
import { MaterialLinksService } from 'src/app/services/materiallinks/material-links.service';

// TODO
// pri option groups:
// - opcija show only this group
// - collapsible groups

@Component({
  selector: 'app-geometry-groups',
  templateUrl: './geometry-groups.component.html',
  styleUrls: ['./geometry-groups.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeometryGroupsComponent implements OnInit {
  @Input() thumbnails;
  @Input() currentGroupLayer: GroupLayer;
  @Input() currentGroupLayerTo: GroupLayer;
  @Input() materialLabel: string = "";
  @Input() visible: boolean = false;

  @Input() options = {
    filterButton: true,
    excIncUniButton: true,
    extendedButton: true,
    clrButton: true,
    plusGroupButton: true,

    groupCheckbox: true,
    layerCheckboxes: true,
    shaders: true,

    addGroup: true,
    setMaterialToAll: true,
    clearMaterialsButton: true,
    clearLayout: true,
    importLayout: true,
  }

  selected: GroupLayer[] = [];
  optionGroupExpanded: boolean = false;
  showFullLongNames: boolean = false;
  showMaterialsInGroups: boolean = false;

  _groups: GeometryGroups;
  // dropLists:any[] = [];
  layersMaterialDropMap: any = {};
  layersTD1DropMap: any = {};

  lastId: number = 0;

  showShaders: boolean = true;
  showLayers: boolean = false;
  showOther: boolean = false;

  drag: boolean = false;
  dragGroup: GeometryGroup = null;
  dragLayer: GroupLayer = null;
  dragOption: OptionGroup = null;
  dragOverItem: any = null;

  currentEditingItem: any = null;
  currentEditingValue: string;


  // public materialLinkLabels = [
  //   { label: '---', value: null},
  //   { label: 'I', value: 'I'},
  //   { label: 'II', value: 'II'},
  //   { label: 'III', value: 'III'},
  //   { label: 'IV', value: 'IV'},
  //   { label: 'V', value: 'V'},
  //   { label: 'VI', value: 'VI'},
  //   { label: 'VII', value: 'VII'},
  //   { label: 'VIII', value: 'VIII'},
  //   { label: 'IX', value: 'IX'},
  //   { label: 'X', value: 'X'},
  //   { label: 'XI', value: 'XI'},
  //   { label: 'XII', value: 'XII'},
  //   { label: 'XIII', value: 'XIII'},
  //   { label: 'XIV', value: 'XIV'},
  //   { label: 'XV', value: 'XV'},
  //   { label: 'XVI', value: 'XVI'},
  //   { label: 'XVII', value: 'XVII'},
  //   { label: 'XVIII', value: 'XVIII'},
  //   { label: 'XIX', value: 'XIX'},
  //   { label: 'XX', value: 'XX'},
  // ]



  @Output() groupAdd: EventEmitter<any> = new EventEmitter();
  @Output() layerAdd: EventEmitter<any> = new EventEmitter();
  @Output() materialAddClick: EventEmitter<any> = new EventEmitter();
  @Output() materialToAllGroupsClick: EventEmitter<any> = new EventEmitter();
  @Output() materialToGroupClick: EventEmitter<GeometryGroup> = new EventEmitter();
  @Output() clrMaterialsOnAllGroupsClick: EventEmitter<GeometryGroup> = new EventEmitter();
  @Output() clrMaterialsOnGroupClick: EventEmitter<GeometryGroup> = new EventEmitter();
  @Output() refreshAllGroups: EventEmitter<any> = new EventEmitter();
  @Output() layerMaterialClick: EventEmitter<any> = new EventEmitter();
  @Output() layerMaterialDblClick: EventEmitter<any> = new EventEmitter();
  @Output() labelsClick: EventEmitter<any> = new EventEmitter();
  @Output() layerPinSetClick: EventEmitter<any> = new EventEmitter();
  @Output() matLabel: EventEmitter<any> = new EventEmitter();

  @Output() modification: EventEmitter<any> = new EventEmitter();
  @Output() currentLayerChange: EventEmitter<any> = new EventEmitter();
  @Output() import: EventEmitter<any> = new EventEmitter();
  @Output() selectAllVisibleInMenu = new EventEmitter<any>();
  @Output() hideAllHotspots = new EventEmitter<any>();

  @ViewChild("editLabel1", { static: false }) editLabel1: HTMLInputElement;
  @ViewChild("editLabel2", { static: false }) editLabel2: HTMLInputElement;


  constructor(
    public dragdrop: DragDropService,
    public scene: SimpleKonfiguratorProfileSceneService,
    public optionGroupService: OptionGroupsService,
    public sanitization: DomSanitizer,
    public globals: GlobalEventsActions,
    public maService: MaterialLinksService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  get groups() {
    return this._groups;
  }

  @Input("groups")
  set groups(groups: GeometryGroups) {
    this._groups = groups;
  }

  registerGroupsForDND() {
    this.layersMaterialDropMap = {};
    this.layersTD1DropMap = {};
    this._groups.groups.forEach(group => {
      group.layers.forEach(layer => {
        this.layersMaterialDropMap["drop" + layer.id] = layer;
      })
      this.layersTD1DropMap["dropTD1" + group.id] = group;
    })
    this.dragdrop.init(this.modification, this._groups);
  }

  @Input()
  set content(content: any) {
    this.groups = content.groups;
    this.thumbnails = content.thumbnails;
  }

  getLayersMaterialId(layer: GroupLayer): string {
    return "drop" + layer.id;
  }

  getLayersMaterialIds() {
    return Object.keys(this.layersMaterialDropMap);
  }

  getLayersTD1Id(group: GeometryGroup): string {
    return "dropTD" + group.id;
  }

  getLayersTD1Ids() {
    return Object.keys(this.layersTD1DropMap);
  }

  onGroupAddClick() {
    this._groups.add(null)
    // this.groupAdd.emit();
    this.modification.emit();
    this.registerGroupsForDND();
  }

  onLayerAddClick(group: any) {
    this.layerAdd.emit(group);
  }

  onChange(data) {
    this.registerGroupsForDND();
    setTimeout(() => {
      this.groups.refreshTags();
      this.modification.emit();
    }, 10)
  }

  onGroupChange(data) {
    setTimeout(() => {
      this.modification.emit();
    }, 10)
  }

  onMaterialAddClick(layer) {
    console.log("onMaterialAddClick");

    this.materialAddClick.emit(layer);
  }

  onMaterialClick(material, layer: GroupLayer) {
    let ev: any = window.event;
    if (ev.shiftKey && !ev.ctrlKey) {
      let grp = layer.parent;
      grp.layers.forEach(l => {
        l.setCurrentMaterial(material);
      })
      this.groups.assign();
      this.refreshAllGroups.emit(layer);
    }
    if (ev.shiftKey && ev.ctrlKey) {
      this.groups.groups.forEach(grp => {
        grp.layers.forEach(l => {
          l.setCurrentMaterial(material);
        })
      });
      this.groups.assign();
      this.refreshAllGroups.emit(layer);
    }
    if (ev.ctrlKey) {
    }
    if (!ev.shiftKey && !ev.ctrlKey) {
      layer.setCurrentMaterial(material);
      this.layerMaterialClick.emit(layer);
      this.groups.assign();
      this.refreshAllGroups.emit(layer);
    }
    this.maService.setCurrent(material, layer.materialLink);
  }

  onMaterialDblClick(material, layer: GroupLayer) {
    let ev: any = window.event;
    if (!ev.ctrlKey && ev.shiftKey) {
      let grp = layer.parent;
      grp.layers.forEach(l => {
        l.setPrimaryMaterial(material);
      })
      this.groups.assign();
      this.refreshAllGroups.emit(layer);
    }
    if (ev.ctrlKey && ev.shiftKey) {
      this.groups.groups.forEach(grp => {
        grp.layers.forEach(l => {
          l.setPrimaryMaterial(material);
        })
      });
      this.groups.assign();
      this.refreshAllGroups.emit(layer);
    }
    if (ev.ctrlKey && !ev.shiftKey) {
      if (layer.materials.materials.length > 0) {
        layer.removeMaterial(material);
        if (layer.materials.materials.length > 0) {
          layer.fixMaterials();
        }
        this.modification.emit();
      }
    }
    if (!ev.ctrlKey && !ev.shiftKey) {
      layer.setPrimaryMaterial(material);
      this.layerMaterialDblClick.emit(layer);
      this.groups.assign();
      this.refreshAllGroups.emit(layer);
    }
    this.maService.setPrimary(material, layer.materialLink);
  }

  onGroupTypeClick(group: GeometryGroup, type) {
    group.setType(type);
    this.modification.emit();
  }



  onImportClick(event) {
    this.import.emit(event);
    this.registerGroupsForDND();
  }

  onClearClick() {
    this.groups.clear()
  }

  onLabelsClick() {
    this.labelsClick.emit();
  }

  onShadersClick() {
    this.showShaders = true;
    this.showLayers = false;
    this.showOther = false;
  }

  onLayersClick() {
    this.showShaders = false;
    this.showLayers = true;
    this.showOther = false;
  }

  onOtherClick() {
    this.showShaders = false;
    this.showLayers = false;
    this.showOther = true;
  }

  onEditingKeyDown($event) {
    let keyboardEvent = <KeyboardEvent>event;
    if (keyboardEvent.keyCode == 13) {
      this.currentEditingItem = null;
      this.modification.emit();
    }
  }

  onEditingStart1($event, target) {
    this.editLabel1 = <HTMLInputElement>document.getElementById('editLabel1')
    this.currentEditingItem = target;
  }

  onEditingStart2($event, target) {
    this.currentEditingItem = target;
  }

  onTd1MouseDown($event, layer: GroupLayer) {

    this.dragLayer = layer;
    this.selected = [layer];
  }

  onTd1GroupMouseDown($event, group: GeometryGroup) {
    this.dragGroup = group;
    this.dragLayer = null;
  }

  onTd1MouseUp($event, group: GeometryGroup, layer: GroupLayer) {


    if (this.dragLayer) {
      let _group: GeometryGroup = this.dragLayer.parent;
      if (group == _group) {
        let istart = this.dragLayer.parent.layers.indexOf(this.dragLayer);
        let idest = this.dragLayer.parent.layers.indexOf(layer);
        if (idest !== istart) {
          this.dragLayer.parent.layers.splice(istart, 1);
          this.dragLayer.parent.layers.splice(idest, 0, this.dragLayer);
          this.modification.emit();
        }
      } else {
        this.selected.forEach(layer => {
          layer.moveTo(group);
        })
        // this.selected = [];
        // this.dragLayer.moveTo(group);
        this.modification.emit();
      }
      this.dragLayer = null;
    }
    if (this.dragGroup) {
      if (this.dragGroup == group) {
      } else {
        let istart = this.dragGroup.parent.groups.indexOf(this.dragGroup);
        let idest = this.dragGroup.parent.groups.indexOf(group);
        if (idest !== istart) {
          this.dragGroup.parent.groups.splice(istart, 1);
          this.dragGroup.parent.groups.splice(idest, 0, this.dragGroup);
          this.modification.emit();
        }
      }
      this.dragGroup = null;
    }
  }

  onTd1GroupMouseUp($event, group: GeometryGroup) {


    if (this.dragLayer) {
      let _group: GeometryGroup = this.dragLayer.parent;
      if (group == _group) {
      } else {
        // this.dragLayer.moveTo(group);
        this.selected.forEach(layer => {
          layer.moveTo(group);
        });
      }
      this.dragLayer = null;
    }
    if (this.dragGroup) {
      if (this.dragGroup == group) {
      } else {
        let istart = this.dragGroup.parent.groups.indexOf(this.dragGroup);
        let idest = this.dragGroup.parent.groups.indexOf(group);
        if (idest !== istart) {
          this.dragGroup.parent.groups.splice(istart, 1);
          this.dragGroup.parent.groups.splice(idest, 0, this.dragGroup);
          this.modification.emit();
        }
      }
      this.dragGroup = null;
    }
  }

  onSetMaterialToAllGroupsClick() {
    this.materialToAllGroupsClick.emit();
  }

  onClrMaterialsOnAllGroupsClick() {
    this.clrMaterialsOnAllGroupsClick.emit();
  }

  onClrMaterialsOnGroupClick(group: GeometryGroup) {
    this.clrMaterialsOnGroupClick.emit(group);
  }

  onLayerClick(layer: GroupLayer, event) {
    if (!this.selected) {
      return false;
    }
    if (!event.shiftKey) {
      this.currentGroupLayer = layer;
      this.currentGroupLayerTo = layer;
      this.currentLayerChange.emit(layer);
      this.selected = [layer];
    } else {
      let add = false;
      this.currentGroupLayerTo = layer;
      this.selected = [];
      this.groups.groups.forEach(grp => {
        grp.layers.forEach(layer => {
          if (layer == this.currentGroupLayer) {
            add = true;
          }
          if (add) {
            this.selected.push(layer);
          }
          if (layer == this.currentGroupLayerTo) {
            add = false;
          }
        });
      })
    }
  }

  isSelected(layer: GroupLayer) {
    return (this.selected.indexOf(layer) >= 0);
  }

  onGroupMaterialsClick(group: GeometryGroup) {
    this.materialToGroupClick.emit(group);
  }

  onLayerPinSetClick(layer) {
    this.layerPinSetClick.emit(layer);
  }

  onFilterClick(group: GeometryGroup) {
    console.log("onFilterClick:");
    console.log(group);

    group.filterByTags = !group.filterByTags;
    this.groups.refreshTags()
  }

  onSelectAllVisibleInMenuGroupClick(group: GeometryGroup) {
    group.layers.forEach((layer: GroupLayer) => {
      layer.visibleInMenu = true;
    })
    this.selectAllVisibleInMenu.emit();
  }

  onSelectAllVisibleGroupClick(group: GeometryGroup) {
    group.layers.forEach((layer: GroupLayer) => {
      layer.v = true;
    })
    this.selectAllVisibleInMenu.emit();
  }

  onHideAllHotspotsClick(group) {
    group.layers.forEach((layer: GroupLayer) => {
      layer.hotspot.visible = false;
    })
    this.hideAllHotspots.emit();
  }

  public onGroupLayerLinkClick(layer: GroupLayer, link) {
    layer.materialLink = link;
    layer.materials.materials = [...this.maService.materialLinkAssignments.filter(ma => ma).find(ma => ma.link == link).materials];
    // this.currentGroupLayer.parent.parent.refreshMaterialLinkMap();
    layer.parent.parent.refreshMaterialLinkMap();
    this.modification.emit();
    this.globals.events.materials.links.assigned$.next(layer);
  }

  public onOptionsMouseUp() {
    this.dragGroup = null;
    this.dragLayer = null;
  }

  public onCheckChange(data) {
    console.log(data);
    this.modification.emit();
    
  }

  onOptionGroupExpandClick() {
    this.optionGroupExpanded = !this.optionGroupExpanded;
  }

}
