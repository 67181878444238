import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { TestComponent } from './pages/test/test.component';
import { AppRoutingModule } from './app-routing.module';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { ColorPickerModule } from 'ngx-color-picker';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
registerPlugin(FilePondPluginFileValidateType);
import { Ng5SliderModule } from 'ng5-slider';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { Test2Component } from './pages/test2/test2.component';
import { ViewerComponent } from './components/viewer/viewer.component';
import { RtableComponent } from './components/ui/rtable/rtable.component';
import { MaterialGroupsComponent } from './components/material-groups/material-groups.component';
import { SimpleKonfiguratorProfileComponent } from './pages/simple-konfigurator-profile/simple-konfigurator-profile.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { GeometryGroupsComponent } from './components/geometry-groups/geometry-groups.component';
import { CheckBoxComponent } from './components/ui/check-box/check-box.component';
import { TreeComponent } from './components/ui/tree/tree.component';
import { MaterialsSelectComponent } from './components/materials-select/materials-select.component';
import { SimpleComponent } from './components/menus/simple/simple.component';
import { TexturePreviewComponent } from './components/texture-preview/texture-preview.component';
import { LabelsEditorComponent } from './components/labels-editor/labels-editor.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectPropertiesComponent } from './components/project-properties/project-properties.component';
import { Styling } from './components/styling/styling.component';
import { AutoFocusDirective } from './directives/autofocus/auto-focus.directive';
import { CommentsComponent } from './components/comments/comments/comments.component';
import { LoadingComponent } from './components/loading/loading.component';
// import { KeycloakService } from 'keycloak-angular';
import { TagsComponent } from './components/tags/tags/tags.component';
import { MaterialBuilderComponent } from './components/material-builder/material-builder.component';
import { BgLightComponent } from './components/bg-light/bg-light.component';
import { FxComponent } from './components/fx/fx.component';
import { ScreenshotComponent } from './components/screenshot/screenshot/screenshot.component';
import { CamerasComponent } from './components/cameras/cameras.component';
import { ShaderReplacementsComponent } from './components/shader-replacements/shader-replacements.component';
import { UserListComponent } from './components/acl/user-list/user-list.component';
import { UsersListComponent } from './pages/admin/users-list/users-list.component';
import { OrganizationsListComponent } from './pages/admin/organizations-list/organizations-list.component';
import { UsersEditComponent } from './pages/admin/users-edit/users-edit.component';
import { OrganizationsEditComponent } from './pages/admin/organizations-edit/organizations-edit.component';
import { RecordingComponent } from './components/recording/recording.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { EnvironmentsComponent } from './components/uploaders/environments/environments.component';
import { PresetsComponent } from './components/presets/presets.component';
import { RenderingComponent } from './components/rendering/rendering/rendering.component';
import { ViewFinderComponent } from './components/view-finder/view-finder.component';
import { TexturePreviewGeneratorComponent } from './components/texture-preview-generator/texture-preview-generator.component';
import { OrganizationalMaterialsComponent } from './components/materials/organizational-materials/organizational-materials.component';
import { GlobalMaterialsComponent } from './components/materials/global-materials/global-materials.component';
import { DropdownsComponent } from './components/materials/dropdowns/dropdowns.component';
import { OptionGroupsComponent } from './components/option-groups/option-groups.component';
import { AdvancedSubMenuComponent } from './components/menus/advanced-sub-menu/advanced-sub-menu.component';
import { HelpComponent } from './pages/help/help.component';
import { MaterialRelationsComponent } from './components/material-relations/material-relations.component';
import { GeometryRelationsComponent } from './components/geometry-relations/geometry-relations.component';
import { MaterialRelationsSectionComponent } from './components/material-relations/material-relations-section/material-relations-section.component';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';
import { MaterialStripComponent } from './components/geometry-groups/material-strip/material-strip.component';
import { RelationsMaterialGeometryComponent } from './components/relations-material-geometry/relations-material-geometry.component';
import { MaterialLinkEditorComponent } from './components/option-groups/material-link-editor/material-link-editor.component';
import { MaterialLinksService } from './services/materiallinks/material-links.service';
import { GlobalEventsActions } from './services/scene/simpleprofile/global-events-actions.service';
import { ProcessingModule } from './modules/processing/processing.module';
import { ProcessingService } from './modules/processing/processing.service';
import { CommonModule } from '@angular/common';
import { MaterialEditModule } from 'external/rtc-3dk-v2-module/modules/material-edit/material-edit.module';
import { MaterialsService } from 'external/rtc-3dk-v2-module/services/materials/materials.service';
import { MaterialSettings } from 'external/rtc-3dk-v2-module/services/materials/material-settings';
import { ExporterViewComponent } from './pages/exporter-view/exporter-view.component';


@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    Test2Component,
    ViewerComponent,
    RtableComponent,
    MaterialGroupsComponent,
    SimpleKonfiguratorProfileComponent,
    MainMenuComponent,
    GeometryGroupsComponent,
    CheckBoxComponent,
    TreeComponent,
    MaterialsSelectComponent,
    SimpleComponent,
    TexturePreviewComponent,
    LabelsEditorComponent,
    ProjectsComponent,
    ProjectPropertiesComponent,
    Styling,
    AutoFocusDirective,
    CommentsComponent,
    LoadingComponent,
    TagsComponent,
    MaterialBuilderComponent,
    BgLightComponent,
    FxComponent,
    ScreenshotComponent,
    CamerasComponent,
    ShaderReplacementsComponent,
    UserListComponent,
    UsersListComponent,
    OrganizationsListComponent,
    UsersEditComponent,
    OrganizationsEditComponent,
    RecordingComponent,
    UploaderComponent,
    EnvironmentsComponent,
    PresetsComponent,
    RenderingComponent,
    ViewFinderComponent,
    TexturePreviewGeneratorComponent,
    OrganizationalMaterialsComponent,
    GlobalMaterialsComponent,
    DropdownsComponent,
    OptionGroupsComponent,
    AdvancedSubMenuComponent,
    HelpComponent,
    MaterialRelationsComponent,
    GeometryRelationsComponent,
    MaterialRelationsSectionComponent,
    ImagePopupComponent,
    MaterialStripComponent,
    RelationsMaterialGeometryComponent,
    MaterialLinkEditorComponent,
    ExporterViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ClarityModule,
    BrowserAnimationsModule,
    DragDropModule,
    HttpClientModule,
    ColorPickerModule,
    FilePondModule,
    Ng5SliderModule,
    NgMultiSelectDropDownModule.forRoot(),
    ProcessingModule,
    CommonModule,
    MaterialEditModule
  ],
  providers: [
    GlobalEventsActions,
    MaterialLinksService,
    ProcessingService,
    MaterialsService,
    MaterialSettings,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
