import { Injectable } from '@angular/core';
import { Project } from './projects.service';
import { HttpClient } from '@angular/common/http';
import { CollectionsService } from '../collections/collections.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarListService {
  public sidebarlist: SidebarOrganization[];
  public projects: Project[];
  projectsMap: any;
  sidebarOrgMap: any;

  public openedOrganization: SidebarOrganization = null;
  public openedCollection: SidebarCollection = null;
  public viewAllProjects: boolean = false;
  public visibleItems: any;
  public showArchived = false;
  public newCollectionItem: VisibleItem;

  public dragItem: VisibleItem;
  public newCollectionText: string;

  public search: string = "";

  constructor(
    public collections: CollectionsService
  ) { }

  public async init(projects: Project[]) {
    this.projects = projects;
    this.projects.sort((a, b) => {
      if (a.path < b.path) {
        return -1;
      }
      if (a.path == b.path) {
        return 0;
      }
      if (a.path > b.path) {
        return 1;
      }
    })
    await this.reload();
  }

  public async  reload() {
    let orgs = [];
    let promises = [];
    this.sidebarlist = [];
    this.sidebarOrgMap = {};
    this.projectsMap = {};
    console.log("SIDEBAR:");
    console.log(this.projects);

    this.projects.forEach(prj => {
      this.projectsMap[prj.path] = prj;
      let org = prj.path.split("/")[0];
      if (orgs.indexOf(org) < 0) {
        promises.push(this.collections.getList(org));
        orgs.push(org);
        const sidebarOrganization = new SidebarOrganization();
        sidebarOrganization.label = org;
        this.sidebarlist.push(sidebarOrganization);
        this.sidebarOrgMap[org] = sidebarOrganization;
      }
    })
    console.log(orgs);
    let data = await Promise.all(promises);
    console.log(data);
    for (let i = 0; i < orgs.length; i++) {
      let orgname = orgs[i];
      let orgdata = data[i];
      let sidebarOrg = this.sidebarOrgMap[orgname];
      this.loadCollection(sidebarOrg, orgdata);
      // sidebarOrg.collections = [];
      // orgdata.forEach(collection => {
      //   let sidebarCollection = new SidebarCollection();
      //   sidebarCollection.label = collection.label;
      //   sidebarCollection.parent = sidebarOrg;
      //   sidebarCollection.projects = [];
      //   sidebarOrg.collections.push(sidebarCollection);
      //   collection.projects.forEach(path => {
      //     console.log(this.projectsMap[path]);

      //     sidebarCollection.projects.push(this.projectsMap[path]);
      //   })
      // })
    }

    this.refresh();
  }

  public loadCollection(sidebarOrg, orgdata) {
    sidebarOrg.collections = []
    orgdata.forEach(collection => {
      let sidebarCollection = new SidebarCollection();
      sidebarCollection.label = collection.label;
      sidebarCollection.path = collection.path;
      sidebarCollection.parent = sidebarOrg;
      sidebarCollection.projects = [];
      sidebarOrg.collections.push(sidebarCollection);
      collection.projects.forEach(path => {
        console.log(this.projectsMap[path]);

        sidebarCollection.projects.push(this.projectsMap[path]);
      })
    })
    // console.log(sidebarOrg.collections);

  }

  public refresh() {
    if (this.search != "") {
      this.refreshFiltered();
    } else {
      this.refreshUnfiltered();
    }
  }

  public refreshFiltered() {
    console.log("REFRESH FILTERED");

    this.visibleItems = [];
    this.sidebarlist.forEach(sidebarOrganization => {
      let item = new VisibleItem();
      item.label = sidebarOrganization.label
      item.organization = sidebarOrganization
      item.collection = null;
      item.project = null;
      item.type = "organization";
      this.visibleItems.push(item);
      sidebarOrganization.collections.forEach(sidebarCollection => {
        this.projects.forEach(prj => {
          console.log(prj.organization, sidebarOrganization.label);

          if (prj.organization != sidebarOrganization.label) {
            return;
          }
          if (this.search != "") {
            let searchItems = this.search.toLowerCase().split(" ");

            let add = true;
            searchItems.forEach(item => {
              if (prj.path.toLowerCase().indexOf(item) < 0) {
                add = false;
              }
            })
            if (!add) {
              return;
            }
          }
          console.log(prj);

          let item = new VisibleItem();
          item.label = prj.name;
          item.type = "project";
          item.organization = sidebarOrganization;
          item.collection = sidebarCollection;
          item.project = prj;
          this.visibleItems.push(item);
        });
      });
    });
  }

  public refreshUnfiltered() {
    console.log("REFRESH UNFILTERED");
    
    this.visibleItems = [];
    this.sidebarlist.forEach(sidebarOrganization => {
      let item = new VisibleItem();
      item.label = sidebarOrganization.label
      item.organization = sidebarOrganization
      item.collection = null;
      item.project = null;
      item.type = "organization";
      this.visibleItems.push(item);
      if ((this.openedOrganization && (this.openedOrganization.label == sidebarOrganization.label)) || (this.search != "")) {
        sidebarOrganization.collections.forEach(sidebarCollection => {
          let item = new VisibleItem();
          item.label = sidebarCollection.label
          item.type = "collection";
          item.organization = sidebarOrganization;
          item.collection = sidebarCollection;
          item.project = null;
          this.visibleItems.push(item);
          if ((this.openedCollection && (this.openedCollection.label == sidebarCollection.label)) || (this.search != "")) {
            sidebarCollection.projects.forEach(prj => {
              if (this.search != "") {
                let searchItems = this.search.split(" ");
                let add = true;
                searchItems.forEach(item => {
                  if (prj.path.indexOf(item) < 0) {
                    add = false;
                  }
                })
                if (!add) {
                  return;
                }
              }
              console.log(prj);

              let item = new VisibleItem();
              item.label = prj.name;
              item.type = "project";
              item.organization = sidebarOrganization;
              item.collection = sidebarCollection;
              item.project = prj;
              this.visibleItems.push(item);
            });
          }
        });
        let itemAll = new VisibleItem();
        itemAll.label = "all"
        itemAll.type = "collectionall";
        itemAll.collection = null;
        this.visibleItems.push(itemAll);
        if (this.viewAllProjects) {
          console.log(this.viewAllProjects);

          this.projects.forEach(prj => {

            console.log(prj.organization, sidebarOrganization.label, prj.archived);

            if ((prj.organization == sidebarOrganization.label)) {
              if (!this.showArchived && ((prj.archived))) {
                return;
              }
              let item = new VisibleItem();
              item.label = prj.name;
              item.type = "project";
              item.project = prj;
              item.organization = sidebarOrganization;
              item.collection = null;
              this.visibleItems.push(item);
              console.log(item);

            }
          })
        }
      }
    });
    console.log(this.visibleItems);

  }

  public selectItem(item: VisibleItem) {
    switch (item.type) {
      case "organization":
        this.openedOrganization = item.organization;
        this.openedCollection = null;
        this.viewAllProjects = true;
        break;
      case "collection":
        this.openedCollection = item.collection;
        this.viewAllProjects = false;
        break;
      case "collectionall":
        console.log("collectionall");

        this.openedCollection = null;
        this.viewAllProjects = true;
        break;
    }
    this.refresh();
  }
}


export class SidebarOrganization {
  public label: string;
  public collections: SidebarCollection[];
}

export class SidebarCollection {
  public label: string;
  public parent: SidebarOrganization;
  public path: string;
  public projects: any[];
}

// export class SidebarProject {
//   public label: string;
//   public path: string;
// }

export class VisibleItem {
  public label: string;
  public type: string;
  public organization: SidebarOrganization;
  public collection: SidebarCollection;
  public project: any;
}