import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaterialSettings } from "external/rtc-3dk-v2-module/services/materials/material-settings";
// import { Materials } from "external/rtc-3dk-v2-module/services/scene/materials";
// import { SimpleKonfiguratorProfileSceneService } from "external/rtc-3dk-v2-module/services/scene/simpleprofile/simple-konfigurator-profile-scene.service";

@Injectable()
export class MaterialEditService {
  public scene: any | undefined;
  public materials: any | undefined;

  projectPath: string = '';

  map = new Map<string, any>();

  constructor(
    private http: HttpClient,
    private settings: MaterialSettings,
  ) {

  }

  public async init(projectPath: string, materials: string[], scenes: any) {
    console.log("BMAT INIT", projectPath, materials);
    this.setProjectPath(projectPath)

    // const promises = materials.map(mat => async () => {return await this.load(mat)});
    const promises: Promise<{name: string, data: any} | null>[] = [];

    for (let mat of materials) {
      const matNameTokens = mat.split("/")
      const matName = matNameTokens[matNameTokens.length-1].split(".")[0].split("_").slice(0, -1).join("_");
      if (!matName) {
        return;
      }
      promises.push(new Promise((res, rej) => {
        console.log("XMATX", matName);
        this.load(matName).then(data => {
          res({
            name: matName,
            data: data
          });
        }).catch(err => {
          res(null);
        })
      }));
    };

    console.log("BMAT promises", promises);
    const data = await Promise.all(promises);
    console.log("BMAT RESULT", data);
    console.log("SCENES", scenes);

    // TODO - PROPER DATA LOADING
    data.forEach(item => {
      if (!item) {
        return;
      }
      
      this.map.set(item.name, item.data);
    })

    this.applyMaterialSettings(scenes);
  }

  public async save(materialName: string, data: any) {
    return new Promise((res, rej) => {
      this.http.post(`/simplekonfiguratorprofile/babylonmaterials/${this.projectPath}/${materialName}`, data).subscribe(data => {
        console.log("BABMAT SAVE", data);
        res(data);
      })
    })
  }

  public load(materialName: string): Promise<any> {
    return new Promise((res, rej) => {
      this.http.get(`/simplekonfiguratorprofile/babylonmaterials/${this.projectPath}/${materialName}`).subscribe(data => {
        console.log("BABMAT LOAD", data);
        res(data);
      },
      err => {
        res(null);
      })
    })
  }

  public setProjectPath(path: string) {
    this.projectPath = path.split("/")[0] + '/Common';
  }

  public get(materialName: string) {
    return this.map.get(materialName);
  }

  public set(materialName: string, settings: any) {
    this.map.set(materialName, settings);
  }

  
  public applyMaterialSettings(scenes: any[]) {
    if (!scenes) {
      console.log("NO SCENE IN MATERIAL, IGNORE APPLYING...", scenes)
      return;
    }
    if (!scenes[0]) {
      console.log("NO SCENE IN MATERIAL, IGNORE APPLYING...", scenes)
      return;
    }
    if (!scenes[0].materials) {
      console.log("NO SCENE IN MATERIAL, IGNORE APPLYING...", scenes)
      return;
    }
    const mats = scenes[0].materials
      .filter((mat:any) => !["", undefined, "skyBox", "<default>", "hotspotmaterial"].includes(mat.name));
    console.log("APPLY MATERIALS", mats.map((mat: any) => mat.name));
    console.log("map", this.map);

    for (let key of Array.from(this.map.keys())) {
      console.log("key", key);

      const item = this.map.get(key);

      if (!item) {
        continue;
      }

      const mat = mats.find((mat: any) => mat.name == key)

      if (!mat) {
        continue;
      }

      this.settings.applyMaterialFromSettings(mat, item);
    }
  }
}