import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class GlobalEventsActions {
  constructor() {
    console.log("GLOBALS INIT")
  }

  public events = {
    materials: {
      links: {
        assigned$: new Subject<any>()
      }
    }
  }

  public actions = {
    materials: {
      refreshAllGroups$: new Subject<any>()
    },

    presets: {
      postprocess: {
        generate: new Subject<any>(),
        submit: new Subject<any>(),
      }
    }
  }
}
