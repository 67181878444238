import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { TestComponent } from './pages/test/test.component';
// import { Test2Component } from './pages/test2/test2.component';
import { SimpleKonfiguratorProfileComponent } from './pages/simple-konfigurator-profile/simple-konfigurator-profile.component';
import { HelpComponent } from './pages/help/help.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ExporterViewComponent } from './pages/exporter-view/exporter-view.component';


const routes: Routes = [
  { path: '', component: ProjectsComponent, },
  { path: 'simple', component: SimpleKonfiguratorProfileComponent, },
  { path: 'viewer', component: SimpleKonfiguratorProfileComponent, },
  { path: 'help', component: HelpComponent, },
  { path: 'preview', component: ExporterViewComponent, },
];
@NgModule({
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes) ],
  providers: []
})
export class AppRoutingModule { }
