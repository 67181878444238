import { Component, OnInit } from '@angular/core';
import { OrganizationalMaterialsComponent } from '../organizational-materials/organizational-materials.component';
import { OrganizationalMaterialsService } from 'src/app/services/materials/organizational/organizational-materials.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { GlobalMaterialsService } from 'src/app/services/materials/global/global-materials.service';
import { MaterialListComponent } from '../material-list';

@Component({
  selector: 'app-global-materials',
  templateUrl: './global-materials.component.html',
  styleUrls: ['./global-materials.component.css']
})
export class GlobalMaterialsComponent extends MaterialListComponent implements OnInit {

  constructor(
    public materials: GlobalMaterialsService,
    public projects: ProjectsService) {
      super(materials, projects);
    }

  ngOnInit() {
  }

  onDoubleClick(file) {
    
  }

}
