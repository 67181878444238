import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CommentsService } from 'src/app/services/comments/comments.service';
import { UsersService } from 'src/app/services/users/users.service';
import { PinsService } from 'src/app/services/pins/pins.service';
import { AudioService } from 'src/app/services/recording/audio.service';
import { VideoService } from 'src/app/services/recording/video.service';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  @Input() pins: PinsService;
  @ViewChild("newCommentTextArea", { static: true }) newComentTextArea: ElementRef;
  @Input() visible: boolean = false;

  showNewComment: boolean = true;
  public newComment: string;
  public modifyComment: string;
  public comments: any;
  project: any;
  currentUser: any;

  currentModifyingComment: any;
  replyOriginalComment: any;

  constructor(
    private commentsService: CommentsService,
    private users: UsersService,
    private audio: AudioService,
    private video: VideoService) {
  }

  ngOnInit() {
    this.pins.onPinModified = (comment) => {
      this.commentsService.modifyPost(comment.id, comment);
    }
    this.audio.recording$.subscribe(obs => {
      console.log("OBS", obs);
      this.reload();
    })
    this.video.recording$.subscribe(obs => {
      console.log("OBS", obs);
      this.reload();
    })
  }

  onNewCommentClick() {

  }

  public reload() {
    this.load();
  }

  public setProject(project: any) {
    this.project = project;
    this.commentsService.setProject(project);
    this.load();
  }

  async load() {
    this.currentUser = await this.users.getCurrent();
    this.comments = await this.commentsService.getComments();
    console.log(this.currentUser);

    this.commentsService.setUser(this.currentUser);
    console.log(this.comments)
  }

  onPinClick(comment) {
    this.pins.setCurrentComment(comment);
    this.pins.pick();
    return false;
  }

  onNewCommentChange(event: KeyboardEvent) {
    console.log(event);
    if (event.ctrlKey) {
      if (event.key == "Enter") {
        this.send();
      }
    }
    if (event.key == "Escape") {
      this.close();
    }
  }

  onModifyCommentChange(event: KeyboardEvent) {
    console.log(event);
    if (event.ctrlKey) {
      if (event.key == "Enter") {
        this.currentModifyingComment.comment = this.modifyComment;
        this.commentsService.modifyPost(this.currentModifyingComment.id, this.currentModifyingComment);
        this.currentModifyingComment = null;
        return false;
      }
    }
    if (event.key == "Escape") {
      this.currentModifyingComment = null;
      return false;
    }
  }

  onCommentClick() {
    // this.showNewComment = true;
    setTimeout(() => {
      console.log(this.newComentTextArea.nativeElement);

      this.newComentTextArea.nativeElement.focus();
      this.newComentTextArea.nativeElement.select();
    }, 0)
  }

  onReplyClick(comment) {
    // this.showNewComment = true;
    setTimeout(() => {
      this.replyOriginalComment = comment;
      console.log(this.newComentTextArea.nativeElement);

      this.newComentTextArea.nativeElement.focus();
      this.newComentTextArea.nativeElement.select();
    }, 0)
  }

  async onAudioRecordClick(comment) {
    const result = await this.audio.toggleRecording(this.commentsService.project.path, comment.id+".mp3");
    console.log("REC", result);
    
  }

  async onVideoRecordClick(comment) {
    const result = await this.video.toggleRecording(this.commentsService.project.path, comment.id+".mkv");
    console.log("REC", result);
    
  }

  onCommentPostClick(comment) {
    if (this.pins.currentComment == comment) {
      console.log("hide");

      this.pins.currentComment = null;
      this.pins.hidePin();
    } else {
      if (comment.pin) {
        this.pins.setCurrentComment(comment);
        this.pins.showPin();
      }
    }
  }

  onModifyOkClick() {
    this.currentModifyingComment.comment = this.modifyComment;
    this.commentsService.modifyPost(this.currentModifyingComment.id, this.currentModifyingComment);
    this.currentModifyingComment = null;
    this.close();
  }

  onModifyCancelClick() {
    this.currentModifyingComment = null;
  }


  async send() {
    this.close();
    let result = await this.commentsService.postComment(this.newComment);
    this.comments = await this.commentsService.getComments();
    return result;
  }

  close() {
    // this.showNewComment = false;
    this.newComentTextArea.nativeElement.value = "";
  }

  onCancelClick() {
    this.close();
  }

  async onOkClick() {
    console.log("OK");

    console.log(this.replyOriginalComment);

    if (this.replyOriginalComment) {
      this.comments = await this.commentsService.replyToComment(this.replyOriginalComment, this.newComment);
    } else {
      this.send();
    }
    this.replyOriginalComment = null;
  }

  async onDeleteClick(comment) {
    if (confirm("Do you want to delete the comment?")) {
      this.comments = await this.commentsService.deletePost(comment.id);
    }
    return false;
  }

  onModifyClick(comment) {
    this.currentModifyingComment = comment;
    this.modifyComment = comment.comment;
  }

  onNewRecordClick() {

  }

  async onPickClick() {
    console.log("PICK");

    console.log(this.replyOriginalComment);

    let comment = await this.send();
    console.log(comment);
    this.replyOriginalComment = null;
    this.pins.setCurrentComment(comment);
    this.pins.pick();
  }

  formatDate(isoDate: string) {
    let current_datetime = new Date(isoDate);
    return current_datetime.getDate() + "." + (current_datetime.getMonth() + 1) + "." + current_datetime.getFullYear() + " " + current_datetime.getHours() + ":" + (current_datetime.getMinutes())
  }

}
