import { Injectable } from '@angular/core';
import { Node, Mesh } from 'babylonjs';
import { SceneService, ISceneService } from '../scene.service';

import { services } from 'src/environments/globals';

import { GeometryGroups, GroupLayer, getMeshFullName } from '../geometry-groups';
import { HotspotsService, Hotspot } from '../../hotspots/hotspots.service';
import { CamerasService } from '../../cameras/cameras.service';
import { ShaderReplacementsService } from '../../shaderreplacements/shader-replacements.service';
import { PostprocessService } from '../../postprocess/postprocess.service';
// import { RenderService } from '../../render/render.service';
import { OptionGroups } from '../option-groups';
import { Subject } from 'rxjs/internal/Subject';
import { KonfiguratorSettingsService } from '../../konfiguratorsettings/konfigurator-settings.service';
import { BackgroundsService } from '../../backgrounds/backgrounds.service';
import { OptionGroupsService } from '../../optiongroups/option-groups.service';
import { PatchService } from './patch.service';
import { ISimpleKonfiguratorProfileConfig } from './simplekonfiguratorprofileconfig.interface';
import { Materials } from '../materials';
import { MaterialLinksService } from '../../materiallinks/material-links.service';
import { ProcessingService } from 'src/app/modules/processing/processing.service';


@Injectable({
  providedIn: 'root'
})
export class SimpleKonfiguratorProfileSceneService
  extends SceneService implements ISceneService {
  public optionGroups: OptionGroups | any;
  public config: ISimpleKonfiguratorProfileConfig;

  public save$ = new Subject();
  public materialChange$ = new Subject();
  public layerChange$ = new Subject();
  public modified$ = new Subject();
  // public materialLinkAssigned$ = new Subject<GroupLayer>();

  public materialAdd$ = new Subject<GroupLayer>();

  // MATERIALS SELECT
  public materialsSelectCB: (() => void) = null;


  rootNodes: Node[];
  options: any = this.getDefaultOptions();

  public thumbnails: any = {};


  constructor(
    public geometryGroups: GeometryGroups,
    public patch: PatchService,
    public hotspots: HotspotsService,
    public cameras: CamerasService,
    public postprocess: PostprocessService,
    public shaderReplacement: ShaderReplacementsService,
    public backgrounds: BackgroundsService,
    public konfiguratorSettings: KonfiguratorSettingsService,
    public optionGroupsService: OptionGroupsService,
    public materials: Materials,
    public materialLinks: MaterialLinksService,
    public processing: ProcessingService,
    ) {
    super(materials);

    services.service = this;
    this.cameras.scene = this;
    this.konfiguratorSettings.scene = this;
    console.log("SERVICE v1.0.101a");
    console.log("SET SCENE SERVICE", services);
  }

  public getGeometryGroups() {
    console.log("getgroups");

    return this.geometryGroups;
  }


  public init() {
    super.init();

    this.optionGroupsService.geometryGroups = this.geometryGroups;
  }


  public async register(currentScene, auxScenes, currentProject, config) {
    await super.register(currentScene, auxScenes, currentProject, config);
    this.rootNodes = this.getRootNode();
    this.geometryGroups.setRootNodes(this.rootNodes);
    this.optionGroups = new OptionGroups(this);
    // this.geometryGroups.optionGroups = this.optionGroups;

    // IMPORT FROM OUTLINER
    // this.geometryGroups.importFromNode(this.rootNode);
    // this.geometryGroups.showHideGeometries();


    this.deserialize(config);
    if (this.geometryGroups.groups.length <= 0) {
      this.geometryGroups.importFromNodes(this.rootNodes);
      this.geometryGroups.showHideGeometries();
    }
  }

  public destroy() {
    super.destroy();
    this.geometryGroups.dispose();
    this.geometryGroups = null;
    this.rootNodes = null;
  }

  public async save() {
    await this.save$.next();
  }

  serialize() {
    let result =  {
      ...super.serialize(),
      visibleNodes: this.getVisibleMeshes(),
      geometryGroups: this.geometryGroups.serialize(),
      options: this.options,
      cameras: this.cameras.serialize(),
      postprocess: this.postprocess.serialize(),
      optionGroups: this.optionGroups.serialize(),
      konfiguratorSettings: this.konfiguratorSettings.serialize(),
      materialLinkAssignments: this.materialLinks.serialize(),
    }
    this.optionGroupsService.serialize(result);

    console.log(result);
    

    return result;
  }

  deserialize(config) {
    this.config = config;
    this.patch.patch_config(config);
    if (config.options) {
      console.log("config options:");
      console.log(config.options);

      this.options = config.options;
      if (config.options.showAdvancedMenu === undefined) {
        config.options.showAdvancedMenu = false;
      }
    } else {
      this.options = this.getDefaultOptions();
    }
    
    this.materialLinks.deserialize(config);
    
    this.geometryGroups.deserialize(config, this);

    this.cameras.deserialize(config.cameras);

    if (config.postprocess) {
      this.postprocess.deserialize(config.postprocess);
    }

    if (config.optionGroups) {
      this.optionGroups.deserialize(config);
    }
    console.log("backgrounds.setConfig", this.currentProject.path, config);
    
    // this.backgrounds.setConfig(this.currentProject.path, config);
    this.optionGroupsService.deserialize(config);

  }

  getDefaultOptions() {
    return {
      showExplosionInViewer: false,
      showAdvancedMenu: false,
    }
  }

  getVisibleMeshes() {
    let result = [];
    this.geometryGroups.groups.forEach(grp => {
      grp.layers.forEach(layer => {
        if (!layer) {
          return
        }
        if (layer.v) {
          if (!layer.ref) {
            return

          }
          this._getVisibleMeshes(layer.ref, result);
          let children = layer.ref.getChildren(undefined, true)
          console.log(children);

          children.forEach(n => {
            console.log(n instanceof Mesh);
            if (!(n instanceof Mesh)) {
              this._getVisibleMeshes(n, result);
            }
          })
        }
      })
    })

    return result;

  }

  _getVisibleMeshes(n: Node, result: any) {
    result.push(getMeshFullName(n).replace("|__root__", "").replace("|RootNode", ""));
    let children = n.getChildren(null, true);
    if (children.length > 0) {
      children.forEach(node => {
        if (!(node instanceof Mesh)) {
          this._getVisibleMeshes(node, result);
        }
      })
    }
  }


  public assign(layer: GroupLayer) {
    if (layer.parent.type != 'unified') {
      layer.assign();
    } else {
      layer.parent.assign();
    }

    // if (this.shaderReplacement.shaderLinks) {
    //   console.log(this.shaderReplacement.shaderLinks);

    //   let sl = this.shaderReplacement.shaderLinks[layer.getFullName()];
    //   if (sl) {
    //     for (let l in sl.layers) {
    //       let layer = this.geometryGroups.getNodeByFullName(l);
    //       console.log(layer);

    //     }
    //   }
    // }

    if (layer.materialLink) {
      let link = layer.materialLink;
      console.log("assign link");
      this.geometryGroups.groups.forEach(group => {
        group.assign();
      })
    }
  }


  getRootNode() {
    console.log(this.scenes);
    if (!this.scenes) {
      return;
    }

    let scene = this.scenes[0];
    let node = null;
    try {
      console.log("try1");
      node = scene.rootNodes[0].getChildren(undefined, true)[0].getChildren(undefined, true);

      if (node.length == 0) {
        console.log("try1-1");
        node = scene.rootNodes;
      }
    } catch (x) {
      try {
        console.log("try2");
        node = scene.rootNodes[0].getChildren(undefined, true)
        if (node.length == 0) {
          console.log("try2-1");
          node = scene.rootNodes;
        }
      } catch (x) {
        try {
          console.log("try3");
          node = scene.rootNodes[0].getChildren(undefined, true)
        } catch (x) {
          console.log("try4");
          node = scene.rootNodes
        }
      }
    }
    console.log(node);

    return node;
  }

  public getGlobalBoundingBox() {
    // TODO
  }

  public selectMesh(mesh: Mesh) {
    console.log(mesh);

    let layer = this.geometryGroups.selectMesh(mesh);
    console.log(layer);

    if (layer) {
      this.onMeshSelect(layer);
      this.layerChange$.next(layer);
      return layer;
    }

    let hotspot = this.hotspots.selectHotspot(mesh);
    if (hotspot) {
      this.onHotspotSelect(hotspot);
    }
  }


  importFromOutliner() {
    this.geometryGroups.importFromNodes(this.rootNodes);
    this.geometryGroups.showHideGeometries();
    this.optionGroups.removeOrphanLayers();
  }

  importFromOutlinerFlat() {
    console.log("FLAT");

    this.geometryGroups.importFromNodesFlat(this.rootNodes);
    this.geometryGroups.showHideGeometries();
  }

  importFromPreconfigured(importData) {
    console.log("PRECONFIGURED");
    this.geometryGroups.importFroPreconfigured(this.rootNodes, importData.groups);
    this.geometryGroups.showHideGeometries();
  }


  onHotspotSelect(hotspot: Hotspot) { }

}
