import { Material as BMaterial, Scene } from 'babylonjs';
import { Injectable } from '@angular/core';
import { services } from 'src/environments/globals';


@Injectable({
  providedIn: 'root'
})
export class Materials {
  materials: MaterialList = new MaterialList();
  mapById: any = {};

  constructor() {
    services.materials = this;
    console.log("SET MATERIAL SERVICE", services);
  }

  public setMaterialsFromScene(scenes: Scene[]) {
    for (var scene of scenes.slice(1)) {
      if (!scene || !scene.meshes) {
        console.log("ERROR no meshes in scene (error loading material)");
        return;
      }
      for (var mesh of scene.meshes) {
        mesh.isVisible = false;
        if (!mesh.material) {
          continue
        }
        var mat = mesh.material;
        var item = new Material(
          mat.name,
          false,
          mat,
          this.materials
        );
        this.materials.push(item);
        this.mapById[mat.name] = item;
      }
    }
    console.log("SET MATERIALS FROM SCENE", this.materials)
  }

  public getMaterialById(uid) {
    return this.mapById[uid];
  }

}

export class MaterialList {
  materials: Material[] = [];

  push(material: Material) {
    if (material) {
      if (this.materials.indexOf(material) < 0) {
        material.parent = this;
        this.materials.push(material);
      }
    }
  }

  getMaterials() { return this.materials; };

  getByName(name:string) {
    let result = null;
    this.materials.forEach(mat => {
      if (mat.material.name == name) {
        result = mat;
      }
    })
    return result;
  }

  public remove(material:Material) {
    this.materials = this.materials.filter(mat => mat !== material);
  }
}

export class Material {
  constructor(
    public label: string,
    public checked: boolean,
    public material: BMaterial,
    public parent:MaterialList
  ) {

  }

  check() {
    this.checked = !this.checked;
  }
}