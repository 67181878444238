import { Injectable } from '@angular/core';
import { TexturePreviewComponent } from 'src/app/components/texture-preview/texture-preview.component';
import { ProjectsService } from '../projects/projects.service';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class TexturePreviewService {
  preview: TexturePreviewComponent;

  projectPath = null;
  thumbs = [];
  unsavedThumbs = [];

  statusText = "";
  status = {
    index: 0,
    length: 3
  }

  interval = null;

  constructor(
    public projects: ProjectsService
  ) {
  }

  public setProjectPath(path) {
    this.projectPath = path;
  }

  public setComponent(preview) {
    this.preview = preview;
  }

  public async generate() {
    this.thumbs = [];
    let thumbs = await this.check();
    this.status.index = 1;
    // await this.generateCommon(thumbs);
    // await this.saveCommon();
    this.status.index = 2;

    this.preview.generatedNewMaterial$.subscribe((data) => {
      this.saveOrganizationSingle(data.label, data.image);
    })
    await this.generateOrganization(thumbs);
    await this.saveOrganization();
    this.status.index = 3;
    // await this.generateProject(thumbs);
    // await this.saveProject();
  }


  async generateCommon(thumbs?) {
    const result: any = await this.projects.getMaterialsFiles(this.projects.currentProject);
    // /simplekonfiguratorprofile/projects/Common/Materials/Plastic_Glossy_Black/Babylon/Plastic_Glossy_Black_512.babylon
    // /simplekonfiguratorprofile/projects/Osann/Common/Materials/Plastic_Glossy_Black/Babylon/Plastic_Glossy_Black_512.babylon
    await this.generateThumbs(
      "Common", 
      result.common, "", thumbs)
  }

  async generateOrganization(thumbs?) {
    const result: any = await this.projects.getMaterialsFiles(this.projects.currentProject);
    // /simplekonfiguratorprofile/projects/Common/Materials/Plastic_Glossy_Black/Babylon/Plastic_Glossy_Black_512.babylon
    // /simplekonfiguratorprofile/projects/Osann/Common/Materials/Plastic_Glossy_Black/Babylon/Plastic_Glossy_Black_512.babylon
    await this.generateThumbs(
      this.projects.currentProject.organization + "/Common", 
      result.organization, "_Original", thumbs)
  }

  async generateProject(thumbs?) {
    const result: any = await this.projects.getMaterialsFiles(this.projects.currentProject);
    // /simplekonfiguratorprofile/projects/Common/Materials/Plastic_Glossy_Black/Babylon/Plastic_Glossy_Black_512.babylon
    // /simplekonfiguratorprofile/projects/Osann/Common/Materials/Plastic_Glossy_Black/Babylon/Plastic_Glossy_Black_512.babylon
    await this.generateThumbs(
      this.projects.currentProject.path, 
      result.files, "_Original", thumbs)
  }

  async generateThumbs(path, collection, prefix, thumbs) {
    const urls = collection.map(mat => "/simplekonfiguratorprofile/projects/" + path + "/Materials/" + mat + "/Babylon/" + mat + prefix + ".babylon");
    console.log("URLS", urls);
    
    let data = await this.preview.init({
      sceneURL: "/simplekonfiguratorprofile/projects/Common/TexturePreview/Sphere/Model/cubeSphere.gltf",
      materialSceneURLs: urls,
      skyboxURL: "https://assets.babylonjs.com/environments/environmentSpecular.env",
      thumbnails: thumbs
    });

    console.log("OUT", data);
    console.log("THUMBS", this.preview.thumbnails);
    this.unsavedThumbs = [];
    for (let key in this.preview.thumbnails) {
      this.thumbs.push({
        label: key,
        image: this.preview.thumbnails[key]
      })
      this.unsavedThumbs.push({
        label: key,
        image: this.preview.thumbnails[key]
      })
    }
  }

  public async saveCommon() {
    await this.preview.savePreview("/Common", this.unsavedThumbs);
  }

  public async saveOrganization() {
    await this.preview.savePreview(this.projects.currentProject.organization + "/Common", this.unsavedThumbs);
  }

  public async saveOrganizationSingle(label, image) {
    await this.preview.savePreview(this.projects.currentProject.organization + "/Common", [{label: label, image: image}]);
  }

  public async saveProject() {
    await this.preview.savePreview(this.projects.currentProject.path, this.unsavedThumbs);
  }

  public async check() {
    const data = await Promise.all([
      await this.preview.checkPreview("/Common") as any,
      await this.preview.checkPreview(this.projects.currentProject.organization + "/Common") as any,
      await this.preview.checkPreview(this.projects.currentProject.path) as any,
    ])
    console.log("DATA1", data[0]);
    console.log("DATA2", data[1]);
    console.log("DATA3", data[2]);
    
    return [
      ...data[0],
      ...data[1],
      ...data[2],
    ]
  }

  async checkCommon(): Promise<[]> {
    const result: any = await this.preview.checkPreview("/Common");
    return result;
  }

  setInterval() {
    this.interval = setInterval(() => {
      let status = this.preview.status;
      this.statusText = "STAGE " + this.status.index + "/" + this.status.length + " " + status.index + "/" + status.length
    }, 1000)
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.statusText = "Done"
  }
}
