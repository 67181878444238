import { Component, OnInit } from '@angular/core';
import { MaterialPreviewsService } from 'src/app/materialpreviews/material-previews.service';
import { OptionGroupsService } from 'src/app/services/optiongroups/option-groups.service';
import { RelationsMaterialGeometryService } from 'src/app/services/relations/relations-material-geometry.service';
import { GeometryGroups } from 'src/app/services/scene/geometry-groups';
import { Material } from 'src/app/services/scene/materials';

@Component({
  selector: 'app-relations-material-geometry',
  templateUrl: './relations-material-geometry.component.html',
  styleUrls: ['./relations-material-geometry.component.css']
})
export class RelationsMaterialGeometryComponent implements OnInit {
  public currentLeftMaterial: Material = null;
  public currentLeftMaterials = [];
  public _currentLeftMaterial: Material = null;
  public currentMaterilalLinkLeft = null;

  constructor(
    public geometryGroups: GeometryGroups,
    public optionGroups: OptionGroupsService,
    public previews: MaterialPreviewsService,
    public relations: RelationsMaterialGeometryService
  ) { }

  ngOnInit() {
    console.log("RMG v20230623a");
  }

  public onLeftChange(link) {
    this.changeLeft(link);
  }

  public onRightChange(currentMaterilalLinkLeft, mat, link) {
  }


  public changeLeft(link) {
    console.log(link);
    this.currentLeftMaterials = this.geometryGroups.getMaterialsForLink(link);

  }

  public changeRight(currentMaterilalLinkLeft, mat, link) {
    this.relations.select(currentMaterilalLinkLeft, mat, link)
  }

  public onRightClick(currentMaterilalLinkLeft, mat: Material, link: any) {
    console.log("REL", currentMaterilalLinkLeft, mat.material.name, link.value)
    this.changeRight(currentMaterilalLinkLeft, mat.material.name, link.value);
  }

  public isSelected(currentMaterilalLinkLeft, mat, link) {
    if (!mat.material) {
      return false;
    }
    // console.log("REL ISSELECTED", mat.material.name, link)
    return this.relations.isSelected(currentMaterilalLinkLeft, mat.material.name, link.value)
  }

}
