import { Component, OnInit } from '@angular/core';
import { MaterialRelationsService, MaterialRelation } from 'src/app/services/materialrelations/material-relations.service';
import { OptionGroupsService } from 'src/app/services/optiongroups/option-groups.service';
import { Material } from 'src/app/services/scene/materials';
import { MaterialPreviewsService } from 'src/app/materialpreviews/material-previews.service';
import { SimpleKonfiguratorProfileSceneService } from 'src/app/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { GeometryGroups } from 'src/app/services/scene/geometry-groups';
import { OptionGroups } from 'src/app/services/scene/option-groups';

@Component({
  selector: 'app-material-relations',
  templateUrl: './material-relations.component.html',
  styleUrls: ['./material-relations.component.css']
})
export class MaterialRelationsComponent implements OnInit {
  public currentLeftMaterial: Material = null;
  public currentMaterilalLinkLeft = null;
  public currentMaterilalLinkRight = null;
  public filteredRightLinks = null;


  constructor(
    public service: SimpleKonfiguratorProfileSceneService,
    public relations: MaterialRelationsService,
    public geometryGroups: GeometryGroups,
    public optionGroups: OptionGroupsService,
    public previews: MaterialPreviewsService
  ) { }

  ngOnInit() {
    console.log("MRONINIT");
  }


  public changeLeft(link) {
    // console.log("THUMBS", this.previews.thumbnails);
    
    // this.currentLeftMaterials = this.geometryGroups.getMaterialsForLink(link);
    // if (this.currentMaterilalLinkLeft == this.currentMaterilalLinkRight) {
    //   this.currentMaterilalLinkRight = null;
    //   this.currentRightMaterials = null;
    // }
    this.filteredRightLinks = this.getRightMaterialLinks();
    // console.log(this.currentLeftMaterials);
  }

  public changeRight(link) {
    this.refreshRightList();
  }

  refreshRightList() {
    // console.log(this.currentMaterilalLinkRight);
    
    // const list = this.geometryGroups.getMaterialsForLink(this.currentMaterilalLinkRight);
    // this.currentRightMaterials = [];
    // list.forEach(m => {
    //   let item;
    //   const items = this.relations.list.filter(mr => 
    //       (mr.linkLeft == this.currentMaterilalLinkLeft) &&
    //       (mr.linkRight == this.currentMaterilalLinkRight) &&
    //       (this.currentLeftMaterial && (mr.materialLeft == this.currentLeftMaterial.label)) &&
    //       (mr.materialRight == m.label))
    //   if (!items.length) {
    //     item = new MaterialRelation();
    //     item.linkLeft = this.currentMaterilalLinkLeft;
    //     item.linkRight = this.currentMaterilalLinkRight;
    //     item.materialLeft = this.currentLeftMaterial ? this.currentLeftMaterial.label : '';
    //     item.materialRight = m.label;
    //   } else {
    //     item = items[0];
    //   }
    //   this.currentRightMaterials.push(item);
    // })
    // console.log(this.currentRightMaterials);
  }


  // public selectLeft(mat) {
  //   this.currentLeftMaterial = mat;
  //   this.filteredRightLinks = this.getRightMaterialLinks();
  //   if (this.currentMaterilalLinkRight) {
  //     this.refreshRightList();
  //   }
  // }

  public getRightMaterialLinks() {
    let list = this.optionGroups.materialLinkLabels.filter(l => l.value != this.currentMaterilalLinkLeft);
    console.log(list);
    return list;
  }



  public onLeftChange(link) {
    this.changeLeft(link);
  }

  public onRightChange(link) {
    this.changeRight (link);
  }

  // public onLeftMaterialClick(mat) {
  //   this.selectLeft(mat);
  // }

  public onRightMaterialClick(mat) {

  }

  public onLeftMaterial(mat: Material) {
    this.currentLeftMaterial = mat;
  }


}
