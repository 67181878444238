import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { GeometryGroups, GroupLayer } from 'src/app/services/scene/geometry-groups';
import { StyleSheetsService } from 'src/app/services/stylesheets/style-sheets.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SimpleKonfiguratorProfileSceneService } from 'src/app/services/scene/simpleprofile/simple-konfigurator-profile-scene.service';
import { OptionGroups } from 'src/app/services/scene/option-groups';

@Component({
  selector: 'app-simple',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleComponent implements OnInit, OnDestroy {
  @Input() currentLayer: GroupLayer = null;
  @Output() modification: EventEmitter<any> = new EventEmitter();
  @Output() layerChange: EventEmitter<any> = new EventEmitter();
  @Output() materialHover: EventEmitter<any> = new EventEmitter();
  @Input() thumbnails: any;
  @Input() advancedMenu: boolean = false;
  @Input() showMaterialLabel = false;

  data: GeometryGroups;
  materials = [];
  styles: SafeHtml;
  private styleSubscription: Subscription;
  currentMaterialLabel = "";

  constructor(
    private stylesheetsService: StyleSheetsService, 
    private sanitizer: DomSanitizer, 
    private cd: ChangeDetectorRef, 
    public scene: SimpleKonfiguratorProfileSceneService) {
  }

  ngOnInit() {
    this.styleSubscription = this.stylesheetsService.styles$.subscribe(
      data => {
        this.styles = this.sanitizer.bypassSecurityTrustHtml(`
          <style>
           ${data}
          </style>
        `);
        // console.log(this.styles);
        this.cd.markForCheck();
      },
      err => console.log(err)
    );
  }

  @Input()
  set content(content:any) {
    if (content) {
      this.data = content.data;
    }
  }

  onLayerClick(layer) {
    this.currentLayer = layer;

    if (this.currentLayer.parent.type == 'exclusive') {
      this.currentLayer.v = true;
    }
    this.data.refreshTags();
    if (this.currentLayer.parent.type == 'exclusive') {
      this.currentLayer.v = true;
    }
    this.layerChange.emit(layer);
    this.modification.emit();
  }

  onLayerDblClick(layer) {
    this.currentLayer = layer;

    if (this.currentLayer.parent.type == 'inclusive') {
      this.currentLayer.v = !this.currentLayer.v;
    }

    this.modification.emit();
  }

  onTitleDblClick(group) {
    group.visible = !group.visible;

    this.modification.emit();
  }

  onMaterialClick(material) {
    this.currentLayer.setCurrentMaterial(material);
    // this.currentLayer.assign();
    this.scene.assign(this.currentLayer);
    this.modification.emit();
    this.scene.materialChange$.next();
  }

  onMaterialMouseOver(material) {
    this.materialHover.emit(material)
    this.currentMaterialLabel = material.label;
  }

  onMaterialMouseOut(material) {
    this.materialHover.emit(null)
    this.currentMaterialLabel = "";
  }



  ngOnDestroy(): void {
    this.styleSubscription.unsubscribe();
  }

  public isGeometryMenuVisible() {
    console.log("ISGEOMETRYMENUVISIBLE", this.scene.options.showGeometryMenu);
    
    return this.scene.options.showGeometryMenu;
  }

  onAdvancedModification() {
    this.modification.emit();
  }

}
