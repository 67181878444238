import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.css']
})
export class UsersEditComponent implements OnInit {
  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  public newOrganization: string = '';

  constructor(
    public users: UsersService
  ) { }

  ngOnInit() {
  }

  public onSaveClick() {
    console.log("save");
    this.save.emit();
  }

  public onCancelClick() {
    this.cancel.emit();
  }

  public onDeleteClick(organization) {
    console.log(organization, this.users.user.organizations);
    
    this.users.user.organizations = this.users.user.organizations.filter(org => org != organization);
  }

  public onAddOrganizationClick() {
    this.users.user.organizations.push(this.newOrganization);
    // this.save.emit();
  }
}
