import { Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { UploaderService } from 'src/app/services/uploader/uploader.service';

export class Uploader {
  _projectPath: string;
  @Output() generated: EventEmitter<any> = new EventEmitter();
  @ViewChild('pond', { static: true }) pond: any;

  filepondOptions: any = {};
  files: any;
  label = "Drop Texture files here";

  private urlPostfix: string

  constructor(
    public service: UploaderService,
    public projects: ProjectsService,
    public loader: LoaderService) {
    this.files = [];

  }

  ngOnInit() {
    // this.service.setCurrentProjectPath(this.projects.currentProject.path);
    // this.refreshFiles()
  }

  public setPostfix(url:string) {
    console.log("SET postfix", url);
    
    this.urlPostfix = url;
    this.service.setPostfix(url);
  }


  @Input()
  set projectPath(projectPath: String) {
    console.log("UPLOADER set proj path", projectPath);
    this.service.setCurrentProjectPath(projectPath)
    
    this.setFilepondOptions(this.projects.PREFIX + '/' + projectPath + '/' + this.urlPostfix);
    this.refresh();
  }

  setFilepondOptions(url) {
    this.filepondOptions = {
      class: 'my-filepond',
      multiple: true,
      labelIdle: this.label,
      allowRevert: false,
      server: {
        url: url,
      },
    }
  }

  public setLabel(label) {
    this.label = label;
  }

  onFileUpload(response) {
    if (response.err) {
      console.log(response.err);
    }
    else {
      this.handle(response.file.filenameWithoutExtension)
      setTimeout(() => {
        this.pond.removeFile(response.file.id);
        this.generated.emit();
      }, 1000);
    }
  }

  refresh() {
    this.service.getFiles()
    .then(data => {
      console.log(data);
      this.setFiles(<any>data['files']);
    });
  }


  handle(data) {
    this.refreshFiles();
  }


  async deleteFile(index: number) {
    // this.files.splice(index, 1);
    console.log(index, this.files[index]);
    
    let result = await this.service.deleteFile(this.files[index].filename);
    this.setFiles(result['files']);
  }

  
  pondHandleInit() {
    console.log('FilePond has initialised', this.pond);
  }

  pondHandleAddFile(event) {
    console.log('A file was added', event);
  }

  async pondProcessFile(event) {
    console.log('A file was processed', event);
    console.log(event.file.filename);

    await this.refreshFiles();
    this.pond.removeFile(event.file.id);

  }

  onRefresh() {
    console.log("REFRESH uploader");
    
    this.refreshFiles();
  }

  
  async refreshFiles() {
    let files = (await this.service.getFiles())['files'];
    this.setFiles(files);
  }

  
  setFiles(list) {
    console.log(list);
    
    this.files = [];
    if (!list) {
      return
    }
    list.forEach(f => {
      this.files.push(
        {
          filename: f
        }
      )
    });
  }

}
