import { Injectable } from '@angular/core';
import { GeometryGroups, GroupLayer } from '../scene/geometry-groups';
import { Node, Scene, Camera, Vector3, Mesh, MeshBuilder, Material, StandardMaterial, BackgroundMaterial, Color3, Color4 } from 'babylonjs';
import { EngineService } from '../engine/engine.service';
import { htmlAstToRender3Ast } from '@angular/compiler/src/render3/r3_template_transform';

export let PINWIDTH = 20;
export let PINOFFSET = 20;

@Injectable({
  providedIn: 'root'
})
export class HotspotsService {
  geometryGroups:GeometryGroups;
  public hotspots:Hotspot[] = [];
  spheres: Mesh[];
  engine:EngineService;
  scene:Scene;
  camera:Camera;
  public visible: boolean = false;
  timeout:any;
  currentHotspot:Hotspot;
  picking:boolean = false;
  material:StandardMaterial;
  selectedMaterial:StandardMaterial;

  constructor() { }

  public static createHotspot(layer:GroupLayer) {
    let vectors = [];
    let _mesh = null;
    try {
      let node:Node = layer.ref;
      node.getChildMeshes().forEach(mesh => {
        if (mesh.isVisible) {
          if (!_mesh) {
            _mesh = mesh;
          }            
          vectors.push(mesh.getBoundingInfo().boundingBox.centerWorld)
        }
      });
      if (vectors.length == 0) {
        try {
          // layer.ref is mesh when imported from OBJ
          
          let mesh:any = layer.ref;
          vectors.push(mesh.getBoundingInfo().boundingBox.centerWorld)
        } catch (e) {}
      }
    } catch (ex) {}
    if (vectors.length <= 0) {
    }
    if (vectors.length > 0) {
        let x = 0;
      let y = 0;
      let z = 0;
      vectors.forEach(vec => {
        x += vec.x;
        y += vec.y;
        z += vec.z;
      })
      
      let vector = new Vector3(
        x/vectors.length, 
        y/vectors.length, 
        z/vectors.length, 
      )
      layer.hotspot = {
        visible: false,
        x: vector.x,
        y: vector.y,
        z: vector.z,
      }
      return new Hotspot(layer);
    }
    
  }

  public register(engine:EngineService, geometryGroups:GeometryGroups) {
    this.hotspots = [];
    this.engine = engine;
    this.scene = this.engine.currentScene;
    this.camera = this.engine.currentScene.activeCamera;
    this.geometryGroups = geometryGroups;
    let rootNodes = this.geometryGroups.getRootNodes();
    this.material = new StandardMaterial("__3dk__hotspotmaterial", this.scene);
    this.material.disableLighting = true;
    this.material.emissiveColor = new Color3(1, 1, 0);
    this.material.alpha = 0.5;
    this.selectedMaterial = new StandardMaterial("__3dk__hotspotmaterial", this.scene);
    this.selectedMaterial.disableLighting = true;
    this.selectedMaterial.emissiveColor = new Color3(1, 1, 0);
    // this.scene.debugLayer.show();
    this.geometryGroups.groups.forEach(grp => {
      grp.layers.forEach(lay => {
        if (!lay.hotspot) {
          let hotspot = HotspotsService.createHotspot(lay);
          this.hotspots.push(hotspot);
        } else {
          this.hotspots.push(new Hotspot(lay))
        }
      
      })
    })
    this.createSpheres();

    let onMeshClick = engine.onMeshClick;

    engine.onMeshClick = (mesh, picking) => {
      onMeshClick(mesh, picking);

      if (this.picking) {
        if (picking.hit) {
          this.currentHotspot.mesh.position = picking.pickedPoint;
          this.currentHotspot.getLayer().hotspot = {
            visible: true,
            x:picking.pickedPoint.x,
            y:picking.pickedPoint.y,
            z:picking.pickedPoint.z,
          }
        }
        this.picking = false;
      }
    }
  }

  createSpheres() {
    let i = 0;
    // let mat = new BackgroundMaterial("hotspotmaterial" + i, this.scene);
    for (let hotspot of this.hotspots) {
      i++;
      let mesh = MeshBuilder.CreateSphere("__3dk__hotspot" + i, {
        segments: 32,
        diameter: 0.03,
      }, this.scene);
      // let h = hotspot.getLayer().hotspot;
      // mesh.position = new Vector3(h.x, h.y, h.z);
      // mesh.material = this.material;
      if (hotspot) {
        hotspot.mesh = mesh;
      }
    }
    this.refresh();
  }

  public refresh() {
    this.hotspots.forEach(hs => {
      if (!hs) {
        return;
      }
      if (hs.getLayer().hotspot.visible && this.visible && hs.getLayer().v) {
        hs.mesh.isVisible = true;
        hs.mesh.position = new Vector3(
          hs.getLayer().hotspot.x,
          hs.getLayer().hotspot.y,
          hs.getLayer().hotspot.z)
          hs.mesh.material = this.material;
        if (hs === this.currentHotspot) {
          hs.mesh.material = this.selectedMaterial;
        }
      } else {
        hs.mesh.isVisible = false;
      }
    })
    if (this.engine) {
      this.engine.simpleRender();
    }
  }


  public show() {
    
    this.visible = true;
    this.refresh();
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.hide();
    }, 2000)
  }

  public hide() {
    this.visible = false;
    this.timeout = null;
    this.refresh();
  }

  selectHotspot(mesh:Mesh) {
    this.currentHotspot = null;
    this.hotspots.forEach(hs => {
      if (hs.mesh === mesh) {
        this.currentHotspot = hs;
      }
    });
    this.refresh();
    return this.currentHotspot;
  }

  selectLayer(layer:GroupLayer) {
    this.currentHotspot = this.getHotspotByLayer(layer);
  }

  getHotspotByLayer(layer:GroupLayer) {
    let result = null
    this.hotspots.forEach(hs => {
      if (!hs) {
        return;
      }
      
      if (hs.getLayer() == layer) {
        result = hs
      }
    })
    return result;
  }

  public pickPosition(layer:GroupLayer) {
    let hs = this.getHotspotByLayer(layer);
    
    this.currentHotspot = hs;
    this.picking = true;
  }

}



export class Hotspot {
  public mesh:Mesh;

  constructor(private layer:GroupLayer) {

  }

  public getLayer() {
    return this.layer;
  }
}
