export const QUALITY_3DK: IDropdownItem[] = [
  { value: "HQ",              label: "HQ"},
  { value: "LQ",              label: "LQ"},
  { value: "HQ_HM_ZYSEME",    label: "HQ HM ZYSEME"},
  { value: "HQ_ETON",         label: "HQ ETON"},
  { value: "HQ_ETON_WITH_AA", label: "HQ ETON WITH AA"},
];
export const QUALITY_2DK: IDropdownItem[] = QUALITY_3DK;


export const RESOLUTION_3DK: IDropdownItem[] = [
  { value: "720x1280",    label: "1280x720 - HD"},
  { value: "853x1280",    label: "1280x853 - HD+"},
  { value: "360x640",     label: "640x360"},
  { value: "1000x1000",   label: "1000x1000"},
  { value: "1000x1500",   label: "1000x1500"},
  { value: "1500x1500",   label: "1500x1500"},
  { value: "1456x1549",   label: "1549x1456"},
  { value: "1133x1700",   label: "1133x1700"},
  { value: "2000x2000",   label: "2000x2000"},
  { value: "1080x1920",   label: "1920x1080 - Full HD"},
  { value: "1280x1920",   label: "1920x1280 - Full HD+"},
  { value: "1080x2400",   label: "2400x1080"},
  { value: "1350x2400",   label: "2400x1350"},
  { value: "2160x3840",   label: "2160x3840 - 4K portrait"},
  { value: "3840x2160",   label: "3840x2160 - 4K landscape"},
  { value: "2480x3508",   label: "3508x2480 - A4"},
  { value: "4096x4096",   label: "4096x4096"},
  { value: "1920x2880",   label: "2880x1920 - Full HD++"},
  { value: "886x1920",    label: "1920x886 - Eton"},
  { value: "692x1500",    label: "1500x692 - Eton"},
  { value: "591x1280",    label: "1280x591 - Eton"},
  { value: "2048x3072",   label: "3072x2048 - Eton"},
  { value: "415x900",     label: "900X415 - Eton"},
  { value: "2000x1500",   label: "2000x1500 - Eton landscape"},
];
export const RESOLUTION_2DK: IDropdownItem[] = RESOLUTION_3DK;


export interface IDropdownItem {
  value: string;
  label: string;
}

export function getResolution3DK(): Promise<IDropdownItem[]> {
  return fetch("/simplekonfiguratorprofile/config/resolutions").then(data => {
    return data.json();
  })
}

export function getResolution2DK(): Promise<IDropdownItem[]> {
  return fetch("/simplekonfiguratorprofile/config/resolutions").then(data => {
    return data.json();
  })
}