import { Component, OnInit, ViewChild } from '@angular/core';
import { UploaderService } from 'src/app/services/uploader/uploader.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Uploader } from './uploader';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.css']
})
export class UploaderComponent extends Uploader implements OnInit {

  constructor(
    public service:UploaderService,
    public projects: ProjectsService,
    public loader:LoaderService,
    ) {
      super(service, projects,loader);
  }

  ngOnInit() {
  }

  public setService(service:UploaderService) {
    this.service = service;
  }

  async onDeleteClick(file) {
    this.files = (await this.service.deleteFile(file))['files'];
  }


}
