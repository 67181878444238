import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rtable',
  templateUrl: './rtable.component.html',
  styleUrls: ['./rtable.component.css']
})
export class RtableComponent implements OnInit {
  @Output() expand: EventEmitter<any> = new EventEmitter();
  @Output() showHide: EventEmitter<any> = new EventEmitter();
  @Input("data") nodes:any[];
  @Input("materials") materials:boolean = false;
  @Input("checkbox") checkbox:boolean = false;
  @Input("visible") visible:boolean = false;
  @Input("title") title = "Geometry"

  Arr = Array;


  constructor() { }

  ngOnInit() {

  }

  

  onLabelClick(node:any) {
    this.expand.emit(node);
  }

  onVisibleClick(node) {
    this.showHide.emit(node)
  }

  getRange(node:any) {
    if (node.level){
      return Array(node.level).keys();
    } else {
      return [];
    }
  }

}
