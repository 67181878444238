import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ShaderReplacementsService } from 'src/app/services/shaderreplacements/shader-replacements.service';

@Component({
  selector: 'app-shader-replacements',
  templateUrl: './shader-replacements.component.html',
  styleUrls: ['./shader-replacements.component.css']
})
export class ShaderReplacementsComponent implements OnInit {
  @Output() materialClick:EventEmitter<any> = new EventEmitter();
  public service:ShaderReplacementsService;

  constructor() { }

  ngOnInit() {
  }

  public init(config:any, service:ShaderReplacementsService) {
    this.service = service;
  }

  public onMaterialClick(s, m) {
    this.materialClick.emit({
      id: s,
      material: m
    });
  }


}
