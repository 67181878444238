import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaterialGroupsService {
  groups:Group[] = [];
  selectedGroup: Group = null;

  constructor() {
    this.newGroup();
  }

  public newGroup() {
    var id = 0;
    this.groups.forEach((group) => { id = (group.id >= id) ? group.id+1 : id })
    var group = new Group(id);
    this.groups.push(group);
    this.selectedGroup = group;
  }

  public deleteGroupByReference(group) {
    this.groups = this.groups.filter((item) => item === group ? undefined : item);
  }

  public isSelected(group) {
    return (group === this.selectedGroup) ? true : false;
  }
}

export class Group {
  id:number;
  name:string;
  nodes:any[] = [];
  materials:any[] = [];
  colors:any[] = [];

  currentMaterial: any;

  constructor(id:number) {
    this.id = id;
    this.name = "Group" + id;
  }

  setNodes(nodes) {
    this.nodes = nodes;
  }

  setMaterials(materials) {
    this.materials = materials;
    this.currentMaterial = materials[0];
  }

  setColors(colors) {
    this.colors = colors;
  }


}